import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { Control, useWatch } from 'react-hook-form';
import { DeviceAlertComparisonType } from 'src/__generated__/graphql';
import { FormInputText, FormSelect } from 'src/components/form';
import { FormProps } from '../utils/alertAdvancedCreationForm';
import { AlertState } from '../utils/alertStates';

const colorsHue = {
  ok: '#2A3D44',
  critical: '#402634',
  warning: '#3E322F',
};

const AlertStateRow = (props: { control: Control<FormProps>; alertState: AlertState }) => {
  const configs: Record<
    AlertState,
    {
      backgroundColor: string;
      title: string;
      formName: 'ok' | 'warning' | 'critical';
    }
  > = {
    [AlertState.OK]: {
      title: 'OK',
      backgroundColor: colorsHue.ok,
      formName: 'ok',
    },
    [AlertState.WARNING]: {
      title: 'WARNING',
      backgroundColor: colorsHue.warning,
      formName: 'warning',
    },
    [AlertState.CRITICAL]: {
      title: 'CRITICAL',
      backgroundColor: colorsHue.critical,
      formName: 'critical',
    },
  };
  const { alertState, control } = props;

  const { backgroundColor, title, formName } = configs[alertState];

  const watch = useWatch({ control });
  const comparisonType = watch[formName]?.comparisonType;
  const criteriaOptions = [
    {
      label: 'Sobre el valor',
      value: DeviceAlertComparisonType.Above,
    },
    {
      label: 'Bajo el valor',
      value: DeviceAlertComparisonType.Below,
    },
    {
      label: 'Entre los valores',
      value: DeviceAlertComparisonType.InsideRange,
    },
    {
      label: 'Fuera de los valores',
      value: DeviceAlertComparisonType.OutsideRange,
    },
  ];
  return (
    <TableRow sx={{ backgroundColor }}>
      <TableCell align="left">
        <Typography variant="h6">{title}</Typography>
      </TableCell>
      <TableCell align="left">
        <FormInputText control={control} name={`${formName}.eventName`} label="Nombre" />
      </TableCell>

      <TableCell align="left">
        <FormSelect
          control={control}
          name={`${formName}.comparisonType`}
          options={criteriaOptions}
          label="Criterio"
        />
      </TableCell>
      <TableCell>
        {comparisonType != null &&
          [DeviceAlertComparisonType.OutsideRange, DeviceAlertComparisonType.InsideRange].includes(
            comparisonType,
          ) && (
            <Box display="flex" gap={2}>
              <FormInputText
                control={control}
                name={`${formName}.thresholdMin`}
                type="number"
                label="Mínimo"
              />
              <FormInputText
                control={control}
                name={`${formName}.thresholdMax`}
                type="number"
                label="Máximo"
              />
            </Box>
          )}
        {comparisonType != null &&
          [DeviceAlertComparisonType.Above, DeviceAlertComparisonType.Below].includes(
            comparisonType,
          ) && (
            <FormInputText
              control={control}
              name={`${formName}.thresholdValue`}
              type="number"
              label="Valor"
            />
          )}
      </TableCell>
    </TableRow>
  );
};

export { AlertStateRow };
