import { createSvgIcon } from '@mui/material';

const SensorVelocidadSumergido = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M1.78,150.12c0-47.84,0-95.68,0.01-143.52c0-0.64,0-1.29,0.14-1.9c0.35-1.56,1.34-2.52,2.93-2.77
		C5.6,1.81,6.37,1.8,7.12,1.8c42.52-0.01,85.04,0,127.56,0c52.92,0,105.84,0,158.75,0.01c0.76,0,1.53,0,2.27,0.14
		c1.53,0.28,2.47,1.24,2.83,2.74c0.1,0.42,0.12,0.87,0.16,1.31c0.02,0.32,0.01,0.64,0.01,0.96c0,95.44,0,190.88,0,286.32
		c0,0.68,0,1.36-0.11,2.03c-0.28,1.81-1.58,2.99-3.4,3.16c-0.4,0.04-0.8,0.01-1.2,0.01c-95.84,0-191.67-0.01-287.51,0.03
		c-3.14,0-4.73-1.65-4.73-4.74C1.8,245.88,1.78,198,1.78,150.12z M9.23,290.98c1.78,0.27,281.09,0.18,281.97-0.07
		c0.04-0.1,0.11-0.21,0.11-0.32c0.01-93.4,0.02-186.8,0.03-280.19c0-0.32-0.05-0.63-0.09-0.95c0-0.03-0.04-0.06-0.07-0.09
		c-0.03-0.03-0.06-0.06-0.11-0.1c-0.09-0.02-0.21-0.08-0.32-0.08C197.28,9.17,103.8,9.16,10.33,9.16c-0.28,0-0.55,0.04-0.83,0.07
		c-0.04,0-0.07,0.03-0.11,0.05C9.36,9.3,9.33,9.33,9.23,9.43C9.23,103.21,9.23,197.06,9.23,290.98z"
    />
    <path
      d="M52.32,125.86c1.13,0.26,2.05-0.03,2.97-0.28c2.1-0.58,4.04-1.52,5.88-2.66c2.48-1.53,4.93-3.12,7.37-4.72
		c2.85-1.86,5.72-3.65,8.82-5.09c5.56-2.58,11.17-2.74,16.87-0.53c2.92,1.13,5.67,2.58,8.38,4.11c2.54,1.43,5.05,2.92,7.64,4.26
		c1.66,0.86,3.41,1.58,5.17,2.22c2.92,1.06,5.86,1.01,8.81,0.02c2.55-0.85,4.94-2.04,7.3-3.31c2.57-1.37,5.12-2.79,7.72-4.12
		c2.53-1.3,5.16-2.34,7.94-2.98c4.02-0.93,7.94-0.51,11.79,0.83c2.84,0.99,5.51,2.34,8.13,3.8c2.9,1.62,5.76,3.3,8.68,4.86
		c1.47,0.78,3.04,1.4,4.6,2.01c2.99,1.17,6.04,1.17,9.02,0.05c2.05-0.77,4.07-1.65,6.02-2.65c3.13-1.6,6.17-3.38,9.29-4.99
		c3.14-1.62,6.42-2.87,9.95-3.33c0.83-0.11,1.66-0.16,2.63-0.25c0.04-0.56,0.1-1.1,0.1-1.64c0.01-12.23,0-24.46,0-36.69
		c0-0.4-0.01-0.8,0-1.2c0.06-2.27,1.52-3.73,3.79-3.75c3.24-0.02,6.48,0,9.72,0c5.48,0,10.96,0,16.44,0c0.47,0,0.94,0,1.56,0
		c0-0.61,0-1.08,0-1.55c0-7.83,0-15.67,0.01-23.5c0-0.63,0.04-1.29,0.23-1.89c0.53-1.68,2.2-2.67,3.94-2.42
		c1.78,0.26,3.06,1.62,3.17,3.38c0.02,0.28,0.01,0.56,0.01,0.84c0.02,18.46,0.06,36.93,0.07,55.39c0.01,49.91,0,99.83,0,149.74
		c0,2.04-0.03,4.08-0.07,6.11c-0.04,2.23-1.55,3.71-3.81,3.74c-0.32,0-0.64,0-0.96,0c-67.23,0-134.46,0-201.7,0
		c-0.4,0-0.8,0.01-1.2,0c-2.03-0.06-3.53-1.53-3.64-3.56c-0.02-0.36-0.01-0.72-0.01-1.08c0-70.02,0-140.03,0.01-210.05
		c0-0.59,0-1.21,0.14-1.78c0.42-1.78,2.04-2.93,3.83-2.76c1.88,0.17,3.25,1.56,3.36,3.45c0.03,0.52,0.02,1.04,0.02,1.56
		c0,26.26,0,52.51,0,78.77C52.32,124.77,52.32,125.32,52.32,125.86z M217.33,120.52c-0.82,0.07-1.5,0.09-2.16,0.2
		c-2.43,0.4-4.7,1.3-6.86,2.43c-2.8,1.45-5.53,3.05-8.34,4.48c-2.38,1.21-4.79,2.39-7.28,3.37c-3.75,1.47-7.65,1.94-11.65,1.1
		c-2.68-0.56-5.23-1.5-7.63-2.78c-3.07-1.63-6.09-3.35-9.12-5.04c-2.58-1.44-5.19-2.83-8-3.8c-3.1-1.07-6.19-1.13-9.27,0.01
		c-1.38,0.51-2.78,1.03-4.09,1.7c-2.74,1.39-5.44,2.88-8.15,4.33c-2.54,1.36-5.12,2.62-7.85,3.55c-3.67,1.24-7.4,1.64-11.22,0.83
		c-2.67-0.57-5.23-1.49-7.64-2.77c-3.21-1.7-6.35-3.54-9.56-5.24c-2.22-1.18-4.48-2.29-6.77-3.31c-1.53-0.69-3.17-1.06-4.87-1.16
		c-2.61-0.14-5,0.63-7.24,1.81c-2.33,1.23-4.57,2.63-6.81,4.01c-3.37,2.06-6.67,4.26-10.1,6.22c-2.93,1.67-6.11,2.76-9.54,2.91
		c-0.26,0.01-0.52,0.14-0.77,0.2c0,39.58,0,79.09,0,118.68c37.29,0,74.5,0,111.85,0c0-0.55,0-1.02,0-1.48
		c0-6.51,0.04-13.03-0.02-19.54c-0.02-2.49,1.78-4.13,4.1-4.12c10.96,0.07,21.92,0.03,32.88,0.03c0.2,0,0.4,0,0.6,0
		c1.68,0.07,3.05,1.21,3.38,2.87c0.11,0.58,0.15,1.19,0.15,1.79c0.01,6.35,0.01,12.71,0.01,19.06c0,0.46,0.04,0.92,0.06,1.4
		c4.04,0,7.94,0,11.93,0C217.33,208.36,217.33,164.54,217.33,120.52z M224.85,221.16c0.54,0,1.01,0,1.48,0
		c3.04,0,6.08-0.03,9.12,0.01c2.81,0.04,4.6,2.7,3.49,5.17c-0.65,1.44-1.82,2.18-3.43,2.18c-3.12,0-6.24,0-9.36,0.01
		c-0.42,0-0.85,0.04-1.31,0.06c0,7.95,0,15.77,0,23.57c1.2,0.26,23.05,0.23,24-0.03c0-41.45,0-82.91,0-124.46
		c-1.06-0.08-2.05-0.11-3.03-0.22c-3.39-0.38-6.68-1.21-9.91-2.26c-3.3-1.08-6.6-2.15-9.91-3.22c-0.35-0.11-0.73-0.15-1.21-0.25
		c0,0.52,0,0.88,0,1.23c0.02,10.83,0.05,21.66,0.07,32.48c0,0.03,0.04,0.07,0.06,0.09c0.03,0.03,0.06,0.05,0.19,0.16
		c0.34,0,0.77,0,1.21,0c3.12,0,6.24-0.02,9.36,0.01c1.82,0.02,3.31,1.37,3.55,3.13c0.25,1.85-0.82,3.53-2.62,4.04
		c-0.49,0.14-1.02,0.17-1.54,0.17c-2.96,0.02-5.92,0.01-8.88,0.01c-0.43,0-0.85,0-1.25,0c-0.26,1.51-0.2,25.36,0.07,26.36
		c0.39,0,0.82,0,1.25,0c2.08,0,4.16,0,6.24,0c1.16,0,2.32-0.03,3.48,0.04c1.7,0.11,3.06,1.47,3.25,3.15
		c0.2,1.75-0.84,3.46-2.47,3.93c-0.57,0.16-1.19,0.18-1.78,0.19c-2.88,0.02-5.76,0.01-8.64,0.01c-0.47,0-0.94,0-1.49,0
		C224.85,204.9,224.85,212.92,224.85,221.16z M224.86,77.24c0,5.9,0,11.66,0,17.55c0.52,0,0.95,0,1.38,0c3,0,6,0,9,0
		c1.45,0,2.66,0.5,3.46,1.77c1.49,2.4-0.15,5.53-2.98,5.57c-3.2,0.05-6.4,0.01-9.6,0.02c-0.42,0-0.84,0.04-1.27,0.07
		c0,4.01,0,7.87,0,11.7c0.18,0.1,0.27,0.17,0.38,0.21c2.94,0.9,5.89,1.76,8.81,2.71c3.46,1.12,6.88,2.34,10.47,2.98
		c1.4,0.25,2.81,0.54,4.24,0.33c0.28-1.36,0.22-42.02-0.07-42.91C240.8,77.24,232.89,77.24,224.86,77.24z M171.73,234.54
		c-0.26,1.7-0.18,16.82,0.06,17.67c1.62,0.24,25.22,0.15,26.05-0.1c0.24-1.42,0.18-16.67-0.09-17.58
		C189.11,234.54,180.45,234.54,171.73,234.54z"
    />
    <path
      d="M111.4,194.95c-0.47,0-0.93,0-1.4,0c-15.99,0-31.99,0-47.98,0c-0.36,0-0.72-0.03-1.08,0
		c-1.83,0.16-3.73-1.38-3.79-3.5c-0.06-2.09,1.44-3.71,3.61-3.85c0.4-0.03,0.8-0.01,1.2-0.01c16.03,0,32.07,0,48.1,0
		c0.46,0,0.92,0,1.67,0c-0.49-0.54-0.76-0.86-1.06-1.15c-2.37-2.38-4.73-4.76-7.11-7.13c-0.76-0.76-1.32-1.6-1.36-2.71
		c-0.06-1.54,0.54-2.74,1.91-3.47c1.36-0.73,2.7-0.59,3.95,0.29c0.26,0.18,0.49,0.41,0.71,0.64c4.77,4.79,9.5,9.61,14.31,14.35
		c1.67,1.64,1.59,4.22,0.03,5.75c-4.85,4.76-9.6,9.61-14.39,14.43c-0.63,0.64-1.33,1.13-2.25,1.26c-1.63,0.23-3.1-0.46-3.85-1.84
		c-0.72-1.34-0.55-3.09,0.52-4.24c1.12-1.2,2.29-2.34,3.45-3.5c1.35-1.36,2.72-2.71,4.07-4.07c0.3-0.31,0.59-0.63,0.88-0.95
		C111.48,195.14,111.44,195.05,111.4,194.95z"
    />
    <path
      d="M86.96,154.67c-0.41-0.43-0.82-0.86-1.23-1.28c-2.28-2.3-4.56-4.6-6.86-6.88c-1.01-1-1.51-2.17-1.21-3.58
		c0.59-2.77,3.87-3.86,6.03-2.01c0.21,0.18,0.4,0.39,0.6,0.59c4.73,4.76,9.46,9.53,14.21,14.28c1.23,1.23,1.65,2.63,1.01,4.25
		c-0.2,0.5-0.56,0.97-0.95,1.36c-4.81,4.86-9.64,9.69-14.44,14.55c-1.16,1.17-2.5,1.58-4.04,1.1c-2.47-0.77-3.3-3.88-1.59-5.87
		c0.57-0.67,1.22-1.27,1.84-1.89c1.97-1.98,3.95-3.96,5.92-5.95c0.25-0.25,0.47-0.52,0.88-0.98c-0.64-0.02-1.05-0.05-1.46-0.05
		c-8.08,0-16.15,0-24.23-0.01c-0.55,0-1.13-0.02-1.66-0.17c-1.76-0.5-2.81-2.11-2.62-3.93c0.18-1.76,1.64-3.14,3.45-3.24
		c0.36-0.02,0.72-0.01,1.08-0.01c7.96,0,15.91,0,23.87,0c0.43,0,0.86,0,1.29,0C86.89,154.87,86.93,154.77,86.96,154.67z"
    />
    <path
      d="M86.94,218.99c-1.51-1.53-3.02-3.06-4.54-4.59c-1.21-1.22-2.43-2.43-3.64-3.65c-0.93-0.94-1.37-2.07-1.1-3.39
		c0.28-1.4,1.11-2.37,2.49-2.82c1.33-0.43,2.52-0.14,3.57,0.76c0.18,0.15,0.34,0.33,0.51,0.5c4.71,4.73,9.42,9.46,14.14,14.18
		c0.7,0.7,1.27,1.45,1.4,2.45c0.18,1.32-0.27,2.43-1.21,3.36c-1.59,1.58-3.16,3.17-4.74,4.76c-3.25,3.26-6.5,6.5-9.74,9.77
		c-1,1.01-2.15,1.5-3.57,1.19c-2.63-0.57-3.78-3.53-2.23-5.72c0.25-0.36,0.57-0.67,0.88-0.98c2.28-2.3,4.57-4.59,6.85-6.88
		c0.3-0.31,0.59-0.63,0.9-0.97c-0.4-0.45-0.86-0.29-1.27-0.29c-4.12-0.01-8.24-0.01-12.36-0.01c-3.88,0-7.76,0.01-11.64-0.01
		c-0.64,0-1.29,0-1.91-0.15c-1.57-0.39-2.64-1.93-2.61-3.6c0.03-1.67,1.17-3.12,2.78-3.45c0.58-0.12,1.19-0.14,1.79-0.14
		c7.96-0.01,15.92-0.01,23.87-0.01c0.43,0,0.85,0,1.28,0C86.89,219.2,86.92,219.09,86.94,218.99z"
    />
    <path
      d="M123.62,218.91c-2.77-0.02-4.52-2.75-3.42-5.19c0.16-0.36,0.38-0.7,0.61-1.03
		c7.65-11.17,18.15-17.98,31.46-20.48c2.06-0.39,3.92,0.95,4.31,3.03c0.35,1.88-0.93,3.75-2.89,4.2c-1.36,0.31-2.74,0.55-4.08,0.93
		c-9.52,2.72-17.07,8.17-22.62,16.36c-0.7,1.04-1.5,1.87-2.76,2.12C123.98,218.91,123.74,218.9,123.62,218.91z"
    />
    <path
      d="M184.35,185.83c6.48,0.09,12.7,1.34,18.66,3.87c1,0.42,1.81,1.01,2.27,2.01c0.62,1.35,0.37,2.94-0.62,4.02
		c-1.03,1.13-2.62,1.44-4.19,0.9c-2.22-0.77-4.42-1.65-6.7-2.21c-8.49-2.11-16.8-1.39-24.9,1.9c-0.69,0.28-1.44,0.52-2.18,0.56
		c-1.7,0.08-3.07-1.01-3.54-2.66c-0.44-1.54,0.19-3.2,1.57-4.06c0.4-0.25,0.85-0.44,1.29-0.63c4.58-1.88,9.31-3.1,14.26-3.45
		C181.64,185.99,182.99,185.92,184.35,185.83z"
    />
    <path
      d="M132.71,221.46c-0.05-0.89,0.44-1.7,1.03-2.48c3.35-4.4,7.49-7.85,12.53-10.13c2.02-0.91,4.18-1.55,6.29-2.25
		c0.92-0.31,1.88-0.31,2.81,0.1c1.39,0.61,2.29,2.03,2.22,3.53c-0.08,1.52-1.06,2.84-2.52,3.32c-0.57,0.19-1.16,0.3-1.73,0.46
		c-5.62,1.65-10.22,4.79-13.75,9.47c-1.03,1.36-2.28,2.1-4.01,1.73C133.82,224.83,132.7,223.39,132.71,221.46z"
    />
    <path
      d="M184.71,200.04c3.67,0.06,7.55,0.68,11.28,2.1c1.79,0.68,2.74,2.16,2.54,3.88c-0.28,2.39-2.47,3.8-4.8,3.11
		c-1.57-0.46-3.13-0.95-4.73-1.27c-4.57-0.92-9.06-0.45-13.44,1.1c-0.85,0.3-1.69,0.48-2.58,0.26c-1.54-0.38-2.66-1.69-2.78-3.23
		c-0.13-1.64,0.7-3.14,2.25-3.72c1.57-0.58,3.18-1.07,4.8-1.45C179.61,200.29,182,200.05,184.71,200.04z"
    />
    <path
      d="M160,223.01c0.03,1.64-1.01,2.99-2.65,3.59c-1.15,0.43-2.31,0.89-3.39,1.46c-1.39,0.73-2.52,1.81-3.51,3.03
		c-1,1.22-2.28,1.75-3.83,1.37c-2.61-0.64-3.72-3.66-2.03-5.77c1.09-1.36,2.39-2.57,3.73-3.69c1.99-1.65,4.3-2.74,6.79-3.42
		C158.16,218.76,159.95,220.97,160,223.01z"
    />
    <path
      d="M175.49,217.79c0.02-1.59,0.93-2.86,2.51-3.43c2.2-0.79,4.5-1.11,6.83-1.06c2.08,0.04,4.14,0.33,6.11,1.07
		c1.88,0.7,2.86,2.55,2.36,4.4c-0.53,1.97-2.46,3.09-4.46,2.61c-0.85-0.2-1.7-0.41-2.57-0.57c-1.93-0.36-3.82-0.12-5.67,0.44
		c-0.86,0.26-1.7,0.38-2.58,0.07C176.46,220.8,175.47,219.43,175.49,217.79z"
    />
  </svg>,
  'SensorVelocidadSumergido',
);

export default SensorVelocidadSumergido;
