import { useSuspenseQuery } from '@apollo/client';
import { DeviceTabProps } from '@features/deviceDataConfigurationModal/components/tabs/types';
import GET_DEVICE_SENSOR from '@features/deviceDataConfigurationModal/graphql/queries/getDeviceSensor';
import { Trans } from '@lingui/macro';
import { DialogContent, Typography } from '@mui/material';
import { type FC } from 'react';
import LGCalibrationDisplay from './LGCalibrationDisplay';
import LevelCalibrationForm from './LevelCalibrationForm';
import PositionFromAngleCalibrationForm from './PositionFromAngleCalibrationForm';

const CalibrationTab: FC<DeviceTabProps> = (props) => {
  const { data } = useSuspenseQuery(GET_DEVICE_SENSOR, {
    variables: { deviceId: props.deviceId },
  });

  if (!data.device.profile.sensor)
    return (
      <DialogContent
        sx={{
          maxWidth: 'md',
          minHeight: 500,
          margin: 'auto',
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <Typography>
          <Trans>El dispositivo no tiene sensor configurado</Trans>
        </Typography>
      </DialogContent>
    );

  const showLevelCalibration = data.device.profile.sensor?.includes('L') ?? false;
  const showPositionFromAngleCalibration = data.device.profile.sensor?.includes('G') ?? false;

  if (showLevelCalibration && !showPositionFromAngleCalibration)
    return <LevelCalibrationForm {...props} />;

  if (showPositionFromAngleCalibration && !showLevelCalibration) {
    return <PositionFromAngleCalibrationForm {...props} />;
  }
  if (showLevelCalibration && showPositionFromAngleCalibration)
    return <LGCalibrationDisplay {...props} />;

  return (
    <DialogContent
      sx={{
        maxWidth: 'md',
        minHeight: 500,
        margin: 'auto',
        borderBottom: '1px solid',
        borderColor: 'divider',
        pb: 0,
      }}
    >
      <Typography>
        <Trans>No hay calibraciones para este dispositivo</Trans>
      </Typography>
    </DialogContent>
  );
};

export default CalibrationTab;
