import { createSvgIcon } from '@mui/material';

const HousingStandardIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 600">
    <path
      d="M1.97,4.95c0.09-1.97,1.4-3.37,3.36-3.59C5.84,1.3,294.36,1.3,294.99,1.39c1.76,0.25,2.96,1.51,3.12,3.27
		c0.06,0.63,0.06,529.74,0.02,530.3c-0.13,2.02-1.5,3.41-3.51,3.57c-0.36,0.03-289.28-0.01-289.89-0.16
		c-1.58-0.38-2.61-1.62-2.74-3.24C1.94,534.57,1.95,5.47,1.97,4.95z M290.59,531.14c0.1-0.19,0.15-0.26,0.16-0.33
		c0.03-0.16,0.06-0.32,0.06-0.47c0-173.54,0.01-347.07,0-520.61c0-0.24-0.04-0.48-0.07-0.71c0-0.04-0.03-0.07-0.05-0.1
		c-0.02-0.03-0.05-0.06-0.08-0.09c-0.07-0.03-0.14-0.09-0.21-0.1c-0.2-0.03-0.4-0.04-0.6-0.04c-93.24,0-186.49,0-279.73,0.01
		c-0.16,0-0.32,0.04-0.47,0.07C9.57,8.76,9.54,8.8,9.5,8.82c-0.03,0.02-0.06,0.05-0.12,0.1C9.36,9.05,9.32,9.2,9.32,9.36
		c0,173.7,0,347.39,0,521.09c0,0.16,0.04,0.31,0.07,0.47c0.01,0.03,0.04,0.06,0.07,0.09c0.03,0.03,0.06,0.05,0.14,0.13
		C103.22,531.14,196.89,531.14,290.59,531.14z"
    />
    <path
      d="M150.91,594.49c-2.18,0-4.15,0-6.2,0c0-12.5,0-24.91,0-37.48c4.05-0.1,8.1-0.05,12.17-0.04
		c0.21,0.74,0.4,1.34,0.56,1.96c2.67,10.03,5.34,20.06,8.01,30.09c0.07,0.27,0.15,0.54,0.23,0.81c0.14,0.51,0.47,0.58,1.04,0.35
		c0-10.97,0-21.98,0-32.96c1.06-0.35,5.22-0.36,6.31-0.05c0,12.36,0,24.74,0,37.26c-2.02,0.3-4.04,0.09-6.05,0.13
		c-2.03,0.04-4.06,0.01-6.14,0.01c-0.53-1.97-1.04-3.81-1.53-5.65c-2.34-8.8-4.68-17.59-7.02-26.39c-0.07-0.27-0.16-0.54-0.23-0.81
		c-0.1-0.36-0.35-0.59-0.69-0.47c-0.19,0.07-0.38,0.37-0.42,0.6c-0.07,0.39-0.03,0.8-0.03,1.2c0,9.87,0,19.74,0,29.62
		C150.91,593.22,150.91,593.77,150.91,594.49z"
    />
    <path
      d="M179.43,594.49c0-1.89,0-3.59,0-5.44c1.62,0,3.2,0,4.92,0c0.19-3.02,0.07-5.92,0.09-8.81
		c0.02-2.92,0-5.83,0-8.75c0-2.91,0-5.82,0-8.84c-1.66-0.2-3.26-0.01-4.88-0.13c-0.33-0.89-0.13-1.81-0.16-2.7
		c-0.03-0.87-0.01-1.75-0.01-2.75c0.44-0.04,0.82-0.11,1.2-0.11c4.79-0.01,9.59-0.06,14.38,0.02c1.74,0.03,3.52,0.23,5.21,0.64
		c5.47,1.32,8.63,4.88,9.6,10.39c0.23,1.29,0.32,2.62,0.33,3.93c0.03,3.15,0,6.31-0.06,9.46c-0.03,1.73-0.42,3.41-1.04,5.02
		c-1.57,4.1-4.63,6.53-8.82,7.51c-1.66,0.39-3.39,0.58-5.09,0.61c-4.79,0.08-9.59,0.03-14.38,0.02
		C180.31,594.57,179.92,594.52,179.43,594.49z M190.89,562.79c0,8.74,0,17.31,0,26.01c2.35-0.11,4.62,0.23,6.86-0.3
		c2.7-0.63,4.56-2.22,5.41-4.89c0.24-0.75,0.45-1.55,0.49-2.33c0.1-2.43,0.14-4.87,0.15-7.3c0-1.36-0.04-2.72-0.17-4.07
		c-0.37-3.59-2.47-6.07-5.75-6.84C195.59,562.55,193.28,562.8,190.89,562.79z"
    />
    <path
      d="M272.44,577.15c0.29,0.05,0.57,0.08,0.84,0.14c1.91,0.44,3.21,1.87,3.43,3.83c0.05,0.4,0.05,0.8,0.05,1.2
		c0,3.64,0,7.27,0,10.91c0,0.39-0.04,0.78-0.07,1.25c-2.06,0-4.03,0-6.13,0c-0.03-0.47-0.07-0.89-0.07-1.32
		c-0.01-3.08,0-6.15-0.01-9.23c0-0.48-0.02-0.96-0.1-1.43c-0.26-1.59-1.15-2.39-2.76-2.44c-1.28-0.04-2.56-0.01-3.84-0.01
		c-1.96,0-3.92,0-5.87,0c-0.39,0-0.78,0-1.31,0c0,4.85,0,9.58,0,14.42c-2.19,0.15-4.23,0.15-6.34,0.01c0-12.51,0-24.92,0-37.45
		c0.46-0.03,0.88-0.08,1.31-0.08c4.83-0.01,9.67-0.02,14.5,0c2.01,0.01,3.99,0.22,5.86,1.03c3.85,1.67,5.99,4.53,6.03,8.8
		c0.01,1,0,2.01-0.16,2.98c-0.48,2.86-2.27,4.77-4.75,6.1C272.56,576.14,272.06,576.33,272.44,577.15z M256.62,574.2
		c0.47,0.04,0.83,0.09,1.18,0.09c2.51,0.01,5.03,0.01,7.54,0c0.64,0,1.28-0.07,1.9-0.17c2.28-0.38,3.98-2.06,4.28-4.24
		c0.09-0.71,0.12-1.43,0.06-2.14c-0.22-2.69-1.8-4.4-4.44-4.87c-0.47-0.08-0.95-0.09-1.43-0.09c-2.67-0.01-5.34-0.01-8.02,0
		c-0.34,0-0.68,0.06-1.07,0.1C256.62,566.66,256.62,570.36,256.62,574.2z"
    />
    <path
      d="M49.34,580.96c1.99-0.05,4-0.03,6.09,0c0.05,0.41,0.13,0.72,0.11,1.02c-0.21,4.25,2.04,6.99,6.55,7.64
		c2.08,0.3,4.16,0.2,6.12-0.63c1.94-0.82,3.18-2.24,3.38-4.41c0.17-1.84-0.46-3.33-2.11-4.23c-0.97-0.53-2.03-0.95-3.09-1.25
		c-2.18-0.62-4.42-1.07-6.6-1.7c-1.37-0.4-2.73-0.91-4.02-1.53c-4.69-2.26-6.15-5.7-5.5-10.71c0.47-3.62,2.58-6.11,5.88-7.59
		c2.68-1.21,5.53-1.51,8.43-1.32c1.84,0.12,3.65,0.43,5.36,1.15c4.36,1.82,6.75,5.07,7.02,9.82c0.05,0.87,0.01,1.74,0.01,2.69
		c-2.06,0-4.04,0-6.16,0c-0.02-0.4-0.06-0.83-0.06-1.25c-0.04-4.14-1.7-6.12-5.77-6.73c-1.79-0.27-3.59-0.19-5.33,0.41
		c-2.54,0.88-3.76,3.14-3.12,5.69c0.33,1.29,1.13,2.24,2.3,2.76c1.3,0.58,2.67,1.06,4.05,1.41c2.78,0.7,5.6,1.28,8.26,2.41
		c1.48,0.63,2.86,1.41,4.05,2.51c1.67,1.54,2.52,3.46,2.69,5.7c0.5,6.37-3.34,9.86-7.31,11.39c-2.62,1.01-5.34,1.27-8.12,1.09
		c-2.04-0.13-4.03-0.53-5.92-1.36C51.51,591.74,48.7,587.01,49.34,580.96z"
    />
    <path
      d="M129.38,557.02c3.42,12.45,6.82,24.86,10.24,37.34c-0.33,0.09-0.55,0.19-0.76,0.19
		c-1.64,0.02-3.27,0-4.91,0.02c-0.62,0.01-0.92-0.23-1.07-0.85c-0.52-2.13-1.1-4.25-1.66-6.37c-0.11-0.42-0.25-0.83-0.39-1.29
		c-4.7,0-9.36,0-14.13,0c-0.74,2.77-1.47,5.54-2.24,8.4c-2.15,0.22-4.28,0.07-6.51,0.08c0.12-0.54,0.18-0.92,0.29-1.29
		c3.2-11.7,6.41-23.39,9.62-35.08c0.33-1.21,0.34-1.21,1.62-1.21c2.88,0,5.75,0,8.63,0C128.49,556.96,128.89,556.99,129.38,557.02z
		 M129.27,580.08c-0.06-0.35-0.08-0.58-0.14-0.8c-1.54-5.82-3.08-11.65-4.62-17.47c-0.05-0.19-0.07-0.42-0.19-0.56
		c-0.14-0.16-0.38-0.32-0.57-0.31c-0.19,0.01-0.43,0.18-0.53,0.35c-0.14,0.23-0.18,0.52-0.25,0.79c-1.45,5.48-2.9,10.95-4.34,16.43
		c-0.13,0.49-0.24,0.98-0.39,1.57C121.95,580.08,125.53,580.08,129.27,580.08z"
    />
    <path
      d="M245.23,594.5c-2.27,0-4.33,0-6.55,0c-0.74-2.78-1.49-5.58-2.25-8.43c-4.71,0-9.37,0-14.17,0
		c-0.73,2.75-1.47,5.52-2.24,8.39c-2.12,0.2-4.25,0.11-6.57,0.05c3.45-12.58,6.86-25.03,10.28-37.5c3.78,0,7.44,0,11.2,0
		C238.37,569.47,241.77,581.89,245.23,594.5z M223.86,580.07c3.73,0,7.3,0,10.95,0c-0.05-0.34-0.05-0.62-0.12-0.88
		c-1.53-5.78-3.06-11.56-4.6-17.34c-0.11-0.4-0.09-0.92-0.71-0.95c-0.64-0.03-0.62,0.5-0.76,0.89c-0.03,0.07-0.05,0.15-0.07,0.23
		c-1.5,5.67-3,11.33-4.49,17C223.98,579.32,223.94,579.64,223.86,580.07z"
    />
    <path
      d="M44.84,562.81c-5.92,0-11.62,0-17.39,0c0,3.32,0,6.54,0,9.92c5.31,0,10.56,0,15.87,0c0,1.98,0,3.81,0,5.8
		c-1.31,0.01-2.61,0.03-3.91,0.03c-1.32,0.01-2.64,0-3.96,0c-1.32,0-2.64,0-3.96,0c-1.31,0-2.63,0-4.03,0c0,3.45,0,6.78,0,10.2
		c5.87,0,11.66,0,17.4,0c0.36,0.94,0.41,4.34,0.12,5.61c-0.91,0.24-22.44,0.3-23.79,0.07c-0.28-0.83-0.35-35.99-0.07-37.3
		c0.92-0.23,22.31-0.27,23.73-0.01C44.88,558.93,44.88,560.78,44.84,562.81z"
    />
    <path
      d="M97.62,594.48c-2.16,0-4.13,0-6.25,0c0-10.57,0-21.05,0-31.7c-3.79-0.05-7.44,0-11.26-0.03
		c-0.05-1.94-0.04-3.78,0-5.61c1.19-0.27,27.71-0.25,28.73,0.01c0,1.81,0,3.63,0,5.59c-3.72,0-7.37,0-11.19,0
		C97.61,573.37,97.64,583.85,97.62,594.48z"
    />
    <path
      d="M120.06,553.72c0.11-0.32,0.15-0.51,0.24-0.68c0.99-1.82,1.99-3.63,2.97-5.45c0.2-0.38,0.43-0.64,0.91-0.63
		c2.07,0.01,4.13,0.01,6.35,0.01c-0.2,0.36-0.31,0.6-0.46,0.8c-1.37,1.76-2.76,3.5-4.1,5.28c-0.38,0.5-0.79,0.7-1.41,0.68
		C123.1,553.7,121.63,553.72,120.06,553.72z"
    />
    <path
      d="M146.7,44.86c27.25,0,54.51-0.02,81.76,0.02c7.92,0.01,14.05,3.51,18.37,10.11c2.31,3.53,3.29,7.48,3.29,11.71
		c-0.02,39.08-0.02,119.65-0.01,120.85c0.11,9.64-6.79,19.25-17.91,21.23c-1.17,0.21-112.91,0.32-167.58,0.3
		c-10.59,0-19.59-7.76-21.2-18.21c-0.18-1.18-0.25-83.98-0.29-124.19c-0.01-9.41,5.84-17.44,14.07-20.49
		c2.5-0.93,5.07-1.34,7.74-1.34c15.39,0.02,30.77,0.01,46.16,0.01C122.97,44.86,134.84,44.86,146.7,44.86z M242.77,121.52
		c-64.13,0-128.13,0-192.27,0c0,0.46,0.04,67.4,0.18,68.26c1.15,7.01,7.29,11.97,14.36,11.97c54.39-0.03,164.39-0.01,164.98-0.08
		c8.16-0.99,12.81-7.87,12.78-14.38C242.72,165.82,242.77,122.03,242.77,121.52z M169.31,59.89c-0.26,0.35-0.44,0.56-0.58,0.79
		c-3.82,5.9-7.46,11.92-10.68,18.17c-1.77,3.45-3.44,6.94-4.47,10.7c-0.65,2.39-0.78,4.8-0.31,7.24
		c1.76,9.14,10.39,14.49,18.67,13.13c9.09-1.48,15.3-10.45,13.33-19.44c-0.6-2.75-1.66-5.33-2.85-7.86
		c-3.62-7.72-8.04-14.98-12.64-22.14C169.67,60.3,169.53,60.16,169.31,59.89z M124.04,59.87c-0.3,0.42-0.53,0.69-0.71,0.99
		c-2.84,4.72-5.73,9.41-8.5,14.18c-2.18,3.77-4.12,7.67-5.64,11.76c-1.03,2.76-1.64,5.56-1.37,8.55
		c0.85,9.58,10.13,16.47,19.56,14.45c9.31-1.99,14.98-11.35,12.31-20.47c-0.71-2.44-1.68-4.83-2.76-7.14
		c-3.54-7.58-7.9-14.7-12.42-21.72C124.4,60.3,124.27,60.16,124.04,59.87z"
    />
    <path
      d="M79.9,404.17c0.57-2.57,1.04-5,1.67-7.39c1.28-4.9,3.2-9.54,5.94-13.81c5.97-9.27,14.38-14.78,25.33-16.3
		c6.86-0.96,13.64-0.59,20.24,1.59c9.89,3.26,16.64,9.97,20.81,19.41c1.15,2.6,2,5.3,2.64,8.07c0.07,0.3,0.17,0.6,0.25,0.89
		c1.35,0,2.62-0.03,3.89,0c4.56,0.11,8.73,1.5,12.45,4.09c7.62,5.29,12,12.55,12.97,21.82c0.1,0.95,0.16,1.91,0.25,2.99
		c0.31,0.12,0.67,0.27,1.03,0.41c5.39,2.08,8.11,6.13,8.5,11.79c0.11,1.58,0.04,3.2-0.2,4.77c-0.44,2.88-1.96,5.21-4.21,7.04
		c-2.83,2.29-6.04,3.73-9.67,4.13c-1.78,0.2-3.58,0.32-5.38,0.32c-32.29,0.02-64.58,0.01-96.87,0.02c-3.7,0-7.22-0.73-10.52-2.41
		c-5.42-2.75-8.84-7.16-10.42-12.95c-1.77-6.47-1.67-12.98,0.42-19.37c2.58-7.9,8.16-12.57,16.18-14.42
		c1.2-0.28,2.44-0.36,3.67-0.54C79.21,404.27,79.56,404.22,79.9,404.17z M128.54,446.64c15.79,0,31.57,0,47.36,0
		c1.16,0,2.32-0.01,3.47-0.09c2.21-0.15,4.31-0.69,6.24-1.87c2.02-1.23,3.16-2.88,2.98-5.31c-0.04-0.52-0.02-1.04-0.07-1.56
		c-0.26-2.5-1.48-4.27-3.94-5.04c-0.83-0.26-1.69-0.49-2.55-0.65c-2.31-0.43-3.57-2.02-3.29-4.34c0.2-1.64,0.19-3.26,0-4.9
		c-0.82-6.79-4.07-12.14-9.66-15.99c-4.29-2.96-9-3.74-14.04-2.18c-2.65,0.82-4.81-0.62-5.08-3.42c-0.43-4.3-1.63-8.38-3.55-12.24
		c-3.7-7.43-9.52-12.3-17.6-14.37c-4.38-1.12-8.83-1.33-13.32-0.92c-3.82,0.35-7.47,1.29-10.89,3.04
		c-5.34,2.73-9.22,6.93-12.07,12.13c-3.25,5.94-4.95,12.35-5.51,19.07c-0.19,2.27-1.85,3.85-4.05,3.62c-2.02-0.21-4-0.04-5.95,0.37
		c-5.74,1.2-9.61,4.53-11.24,10.22c-1.31,4.58-1.4,9.23-0.28,13.86c1.07,4.42,3.61,7.7,7.9,9.45c1.98,0.81,4.06,1.13,6.2,1.13
		C95.92,446.64,112.23,446.64,128.54,446.64z"
    />
    <path
      d="M230.76,312.87c-0.95,0.16-1.73,0.29-2.51,0.42c-5.66,0.92-11.34,1.27-17.07,0.93
		c-16.21-0.97-30.78-6.63-44.29-15.36c-5.92-3.83-11.43-8.18-16.53-13.05c-0.28-0.27-0.59-0.52-0.96-0.84
		c-0.39,0.35-0.74,0.66-1.08,0.98c-9.93,9.34-21.07,16.83-33.71,22.03c-7.16,2.95-14.58,4.93-22.28,5.85
		c-7.75,0.92-15.47,0.65-23.14-0.78c-0.31-0.06-0.62-0.09-1.1-0.16c-0.01,0.58-0.04,1.08-0.04,1.59c0,11.59,0,23.18,0,34.77
		c0,14.11,0,28.21-0.01,42.32c0,0.67-0.03,1.37-0.21,2.01c-0.5,1.73-2.23,2.79-3.99,2.53c-1.72-0.25-3.03-1.69-3.13-3.44
		c-0.02-0.32-0.01-0.64-0.01-0.96c0-27.57,0.04-55.15-0.05-82.72c-0.01-3.23,2.26-5.07,5.37-4.22c6.71,1.83,13.58,2.5,20.53,2.16
		c8.08-0.4,15.89-2.12,23.42-5.05c13.96-5.43,26.05-13.68,36.39-24.48c1.8-1.88,4.32-1.83,6.14,0.05
		c11.75,12.16,25.51,21.19,41.72,26.27c7.84,2.46,15.88,3.56,24.09,3.23c4.96-0.2,9.85-0.96,14.66-2.17
		c0.57-0.14,1.18-0.24,1.77-0.22c1.74,0.07,3.16,1.44,3.34,3.18c0.04,0.4,0.03,0.8,0.03,1.2c0,9.79,0,19.58,0,29.37
		c0,0.32,0.01,0.64-0.01,0.96c-0.11,1.97-1.61,3.44-3.57,3.49c-1.95,0.05-3.55-1.36-3.75-3.31c-0.04-0.4-0.02-0.8-0.02-1.2
		c0-7.95,0-15.9,0-23.86C230.76,313.92,230.76,313.46,230.76,312.87z"
    />
    <path
      d="M225.82,378.57c-0.07,16.13-10.67,31.64-27.65,36.65c-0.97,0.29-1.92,0.49-2.93,0.12
		c-1.46-0.55-2.4-1.82-2.43-3.37c-0.03-1.57,0.83-2.93,2.25-3.49c0.67-0.26,1.38-0.41,2.05-0.66c11.63-4.22,18.81-12.4,20.8-24.58
		c2.79-17.1-8.89-32.11-24.62-35.09c-16.94-3.21-32.95,7.67-36.25,24.65c-0.41,2.13-2.28,3.86-4.87,3.08
		c-1.82-0.54-2.77-2.38-2.38-4.41c0.86-4.56,2.44-8.86,4.89-12.79c6.13-9.83,14.87-15.91,26.3-17.88
		c20.71-3.58,40.22,10.09,44.07,30.78C225.48,373.87,225.57,376.23,225.82,378.57z"
    />
    <path
      d="M149.38,489.78c0.4-0.31,0.72-0.54,1.03-0.79c18.57-15.04,37.13-30.08,55.7-45.12
		c4.84-3.92,9.09-8.41,12.66-13.52c0.84-1.2,1.9-1.91,3.4-1.85c2.62,0.09,4.28,2.78,3.18,5.17c-0.18,0.4-0.44,0.76-0.69,1.12
		c-3.8,5.39-8.23,10.19-13.35,14.35c-19.71,15.98-39.43,31.95-59.14,47.92c-1.97,1.59-3.55,1.61-5.49,0.04
		c-12.58-10.18-25.15-20.37-37.73-30.55c-1.01-0.82-1.72-1.77-1.73-3.13c-0.01-1.45,0.61-2.56,1.86-3.26
		c1.25-0.69,2.53-0.65,3.76,0.1c0.41,0.25,0.77,0.57,1.14,0.87c11.43,9.25,22.85,18.51,34.28,27.76
		C148.6,489.16,148.94,489.43,149.38,489.78z"
    />
    <path
      d="M191.08,327.88c0,2.55,0.05,5.11-0.01,7.66c-0.07,2.84-3.16,4.48-5.56,3.01c-1.18-0.73-1.76-1.82-1.77-3.2
		c-0.01-2.35,0-4.71,0-7.06c0-2.63-0.02-5.26,0.01-7.9c0.01-1.68,0.93-3.01,2.36-3.55c2.4-0.9,4.88,0.78,4.97,3.38
		c0,0.08,0,0.16,0,0.24C191.08,322.94,191.08,325.41,191.08,327.88C191.08,327.88,191.08,327.88,191.08,327.88z"
    />
    <path
      d="M226.67,364.38c-1.73-0.12-3.17-1.19-3.6-2.79c-0.44-1.62,0.19-3.3,1.62-4.14c0.69-0.4,1.43-0.7,2.16-1.03
		c3.81-1.74,7.61-3.49,11.43-5.2c0.64-0.29,1.36-0.52,2.05-0.56c1.62-0.09,3.04,0.99,3.52,2.52c0.5,1.59-0.09,3.3-1.48,4.19
		c-0.33,0.21-0.7,0.38-1.06,0.55c-4.2,1.92-8.41,3.85-12.62,5.75C228.03,363.97,227.34,364.15,226.67,364.38z"
    />
    <path
      d="M148.26,364.35c-0.57-0.16-1.17-0.25-1.7-0.49c-4.51-2.03-9-4.08-13.49-6.15c-1.52-0.7-2.39-2.15-2.27-3.62
		c0.13-1.58,1.17-2.87,2.69-3.32c1.06-0.31,2.04-0.05,3.01,0.4c3.91,1.8,7.83,3.59,11.74,5.38c0.62,0.28,1.25,0.53,1.84,0.87
		c1.45,0.83,2.12,2.48,1.7,4.1C151.36,363.14,149.97,364.23,148.26,364.35z"
    />
    <path
      d="M235.03,416.35c0.03,1.64-0.6,2.74-1.85,3.44c-1.3,0.72-2.61,0.64-3.85-0.17c-0.27-0.17-0.51-0.39-0.75-0.6
		c-3.56-3.09-7.12-6.18-10.69-9.25c-0.99-0.86-1.61-1.85-1.57-3.21c0.08-2.76,3.08-4.45,5.47-3.07c0.31,0.18,0.59,0.41,0.86,0.65
		c3.63,3.14,7.24,6.28,10.88,9.41C234.44,414.34,235.06,415.26,235.03,416.35z"
    />
    <path
      d="M152.3,329.64c0-1.68,0.92-3.01,2.36-3.55c1.42-0.53,3.05-0.13,4.05,1.01c0.21,0.24,0.38,0.51,0.56,0.78
		c2.6,4.03,5.18,8.07,7.78,12.09c0.69,1.06,0.97,2.17,0.57,3.41c-0.88,2.68-4.29,3.43-6.19,1.35c-0.24-0.26-0.44-0.57-0.63-0.87
		c-2.55-3.96-5.1-7.93-7.65-11.89C152.66,331.21,152.24,330.44,152.3,329.64z"
    />
    <path
      d="M219.01,325.89c2.57,0.01,4.31,2.7,3.21,5.15c-0.11,0.25-0.26,0.49-0.41,0.73c-2.61,4.07-5.23,8.13-7.84,12.2
		c-0.56,0.87-1.26,1.54-2.29,1.81c-1.5,0.38-2.79,0-3.78-1.17c-0.99-1.17-1.15-2.51-0.52-3.92c0.1-0.22,0.22-0.43,0.35-0.63
		c2.63-4.1,5.27-8.2,7.9-12.3C216.4,326.56,217.43,325.85,219.01,325.89z"
    />
    <path
      d="M244.57,390.4c-0.96-0.14-2.27-0.32-3.57-0.51c-3.83-0.55-7.65-1.09-11.47-1.67c-1.96-0.3-3.19-1.82-3.13-3.75
		c0.05-1.88,1.47-3.39,3.4-3.53c0.66-0.05,1.35,0.09,2.02,0.19c4.46,0.63,8.91,1.27,13.37,1.92c2.07,0.3,3.4,1.64,3.48,3.48
		C248.77,388.76,247.15,390.43,244.57,390.4z"
    />
    <path
      d="M147.23,160.95c0.58,0.31,0.93,0.11,1.26-0.24c1.93-2.03,4.36-2.85,7.11-2.91c1.93-0.04,3.82,0.05,5.66,0.7
		c4.55,1.61,7.46,5.44,7.72,10.25c0.1,1.8,0.04,3.6-0.5,5.34c-1.13,3.68-3.58,6.21-7.13,7.57c-4.09,1.56-8.29,1.62-12.42,0.14
		c-4.95-1.77-7.89-6.03-7.91-11.28c-0.02-4.59-0.03-9.19,0.02-13.78c0.01-1.23,0.16-2.48,0.44-3.67c0.93-3.88,3.28-6.65,6.97-8.12
		c4.44-1.78,8.99-1.81,13.44-0.06c4.27,1.69,6.49,4.98,6.84,9.55c0.01,0.12,0,0.24-0.02,0.36c-0.01,0.07-0.06,0.14-0.14,0.3
		c-1.94,0-3.91,0-5.97,0c-0.06-0.25-0.14-0.51-0.19-0.77c-0.47-2.57-1.93-4.24-4.5-4.86c-1.77-0.42-3.57-0.44-5.34,0.01
		c-2.87,0.72-4.67,2.52-5.21,5.45C146.99,156.95,147.2,158.98,147.23,160.95z M155.02,177.36c0.75-0.1,1.51-0.13,2.25-0.3
		c3.26-0.72,5.18-2.8,5.5-6.1c0.38-3.87-1.24-6.67-4.68-7.82c-2.14-0.72-4.33-0.69-6.46,0.11c-2.4,0.89-3.91,2.59-4.33,5.14
		c-0.13,0.78-0.13,1.6-0.09,2.39c0.15,2.77,1.53,4.73,4.06,5.86C152.45,177.16,153.72,177.33,155.02,177.36z"
    />
    <path
      d="M173.71,169.85c0.47-0.02,0.82-0.05,1.18-0.05c1.59-0.01,3.19,0,4.85,0c0.09,0.58,0.16,1.08,0.24,1.58
		c0.52,3.26,2.65,5.43,5.93,5.82c1.25,0.15,2.58,0.13,3.81-0.1c3.39-0.64,5.44-3.03,5.72-6.46c0.06-0.79,0.06-1.6-0.04-2.39
		c-0.56-4.5-4.37-6.46-8.04-6.08c-1.85,0.19-3.35,1-4.44,2.52c-0.24,0.33-0.47,0.63-0.94,0.63c-1.96-0.01-3.92,0.01-5.87,0
		c-0.36,0-0.71-0.04-1.05-0.06c-0.26-0.93-0.31-19.43-0.06-20.77c8.33,0,16.66,0,25.16,0c0.07,1.82,0.02,3.63,0.03,5.59
		c-6.42,0-12.75,0-19.14,0c0,3.45,0,6.75,0,10.03c0.55,0.31,0.93,0.12,1.29-0.28c1.71-1.9,3.91-2.67,6.41-2.77
		c1.69-0.07,3.36,0.01,4.97,0.55c3.95,1.31,6.47,3.99,7.47,8.04c0.6,2.44,0.62,4.92,0.11,7.37c-0.94,4.53-3.66,7.58-8.07,8.97
		c-4,1.26-8.05,1.2-11.99-0.28c-4.7-1.76-7.58-6.02-7.58-11.03C173.65,170.45,173.68,170.21,173.71,169.85z"
    />
    <path
      d="M109.48,144.42c0.46,0,0.85,0,1.24,0c4.55,0,9.11-0.04,13.66,0.03c1.42,0.02,2.88,0.18,4.25,0.55
		c4.71,1.27,7.76,5.14,7.95,10.06c0.06,1.46,0.02,2.98-0.32,4.4c-1.05,4.43-4,7.07-8.37,8.1c-1.27,0.3-2.61,0.34-3.92,0.38
		c-2.23,0.06-4.47,0.01-6.71,0.02c-0.43,0-0.86,0.04-1.41,0.07c0,4.65,0,9.23,0,13.76c-0.94,0.32-4.69,0.4-6.26,0.16
		C109.34,181.06,109.23,147.29,109.48,144.42z M115.89,150.36c0,3.91,0,7.8,0,11.75c3.16,0,6.23,0.14,9.29-0.05
		c2.2-0.14,3.94-1.33,4.68-3.55c0.54-1.59,0.53-3.24-0.06-4.82c-0.79-2.09-2.44-3.21-4.57-3.34c-2.95-0.18-5.91-0.1-8.86-0.12
		C116.22,150.23,116.07,150.31,115.89,150.36z"
    />
    <path
      d="M101.78,182.05c-1.82,0-3.53,0-5.24,0c-0.31,0-0.62-0.05-1.01-0.09c0-12.49,0-24.93,0-37.45
		c2.07,0,4.12,0,6.25,0C101.78,156.97,101.78,169.41,101.78,182.05z"
    />
  </svg>,
  'HousingStandardIcon',
);

export default HousingStandardIcon;
