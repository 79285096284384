import { createSvgIcon } from '@mui/material';

const HousingCarcasaAcero3mmIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M195.42,136.04c0.64,0.04,1.03,0.08,1.41,0.08c4.84,0,9.68-0.01,14.52,0.02c0.83,0.01,1.41-0.25,1.99-0.83
		c6.88-6.92,13.79-13.81,20.69-20.71c0.31-0.31,0.61-0.64,0.95-0.91c1.46-1.15,3.49-1.04,4.82,0.24c1.32,1.27,1.51,3.32,0.41,4.81
		c-0.28,0.38-0.63,0.72-0.97,1.06c-5.03,5.04-10.07,10.07-15.1,15.11c-0.3,0.3-0.59,0.62-1.09,1.15c0.68,0.03,1.09,0.07,1.5,0.07
		c4.92,0,9.84,0,14.76,0.01c0.48,0,0.97-0.01,1.43,0.1c1.8,0.41,2.97,2.06,2.81,3.93c-0.15,1.77-1.58,3.17-3.39,3.31
		c-0.32,0.02-0.64,0.01-0.96,0.01c-57.55,0-115.11,0-172.66,0c-0.52,0-1.05,0.01-1.55-0.11c-1.86-0.43-3-2.11-2.8-4.04
		c0.19-1.74,1.66-3.14,3.49-3.18c2.08-0.05,4.16-0.02,6.24-0.02c2.24,0,4.48-0.02,6.72,0.01c0.61,0.01,1.05-0.13,1.4-0.69
		c0.25-0.4,0.64-0.71,0.98-1.05c8.14-8.15,16.29-16.29,24.43-24.44c0.33-0.33,0.64-0.66,0.96-1c-0.03-0.09-0.06-0.18-0.1-0.27
		c-0.46,0-0.92,0-1.37,0c-12.68,0-25.36,0-38.04,0c-0.4,0-0.8,0.01-1.2-0.01c-2.03-0.09-3.53-1.64-3.54-3.64
		c-0.01-2,1.48-3.57,3.5-3.69c0.36-0.02,0.72-0.01,1.08-0.01c57.39,0,114.79,0,172.18,0.01c0.67,0,1.37,0.01,2.01,0.18
		c1.75,0.47,2.8,2.13,2.59,3.95c-0.19,1.71-1.62,3.07-3.37,3.2c-0.36,0.03-0.72,0.01-1.08,0.01c-5.08,0-10.16,0.02-15.24-0.01
		c-0.82,0-1.42,0.21-2,0.8c-8.47,8.5-16.96,16.98-25.44,25.47C196.12,135.24,195.85,135.56,195.42,136.04z M115.56,135.87
		c0.05,0.09,0.09,0.17,0.14,0.26c0.44,0,0.87,0,1.31,0c4.68,0,9.35-0.03,14.03,0.02c1.16,0.01,2-0.31,2.83-1.15
		c8.31-8.36,16.65-16.69,24.99-25.02c0.17-0.17,0.36-0.33,0.5-0.51c0.15-0.19,0.26-0.42,0.44-0.71c-0.34-0.03-0.54-0.06-0.74-0.06
		c-5.19,0-10.39,0.02-15.58-0.02c-0.75-0.01-1.17,0.37-1.63,0.82c-8.5,8.51-17.01,17.01-25.51,25.52
		C116.06,135.29,115.82,135.58,115.56,135.87z M186.27,108.79c-0.46-0.04-0.72-0.09-0.98-0.09c-4.64,0-9.28,0.01-13.92-0.02
		c-0.68,0-1.11,0.29-1.55,0.73c-8.56,8.58-17.13,17.14-25.7,25.71c-0.24,0.24-0.45,0.51-0.78,0.9c0.44,0.04,0.71,0.09,0.97,0.09
		c4.64,0,9.28-0.01,13.92,0.02c0.68,0,1.11-0.28,1.55-0.72c8.56-8.57,17.13-17.14,25.7-25.71
		C185.72,109.47,185.93,109.19,186.27,108.79z M169.83,135.85c0.04,0.09,0.07,0.18,0.11,0.27c0.39,0,0.79,0,1.18,0
		c4.16,0,8.31-0.02,12.47,0.02c0.87,0.01,1.48-0.24,2.1-0.86c5.97-6.01,11.97-11.99,17.96-17.99c2.49-2.49,4.98-4.97,7.46-7.46
		c0.29-0.29,0.54-0.61,0.94-1.07c-0.62-0.03-0.97-0.06-1.32-0.06c-4.24,0-8.47,0.02-12.71-0.02c-0.78-0.01-1.34,0.24-1.89,0.79
		c-8.49,8.52-17,17.02-25.51,25.53C170.34,135.27,170.09,135.57,169.83,135.85z M90.07,135.83c0.03,0.1,0.07,0.2,0.1,0.3
		c0.43,0,0.85,0,1.28,0c3.68,0,7.36-0.09,11.03,0.04c1.69,0.06,2.83-0.46,4.02-1.67c8.11-8.23,16.31-16.37,24.47-24.54
		c0.32-0.32,0.63-0.67,0.94-1c-0.04-0.09-0.07-0.17-0.11-0.26c-0.42,0-0.84,0-1.27,0c-4,0-8,0.03-11.99-0.02
		c-1.01-0.01-1.7,0.31-2.4,1.01c-8.4,8.44-16.83,16.86-25.26,25.28C90.6,135.25,90.34,135.54,90.07,135.83z"
    />
    <path
      d="M210.81,240.39c-2.05,0-3.96,0-6.04,0c0-0.47,0-0.89,0-1.32c0-4.96,0-9.92,0-14.88c0-0.36,0.01-0.72-0.01-1.08
		c-0.09-2.97-1.85-4.67-4.8-4.63c-2.7,0.03-4.64,1.65-5.1,4.31c-0.14,0.82-0.2,1.67-0.21,2.5c-0.02,4.52-0.01,9.04-0.01,13.56
		c0,0.47,0,0.94,0,1.49c-2.02,0-3.91,0-5.93,0c-0.02-0.48-0.06-0.95-0.06-1.41c0-5.04,0-10.08,0-15.12c0-0.44,0.01-0.88-0.05-1.32
		c-0.33-2.48-1.68-3.76-4.17-3.99c-2.99-0.27-5.13,1.32-5.69,4.27c-0.13,0.7-0.19,1.43-0.19,2.14c-0.02,4.68-0.01,9.36-0.01,14.04
		c0,0.43,0,0.87,0,1.38c-2.04,0-3.98,0-5.92,0c-0.26-0.94-0.28-25.26,0-26.38c1.88,0,3.77,0,5.82,0c0,0.96,0,1.86,0,2.7
		c0.69,0.34,1,0.08,1.34-0.35c2.7-3.38,8.28-4,11.64-1.27c0.67,0.55,1.21,1.27,1.74,1.96c0.65,0.85,1.02,0.89,1.6,0
		c0.94-1.45,2.12-2.62,3.81-3.13c2.2-0.67,4.43-0.71,6.6,0.06c3.1,1.1,4.88,3.37,5.47,6.57c0.16,0.86,0.22,1.75,0.22,2.62
		c0.02,5.36,0.01,10.72,0.01,16.08C210.87,239.57,210.83,239.92,210.81,240.39z"
    />
    <path
      d="M158.66,240.28c0-0.4,0-0.83,0-1.26c0-5,0-10,0-15c0-0.48,0.01-0.96-0.04-1.44c-0.29-2.51-1.69-3.86-4.22-4.07
		c-2.96-0.25-5.13,1.39-5.66,4.32c-0.13,0.74-0.19,1.51-0.2,2.26c-0.02,4.64-0.01,9.28-0.01,13.92c0,0.43,0,0.86,0,1.35
		c-2.04,0-3.94,0-6,0c0-0.53,0-1,0-1.46c0-4.96,0-9.92,0-14.88c0-0.44,0.01-0.88-0.04-1.32c-0.32-2.89-2.15-4.4-5.06-4.21
		c-2.91,0.19-4.71,2.05-4.97,5.15c-0.04,0.44-0.03,0.88-0.03,1.32c0,4.68,0,9.36,0,14.04c0,0.43,0,0.86,0,1.36
		c-2.07,0-4.01,0-6.03,0c0-8.78,0-17.52,0-26.25c0.86-0.26,4.26-0.31,5.81-0.07c0.05,0.87,0.1,1.76,0.16,2.66
		c0.51,0.27,0.83,0.17,1.18-0.23c2.53-2.96,6.34-3.9,9.96-2.48c1.38,0.54,2.46,1.47,3.31,2.68c0.16,0.23,0.28,0.49,0.47,0.7
		c0.34,0.39,0.78,0.38,1.13,0c0.08-0.09,0.14-0.2,0.2-0.3c2.01-3.31,5.14-4.02,8.64-3.53c4.35,0.6,7.04,3.61,7.39,8
		c0.04,0.52,0.06,1.04,0.06,1.56c0,5.24,0,10.48,0,15.72c0,0.48,0,0.95,0,1.39C163.75,240.49,160.15,240.53,158.66,240.28z"
    />
    <path
      d="M91.82,227.79c1.98,0,3.89,0,5.87,0c0.05,0.35,0.12,0.65,0.13,0.96c0.03,3.68,2.63,5.88,5.3,6.58
		c2.06,0.54,4.12,0.54,6.15-0.1c3.13-0.99,5.21-3.71,4.39-7.84c-0.31-1.57-1.35-2.59-2.75-3.27c-1.44-0.7-2.99-0.82-4.56-0.84
		c-1.76-0.02-3.52,0-5.28-0.01c-0.43,0-0.87,0-1.41,0c-0.05-0.45-0.13-0.79-0.13-1.13c-0.01-1.6,0.03-3.2-0.02-4.8
		c-0.02-0.74,0.26-1.15,0.89-1.5c3.67-2.03,7.33-4.09,10.98-6.15c0.46-0.26,1.12-0.42,0.78-1.36c-3.21-0.12-6.47-0.04-9.73-0.05
		c-3.23-0.02-6.46,0-9.78,0c0-1.86,0-3.59,0-5.4c8.78,0,17.48,0,26.32,0c0.17,2.74,0.04,5.48,0.08,8.24
		c-0.47,0.27-0.87,0.52-1.29,0.75c-3.31,1.86-6.62,3.72-9.93,5.59c-0.43,0.24-1.01,0.42-0.69,1.27c0.49,0.03,1.04,0.08,1.58,0.08
		c1.94,0,3.83,0.23,5.62,1.02c3.56,1.58,5.47,4.29,5.6,8.21c0.05,1.48,0.02,2.95-0.37,4.4c-0.93,3.44-3.1,5.8-6.28,7.28
		c-3.1,1.45-6.39,1.67-9.74,1.32c-1.92-0.2-3.78-0.69-5.49-1.6c-4.48-2.38-6.53-6.16-6.37-11.19
		C91.71,228.14,91.76,228.03,91.82,227.79z"
    />
    <path
      d="M149.34,71.33c0-0.42,0-0.85,0-1.27c0-6.32,0-12.63,0.01-18.95c0-0.55,0.02-1.13,0.16-1.66
		c0.49-1.81,2.1-2.84,4.03-2.63c1.7,0.18,3.03,1.62,3.14,3.4c0.02,0.36,0.01,0.72,0.01,1.08c0,6.24,0,12.47,0,18.71
		c0,0.43,0,0.87,0,1.36c0.66-0.03,0.88-0.52,1.22-0.8c2.24-1.88,5.48-0.76,6.05,2.1c0.24,1.18-0.06,2.26-0.88,3.1
		c-2.49,2.54-5,5.07-7.57,7.53c-1.43,1.38-3.57,1.36-5-0.02c-2.53-2.44-5.03-4.93-7.48-7.44c-1.39-1.43-1.33-3.71,0.04-5.09
		c1.38-1.41,3.55-1.47,5.1-0.15c0.3,0.26,0.59,0.53,0.88,0.8C149.15,71.38,149.24,71.35,149.34,71.33z"
    />
    <path
      d="M149.05,173.29c-0.26,0.24-0.53,0.47-0.79,0.71c-1.58,1.38-3.8,1.35-5.2-0.07c-1.39-1.41-1.4-3.7,0.06-5.19
		c2.38-2.42,4.79-4.82,7.22-7.19c1.62-1.58,3.73-1.59,5.35-0.01c2.43,2.37,4.84,4.77,7.22,7.19c1.52,1.55,1.49,3.82,0.01,5.26
		c-1.42,1.39-3.64,1.36-5.2-0.06c-0.26-0.23-0.52-0.46-0.98-0.88c-0.02,0.64-0.05,1.05-0.05,1.46c0,6.4,0,12.8-0.01,19.19
		c0,0.52-0.01,1.05-0.14,1.54c-0.46,1.69-2.1,2.81-3.81,2.66c-1.78-0.16-3.2-1.55-3.37-3.35c-0.03-0.36-0.02-0.72-0.02-1.08
		c0-6.28,0-12.56,0-18.83c0-0.43,0-0.85,0-1.28C149.25,173.35,149.15,173.32,149.05,173.29z"
    />
    <path
      d="M152.31,156.2c-4.4,0-8.79,0.01-13.19-0.01c-0.55,0-1.13-0.05-1.65-0.22c-1.62-0.53-2.67-2.2-2.45-3.82
		c0.24-1.79,1.56-3.11,3.3-3.28c0.32-0.03,0.64-0.02,0.96-0.02c8.67,0,17.34-0.01,26.01,0.01c0.63,0,1.29,0.05,1.88,0.24
		c1.56,0.51,2.57,2.15,2.41,3.75c-0.18,1.75-1.53,3.14-3.25,3.33c-0.36,0.04-0.72,0.02-1.08,0.02
		C160.94,156.2,156.62,156.2,152.31,156.2z"
    />
    <path
      d="M152.32,88.59c4.48,0,8.95-0.01,13.43,0c1.81,0.01,3.16,0.97,3.66,2.54c0.71,2.26-0.8,4.55-3.16,4.79
		c-0.32,0.03-0.64,0.02-0.96,0.02c-8.67,0-17.34,0-26.01,0c-0.44,0-0.89,0-1.31-0.08c-1.82-0.38-3.11-2.07-2.96-3.82
		c0.17-1.96,1.66-3.41,3.65-3.43C143.21,88.57,147.76,88.59,152.32,88.59z"
    />
  </svg>,
  'HousingCarcasa3mmAceroIcon',
);

export default HousingCarcasaAcero3mmIcon;
