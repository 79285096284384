import { Access, AuthorizationWrapper } from '@features/authorization';
import { Trans } from '@lingui/macro';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { type FC } from 'react';

interface Props {
  deviceId: string;
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

const DeleteModal: FC<Props> = ({ deviceId, open, handleClose, handleDelete }) => {
  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog open={open}>
      <AuthorizationWrapper
        access={Access.UpdateRatingCurve}
        deviceId={deviceId}
        onClose={handleClose}
      >
        <DialogTitle>
          <Trans>¿Estás seguro que deseas eliminar esta curva de aforo?</Trans>
        </DialogTitle>
        {/* <DialogContent dividers>Esta acción no se puede deshacer.</DialogContent> */}
        <DialogContent dividers>
          <Trans>
            Si quieres deshacer esta acción, presiona <b>Restablecer Formulario</b>
          </Trans>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            <Trans>Cancelar</Trans>
          </Button>
          <Button variant="contained" color="error" onClick={() => handleDelete()}>
            <Trans>Eliminar</Trans>
          </Button>
        </DialogActions>
      </AuthorizationWrapper>
    </Dialog>
  );
};

export default DeleteModal;
