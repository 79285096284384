import useConfirmationModal from '@/hooks/useConfirmationModal';
import useExitPageConfirmation from '@/hooks/useExitPageConfirmation';
import useReduxModal from '@/hooks/useReduxModal';
import { type ModalsState } from '@/slices/modals';
import { type GlobalModalProps } from '@components/GlobalModal';
import { type ChangeTabMiddleware } from '@components/ModalTabs';
import { useState } from 'react';

type ModalStateKey = keyof ModalsState;

export const useGlobalModal = <TKey extends ModalStateKey>(
  modalKey: TKey,
  options?: {
    closeConfirmation?: boolean;
  },
) => {
  const { isOpenModal, modalState, closeModal, dialogProps, setModalState } =
    useReduxModal(modalKey);
  const [isDirty, setIsDirty] = useState(false);
  const confirmationModal = useConfirmationModal();
  useExitPageConfirmation(isDirty);

  const changeTabMiddleware: ChangeTabMiddleware = (changeTab) => {
    if (options?.closeConfirmation && isDirty) {
      confirmationModal.openConfirmationModal(
        () => {
          setIsDirty(false);
          changeTab();
        },
        {
          preset: 'change-tab',
        },
      );
    } else {
      changeTab();
    }
  };

  const onClose = () => {
    if (options?.closeConfirmation && isDirty) {
      confirmationModal.openConfirmationModal(
        () => {
          setIsDirty(false);
          closeModal();
        },
        {
          preset: 'close-modal',
        },
      );
    } else {
      closeModal();
    }
  };

  const globalModalProps: Omit<GlobalModalProps, 'children'> = {
    confirmationModal,
    open: isOpenModal,
    onExited: dialogProps.TransitionProps.onExited,
    onClose,
  };

  return {
    /** Redux state */
    state: modalState,
    setModalState,
    onClose,
    /** Middleware for ModalTabs component to add confirmation on change tab */
    changeTabMiddleware,
    /** Props for GlobalModal component */
    globalModalProps,
    isDirty,
    setIsDirty,
  };
};
