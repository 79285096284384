import { gql } from 'src/__generated__';

const CREATE_USER = gql(`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        email
        name
        lastnames
        username
        phoneNumber
        picture
      }
    }
  }
`);

export default CREATE_USER;
