import { gql } from '@/__generated__';
import { DevicesAccess_GetUsersAccessForDeviceQuery } from '@/__generated__/graphql';

export type UserDeviceRelation =
  DevicesAccess_GetUsersAccessForDeviceQuery['device']['users'][number];

const GET_USERS_ACCESS_FOR_DEVICE = gql(`#graphql
  query DevicesAccess_GetUsersAccessForDevice($deviceId: ID!, $devicesUsersInput: DeviceUsersInput) {
    device(input: { id: $deviceId }) {
      id
      users(input: $devicesUsersInput) {
        id
        hasAccess
        device {
          id
        }
        user {
          id
          fullName
          email
          mainOrganization {
            organization {
              name
              id
            }
            role {
              id
              name
            }
          }
        }
      }
    }
  }
`);

export default GET_USERS_ACCESS_FOR_DEVICE;
