import { createSvgIcon } from '@mui/material';

const ConnectivitySatellitalIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M24.13,296c-0.16-0.11-0.32-0.23-0.49-0.32c-2.16-1.11-2.86-3.31-1.67-5.42c1.15-2.04,2.36-4.05,3.54-6.08
		c11.83-20.25,23.66-40.49,35.49-60.74c0.18-0.31,0.35-0.63,0.57-1.04c-20.87-21.16-33.46-46.39-36.75-76
		c-3.28-29.54,3.21-56.95,18.93-82.26c-0.32-0.34-0.62-0.65-0.93-0.96c-3.4-3.41-6.79-6.82-10.19-10.23
		c-1.01-1.01-1.53-2.17-1.23-3.61c0.57-2.76,3.78-3.91,5.98-2.14c0.31,0.25,0.59,0.55,0.88,0.84c11.34,11.37,22.68,22.73,34.02,34.1
		c6.28,6.3,12.56,12.61,18.84,18.91c11.31,11.34,22.62,22.68,33.93,34.01c1.91,1.92,3.82,3.84,5.73,5.76
		c0.28,0.28,0.58,0.55,0.77,0.73c5.83-5.84,11.62-11.64,17.47-17.5c-5.84-7.4-8.16-15.93-6.35-25.43
		c1.45-7.62,5.37-13.81,11.54-18.52c12.25-9.34,29.63-8.07,40.4,2.91c10.82,11.04,11.77,28.27,2.26,40.47
		c-9.52,12.21-27.78,16.04-41.95,6.09c-5.87,5.87-11.72,11.72-17.67,17.68c0.12,0.13,0.4,0.46,0.7,0.76
		c5.02,5.05,10.04,10.09,15.07,15.13c12.65,12.69,25.31,25.36,37.97,38.05c13.28,13.31,26.56,26.63,39.84,39.95
		c1.11,1.12,2.23,2.23,3.32,3.37c1.16,1.21,1.42,2.97,0.68,4.37c-1.14,2.18-4.03,2.66-5.89,0.92c-1.56-1.47-3.04-3.02-4.55-4.54
		c-1.8-1.8-3.6-3.61-5.4-5.41c-0.17-0.17-0.36-0.31-0.63-0.54c-18.76,11.79-39.21,18.28-61.54,19.48c0.23,0.52,0.38,0.91,0.57,1.27
		c4.05,7.65,8.1,15.29,12.15,22.93c1.23,2.32,2.47,4.64,3.69,6.97c1.39,2.65,0.77,4.52-1.91,5.86c-0.07,0.04-0.12,0.11-0.18,0.16
		C122.15,296,73.14,296,24.13,296z M49.31,69.68C22.56,110.8,22.56,178.05,72.09,221.9c16.42,14.53,35.53,23.74,57.11,27.65
		c20.5,3.72,40.64,2.17,60.37-4.57c8.12-2.78,21.59-9.32,23.28-11.3C158.35,179.03,103.84,124.36,49.31,69.68z M163.94,288.45
		c-0.24-0.52-0.41-0.92-0.61-1.3c-4.77-9-9.55-17.99-14.28-27.02c-0.51-0.97-1.1-1.29-2.16-1.3c-4.77-0.03-9.52-0.52-14.24-1.18
		c-9.73-1.36-19.15-3.85-28.29-7.44c-13.21-5.19-25.2-12.42-35.99-21.64c-0.35-0.3-0.72-0.59-1.08-0.88
		c-1.51,2.02-35.19,59.9-35.37,60.76C75.87,288.45,119.83,288.45,163.94,288.45z M172.85,81.33c-12.82-0.05-23.28,10.33-23.32,23.13
		c-0.04,12.8,10.36,23.29,23.14,23.34c12.79,0.05,23.25-10.35,23.31-23.16C196.03,91.81,185.68,81.38,172.85,81.33z"
    />
    <path
      d="M184.37,3.23c43.29,0.07,81.31,28.82,93.17,70.53c1.71,6,2.88,12.11,3.28,18.35
		c0.46,7.2,0.45,14.39-0.55,21.55c-0.05,0.36-0.11,0.72-0.19,1.07c-0.44,1.95-2.15,3.12-4.16,2.86c-1.85-0.24-3.2-1.78-3.13-3.71
		c0.04-1.17,0.24-2.33,0.37-3.5c0.69-5.92,0.76-11.85,0.26-17.8c-0.82-9.8-3.19-19.2-7.13-28.2
		c-11.51-26.33-35.01-45.57-63.08-51.65c-7.26-1.57-14.62-2.22-22.04-1.94c-2.5,0.09-5,0.37-7.49,0.61
		c-2.05,0.2-3.61-0.54-4.32-2.16c-1.02-2.32,0.47-4.87,3.05-5.21c1.48-0.19,2.97-0.36,4.46-0.45
		C179.36,3.43,181.87,3.34,184.37,3.23z"
    />
    <path
      d="M179.73,35.63c32.12,0,59.33,21.83,66.07,52.25c1.39,6.28,1.81,12.64,1.44,19.05
		c-0.11,1.89-0.35,3.79-0.66,5.66c-0.28,1.66-1.66,2.76-3.31,2.87c-1.69,0.11-3.2-0.78-3.71-2.34c-0.22-0.67-0.27-1.44-0.18-2.14
		c0.44-3.42,0.63-6.84,0.53-10.29c-0.38-12.1-4.04-23.12-11.17-32.9c-9.34-12.82-21.88-20.74-37.46-23.73
		c-5.63-1.08-11.31-1.28-17.02-0.61c-0.52,0.06-1.05,0.09-1.57,0.09c-1.89-0.02-3.42-1.41-3.62-3.25c-0.2-1.86,0.95-3.57,2.79-3.99
		c1.02-0.23,2.07-0.34,3.12-0.41C176.86,35.74,178.76,35.68,179.73,35.63z"
    />
  </svg>,
  'ConnectivitySatellitalIcon',
);

export default ConnectivitySatellitalIcon;
