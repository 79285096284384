import { useState, useCallback } from 'react';
import { type ConfirmationModalProps } from '@components/modal/ConfirmationModal';

/**
 * Return props to use with ConfirmationModal
 * and functions to open and set props
 */
const useConfirmationModal = () => {
  const [modalProps, setModalProps] = useState<ConfirmationModalProps>({
    open: false,
    onClose: () => {
      setModalProps((previous) => ({ ...previous, open: false }));
    },
  });

  const openConfirmationModal = useCallback(
    (onConfirm: () => void, modalProps?: Partial<ConfirmationModalProps>) => {
      setModalProps((previous) => ({
        ...previous,
        open: true,
        ...modalProps,
        submitButton: {
          onClick: () => {
            onConfirm();
            setModalProps((previous) => ({ ...previous, open: false }));
          },
          ...modalProps?.submitButton,
        },
      }));
    },
    [setModalProps],
  );

  return {
    confirmationModalProps: modalProps,
    openConfirmationModal,
    setConfirmationModal: setModalProps,
  };
};

export default useConfirmationModal;
