import { gql } from '@/__generated__';

const GET_DGA_MAX_FLOW = gql(`#graphql
  query GetDgaMaxFlow($deviceId: ID!, $deviceIdString: String!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        name
      }
    }
    DGADeviceConfiguration(deviceId: $deviceIdString) {
      maxFlow
    }
  }
`);

export default GET_DGA_MAX_FLOW;
