import { createSvgIcon } from '@mui/material';

const HousingRedElectricaIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M83.52,40.58c0-0.65,0-1.15,0-1.66c0-10.75,0-21.5,0-32.25c0-0.44-0.01-0.88,0.01-1.32
		c0.11-1.99,1.59-3.45,3.55-3.5c1.94-0.06,3.55,1.35,3.77,3.3c0.04,0.4,0.02,0.8,0.02,1.2c0,10.83,0,21.66,0,32.49
		c0,0.52,0,1.03,0,1.52c0.92,0.27,29.57,0.35,31.37,0.08c0-0.47,0-0.98,0-1.48c0-10.87,0-21.74,0.01-32.61
		c0-0.59,0.03-1.21,0.17-1.78c0.44-1.81,2.09-2.9,3.98-2.69c1.82,0.21,3.12,1.61,3.19,3.46c0.02,0.52,0.01,1.04,0.01,1.56
		c0,10.67,0,21.34,0,32.01c0,0.51,0,1.02,0,1.67c0.58,0,1.01,0,1.44,0c4.88,0,9.76-0.01,14.64,0.01c0.59,0,1.21,0.05,1.77,0.22
		c1.44,0.42,2.41,1.67,2.52,3.17c0.03,0.4,0.01,0.8,0.01,1.2c0,16.19-0.05,32.37,0.01,48.56c0.06,15.05-10.33,28.01-24.52,31.5
		c-2.54,0.62-5.1,0.94-7.71,0.94c-2.16,0-4.32,0-6.48,0c-0.43,0-0.86,0-1.24,0c-1.05,2.99-1.42,20.4-0.52,23.98
		c0.33-0.15,0.65-0.27,0.95-0.43c4.13-2.19,8.45-3.85,13.09-4.48c10.08-1.38,19.28,0.63,26.97,7.62c4.41,4.01,6.69,9.17,7.39,15.05
		c0.4,3.38,0.16,6.7-0.74,9.97c-1.51,5.49-4.64,9.8-9.56,12.7c-6.96,4.09-14.42,5.25-22.29,3.38c-4.47-1.06-8.07-3.57-11.05-7.02
		c-2.83-3.27-4.79-7.06-6.44-11.03c-1.5-3.62-2.66-7.36-3.6-11.16c-0.09-0.38-0.21-0.75-0.33-1.21c-0.26,0.18-0.44,0.26-0.58,0.4
		c-6.27,5.91-11.17,12.74-13.75,21.04c-2.66,8.55-2.37,17.03,1.27,25.28c3.47,7.85,9.53,12.87,17.55,15.63
		c3.88,1.33,7.88,2.07,11.98,2.22c2.76,0.1,5.52,0.17,8.27,0.17c12.4,0.02,24.8,0.01,37.2,0.01c0.52,0,1.03,0,1.65,0
		c0.15-0.64,0.31-1.2,0.43-1.78c2.84-14.54,5.67-29.08,8.5-43.61c1.75-8.97,3.5-17.95,5.26-26.92c0.43-2.19,1.69-3.22,3.93-3.23
		c15.04-0.01,30.08,0.03,45.12-0.04c2.81-0.01,4.75,2.57,3.59,5.47c-4.94,12.39-9.78,24.82-14.66,37.23
		c-0.49,1.25-0.97,2.51-1.54,3.98c0.63,0,1.06,0,1.48,0c2.8,0,5.6,0,8.4,0c0.48,0,0.96,0.01,1.43,0.09
		c2.53,0.39,3.88,2.95,2.78,5.26c-0.14,0.29-0.31,0.56-0.48,0.83c-18.12,30.27-36.24,60.54-54.37,90.82
		c-0.21,0.34-0.4,0.7-0.63,1.02c-1,1.37-2.67,1.88-4.26,1.32c-1.5-0.53-2.51-1.99-2.36-3.65c0.12-1.35,0.39-2.69,0.62-4.02
		c1.91-10.93,3.82-21.87,5.74-32.8c1.24-7.08,2.49-14.15,3.74-21.23c0.07-0.39,0.09-0.79,0.15-1.34c-0.52-0.02-0.98-0.05-1.44-0.05
		c-9.36,0-18.72,0-28.08,0c-8.76,0-17.52,0.02-26.28-0.01c-5.53-0.02-11.01-0.53-16.36-2.08c-7.1-2.06-13.34-5.55-18.26-11.15
		c-4.74-5.39-7.57-11.72-8.78-18.75c-2.17-12.64,1-24.07,8.07-34.57c3.42-5.08,7.56-9.5,12.31-13.35c0.67-0.54,0.88-1.05,0.78-1.92
		c-0.57-5.05-0.86-10.12-0.7-15.19c0.11-3.39,0.39-6.78,0.59-10.17c0.04-0.67,0.09-1.35,0.14-2.18c-0.49-0.03-0.9-0.08-1.32-0.08
		c-2.36-0.02-4.72,0.03-7.08-0.05c-15.03-0.54-27.98-12-30.35-26.88c-0.29-1.81-0.44-3.65-0.44-5.48
		c-0.03-16.27-0.02-32.53-0.02-48.8c0-0.52-0.02-1.05,0.1-1.55c0.38-1.69,1.83-2.8,3.7-2.82c2.16-0.03,4.32-0.01,6.48-0.01
		c2.88,0,5.76,0,8.64,0C82.51,40.58,82.94,40.58,83.52,40.58z M70.58,48.03c-0.02,0.5-0.06,0.93-0.06,1.36
		c0,14.94-0.02,29.88,0.02,44.83c0.01,1.7,0.22,3.44,0.59,5.1c2.5,11.35,12.72,19.58,24.42,19.52c7.36-0.04,14.71,0.01,22.07-0.02
		c1.27,0,2.56-0.11,3.82-0.29c11.98-1.74,21.14-12.23,21.18-24.34c0.04-14.98,0.01-29.96,0.01-44.95c0-0.39-0.05-0.77-0.07-1.21
		C118.52,48.03,94.6,48.03,70.58,48.03z M225.55,160.17c-12.52,0-24.82,0-37.2,0c-4.42,22.68-8.82,45.3-13.27,68.11
		c0.64,0,1.06,0,1.49,0c2.68,0,5.36-0.01,8.04,0c2.66,0.01,4.24,1.85,3.8,4.45c-0.8,4.68-1.62,9.36-2.44,14.04
		c-1.42,8.1-2.84,16.2-4.25,24.29c-0.26,1.47-0.5,2.95-0.74,4.43c0.09,0.04,0.17,0.07,0.26,0.11
		c13.68-22.86,27.37-45.72,41.17-68.78c-0.66-0.04-1.09-0.08-1.51-0.08c-2.76-0.01-5.52-0.03-8.28,0c-2.18,0.02-4.25-2.13-3.38-4.88
		c0.11-0.34,0.24-0.68,0.37-1.01c5.16-13.11,10.31-26.23,15.47-39.34C225.24,161.12,225.37,160.71,225.55,160.17z M110.46,158.31
		c0.06,0.46,0.09,0.78,0.15,1.09c1.13,5.82,2.82,11.47,5.43,16.81c1.06,2.16,2.3,4.2,3.88,6.02c2.08,2.38,4.59,4.12,7.72,4.74
		c6.07,1.22,11.76,0.19,16.95-3.22c2.56-1.68,4.25-4.07,5.21-6.97c0.89-2.7,1.13-5.45,0.76-8.27c-0.66-5.03-2.97-9.07-7.14-11.97
		c-5.36-3.74-11.38-4.89-17.79-4.16c-4.97,0.57-9.48,2.44-13.73,5C111.43,157.67,110.97,157.98,110.46,158.31z"
    />
  </svg>,
  'HousingRedElectricaIcon',
);

export default HousingRedElectricaIcon;
