import { useSuspenseQuery } from '@apollo/client';
import { Suspense, type FC } from 'react';
import { GET_DEVICE_NAME } from './query';
import { Skeleton, Typography, type TypographyProps } from '@mui/material';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';

interface Props {
  deviceId: string;
}

const Component: FC<Props & TypographyProps> = ({ deviceId, ...typographyProps }) => {
  const { data } = useSuspenseQuery(GET_DEVICE_NAME, { variables: { deviceId } });
  return (
    <Typography variant="inherit" component="span" {...typographyProps}>
      {data.device.profile.name}
    </Typography>
  );
};

const Fallback: FC<TypographyProps> = (props) => {
  return (
    <Skeleton variant="text" sx={{ display: 'inline', ...props }}>
      <Typography variant="inherit" component="span" {...props}>
        placeholder
      </Typography>
    </Skeleton>
  );
};

const DeviceNameSpan: FC<Props & TypographyProps> = (props) => {
  const { deviceId, ...typographyProps } = props;
  return (
    <ApolloErrorBoundary variant="section-alert">
      <Suspense fallback={<Fallback {...typographyProps} />}>
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default DeviceNameSpan;
