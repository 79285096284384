import { useFormatters } from '@/hooks/useFormatters';
import { useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import {
  getColorByPrimitiveActionType,
  getDisplayNameActionType,
  getIconByPrimitiveActionType,
  getPrimitiveActionType,
} from '@features/audit/utils';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { Suspense, type FC } from 'react';
import GET_DEVICE_AUDIT_LOGS from '../../graphql/queries/getDeviceAuditLogs';

interface Props {
  deviceId: string;
}

const Component: FC<Props> = ({ deviceId }) => {
  const { i18n } = useLingui();
  const { formatDateTime } = useFormatters();
  const { data } = useSuspenseQuery(GET_DEVICE_AUDIT_LOGS, {
    variables: {
      deviceId,
    },
  });

  const auditLogs = data.device.profile.auditLogs?.map((log) => {
    const responsible = log.responsible?.displayName ?? <Trans>Un captiano</Trans>;
    const action = getDisplayNameActionType(log.action.type, i18n).displayName;
    const primitiveAction = getPrimitiveActionType(log.action.type);
    const color = getColorByPrimitiveActionType(primitiveAction);
    const icon = getIconByPrimitiveActionType(primitiveAction);

    return {
      id: log.id,
      timestamp: log.timestamp,
      description: `${responsible} ${action}`,
      color,
      icon,
    } as const;
  });

  if (!auditLogs) {
    return null;
  }

  return (
    <List disablePadding>
      {auditLogs.length === 0 ? (
        <ListItem>
          <Typography color="textSecondary" fontStyle="italic">
            <Trans>No hay actividad reciente</Trans>
          </Typography>
        </ListItem>
      ) : (
        auditLogs.map((log) => (
          <ListItem key={log?.id} sx={{ py: 0 }}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: `${log.color}.main`, width: 34, height: 34 }}>
                {log.icon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={log.description} secondary={formatDateTime(log.timestamp)} />
          </ListItem>
        ))
      )}
    </List>
  );
};

const Fallback = () => {
  return (
    <List>
      {Array.from({ length: 5 }).map((_, index) => (
        <ListItem key={index} sx={{ py: 0 }}>
          <ListItemAvatar>
            <Skeleton variant="circular" width={34} height={34} />
          </ListItemAvatar>
          <ListItemText primary={<Skeleton width={340} />} secondary={<Skeleton width={100} />} />
        </ListItem>
      ))}
    </List>
  );
};

const DeviceProfileRecentActivity: FC<Props> = (props) => {
  return (
    <ApolloErrorBoundary variant="section-alert">
      <Suspense fallback={<Fallback />}>
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default DeviceProfileRecentActivity;
