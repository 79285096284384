import { DeviceType } from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { setUpdateDeviceAliasesModal } from '@/slices/modals';
import { getDeviceTypeOptionsFromSameFamily } from '@/utils/i18n/getDeviceTypeOptionsFromSensorFamily';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import NumericTextField from '@components/NumericTextField';
import ModalActions from '@components/modal/ModalActions';
import { Access, AuthorizationWrapper } from '@features/authorization';
import StandardAutocompleteIds from '@features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { Suspense, useEffect, useState, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import UPDATE_DEVICE_GENERAL from '../../graphql/mutations/updateDeviceGeneral';
import GET_UPDATE_DEVICE_FIELDS from '../../graphql/queries/getUpdateDeviceFields';
import { TabContent, TabFallback } from '../common';
import { DeviceTabProps } from '../tabProps';
import DeviceLocationMap from './DeviceLocationMap';

const schema = z.object({
  name: z.string(),
  alias: z.string(),
  type: z.nativeEnum(DeviceType),
  location: z
    .object({
      latitude: z.coerce.number(),
      longitude: z.coerce.number(),
    })
    .nullish(),
  organizationId: z.number(),
  irrigationId: z.number(),
});
type FormValues = z.infer<typeof schema>;

const Component: FC<DeviceTabProps> = ({ deviceId, setIsDirty, onClose }) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const [showMap, setShowMap] = useState(false);
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { data } = useSuspenseQuery(GET_UPDATE_DEVICE_FIELDS, {
    variables: {
      deviceId,
    },
  });
  const [update] = useMutation(UPDATE_DEVICE_GENERAL, {
    onCompleted(data) {
      openModalSuccessMessage(_(msg`Dispositivo actualizado correctamente`));
      methods.reset({
        name: data.updateDevice.profile.originalName,
        alias: data.updateDevice.profile.alias ?? '',
        type: data.updateDevice.profile.type,
        location: data.updateDevice.profile.location,
        irrigationId: data.updateDevice.profile.irrigation?.id,
        organizationId: data.updateDevice.profile.organization?.id,
      });
    },
    onError(error) {
      openModalErrorMessage(_(msg`Error al actualizar el dispositivo: ${error.message}`));
    },
    update(cache) {
      cache.modify({
        id: cache.identify({ __typename: 'Device', id: deviceId }),
        fields: {
          profile(_, { INVALIDATE }) {
            return INVALIDATE;
          },
        },
      });
    },
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      name: data.device.profile.originalName,
      alias: data.device.profile.alias ?? '',
      type: data.device.profile.type,
      location: data.device.profile.location,
      irrigationId: data.device.profile.irrigation?.id,
      organizationId: data.device.profile.organization?.id,
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    methods.reset({
      name: data.device.profile.originalName,
      alias: data.device.profile.alias ?? '',
      type: data.device.profile.type,
      location: data.device.profile.location,
      irrigationId: data.device.profile.irrigation?.id,
      organizationId: data.device.profile.organization?.id,
    });
  }, [data.device.profile, methods]);

  useEffect(() => setIsDirty(methods.formState.isDirty), [methods.formState.isDirty, setIsDirty]);

  const submitHandler = async (values: FormValues) => {
    await update({
      variables: {
        deviceId,
        input: {
          name: values.name,
          alias: values.alias,
          type: values.type,
          location: values.location,
          irrigationId: values.irrigationId,
          organizationId: values.organizationId,
        },
      },
    });
  };

  const typeOptions = getDeviceTypeOptionsFromSameFamily(data.device.profile.type);

  const openAliasModal = () => {
    dispatch(
      setUpdateDeviceAliasesModal({
        open: true,
        deviceId,
      }),
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(submitHandler)}>
      <TabContent>
        <Typography variant="h6" mb={2}>
          <Trans>Configuración básica</Trans>
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            sm: '1fr',
            md: '1fr 1fr',
          }}
          gap={2}
        >
          <Controller
            control={methods.control}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                onBlur={(event) => {
                  event.target.value = event.target.value.trim();
                  field.onChange(event);
                  field.onBlur();
                }}
                label={_(msg`Nombre`)}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={methods.control}
            name="alias"
            render={({ field, fieldState }) => (
              <Box display="flex" gap={1} alignItems="start">
                <TextField
                  {...field}
                  sx={{
                    flexGrow: 1,
                  }}
                  onBlur={(event) => {
                    event.target.value = event.target.value.trim();
                    field.onChange(event);
                    field.onBlur();
                  }}
                  label={_(msg`Alias`)}
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error?.message ??
                    _(msg`Es el nombre que verán los miembros de tu organización`)
                  }
                />
                <AuthorizationWrapper
                  access={Access.UpdateAliasModal}
                  deviceId={deviceId}
                  fallback={null}
                >
                  <Button onClick={openAliasModal} color="info" variant="text" sx={{ py: 2 }}>
                    <Trans>Alias por organización</Trans>
                  </Button>
                </AuthorizationWrapper>
              </Box>
            )}
          />
          <Controller
            control={methods.control}
            name="type"
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                options={typeOptions.map((type) => type.enumValue)}
                getOptionLabel={(option) => {
                  const type = typeOptions.find((type) => type.enumValue === option);
                  return type?.label || option;
                }}
                disableClearable
                value={field.value}
                onChange={(_, value) => field.onChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={_(msg`Tipo`)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
          <Controller
            control={methods.control}
            name="organizationId"
            render={({ field, fieldState }) => (
              <StandardAutocompleteIds
                dataType="organizations"
                value={field.value}
                onChange={(_, values) => field.onChange(values)}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={methods.control}
            name="irrigationId"
            render={({ field, fieldState }) => (
              <StandardAutocompleteIds
                dataType="irrigations"
                value={field.value}
                onChange={(_, values) => field.onChange(values)}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Typography variant="h6" my={2}>
          <Trans>Ubicación</Trans>
        </Typography>
        <Controller
          control={methods.control}
          name="location"
          render={({ field }) => (
            <>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr auto 1fr"
                gap={2}
                gridColumn="1 / span 2"
              >
                <NumericTextField
                  value={field.value?.latitude ?? ''}
                  fullWidth
                  label={_(msg`Latitud`)}
                  onChange={(event) => {
                    field.onChange({
                      latitude: event.target.value,
                      longitude: field.value?.longitude || 0,
                    });
                  }}
                />
                <NumericTextField
                  value={field.value?.longitude ?? ''}
                  fullWidth
                  label={_(msg`Longitud`)}
                  onChange={(event) => {
                    field.onChange({
                      latitude: field.value?.latitude || 0,
                      longitude: event.target.value,
                    });
                  }}
                />
                <Button color="info" onClick={() => setShowMap((show) => !show)}>
                  {!showMap ? <Trans>Mostrar mapa</Trans> : <Trans>Ocultar mapa</Trans>}
                </Button>
              </Box>
              {showMap && (
                <Box height={200} mt={2} borderRadius={1} overflow="hidden">
                  <DeviceLocationMap
                    position={{
                      lat:
                        field.value && !Number.isNaN(Number(field.value.latitude))
                          ? Number(field.value.latitude)
                          : 0,
                      lng:
                        field.value && !Number.isNaN(Number(field.value.longitude))
                          ? Number(field.value.longitude)
                          : 0,
                    }}
                    handleChangeMarkerPosition={(position) => {
                      const { lat, lng } = position;
                      field.onChange({
                        latitude: lat,
                        longitude: lng,
                      });
                    }}
                    onMapClick={(position) => {
                      const { lat, lng } = position;
                      field.onChange({
                        latitude: lat,
                        longitude: lng,
                      });
                    }}
                  />
                </Box>
              )}
            </>
          )}
        />
      </TabContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        onResetForm={() => methods.reset()}
        submitLoading={methods.formState.isSubmitting}
        dirtyForm={methods.formState.isDirty}
      />
    </form>
  );
};

const GeneralTab: FC<DeviceTabProps> = (props) => {
  return (
    <ApolloErrorBoundary variant="modal">
      <Suspense fallback={<TabFallback {...props} />}>
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default GeneralTab;
