import { createSvgIcon } from '@mui/material';

const HousingCapasAceroBlindadoIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M160.63,64.14c0.43-0.35,0.82-0.67,1.21-1c7.78-6.71,15.56-13.42,23.33-20.15c0.88-0.76,1.8-1.3,3.01-1.25
		c1.69,0.07,3.07,1.17,3.41,2.83c0.13,0.62,0.15,1.27,0.15,1.9c0.01,11.6,0.01,23.2,0.01,34.8c0,0.46,0.03,0.92,0.05,1.6
		c0.53-0.41,0.88-0.66,1.2-0.93c6.6-5.51,13.2-11.01,19.79-16.53c3.32-2.78,7.09-4.6,11.39-5.25c3.21-0.49,6.37-0.17,9.51,0.57
		c5.17,1.22,9.9,3.35,14.05,6.69c0.75,0.6,1.47,1.24,2.12,1.94c1.7,1.8,1.56,4.08-0.35,5.67c-4.02,3.36-8.07,6.68-12.1,10.02
		c-7.36,6.09-14.71,12.19-22.1,18.26c-0.75,0.62-1.03,1.25-1.03,2.23c0.03,14.64,0.02,29.28,0.01,43.92c0,0.63-0.03,1.29-0.19,1.89
		c-0.46,1.69-2.18,2.81-3.84,2.59c-1.86-0.24-3.18-1.6-3.29-3.41c-0.03-0.44-0.01-0.88-0.01-1.32c0-12.4,0-24.8,0-37.2
		c0-0.46,0-0.92,0-1.39c-0.09-0.04-0.17-0.08-0.26-0.12c-0.36,0.28-0.73,0.55-1.08,0.84c-10.38,8.59-20.76,17.17-31.14,25.76
		c-3.7,3.06-7.39,6.12-11.09,9.17c-2.01,1.66-3.4,1.73-5.51,0.26c-3.06-2.12-6.26-3.99-9.74-5.37c-3.72-1.47-7.55-1.69-11.43-0.97
		c-5.09,0.94-8.06,4.15-9.12,9.13c-0.34,1.59-0.45,3.25-0.46,4.88c-0.04,9.08-0.05,18.16,0.01,27.24c0.02,2.56-2.4,4.56-5.02,3.57
		c-1.16-0.44-1.92-1.29-2.19-2.51c-0.12-0.54-0.16-1.11-0.16-1.66c-0.02-9.2-0.05-18.4-0.01-27.6c0.02-4.56,1.08-8.87,3.66-12.72
		c1.33-1.99,3.02-3.61,5.03-4.91c0.57-0.37,1.13-0.76,1.65-1.19c17.77-14.83,35.54-29.67,53.32-44.48c0.72-0.6,0.98-1.22,0.98-2.14
		c-0.03-10.92-0.02-21.84-0.02-32.76c0-0.42-0.04-0.84-0.08-1.49c-0.5,0.39-0.82,0.61-1.11,0.86
		c-25.15,21.71-50.29,43.42-75.45,65.11c-0.73,0.63-1.03,1.27-1.03,2.24c0.03,16.56,0.02,33.12,0.02,49.68
		c0,0.48,0.01,0.96-0.03,1.44c-0.14,1.7-1.5,3.08-3.17,3.23c-1.87,0.18-3.5-0.87-3.96-2.58c-0.14-0.53-0.19-1.11-0.19-1.66
		c-0.01-17.52,0-35.04-0.02-52.56c0-1.65,0.65-2.86,1.89-3.93c17.51-15.08,34.99-30.19,52.48-45.29c0.36-0.31,0.7-0.63,1.02-0.92
		c-0.05-0.23-0.06-0.35-0.11-0.45c-2.81-5.26-6.69-9.42-12.13-12.04c-0.89-0.43-1.48-0.31-2.24,0.3
		c-24.18,19.56-48.38,39.09-72.57,58.63c-0.3,0.25-0.59,0.52-0.93,0.82c0.26,0.25,0.44,0.45,0.66,0.61
		c6.35,4.7,10.89,10.82,14.31,17.89c2.39,4.95,3.83,10.15,4.78,15.52c1.1,6.24,1.58,12.53,1.42,18.86
		c-0.05,2.08,0.04,4.16-0.03,6.24c-0.08,2.67-2.71,4.38-5.09,3.34c-1.5-0.66-2.24-1.86-2.24-3.51c0.01-3.52,0.05-7.04-0.01-10.56
		c-0.13-7.15-0.98-14.23-3.21-21.02c-3.02-9.21-8.19-16.95-16.43-22.37c-0.73-0.48-1.46-0.97-2.19-1.46
		c-2.47-1.65-2.63-4.44-0.33-6.32c4.15-3.38,8.33-6.74,12.5-10.1c22.17-17.91,44.36-35.8,66.5-53.74c1.65-1.34,3.26-1.61,5.21-0.95
		c6.03,2.02,10.86,5.69,14.76,10.63c1.01,1.28,1.87,2.69,2.79,4.04C160.31,63.72,160.44,63.88,160.63,64.14z M144.15,132.38
		c0.02,0.09,0.04,0.17,0.06,0.26c2.92,0.34,5.7,1.17,8.33,2.43c2.65,1.27,5.23,2.7,7.91,4.09c26.97-22.31,53.99-44.67,80.98-67
		c-0.12-0.22-0.13-0.32-0.19-0.36c-0.26-0.18-0.53-0.36-0.8-0.53c-2.69-1.62-5.58-2.74-8.65-3.42c-1.68-0.37-3.37-0.66-5.1-0.56
		c-3.52,0.2-6.55,1.58-9.24,3.82c-24.14,20.16-48.28,40.32-72.43,60.47C144.73,131.85,144.44,132.12,144.15,132.38z"
    />
    <path
      d="M114.03,219.23c-2.07,0-4.05,0-6.05,0c-0.07-0.22-0.16-0.4-0.19-0.59c-0.58-3.1-2.09-4.74-5.11-5.2
		c-1.36-0.2-2.81-0.21-4.17,0.01c-3.44,0.56-5.42,2.7-5.91,6.15c-0.08,0.55-0.07,1.12-0.08,1.68c-0.01,1.23,0,2.47,0,3.66
		c0.54,0.32,0.92,0.26,1.27-0.11c2.6-2.78,5.97-3.21,9.49-2.96c3.04,0.22,5.74,1.33,7.9,3.56c1.6,1.66,2.62,3.61,2.97,5.89
		c0.33,2.11,0.35,4.23-0.17,6.31c-1.04,4.13-3.71,6.84-7.65,8.25c-3.92,1.4-7.92,1.43-11.87,0.07c-4.98-1.72-8.06-6.04-8.1-11.3
		c-0.03-4.64-0.05-9.28,0.02-13.91c0.02-1.34,0.19-2.73,0.55-4.02c1.05-3.79,3.48-6.45,7.15-7.83c4.14-1.56,8.39-1.61,12.56-0.17
		c4.32,1.48,6.82,4.58,7.38,9.15C114.08,218.3,114.03,218.74,114.03,219.23z M100.43,226.62c-0.01,0.05-0.02,0.1-0.02,0.15
		c-0.36,0-0.72-0.02-1.08,0c-3.63,0.29-6.2,2.51-6.7,5.88c-0.14,0.94-0.14,1.92-0.01,2.86c0.4,2.8,2.06,4.62,4.73,5.46
		c1.99,0.63,4.02,0.62,6.01,0c2.28-0.71,3.87-2.2,4.53-4.53c0.37-1.28,0.38-2.6,0.16-3.92c-0.45-2.71-2.04-4.45-4.6-5.3
		C102.49,226.91,101.44,226.82,100.43,226.62z"
    />
    <path
      d="M205.12,246.09c-2.06,0-3.96,0-5.97,0c-0.02-0.48-0.06-0.91-0.06-1.34c0-5.12,0.01-10.24-0.01-15.36
		c0-0.75-0.09-1.52-0.27-2.25c-0.27-1.1-0.97-1.91-1.99-2.42c-3.19-1.62-6.95,0.18-7.62,3.69c-0.16,0.86-0.22,1.75-0.22,2.62
		c-0.02,4.56-0.01,9.12-0.01,13.68c0,0.43,0,0.86,0,1.36c-2.03,0-3.93,0-5.99,0c0-0.52,0-0.98,0-1.45c0-5.08,0.01-10.16-0.01-15.24
		c0-0.63-0.06-1.28-0.19-1.9c-0.36-1.69-1.43-2.74-3.1-3.12c-2.8-0.64-6.3,0.25-6.77,5.1c-0.04,0.44-0.03,0.88-0.03,1.32
		c0,4.64,0,9.28,0,13.92c0,0.43,0,0.86,0,1.37c-2.05,0-3.99,0-5.9,0c-0.24-0.91-0.31-24.8-0.06-26.44c1.86-0.02,3.76-0.01,5.8,0
		c0.05,0.94,0.1,1.83,0.15,2.75c0.53,0.32,0.93,0.19,1.22-0.3c2.38-3.98,10.7-4.17,13.4,0.59c0.53,0.94,1.07,0.9,1.67-0.04
		c1.48-2.32,3.62-3.4,6.33-3.48c1.37-0.04,2.72,0.03,4.02,0.5c3.09,1.13,4.86,3.38,5.46,6.58c0.15,0.82,0.22,1.67,0.22,2.5
		c0.02,5.4,0.01,10.8,0.01,16.2C205.19,245.29,205.15,245.64,205.12,246.09z"
    />
    <path
      d="M126.82,246.09c-2.11,0-4.01,0-5.89,0c-0.26-0.91-0.35-24.35-0.12-26.27c0.84-0.25,4.05-0.31,5.8-0.09
		c0.03,0.43,0.07,0.89,0.08,1.36c0.01,0.43,0,0.87,0,1.25c0.64,0.36,1.02,0.21,1.32-0.28c2.43-3.95,10.6-4.12,13.34,0.52
		c0.61,1.03,1.1,1.01,1.74,0.02c1.49-2.31,3.64-3.43,6.35-3.43c1.15,0,2.33,0.05,3.44,0.33c3.32,0.84,5.68,3.66,6.08,7.11
		c0.07,0.63,0.11,1.28,0.11,1.91c0.01,5.4,0.01,10.8,0,16.2c0,0.43-0.04,0.86-0.07,1.35c-2,0-3.9,0-5.91,0
		c-0.02-0.46-0.06-0.88-0.06-1.31c0-5.08,0.01-10.16-0.01-15.24c0-0.71-0.06-1.44-0.22-2.14c-0.32-1.49-1.26-2.5-2.71-2.93
		c-3.07-0.91-6.37,0.37-7,4.17c-0.12,0.71-0.18,1.43-0.18,2.14c-0.02,4.64-0.01,9.28-0.01,13.92c0,0.43,0,0.86,0,1.37
		c-2.01,0-3.91,0-5.92,0c-0.02-0.5-0.07-0.96-0.07-1.42c0-5.04,0-10.08-0.01-15.12c0-0.68-0.06-1.36-0.18-2.02
		c-0.18-0.98-0.67-1.79-1.47-2.4c-2.44-1.86-6.5-0.87-7.79,1.91c-0.5,1.07-0.65,2.21-0.64,3.39c0.01,4.76,0,9.52,0,14.28
		C126.82,245.12,126.82,245.55,126.82,246.09z"
    />
  </svg>,
  'HousingCapasAceroBlindadoIcon',
);

export default HousingCapasAceroBlindadoIcon;
