import { createSvgIcon } from '@mui/material';

const HousingAnclajeIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M79.37,58.19c0.57-0.24,0.9-0.36,1.22-0.51c7.52-3.54,15.03-7.09,22.56-10.59c0.83-0.39,1.06-0.87,1.05-1.74
			c-0.05-3.4-0.03-6.8-0.01-10.2c0.01-2.31,1.64-3.84,3.9-3.73c1.82,0.09,3.25,1.5,3.42,3.4c0.03,0.36,0.02,0.72,0.02,1.08
			c0,3.24,0.03,6.48-0.02,9.72c-0.01,0.88,0.3,1.35,1.05,1.77c9.63,5.45,19.25,10.93,28.87,16.4c3.26,1.86,6.52,3.72,9.8,5.56
			c1.06,0.6,1.84,1.4,2.07,2.63c0.33,1.77-0.38,3.23-2.02,4.08c-2.06,1.06-4.14,2.08-6.21,3.12c-4.29,2.14-8.57,4.3-12.88,6.4
			c-0.75,0.37-1.03,0.79-1.03,1.64c0.03,8.6,0.03,17.2,0,25.8c0,0.9,0.32,1.34,1.08,1.74c6.14,3.25,12.27,6.54,18.4,9.82
			c0.39,0.21,0.79,0.4,1.15,0.65c2.2,1.51,2.13,4.74-0.13,6.16c-0.3,0.19-0.63,0.34-0.95,0.5c-22.91,11.16-45.82,22.31-68.72,33.49
			c-1.8,0.88-3.34,0.81-5.07-0.19c-13.77-8.02-27.58-15.99-41.38-23.98c-0.35-0.2-0.69-0.4-1.03-0.61c-1.32-0.8-1.95-1.97-1.84-3.51
			c0.11-1.5,0.91-2.53,2.27-3.11c1.36-0.58,2.73-1.12,4.1-1.67c4.67-1.88,9.33-3.76,14-5.65c1.25-0.5,1.25-0.51,1.25-1.79
			c0-8.76-0.02-17.52,0.03-26.28c0.01-1-0.37-1.49-1.18-1.95c-5.93-3.4-11.83-6.83-17.75-10.26c-0.41-0.24-0.84-0.46-1.23-0.74
			c-2.17-1.57-2.03-4.78,0.27-6.16c0.34-0.2,0.71-0.36,1.07-0.53c11.67-5.5,23.34-11,35.01-16.5c0.18-0.09,0.35-0.19,0.54-0.25
			c0.76-0.24,1.01-0.71,1-1.53c-0.05-3.72-0.02-7.44-0.02-11.16c0-0.36,0.01-0.72,0-1.08c-0.08-2.22,1.79-3.81,3.65-3.78
			c2.14,0.03,3.67,1.59,3.69,3.87c0.02,2.72,0,5.44,0.01,8.16C79.37,57.11,79.37,57.55,79.37,58.19z M111.52,55.28
			c0,0.71,0,1.14,0,1.56c0,4.32,0,8.64,0,12.96c0,0.4,0.02,0.8-0.03,1.2c-0.23,1.81-1.63,3.12-3.45,3.26
			c-1.64,0.13-3.27-1-3.71-2.63c-0.13-0.49-0.14-1.03-0.14-1.54c-0.01-4.6-0.01-9.2-0.01-13.8c0-0.42,0-0.85,0-1.47
			c-0.55,0.22-0.92,0.34-1.26,0.51c-7.52,3.54-15.02,7.09-22.55,10.6c-0.79,0.37-1.01,0.82-1.01,1.64
			c0.03,5.16,0.02,10.32,0.02,15.48c0,0.36,0.02,0.72-0.01,1.08c-0.16,1.8-1.56,3.21-3.34,3.38c-1.7,0.17-3.38-0.94-3.84-2.62
			c-0.15-0.53-0.15-1.11-0.15-1.66c-0.01-3.96,0-7.92-0.01-11.88c0-0.41-0.05-0.83-0.09-1.43c-9.28,4.37-18.36,8.65-27.66,13.03
			c0.56,0.35,0.91,0.59,1.28,0.81c11.03,6.39,22.06,12.76,33.08,19.18c0.8,0.47,1.39,0.44,2.19,0.04
			c12.18-6.1,24.37-12.17,36.56-18.24c7.72-3.85,15.45-7.69,23.17-11.54c0.37-0.18,0.71-0.4,1.26-0.72
			C131.63,66.7,121.7,61.04,111.52,55.28z M61.67,143.98c0.68-0.31,1.18-0.53,1.67-0.77c12.08-5.83,24.16-11.66,36.24-17.49
			c7.73-3.73,15.46-7.47,23.19-11.19c0.6-0.29,1.07-0.53,1.06-1.37c-0.03-7.48-0.02-14.95-0.03-22.43c0-0.25-0.1-0.5-0.17-0.84
			c-0.5,0.23-0.9,0.39-1.29,0.59c-13.47,6.71-26.95,13.4-40.41,20.15c-1.75,0.88-3.27,0.86-4.96-0.14
			c-4.63-2.76-9.32-5.42-13.99-8.12c-0.39-0.23-0.8-0.43-1.33-0.71C61.67,115.84,61.67,129.78,61.67,143.98z M65.87,150.22
			c0.53,0.34,0.83,0.54,1.14,0.72c3.87,2.24,7.75,4.47,11.6,6.75c0.77,0.46,1.37,0.5,2.19,0.09c8.53-4.2,17.08-8.35,25.62-12.51
			c11.27-5.49,22.55-10.99,33.82-16.48c0.37-0.18,0.73-0.4,1.22-0.67c-0.49-0.29-0.79-0.49-1.1-0.66
			c-3.98-2.13-7.97-4.24-11.93-6.41c-0.8-0.44-1.42-0.4-2.22-0.02c-19.66,9.51-39.34,19-59.01,28.5
			C66.83,149.71,66.45,149.91,65.87,150.22z M44.92,137.91c1.15,1.06,8.44,5.19,9.29,5.28c0-2.95,0-5.89,0-9.03
			C51.03,135.38,47.99,136.61,44.92,137.91z"
    />
    <path
      d="M185.97,164.09c0-0.48,0-0.92,0-1.36c0-2.64-0.01-5.28,0-7.92c0.01-2.22,1.52-3.8,3.6-3.82
			c2.13-0.03,3.7,1.56,3.73,3.82c0.02,1.48,0,2.96,0.01,4.44c0,0.39,0.03,0.77,0.06,1.3c0.5-0.2,0.86-0.32,1.22-0.49
			c7.05-3.32,14.08-6.66,21.15-9.94c0.93-0.43,1.19-0.95,1.17-1.91c-0.06-2.8-0.04-5.6-0.01-8.4c0.01-1.61,0.94-2.9,2.36-3.43
			c2.4-0.89,4.89,0.79,4.96,3.43c0.06,2.4,0,4.8,0.02,7.2c0.03,2.37-0.38,1.72,1.72,2.91c12.81,7.3,25.62,14.59,38.45,21.86
			c1.25,0.71,2.21,1.57,2.43,3.06c0.05,0.35,0.06,0.72,0.06,1.08c0,18.12-0.01,36.24,0.01,54.36c0,2.04-0.87,3.34-2.71,4.23
			c-17.93,8.7-35.84,17.44-53.76,26.17c-5.1,2.48-10.21,4.94-15.29,7.47c-1.55,0.77-2.93,0.72-4.42-0.14
			c-14.1-8.19-28.2-16.36-42.32-24.51c-1.58-0.91-2.31-2.14-2.3-3.99c0.03-17.92,0.03-35.84,0-53.76c0-2.03,0.86-3.26,2.66-4.11
			c11.86-5.56,23.71-11.15,35.56-16.74C184.85,164.65,185.38,164.38,185.97,164.09z M196.69,259.14c0.72-0.33,1.18-0.54,1.64-0.76
			c8.9-4.34,17.81-8.67,26.71-13.01c11.13-5.42,22.26-10.85,33.39-16.26c0.68-0.33,1.14-0.64,1.14-1.57
			c-0.04-15.04-0.02-30.07-0.03-45.11c0-0.3-0.06-0.59-0.11-1.01c-0.48,0.21-0.84,0.35-1.19,0.52
			c-20.13,10.03-40.25,20.06-60.39,30.06c-0.87,0.43-1.19,0.93-1.19,1.91c0.03,14.6,0.02,29.19,0.02,43.79
			C196.69,258.09,196.69,258.48,196.69,259.14z M216.88,157.7c-8.01,3.77-15.75,7.42-23.57,11.1c0,0.49,0,0.93,0,1.36
			c0,6.36,0,12.72,0,19.08c0,0.44,0.02,0.88-0.03,1.32c-0.26,2.4-2.7,3.9-4.95,3.05c-1.49-0.56-2.36-1.88-2.36-3.67
			c-0.01-5.4,0-10.8-0.01-16.2c0-0.42-0.03-0.83-0.06-1.45c-9.46,4.45-18.72,8.8-28.12,13.25c0.45,0.31,0.7,0.5,0.98,0.66
			c11.17,6.47,22.34,12.93,33.49,19.41c0.67,0.39,1.19,0.38,1.88,0.04c19.98-9.97,39.96-19.93,59.94-29.89
			c0.35-0.17,0.68-0.37,1.05-0.58c-1.04-0.91-29.88-17.24-30.89-17.48c0,0.46,0,0.88,0,1.31c0,5.16,0,10.32,0,15.48
			c0,0.4,0.02,0.8-0.02,1.2c-0.19,1.77-1.56,3.15-3.27,3.3c-1.79,0.16-3.36-0.84-3.88-2.54c-0.15-0.49-0.17-1.02-0.17-1.54
			c-0.01-5.2-0.01-10.4-0.01-15.6C216.88,158.89,216.88,158.46,216.88,157.7z M189.35,258.58c0-0.63,0-1.03,0-1.42
			c0-14.51-0.01-29.02,0.02-43.53c0-0.87-0.25-1.36-1.02-1.79c-4.1-2.32-8.16-4.71-12.24-7.07c-7.08-4.1-14.17-8.21-21.26-12.3
			c-0.39-0.23-0.74-0.6-1.32-0.5c-0.02,0.34-0.05,0.66-0.05,0.97c0,14.63,0,29.26-0.02,43.89c0,0.78,0.23,1.22,0.92,1.61
			c11.24,6.48,22.47,12.99,33.7,19.49C188.41,258.12,188.76,258.28,189.35,258.58z"
    />
    <path
      d="M31.74,209.71c0.02-1.24,0.76-2.38,2.19-3.07c1.8-0.87,3.6-1.73,5.4-2.59
			c58.16-27.89,116.33-55.78,174.49-83.67c16.75-8.03,33.49-16.06,50.24-24.09c1.03-0.49,2.06-0.88,3.22-0.5
			c1.41,0.47,2.32,1.39,2.57,2.87c0.25,1.51-0.23,2.77-1.49,3.67c-0.35,0.25-0.77,0.43-1.16,0.62
			c-42.32,20.29-84.63,40.59-126.95,60.88c-34.11,16.35-68.21,32.7-102.32,49.05c-0.4,0.19-0.79,0.4-1.2,0.55
			C34.28,214.33,31.78,212.63,31.74,209.71z"
    />
    <path
      d="M214.73,215.65c0-0.36-0.01-0.72,0-1.08c0.05-1.98,1.38-3.46,3.26-3.63c1.95-0.18,3.64,0.94,3.94,2.86
			c0.2,1.24,0.19,2.56-0.01,3.79c-0.3,1.83-2.1,3.05-3.85,2.86c-1.92-0.21-3.27-1.67-3.33-3.61
			C214.72,216.45,214.73,216.05,214.73,215.65z"
    />
    <path
      d="M247.74,198.98c1.86,0.02,3.15,0.89,3.71,2.28c0.57,1.41,0.25,3.1-0.9,4.06c-1.12,0.93-2.44,1.52-3.93,1.55
			c-1.52,0.03-2.8-0.87-3.36-2.26c-0.55-1.37-0.27-2.99,0.82-3.98C245.2,199.61,246.59,199.14,247.74,198.98z"
    />
    <path
      d="M240.26,230.59c-1.45-0.07-2.48-1.08-3.23-2.6c-0.75-1.51-0.96-2.95,0.07-4.39c1.01-1.42,2.88-1.9,4.48-1.18
			c1.72,0.77,3.06,3.48,2.63,5.32C243.8,229.47,242.33,230.62,240.26,230.59z"
    />
    <path
      d="M215.19,240.58c0.01,1.7-1.02,3.08-2.64,3.56c-1.53,0.45-3.2-0.15-4.06-1.52c-0.38-0.6-0.7-1.26-0.95-1.92
			c-0.67-1.78,0.13-3.72,1.81-4.54c1.7-0.83,3.69-0.27,4.72,1.32C214.8,238.63,215.18,239.69,215.19,240.58z"
    />
    <path
      d="M163.37,207.85c0,0.44,0.02,0.88,0,1.32c-0.09,1.8-1.47,3.25-3.23,3.43c-1.81,0.18-3.47-0.88-3.91-2.63
			c-0.35-1.4-0.36-2.86,0-4.26c0.45-1.75,2.11-2.81,3.92-2.62c1.76,0.19,3.13,1.64,3.22,3.44
			C163.39,206.97,163.37,207.41,163.37,207.85z"
    />
    <path
      d="M180.93,231.15c-1.68,0-3.14-1.11-3.54-2.68c-0.41-1.62,0.25-3.32,1.7-4.12c0.73-0.4,1.52-0.72,2.31-0.96
			c1.58-0.47,3.28,0.26,4.11,1.69c0.83,1.43,0.55,3.38-0.65,4.51C184.06,230.35,182.03,231.15,180.93,231.15z"
    />
    <path
      d="M163.53,236.61c-0.08-0.01-0.28-0.02-0.48-0.03c-2.41-0.18-4.69-4.55-3.05-6.83
			c1.49-2.06,4.54-2.22,6.01-0.14c0.5,0.7,0.9,1.52,1.15,2.35C167.9,234.32,166.12,236.62,163.53,236.61z"
    />
  </svg>,
  'HousingAnclajeIcon',
);

export default HousingAnclajeIcon;
