import getRoleColor, { getRoleName } from '@/utils/roles';
import TruncatedTypography from '@components/TruncatedTypography';
import UserTooltip from '@components/tooltips/UserTooltip';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Box,
  Checkbox,
  CheckboxProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { type FC } from 'react';

interface Props {
  style?: React.CSSProperties;
  lastItem?: boolean;
  userId: number;
  userName: string;
  userEmail: string;
  userOrganization?: {
    id: number;
    name: string;
    roleId: number;
  } | null;
  onClick?: () => void;
  hasAccess?: boolean;
  modified?: boolean;
  selected?: boolean;
  checkboxColor?: CheckboxProps['color'];
}

const UsersListItem: FC<Props> = ({
  lastItem = false,
  style,
  userId,
  userName,
  userEmail,
  userOrganization,
  onClick,
  hasAccess: checked,
  modified,
  selected,
  checkboxColor,
}) => {
  const { i18n } = useLingui();
  const hasCheckbox = checked != null;
  return (
    <ListItem
      disablePadding
      style={style}
      sx={{
        borderBottom: lastItem ? null : '1px solid',
        borderColor: 'divider',
      }}
    >
      <UserTooltip userId={userId} placement="right-end">
        <ListItemButton
          selected={selected}
          onClick={onClick}
          sx={{ height: '100%', width: '100%', display: 'flex' }}
        >
          <Box width={hasCheckbox ? 'calc(100% - 125px)' : '100%'}>
            <TruncatedTypography fontSize={14}>
              {userId} - {userName}
              <Typography ml={1} component="span" fontSize={12} color="text.secondary">
                {userEmail}
              </Typography>
            </TruncatedTypography>
            <TruncatedTypography fontSize={12} color="text.secondary">
              {userOrganization ? (
                <>
                  {userOrganization.name}
                  <TruncatedTypography
                    ml={1}
                    color={getRoleColor(userOrganization.roleId)}
                    component="span"
                    fontSize={12}
                  >
                    {getRoleName(userOrganization.roleId, i18n)}
                  </TruncatedTypography>
                </>
              ) : (
                <Typography
                  alignSelf="center"
                  fontSize="small"
                  color="text.secondary"
                  fontStyle="italic"
                >
                  <Trans>Sin organización</Trans>
                </Typography>
              )}
            </TruncatedTypography>
          </Box>
          {hasCheckbox && (
            <Box display="flex" flex={1} justifyContent="right" alignItems="center">
              {modified && (
                <Typography fontSize="small" fontStyle="italic" color="text.secondary">
                  <Trans>(editado)</Trans>
                </Typography>
              )}
              {checked != null && (
                <ListItemIcon>
                  <Checkbox edge="end" checked={checked} tabIndex={-1} color={checkboxColor} />
                </ListItemIcon>
              )}
            </Box>
          )}
        </ListItemButton>
      </UserTooltip>
    </ListItem>
  );
};

export default UsersListItem;
