import { MetricField } from '@/__generated__/graphql';

/**
 * Returns a list of up to 4 most important metrics in order of priority, from most important to least important.
 * The function checks the available fields and adds them to the priority list based on predefined importance.
 *
 * @param availableFields - An array of MetricField representing the available metrics for the device.
 * @returns An array containing the prioritized metrics.
 */
export const getMetricsPriorityList = (availableFields: MetricField[]) => {
  const priorityList = new Set<MetricField>();

  const addIfIsIncluded = (metric: MetricField) => {
    if (availableFields.includes(metric)) priorityList.add(metric);
  };

  const addIf = (condition: boolean, metric: MetricField) => {
    if (condition) priorityList.add(metric);
  };

  // I-L-Vol
  addIfIsIncluded(MetricField.StoredVolume);

  // NV
  addIfIsIncluded(MetricField.SnowLevel);
  addIfIsIncluded(MetricField.SnowTemperature);
  addIfIsIncluded(MetricField.SnowWeight);

  // WQ
  addIfIsIncluded(MetricField.Temperature);
  addIfIsIncluded(MetricField.Orp);
  addIfIsIncluded(MetricField.Ph);

  const hasFlow = availableFields.includes(MetricField.Flow);
  const hasRatingCurves = availableFields.includes(MetricField.RatingCurvesLimits);
  const hasEnabledRatingCurves = availableFields.includes(MetricField.EnabledRatingCurves);
  addIf(hasFlow && !hasRatingCurves, MetricField.Flow);
  addIf(hasRatingCurves && hasEnabledRatingCurves, MetricField.Flow);

  addIfIsIncluded(MetricField.Level);
  addIfIsIncluded(MetricField.Angle);
  addIfIsIncluded(MetricField.Velocity);
  addIfIsIncluded(MetricField.Flow);
  addIfIsIncluded(MetricField.Position);

  return Array.from(priorityList).slice(undefined, 4);
};
