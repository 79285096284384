import TruncatedTypography from '@components/TruncatedTypography';
import { Trans } from '@lingui/react';
import { Close } from '@mui/icons-material';
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';
import { Fragment, isValidElement, type FC } from 'react';

export type ModalTitleProps = {
  onClose?: () => void;
  title?: string | JSX.Element | null;
  subtitle?: string | JSX.Element | null;
  /** @default "normal" */
  variant?: 'normal' | 'tabs';
};

const isTransOrFragmentOrString = (
  element: JSX.Element | string | undefined | null,
): element is JSX.Element | string => {
  if (typeof element === 'string') return true;
  if (isValidElement(element)) {
    return element.type === Trans || element.type === Fragment;
  }
  return false;
};

const ModalTitle: FC<ModalTitleProps> = ({ onClose, title, subtitle, variant }) => {
  return (
    <DialogTitle
      sx={{
        bgcolor: variant === 'tabs' ? '#2a3542' : undefined,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        py: 1,
      }}
    >
      {onClose && (
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <Close />
        </IconButton>
      )}
      <Box maxWidth="100%" display="flex" flexDirection="column" gap={1 / 2} py={1 / 2}>
        {!title && (
          <TruncatedTypography variant="h6" lineHeight={1}>
            Example title
          </TruncatedTypography>
        )}
        {isTransOrFragmentOrString(title) ? (
          <TruncatedTypography variant="h6" lineHeight={1}>
            {title}
          </TruncatedTypography>
        ) : (
          title
        )}
        {isTransOrFragmentOrString(subtitle) ? (
          <TruncatedTypography variant="subtitle1" color="text.secondary" lineHeight={1}>
            {subtitle}
          </TruncatedTypography>
        ) : (
          subtitle
        )}
      </Box>
    </DialogTitle>
  );
};

export default ModalTitle;
