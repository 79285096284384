import { gql } from '@/__generated__';

const DELETE_DEVICE_USER_RELATIONS = gql(`#graphql
  mutation DeleteDeviceUserRelations($inputs: [DeleteDeviceUserRelationInput!]!) {
    deleteDeviceUserRelations(inputs: $inputs) {
      deletedRecordIds
    }
  }
`);

export default DELETE_DEVICE_USER_RELATIONS;
