import { DeviceStatus } from '@/__generated__/graphql';
import { getDeviceStatusOption } from '@/utils/i18n/getDeviceStatus';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import ModalActions from '@components/modal/ModalActions';
import { useUpdateDeviceStatusForm } from '@features/deviceConfigurationModal/hooks/useUpdateDeviceStatusForm';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { ArrowRightAlt } from '@mui/icons-material';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { Suspense, type FC } from 'react';
import { Controller } from 'react-hook-form';
import { TabContent, TabFallback } from '../common';
import { DeviceTabProps } from '../tabProps';

const Component: FC<DeviceTabProps> = (props) => {
  const {
    currentStatus,
    methods: { formState, control, reset },
    onSubmit,
  } = useUpdateDeviceStatusForm(props);
  const { _, i18n } = useLingui();

  return (
    <form onSubmit={onSubmit}>
      <TabContent>
        <Typography variant="h6" mb={2}>
          <Trans>Cambiar estado</Trans>
        </Typography>
        <Box display="grid" gridTemplateColumns="1fr auto 1fr" alignItems="center" gap={2}>
          <Autocomplete
            disabled
            value={currentStatus}
            options={Object.values(DeviceStatus)}
            getOptionLabel={(option) => {
              const { displayName } = getDeviceStatusOption(option, i18n);
              return displayName;
            }}
            renderInput={(props) => {
              return <TextField {...props} label={_(msg`Estado actual`)} />;
            }}
          />
          <ArrowRightAlt sx={{ fill: (theme) => theme.palette.text.secondary }} />
          <Controller
            control={control}
            name="status"
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => field.onChange(value)}
                value={field.value ?? ''}
                disableClearable
                options={Object.values(DeviceStatus)}
                getOptionLabel={(option) => {
                  const { displayName } = getDeviceStatusOption(option, i18n) || {};
                  return displayName;
                }}
                renderInput={(props) => {
                  return (
                    <TextField
                      {...props}
                      label={_(msg`Nuevo estado`)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  );
                }}
              />
            )}
          />
        </Box>
      </TabContent>
      <ModalActions
        onClose={props.onClose}
        onSubmit="submit"
        dirtyForm={formState.isDirty}
        submitLoading={formState.isSubmitting}
        onResetForm={() => reset()}
      />
    </form>
  );
};

const StatusTab: FC<DeviceTabProps> = (props) => {
  return (
    <ApolloErrorBoundary variant="modal">
      <Suspense fallback={<TabFallback {...props} />}>
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default StatusTab;
