import { DeviceStatus } from '@/__generated__/graphql';
import { useGlobalModal } from '@/hooks/useGlobalModal';
import { getDeviceStatusOption } from '@/utils/i18n/getDeviceStatus';
import GlobalModal from '@components/GlobalModal';
import DeviceNameSpan from '@components/deviceNameSpan';
import ModalActions from '@components/modal/ModalActions';
import ModalContent from '@components/modal/ModalContent';
import { Access } from '@features/authorization';
import { useUpdateDeviceStatusForm } from '@features/deviceConfigurationModal/hooks/useUpdateDeviceStatusForm';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { ArrowRightAlt } from '@mui/icons-material';
import { Autocomplete, Box, TextField } from '@mui/material';
import { type FC } from 'react';
import { Controller } from 'react-hook-form';

interface Props {
  deviceId: string;
  onClose: () => void;
}

const Component: FC<Props> = (props) => {
  const { _, i18n } = useLingui();
  const {
    currentStatus,
    methods: { control, formState },
    onSubmit,
  } = useUpdateDeviceStatusForm(props);

  return (
    <form onSubmit={onSubmit}>
      <ModalContent>
        <Box display="grid" gridTemplateColumns="1fr auto 1fr" alignItems="center" gap={2}>
          <Autocomplete
            disabled
            value={currentStatus}
            options={Object.values(DeviceStatus)}
            getOptionLabel={(option) => {
              const { displayName } = getDeviceStatusOption(option, i18n);
              return displayName;
            }}
            renderInput={(props) => {
              return <TextField {...props} label={_(msg`Estado actual`)} />;
            }}
          />
          <ArrowRightAlt sx={{ fill: (theme) => theme.palette.text.secondary }} />
          <Controller
            control={control}
            name="status"
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => field.onChange(value)}
                value={field.value ?? ''}
                disableClearable
                options={Object.values(DeviceStatus)}
                getOptionLabel={(option) => {
                  const { displayName } = getDeviceStatusOption(option, i18n) || {};
                  return displayName;
                }}
                renderInput={(props) => {
                  return (
                    <TextField
                      {...props}
                      label={_(msg`Nuevo estado`)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  );
                }}
              />
            )}
          />
        </Box>
      </ModalContent>
      <ModalActions
        onClose={props.onClose}
        onSubmit="submit"
        dirtyForm={formState.isDirty}
        submitLoading={formState.isSubmitting}
      />
    </form>
  );
};

const UpdateDeviceStatusModal: FC = () => {
  const { globalModalProps, state, onClose } = useGlobalModal('updateDeviceStatusModal');

  return (
    <GlobalModal
      {...globalModalProps}
      dialogProps={{ maxWidth: 'sm' }}
      authorization={
        state.open
          ? {
              access: Access.UpdateDeviceStatus,
              deviceId: state.deviceId,
            }
          : null
      }
      modalTitleProps={{
        title: <Trans>Cambiar estado de dispositivo</Trans>,
        subtitle: state.open ? (
          <>
            {state.deviceId} - <DeviceNameSpan deviceId={state.deviceId} />
          </>
        ) : null,
      }}
    >
      {state.open && <Component deviceId={state.deviceId} onClose={onClose} />}
    </GlobalModal>
  );
};

export default UpdateDeviceStatusModal;
