import { useSuspenseQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { Box, Button, Skeleton, Tooltip, TooltipProps, Typography } from '@mui/material';
import { Suspense, type FC } from 'react';
import USER_TOOLTIP_GET_USER_INFO from '@/graphql/querys/tooltips/getUserInfo';
import ApolloErrorBoundary from '../ApolloErrorBoundary';
import { useDispatch } from 'react-redux';
import { setUserProfileModal } from 'src/slices/modals';

type Props = Omit<TooltipProps, 'title'> & {
  userId: number;
  title?: TooltipProps['title'] | undefined;
};

const UserInfo: FC<{ userId: number }> = ({ userId }) => {
  const dispatch = useDispatch();
  const { data } = useSuspenseQuery(USER_TOOLTIP_GET_USER_INFO, {
    variables: {
      userId,
    },
  });

  const openUserProfile = () => {
    dispatch(
      setUserProfileModal({
        open: true,
        userId,
      }),
    );
  };

  return (
    <Box>
      <Typography sx={{ fontSize: 'inherit' }} fontWeight="bold">
        {data.user.fullName}
      </Typography>
      <Typography sx={{ fontSize: 'inherit' }}>ID: {userId}</Typography>
      <Typography sx={{ fontSize: 'inherit' }}>Email: {data.user.email}</Typography>
      <Typography sx={{ fontSize: 'inherit' }}>
        ORG: {data.user.mainOrganization?.organization.name}
      </Typography>
      <Button
        fullWidth
        size="small"
        sx={{ fontSize: 'inherit', mt: 1, lineHeight: 1 }}
        variant="contained"
        color="info"
        onClick={openUserProfile}
      >
        <Trans>Abrir perfil</Trans>
      </Button>
    </Box>
  );
};

const UserTooltip: FC<Props> = ({ userId, ...props }) => {
  return (
    <Tooltip
      {...props}
      title={
        <ApolloErrorBoundary
          fallback={() => (
            <i>
              <Trans>No se ha encontrado al usuario con id {userId}</Trans>
            </i>
          )}
        >
          <Suspense fallback={<Skeleton width={50} />}>
            <UserInfo userId={userId} />
          </Suspense>
        </ApolloErrorBoundary>
      }
    />
  );
};

export default UserTooltip;
