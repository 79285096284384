import { useModalStatusMessage } from '@/hooks';
import { useMutation } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import ModalActions from '@components/modal/ModalActions';
import UPDATE_DEVICE_USER_RELATIONS from '@features/access/graphql/mutations/updateDeviceUserRelations';
import { DeviceUserValue, UsersAccessForDeviceInput } from '@features/devicesAccessInputs';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Skeleton, Typography } from '@mui/material';
import { Suspense, useEffect, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TabContent, TabFallback } from '../common';
import { DeviceTabProps } from '../tabProps';

type FormValues = {
  deviceId: string;
  updatedRelations: DeviceUserValue[];
};

const Component: FC<DeviceTabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [updateRelations] = useMutation(UPDATE_DEVICE_USER_RELATIONS, {
    onCompleted() {
      methods.reset();
      openModalSuccessMessage(_(msg`Relaciones actualizadas correctamente`));
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      deviceId,
      updatedRelations: [],
    },
  });

  useEffect(() => setIsDirty(methods.formState.isDirty), [methods.formState.isDirty, setIsDirty]);

  const onSubmit = methods.handleSubmit((data) => {
    updateRelations({
      variables: {
        inputs: data.updatedRelations,
      },
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <TabContent>
        <Typography variant="h6" mb={2}>
          <Trans>Usuarios con acceso al dispositivo</Trans>
        </Typography>
        <Box flex={1}>
          <Suspense fallback={<Skeleton height={450} variant="rounded" />}>
            <Controller
              control={methods.control}
              name="updatedRelations"
              render={({ field: { ref, ...props } }) => (
                <UsersAccessForDeviceInput height={380} deviceId={deviceId} {...props} />
              )}
            />
          </Suspense>
        </Box>
      </TabContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        onResetForm={() => methods.reset()}
        submitLoading={methods.formState.isSubmitting}
        dirtyForm={methods.formState.isDirty}
      />
    </form>
  );
};

const AccessTab: FC<DeviceTabProps> = (props) => {
  return (
    <ApolloErrorBoundary variant="modal">
      <Suspense fallback={<TabFallback {...props} />}>
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default AccessTab;
