import { gql } from '@/__generated__';

const UPDATE_DGA_CONFIGURATION = gql(`#graphql
  mutation UpdateDgaConfiguration($deviceId: ID!, $input: UpdateDgaConfigInput!) {
    updateDgaConfiguration(deviceId: $deviceId, input: $input) {
      id
      dga {
        id
        configuration {
          id
          code
          rut
          password
          type
          frequency
          hour
          weekday
          day
          enabled
        }
      }
    }
  }
`);

export default UPDATE_DGA_CONFIGURATION;
