import { type FC } from 'react';
import { type DeviceRatingCurvesCurve } from '@/__generated__/graphql';
import Echarts, { EChartsOptionBuilder } from '@features/echarts';
import { Box } from '@mui/material';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';
import { ratingFactory } from '@features/deviceDataConfigurationModal/utils/ratingFactory';

interface Props {
  selectedCurvesIndexes: number[];
  curves: DeviceRatingCurvesCurve[];
}

const CurvesPreview: FC<Props> = ({ selectedCurvesIndexes, curves: _curves }) => {
  const { _ } = useLingui();
  // to display when the max is not defined
  const curves = _curves.map((curve) => {
    if (curve.levelMax == null) {
      return { ...curve, levelMax: 150 };
    } else return { ...curve };
  });

  const displayedCurves =
    selectedCurvesIndexes.length > 0
      ? curves.filter((_, index) => selectedCurvesIndexes.includes(index))
      : curves.filter((curve) => curve.active);
  const newLineValues: number[] = [];
  const series: [number, number][] = [];
  const ratingMaxValue = displayedCurves.reduce((acc, curve) => {
    if (curve.levelMin != null) newLineValues.push(curve.levelMin);
    if (curve.levelMax != null) newLineValues.push(curve.levelMax);
    return Math.max(acc, curve.levelMax ?? 0);
  }, 0);

  for (let i = 0; i <= (ratingMaxValue > 0 ? ratingMaxValue : 150); i++) {
    const curveInRange = displayedCurves.find(
      (curve) => curve.levelMin! <= i && curve.levelMax! >= i,
    );
    if (curveInRange != null) {
      const { q } = ratingFactory(curveInRange.formula);

      series.push([i, q(i)]);
    }
  }

  const option = new EChartsOptionBuilder({
    toolbox: {
      disabled: true,
    },
    override(option) {
      return {
        ...option,
        legend: {
          show: false,
        },
      };
    },
  });

  option
    .addXAxis(
      {
        type: 'category',
        id: 'category',
      },
      (value) =>
        ({
          ...value,
          axisLabel: {
            ...value.axisLabel,
            formatter: '{value} cm',
          },
          tooltip: {
            show: true,
          },
          boundaryGap: true,
        }) as typeof value,
    )
    .addYAxis({
      type: 'value',
      unit: 'L/s',
      id: 'value',
    })
    .addSeries([
      {
        meta: {
          dataType: 'flow',
          name: _(msg`Caudal`),
          type: 'area',
          data: series,
          xAxisId: 'category',
          yAxisId: 'value',
        },
      },
    ]);

  return (
    <Box height={300}>
      <Echarts option={option} />
    </Box>
  );
};

export default CurvesPreview;
