import { gql } from '@/__generated__';

const UPDATE_DEVICE_USER_RELATIONS = gql(`#graphql
  mutation UpdateDeviceUserRelations($inputs: [UpdateDeviceUserRelationInput!]!) {
    updateDeviceUserRelations(inputs: $inputs) {
      updatedRecords {
        device {
          id
        }
        id
        user {
          id
        }
        hasAccess
      }
    }
  }
`);

export default UPDATE_DEVICE_USER_RELATIONS;
