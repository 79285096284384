import useHasDGAConfiguration from '@/hooks/useHasDGAConfiguration';
import useIsRegis from '@/hooks/useIsRegis';
import {
  setDeprecatedCreateMeasurementModal,
  setSaveHardwareConfigurationModal,
  setUpdateDGAConfigurationModal,
  setUpdateDeviceAlertsModal,
  setUpdateDeviceConfigurationModal,
  setUpdateDeviceDataConfigurationModal,
} from '@/slices/modals';
import { Access } from '@features/authorization';
import StandardMenu from '@features/standardDesign/components/StandardMenu';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Settings } from '@mui/icons-material';
import { ComponentType, PropsWithChildren, type FC } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  deviceId: string;
  CustomIcon?: ComponentType;
}

interface WrapperProps extends PropsWithChildren<Props> {}

const IsRegisWrapper: FC<WrapperProps> = ({ children, deviceId }) => {
  const isRegis = useIsRegis(deviceId);
  return isRegis ? children : null;
};

const HasDGAConfigurationWrapper: FC<WrapperProps> = ({ children, deviceId }) => {
  const hasDGAConfiguration = useHasDGAConfiguration(deviceId);
  return hasDGAConfiguration ? children : null;
};

const DeviceMenu: FC<Props> = ({ deviceId, CustomIcon }) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();

  const openUpdateDeviceConfigurationModal = () => {
    dispatch(
      setUpdateDeviceConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openUpdateDeviceDataConfigurationModal = () => {
    dispatch(
      setUpdateDeviceDataConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openUpdateDeviceAlertsModal = () => {
    dispatch(
      setUpdateDeviceAlertsModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openCreateMeasurementModal = () => {
    dispatch(
      setDeprecatedCreateMeasurementModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openMeasurementsTableModal = () => {
    dispatch(
      setDeprecatedCreateMeasurementModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openUpdateDGAConfigurationModal = () => {
    dispatch(
      setUpdateDGAConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openUpdateDeviceHardwareConfigurationModal = () => {
    dispatch(
      setSaveHardwareConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  return (
    <StandardMenu
      deviceId={deviceId}
      items={[
        {
          id: 1,
          access: Access.DeviceConfigurationModal,
          label: _(msg`Configuración de dispositivo`),
          onClick: openUpdateDeviceConfigurationModal,
        },
        {
          id: 2,
          access: Access.DeviceDataConfigurationModal,
          label: _(msg`Configuración de datos`),
          onClick: openUpdateDeviceDataConfigurationModal,
        },
        {
          id: 7,
          access: Access.SaveHardwareConfigurationModal,
          label: _(msg`Configuración de hardware`),
          onClick: openUpdateDeviceHardwareConfigurationModal,
        },
        {
          id: 3,
          access: Access.DeviceAlertsConfigurationModal,
          label: _(msg`Configuración de alertas`),
          onClick: openUpdateDeviceAlertsModal,
        },
        {
          id: 4,
          label: _(msg`Ingreso manual de datos`),
          onClick: openCreateMeasurementModal,
          Wrapper: ({ children }) => (
            <IsRegisWrapper deviceId={deviceId}>{children}</IsRegisWrapper>
          ),
        },
        {
          id: 5,
          label: _(msg`Ver tabla de datos`),
          onClick: openMeasurementsTableModal,
          Wrapper: ({ children }) => (
            <IsRegisWrapper deviceId={deviceId}>{children}</IsRegisWrapper>
          ),
        },
        {
          id: 6,
          label: _(msg`Configuración DGA`),
          onClick: openUpdateDGAConfigurationModal,
          Wrapper: ({ children }) => (
            <HasDGAConfigurationWrapper deviceId={deviceId}>{children}</HasDGAConfigurationWrapper>
          ),
        },
      ]}
    >
      {CustomIcon ? <CustomIcon /> : <Settings fontSize="large" />}
    </StandardMenu>
  );
};

export default DeviceMenu;
