import { gql } from '@/__generated__';

const GET_DEVICE_AUDIT_LOGS = gql(`#graphql
  query DeviceProfileModal_GetDeviceAuditLogs(
    $deviceId: ID!,
  ) {
    device(input: {
      id: $deviceId,
    }) {
      id
      profile {
        id
        auditLogs {
          id
          timestamp
          action {
            type
          }
          entity {
            id
            type
          }
          responsible {
            id
            type
            displayName
          }
        }
      }
    }
  }
`);

export default GET_DEVICE_AUDIT_LOGS;
