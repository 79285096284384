import { DeviceStatus } from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import UPDATE_DEVICE_GENERAL from '@features/deviceConfigurationModal/graphql/mutations/updateDeviceGeneral';
import { GET_UPDATE_DEVICE_STATUS_FIELDS } from '@features/deviceConfigurationModal/graphql/queries/getUpdateDeviceStatusFields';
import { zodResolver } from '@hookform/resolvers/zod';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { DeviceTabProps } from '../components/tabProps';

const schema = z.object({
  deviceId: z.string(),
  status: z.nativeEnum(DeviceStatus),
});

type FormValues = z.infer<typeof schema>;

interface Props {
  deviceId: string;
  setIsDirty?: DeviceTabProps['setIsDirty'];
}

export const useUpdateDeviceStatusForm = (props: Props) => {
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const { data } = useSuspenseQuery(GET_UPDATE_DEVICE_STATUS_FIELDS, {
    variables: { deviceId: props.deviceId },
  });
  const { _ } = useLingui();
  const [update] = useMutation(UPDATE_DEVICE_GENERAL, {
    onCompleted(data) {
      openModalSuccessMessage(_(msg`Dispositivo actualizado correctamente`));
      methods.reset({
        deviceId: data.updateDevice.profile.id,
        status: data.updateDevice.profile.status,
      });
      methods.reset();
    },
    onError(error) {
      openModalErrorMessage(_(msg`Error al actualizar el dispositivo: ${error.message}`));
    },
    update(cache) {
      cache.modify({
        id: cache.identify({ __typename: 'Device', id: props.deviceId }),
        fields: {
          profile(_, { INVALIDATE }) {
            return INVALIDATE;
          },
        },
      });
    },
    awaitRefetchQueries: true,
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      deviceId: props.deviceId,
      status: data.device.profile.status,
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = methods.handleSubmit(async (data) => {
    return await update({
      variables: {
        deviceId: data.deviceId,
        input: {
          status: data.status,
        },
      },
    });
  });

  return {
    currentStatus: data.device.profile.status,
    onSubmit,
    methods,
  };
};
