import { useGlobalModal } from '@/hooks/useGlobalModal';
import DeviceModalTitle from '@components/DeviceModalTitle';
import GlobalModal from '@components/GlobalModal';
import ModalTabs, { Panel } from '@components/ModalTabs';
import { Access } from '@features/authorization';
import { type I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type FC } from 'react';
import CalibrationTab from './tabs/calibrationTab';
import FiltersTab from './tabs/filtersTab';
import FlowLimitsTab from './tabs/flowLimitsTab';
import MathModelingTab from './tabs/mathModelingTab';
import RadarParametersTab from './tabs/radarTab';
import RatingCurvesTab from './tabs/ratingCurvesTab';
import { type DeviceTabProps } from './tabs/types';

const DeviceDataConfigurationModal: FC = () => {
  const { i18n } = useLingui();
  const { globalModalProps, onClose, state, setIsDirty, changeTabMiddleware } = useGlobalModal(
    'updateDeviceDataConfigurationModal',
    {
      closeConfirmation: true,
    },
  );

  return (
    <GlobalModal
      {...globalModalProps}
      dialogProps={{
        maxWidth: 'xl',
      }}
      authorization={{
        access: Access.DeviceDataConfigurationModal,
        deviceId: state.open ? state.deviceId : undefined,
        onClose,
      }}
    >
      {state.open && (
        <>
          <DeviceModalTitle onClose={onClose} deviceId={state.deviceId} />
          <ModalTabs
            changeTabMiddleware={changeTabMiddleware}
            deviceId={state.deviceId}
            panels={getTabs(state.deviceId, onClose, setIsDirty, i18n)}
          />
        </>
      )}
    </GlobalModal>
  );
};

const getTabs = (
  deviceId: string,
  onClose: () => void,
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>,
  i18n: I18n,
): Array<Panel> => {
  const props: DeviceTabProps = {
    deviceId,
    onClose,
    setIsDirty,
  };
  return [
    {
      key: 'calibration',
      label: t(i18n)`General`,
      component: <CalibrationTab {...props} />,
      access: Access.DeviceDataConfiguration_CalibrationTab,
    },
    {
      key: 'flow-limits',
      label: t(i18n)`Límites de Caudal`,
      component: <FlowLimitsTab {...props} />,
      access: Access.DeviceDataConfiguration_FlowLimitTab,
    },
    {
      key: 'filters',
      label: t(i18n)`Filtros`,
      component: <FiltersTab {...props} />,
      access: Access.DeviceDataConfiguration_FiltersTab,
    },
    {
      key: 'rating-curves',
      label: t(i18n)`Curvas de Aforo`,
      component: <RatingCurvesTab {...props} />,
      access: Access.DeviceDataConfiguration_RatingCurvesTab,
    },
    {
      key: 'math-modeling',
      label: t(i18n)`Modelamientos Matemáticos`,
      component: <MathModelingTab {...props} />,
      access: Access.DeviceDataConfiguration_MathModelingTab,
    },
    {
      key: 'radar',
      label: t(i18n)`Parámetros Radar`,
      component: <RadarParametersTab {...props} />,
      access: Access.DeviceDataConfiguration_RadarTab,
    },
  ];
};

export default DeviceDataConfigurationModal;
