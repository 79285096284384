import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonProps, DialogActions } from '@mui/material';
import { type FC } from 'react';

type ModalButtonProps = ButtonProps & {
  label?: string;
  loading?: boolean;
};

export type ModalActionsProps = {
  onClose?: () => void;
  dirtyForm?: boolean;
  onResetForm?: ButtonProps['onClick'] | 'reset';
  /**
   * Use 'submit' if you have the submit function
   * on a form element, otherwise pass the function
   */
  onSubmit?: ButtonProps['onSubmit'] | 'submit';
  submitLoading?: boolean;
  submitLabel?: string;
  submitColor?: ButtonProps['color'];
  submitDisabled?: boolean;
  closeButton?: ModalButtonProps;
  submitButton?: ModalButtonProps;
  customButtons?: ModalButtonProps[];
  rightButtons?: ModalButtonProps[];
};

const ModalActions: FC<ModalActionsProps> = (props) => {
  const { _ } = useLingui();
  const ModalButton: FC<ModalButtonProps> = ({ loading, label, ...props }) => {
    if (loading != null)
      return (
        <LoadingButton loading={loading} {...props}>
          {label || ''}
        </LoadingButton>
      );
    else return <Button {...props}>{label || ''}</Button>;
  };

  return (
    <DialogActions sx={{ alignItems: 'center', justifyContent: 'space-between', mx: 2 }}>
      {props.onResetForm != null && (
        <Button
          disabled={props.dirtyForm === false}
          {...(props.onResetForm === 'reset' ? { type: 'reset' } : { onClick: props.onResetForm })}
          variant="outlined"
          color="info"
        >
          <Trans>Restablecer formulario</Trans>
        </Button>
      )}
      {props.customButtons?.map((props, index) => (
        <ModalButton variant="outlined" color="info" key={props.label ?? index} {...props} />
      ))}
      <Box sx={{ flexGrow: 1 }}></Box>
      {props.onClose != null || props.closeButton != null ? (
        <ModalButton
          onClick={props.onClose}
          variant="outlined"
          color="info"
          label={_(msg`Cerrar`)}
          {...props.closeButton}
        />
      ) : null}
      {props.rightButtons?.map((props, index) => (
        <ModalButton variant="outlined" color="info" key={props.label ?? index} {...props} />
      ))}
      {props.onSubmit != null || props.submitButton != null ? (
        <ModalButton
          loading={props.submitLoading}
          {...(props.onSubmit === 'submit' ? { type: 'submit' } : { onClick: props.onSubmit })}
          color={props.submitColor || 'primary'}
          variant="contained"
          disabled={props.submitDisabled ?? props.dirtyForm === false}
          label={props.submitLabel ?? _(msg`Guardar`)}
          {...props.submitButton}
        />
      ) : null}
    </DialogActions>
  );
};

export default ModalActions;
