import { useEffect } from 'react';

/**
 * Prevent user from leaving the page if the form is dirty
 */
const useExitPageConfirmation = (isDirty: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      if (isDirty) event.preventDefault();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);
};

export default useExitPageConfirmation;
