export const radarFormSelectOptions = {
  curveType: [
    {
      label: 'Función',
      value: 'function',
    },
    {
      label: 'Función en intervalo',
      value: 'interval',
    },
    {
      label: 'Piecewise',
      value: 'piecewise',
    },
  ],
  velocityModel: [
    {
      label: 'index-velocity',
      value: 'index-velocity',
    },
    {
      label: 'N(h)',
      value: 'N(h)',
    },
  ],
  functionType: {
    variant1: [
      {
        label: 'Modelo de Chiu',
        value: 'chiu',
      },
      {
        label: 'Polinomial',
        value: 'polynomial',
      },
      {
        label: 'Potencial',
        value: 'power',
      },
    ],
    variant2: [
      {
        label: 'Polinomial',
        value: 'polynomial',
      },
      {
        label: 'Potencial',
        value: 'power',
      },
    ],
    variant3: [
      {
        label: 'Trapezoidal',
        value: 'trapezoidal',
      },
      {
        label: 'Polinomial',
        value: 'polynomial',
      },
      {
        label: 'Potencial',
        value: 'power',
      },
    ],
  },
};
