import { useModalStatusMessage } from '@/hooks';
import { useGlobalModal } from '@/hooks/useGlobalModal';
import { setUpdateDeviceConfigurationModal } from '@/slices/modals';
import { useMutation } from '@apollo/client';
import GlobalModal from '@components/GlobalModal';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { Access } from '@features/authorization';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { FormEventHandler, MouseEventHandler, useState, type FC } from 'react';
import { useDispatch } from 'react-redux';
import DELETE_DEVICE from '../../graphql/mutations/deleteDevice';

interface Props {
  deviceId: string;
  onClose: () => void;
}

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const { _ } = useLingui();
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const dispatch = useDispatch();
  const [confirmationDialog, setConfirmationDialog] = useState<{
    hardDelete: boolean;
    text: string;
    error?: string;
  } | null>(null);

  const [deleteDevice, { loading }] = useMutation(DELETE_DEVICE, {
    onCompleted(data) {
      setConfirmationDialog(null);
      const message = data.deleteDevice.hardDelete
        ? msg`El dispositivo ${deviceId} ha sido eliminado`
        : msg`El dispositivo ${deviceId} ha sido archivado`;
      openModalSuccessMessage(_(message));
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
    update(cache, { data }) {
      if (!data) return;
      dispatch(setUpdateDeviceConfigurationModal({ open: false }));
      onClose();
      cache.modify({
        id: cache.identify({ __typename: 'Device', id: data.deleteDevice.id }),
        fields(_, { DELETE }) {
          return DELETE;
        },
      });
      cache.gc();
    },
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
  });

  const onDeleteDevice: FormEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
  };

  const onArchiveDevice: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setConfirmationDialog({ hardDelete: false, text: '' });
  };

  const onConfirm = () => {
    if (!confirmationDialog) return;
    if (confirmationDialog.text !== deviceId) {
      setConfirmationDialog({
        ...confirmationDialog,
        error: _(msg`El ID del dispositivo no coincide`),
      });
      return;
    }
    if (confirmationDialog.hardDelete) {
      // deleteDevice({ variables: { deviceId, hardDelete: true } });
    } else {
      deleteDevice({ variables: { deviceId, hardDelete: false } });
    }
  };

  return (
    <>
      <Dialog
        open={confirmationDialog != null}
        onClose={() => setConfirmationDialog(null)}
        fullWidth
        maxWidth="xs"
      >
        <ModalTitle
          title={
            confirmationDialog?.hardDelete
              ? _(msg`Eliminar dispositivo`)
              : _(msg`Archivar dispositivo`)
          }
          onClose={() => setConfirmationDialog(null)}
        />
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onConfirm();
          }}
        >
          <DialogContent dividers>
            <DialogContentText>
              <Typography gutterBottom>
                {confirmationDialog?.hardDelete ? (
                  <Trans>
                    ¿Estás seguro que deseas <b>eliminar</b> el dispositivo {deviceId}?
                  </Trans>
                ) : (
                  <Trans>
                    ¿Estás seguro que deseas <b>archivar</b> el dispositivo {deviceId}?
                  </Trans>
                )}
              </Typography>
              <Typography>
                <Trans>Escribe el ID del dispositivo para confirmar</Trans>
              </Typography>
            </DialogContentText>
            <TextField
              fullWidth
              autoFocus
              required
              value={confirmationDialog?.text ?? ''}
              onChange={(event) => {
                setConfirmationDialog({ hardDelete: false, text: event.target.value });
              }}
              margin="normal"
              label={_(msg`ID del dispositivo`)}
              error={!!confirmationDialog?.error}
              helperText={confirmationDialog?.error}
            />
          </DialogContent>
          <ModalActions
            onClose={() => setConfirmationDialog(null)}
            onSubmit="submit"
            submitDisabled={confirmationDialog?.text !== deviceId}
            submitLabel={_(msg`Confirmar`)}
          />
        </form>
      </Dialog>
      <DialogContent dividers>
        <DialogContentText>
          <Trans>¿Deseas eliminar el dispositivo {deviceId}?</Trans>
        </DialogContentText>
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onSubmit={onDeleteDevice}
        submitLabel={_(msg`Eliminar`)}
        submitColor="error"
        submitDisabled={true}
        submitLoading={loading}
        rightButtons={[
          {
            label: _(msg`Archivar`),
            color: 'secondary',
            variant: 'contained',
            onClick: onArchiveDevice,
            loading,
          },
        ]}
      />
    </>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { _ } = useLingui();
  return (
    <DialogContent>
      <ModalTitle title={_(msg`Eliminar dispositivo`)} onClose={onClose} />
      <Skeleton height={100} variant="rounded" />
    </DialogContent>
  );
};

const DeleteDeviceModal: FC = () => {
  const { _ } = useLingui();
  const { globalModalProps, state, onClose } = useGlobalModal('deleteDeviceModal');

  return (
    <GlobalModal
      {...globalModalProps}
      dialogProps={{ maxWidth: 'sm' }}
      suspenseFallback={<Fallback onClose={onClose} />}
      authorization={{
        access: Access.DeleteDeviceModal,
        deviceId: state.open ? state.deviceId : undefined,
        onClose,
      }}
    >
      {state.open && (
        <>
          <ModalTitle title={_(msg`Eliminar dispositivo`)} onClose={onClose} />
          <Component deviceId={state.deviceId} onClose={onClose} />
        </>
      )}
    </GlobalModal>
  );
};

export default DeleteDeviceModal;
