import {
  type ButtonProps,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogProps,
} from '@mui/material';
import { type FC } from 'react';
import ModalTitle from './ModalTitle';
import ModalActions from './ModalActions';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

export interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  /** @default "submit-confirmation" */
  preset?: 'submit-confirmation' | 'change-tab' | 'close-modal';
  title?: string;
  content?: string;
  submitButton?: ButtonProps & { label?: string; loading?: boolean };
  closeButton?: ButtonProps & { label?: string; loading?: boolean };
  dialogProps?: Partial<DialogProps>;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  onClose,
  title,
  content,
  submitButton,
  closeButton,
  dialogProps,
  preset,
}) => {
  const { _ } = useLingui();
  let defaultTitle = _(msg`Confirmación`);
  let defaultContent = _(msg`¿Estás seguro de que deseas continuar?`);
  let defaultSubmitButtonLabel = _(msg`Confirmar`);
  let defaultCloseButtonLabel = _(msg`Cancelar`);
  let defaultSubmitColor: ButtonProps['color'] = 'primary';
  if (preset === 'change-tab') {
    defaultTitle = _(msg`¿Estás seguro que deseas cambiar de pestaña?`);
    defaultContent = _(msg`Los cambios que hayas realizado no se guardarán.`);
    defaultSubmitButtonLabel = _(msg`Cambiar de pestaña`);
    defaultSubmitColor = 'error';
  }
  if (preset === 'close-modal') {
    defaultTitle = _(msg`¿Estás seguro que deseas cerrar el formulario?`);
    defaultContent = _(msg`Los cambios que hayas realizado no se guardarán.`);
    defaultSubmitButtonLabel = _(msg`Cerrar formulario`);
    defaultSubmitColor = 'error';
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" disablePortal {...dialogProps}>
      <ModalTitle title={title ?? defaultTitle} onClose={onClose} />
      <DialogContent dividers>
        <DialogContentText>{content ?? defaultContent}</DialogContentText>
      </DialogContent>
      <ModalActions
        onClose={onClose}
        submitButton={{
          ...submitButton,
          label: submitButton?.label ?? defaultSubmitButtonLabel,
          color: submitButton?.color ?? defaultSubmitColor,
        }}
        closeButton={{
          label: closeButton?.label ?? defaultCloseButtonLabel,
        }}
      />
    </Dialog>
  );
};

export default ConfirmationModal;
