import { gql } from 'src/__generated__';

const UPDATE_USER = gql(`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
      lastnames
      username
      phone
      phoneNumber
      callingCode
      picture
      verifyAuth
      status
      access {
        controlCenter
        allDevices
        stations
        users
        virtualStations
        integrations
        dataAnalytics
        dataAdvanceAnalytics
        gates
        systems
        distribution
        dgaReports
        dataExtraction
        deviceConfiguration
        dataTables
        offLogin
        permits
        apiCapta
        distributionMaipo
        metricView
        deviceDiagnostics
        sendCommand
        channelNetwork
        audit
        standardDesign
        announcements
        notifications
        organizations
        billing
        alerts
        scheduledReports
        smaReports
        alertNotification
        viewAllocation
      }
      mainOrganization {
        organization {
          id
          name
        }
        role {
          id
          name
        }
      }
      additionalOrganizations {
        organization {
          id
          name
        }
        role {
          id
          name
        }
      }
    }
  }
`);

export default UPDATE_USER;
