import { createSvgIcon } from '@mui/material';

const HousingPanelEncapsuladoIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M155.81,120.22c0,4.78,0,9.33,0,14.02c0.5,0.03,0.96,0.09,1.42,0.09c3.32,0.01,6.64,0,9.96,0.01
		c0.59,0,1.22,0.03,1.78,0.2c1.61,0.49,2.58,2.09,2.42,3.84c-0.16,1.68-1.38,2.99-3.05,3.21c-0.51,0.07-1.04,0.07-1.56,0.07
		c-3.16,0.01-6.32,0-9.48,0.01c-0.47,0-0.94,0.05-1.47,0.08c0,6.05,0,11.99,0,17.92c0.9,0.3,26.94,0.35,28.22,0.04
		c-0.1-0.85-0.24-1.71-0.3-2.58c-0.05-0.63-0.11-1.29,0.03-1.9c0.4-1.78,1.99-2.9,3.82-2.79c1.73,0.11,3.12,1.4,3.38,3.19
		c0.2,1.38,0.36,2.76,0.55,4.2c9.39,0,18.69,0,28.13,0c-0.23-0.97-0.46-1.85-0.66-2.73c-0.36-1.65,0.19-3.15,1.47-4.02
		c2.08-1.42,4.86-0.45,5.59,2.02c0.61,2.07,1.09,4.17,1.61,6.27c3.98,15.93,7.96,31.85,11.92,47.78c0.16,0.65,0.26,1.35,0.19,2
		c-0.19,1.79-1.57,3.04-3.36,3.16c-0.4,0.03-0.8,0.02-1.2,0.02c-55.43,0-110.86,0-166.29,0c-0.32,0-0.64,0.01-0.96-0.01
		c-2.42-0.11-4.04-2.17-3.48-4.55c0.67-2.88,1.42-5.74,2.13-8.61c6.92-27.67,13.84-55.34,20.76-83.01c0.2-0.81,0.38-1.64,0.61-2.44
		c0.52-1.8,1.82-2.78,3.7-2.83c0.24-0.01,0.48,0,0.72,0c24.84,0,49.67,0,74.51,0c0.48,0,0.97-0.03,1.44,0.05
		c1.93,0.33,3.2,2.01,3.04,3.97c-0.16,1.9-1.63,3.29-3.6,3.31c-3.52,0.03-7.04,0.01-10.56,0.01
		C156.8,120.22,156.37,120.22,155.81,120.22z M155.93,189.11c-0.3,1.47-0.23,16.92,0.06,17.76c1.98,0.26,33.05,0.15,33.96-0.16
		c-0.73-5.83-1.46-11.69-2.2-17.6C177.07,189.11,166.53,189.11,155.93,189.11z M148.33,189.12c-10.69,0-21.22,0-31.86,0
		c-0.74,5.94-1.47,11.82-2.2,17.68c1.56,0.26,33.15,0.2,34.06-0.05C148.33,200.91,148.33,195.06,148.33,189.12z M197.39,206.96
		c0.64,0,1.11,0,1.59,0c10.27,0,20.55,0,30.82,0c0.32,0,0.64,0.02,0.96-0.01c0.21-0.02,0.41-0.12,0.66-0.21
		c-1.48-5.93-2.95-11.77-4.41-17.62c-10.64,0-21.17,0-31.83,0C195.91,195.08,196.64,200.93,197.39,206.96z M106.74,206.9
		c0.64-2.89,2.28-16.9,2.07-17.81c-10.49,0-20.98,0-31.58,0c-0.5,2-0.99,3.97-1.48,5.94c-0.49,1.97-1.01,3.94-1.48,5.92
		c-0.46,1.95-1.11,3.87-1.38,5.95C84.25,206.9,95.46,206.9,106.74,206.9z M120.14,159.82c9.54,0,18.83,0,28.18,0
		c0-6.06,0-12.03,0-18.07c-8.66,0-17.24,0-25.92,0C121.65,147.78,120.9,153.74,120.14,159.82z M84.58,159.72
		c1.36,0.27,27.14,0.23,28.16-0.04c0.74-5.92,1.48-11.88,2.23-17.84c-1.44-0.3-24.96-0.24-25.94,0.06
		C87.55,147.82,86.06,153.77,84.58,159.72z M185.03,167.42c-0.26-0.08-0.37-0.14-0.49-0.14c-9.31-0.01-18.61-0.01-27.92-0.01
		c-0.2,0-0.39,0.04-0.59,0.08c-0.03,0.01-0.06,0.04-0.09,0.07c-0.03,0.03-0.05,0.06-0.08,0.09c-0.24,1.78-0.15,13.23,0.11,14.06
		c1.53,0.27,29.89,0.18,30.81-0.13C186.2,176.78,185.62,172.12,185.03,167.42z M117.43,181.54c1.69,0.25,29.96,0.19,30.84-0.03
		c0.27-1.41,0.19-13.34-0.1-14.18c-9.61,0-19.22,0-28.96,0C118.61,172.07,118.02,176.77,117.43,181.54z M192.43,167.33
		c0.61,4.86,1.2,9.56,1.8,14.29c10.34,0,20.51,0,30.86,0c-1.15-4.86-2.34-9.59-3.56-14.29C211.78,167.33,202.2,167.33,192.43,167.33
		z M111.78,167.32c-0.53-0.03-0.81-0.06-1.08-0.06c-8.99,0-17.98,0-26.97,0c-1.01,0-1.03,0.01-1.28,1
		c-0.95,3.75-1.89,7.51-2.83,11.26c-0.16,0.65-0.31,1.3-0.47,1.94c1,0.28,29.53,0.32,30.85,0.04
		C110.59,176.85,111.17,172.15,111.78,167.32z M148.29,134.14c0.25-1.57,0.17-13.01-0.11-13.85c-7.65,0-15.31,0-23.09,0
		c-0.58,4.67-1.16,9.27-1.73,13.87C124.79,134.43,147.37,134.38,148.29,134.14z M90.98,134.1c1.16,0.32,23.86,0.31,24.95,0
		c0.57-4.54,1.14-9.13,1.73-13.88c-0.59,0-1.02,0-1.44,0c-6.8,0-13.6,0-20.4,0c-0.24,0-0.48,0.01-0.72,0
		c-0.48-0.03-0.72,0.2-0.83,0.67c-0.26,1.13-0.56,2.25-0.84,3.37C92.62,127.51,91.81,130.76,90.98,134.1z"
    />
    <path
      d="M191.38,92.22c0-0.66,0-1.14,0-1.61c0-5.36-0.02-10.72,0.01-16.08c0.01-2.92,0.6-5.73,1.93-8.34
		c3.3-6.42,8.56-10.07,15.7-11.02c8.81-1.17,17.54,3.85,20.92,12.01c0.95,2.28,1.44,4.64,1.44,7.12c-0.01,5.44,0,10.88,0,16.32
		c0,0.47,0,0.94,0,1.49c0.49,0.04,0.83,0.09,1.18,0.1c1.48,0.01,2.96-0.01,4.44,0.01c2.41,0.03,3.91,1.51,3.91,3.9
		c0.01,13.88,0.01,27.76,0,41.64c0,2.31-1.39,3.75-3.67,3.88c-0.4,0.02-0.8,0.01-1.2,0.01c-16.36,0-32.72,0-49.08,0
		c-0.48,0-0.96,0.01-1.44-0.03c-1.96-0.16-3.32-1.51-3.49-3.47c-0.03-0.32-0.01-0.64-0.01-0.96c0-13.48,0-26.96,0-40.44
		c0-0.28-0.01-0.56,0.01-0.84c0.13-2.2,1.59-3.64,3.78-3.69c1.4-0.03,2.8-0.01,4.2-0.01C190.4,92.22,190.79,92.22,191.38,92.22z
		 M233.45,134.13c0.26-1.71,0.16-33.64-0.11-34.45c-1.77-0.23-42.97-0.14-43.89,0.12c0,11.43,0,22.88,0,34.32
		C190.55,134.42,232.43,134.42,233.45,134.13z M198.74,92.15c8.54,0,16.84,0,25.19,0c0.03-0.38,0.08-0.69,0.08-1
		c0-5.68,0.03-11.35-0.02-17.03c-0.01-1.06-0.21-2.16-0.53-3.17c-1.82-5.78-7.81-9.22-13.72-8.47c-5.77,0.73-11.19,5.57-11.03,12.82
		c0.12,5.11,0.02,10.23,0.02,15.35C198.74,91.11,198.74,91.58,198.74,92.15z"
    />
    <path
      d="M47.3,224.88c0.62,0,1.09,0,1.55,0c12.88,0,25.75,0,38.63,0c0.44,0,0.88-0.01,1.32,0.01
		c1.99,0.09,3.49,1.58,3.55,3.51c0.06,2-1.37,3.62-3.38,3.82c-0.28,0.03-0.56,0.01-0.84,0.01c-15.12,0-30.23,0-45.35,0
		c-0.28,0-0.56,0.01-0.84-0.01c-2.71-0.24-3.94-2.84-3.27-4.83c1.09-3.26,2.11-6.54,3.16-9.81c3.51-10.87,7.02-21.74,10.53-32.61
		c0.07-0.23,0.14-0.46,0.23-0.68c0.74-2.06,2.71-3.08,4.68-2.42c1.92,0.64,2.94,2.58,2.33,4.63c-0.6,2.03-1.29,4.03-1.94,6.05
		c-3.32,10.3-6.64,20.61-9.96,30.91C47.57,223.86,47.47,224.28,47.3,224.88z"
    />
    <path
      d="M255.39,224.88c-0.19-0.65-0.3-1.06-0.43-1.47c-3.9-12.09-7.8-24.18-11.69-36.27
		c-0.18-0.56-0.34-1.16-0.36-1.75c-0.06-1.73,1.11-3.21,2.76-3.61c1.71-0.41,3.45,0.41,4.2,2c0.14,0.29,0.24,0.59,0.34,0.9
		c4.54,14.07,9.07,28.13,13.61,42.2c0.12,0.38,0.26,0.76,0.32,1.15c0.31,2.08-1.21,3.98-3.35,4.19c-0.36,0.04-0.72,0.02-1.08,0.02
		c-15,0-29.99,0-44.99-0.01c-0.67,0-1.37-0.04-2-0.25c-1.6-0.52-2.61-2.23-2.39-3.87c0.24-1.76,1.62-3.08,3.36-3.2
		c0.48-0.03,0.96-0.02,1.44-0.02c12.84,0,25.67,0,38.51,0C254.16,224.88,254.68,224.88,255.39,224.88z"
    />
    <path
      d="M83.59,96.16c-1.03,3.37-2.01,6.58-2.98,9.79c-1.95,6.42-3.89,12.85-5.87,19.26
		c-0.22,0.71-0.57,1.43-1.05,1.98c-1.09,1.25-2.93,1.47-4.39,0.67c-1.51-0.83-2.23-2.54-1.72-4.33c0.68-2.38,1.43-4.74,2.15-7.11
		c2.46-8.1,4.92-16.21,7.39-24.31c0.12-0.38,0.23-0.77,0.4-1.13c0.6-1.31,1.63-2.04,3.07-2.15c0.36-0.03,0.72-0.02,1.08-0.02
		c8.64,0,17.28,0,25.92,0c0.28,0,0.56,0,0.84,0.01c2.04,0.09,3.57,1.63,3.59,3.61c0.02,1.95-1.5,3.58-3.5,3.72
		c-0.36,0.02-0.72,0.01-1.08,0.01c-7.44,0-14.88,0-22.32,0C84.63,96.16,84.16,96.16,83.59,96.16z"
    />
    <path
      d="M215.06,116.86c0,1.44-0.03,2.87,0.01,4.31c0.06,1.85-1.4,3.56-3.35,3.69c-2.04,0.13-3.61-1.13-3.94-3.2
		c-0.04-0.24-0.06-0.48-0.06-0.71c0-2.71-0.03-5.43,0-8.14c0.02-2.01,1.08-3.4,2.81-3.83c2.27-0.56,4.42,0.99,4.52,3.34
		C215.1,113.82,215.05,115.34,215.06,116.86C215.06,116.86,215.06,116.86,215.06,116.86z"
    />
  </svg>,
  'HousingPanelEncapsuladoIcon',
);

export default HousingPanelEncapsuladoIcon;
