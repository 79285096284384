import {
  setSaveHardwareConfigurationModal,
  setUpdateDGAConfigurationModal,
  setUpdateDeviceAlertsModal,
} from '@/slices/modals';
import { useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  CancelScheduleSend,
  DeveloperBoard,
  DeveloperBoardOff,
  NotificationsActive,
  NotificationsOff,
  Send,
  Wifi,
  WifiOff,
} from '@mui/icons-material';
import { Avatar, AvatarGroup, Box, Chip, Skeleton, Tooltip, Typography } from '@mui/material';
import { Suspense, type FC } from 'react';
import { useDispatch } from 'react-redux';
import GET_DEVICE_PROFILE_STATUS from '../../graphql/queries/getDeviceStatus';
import {
  HardwareConfigurationState,
  useSuspenseHardwareConfigurationState,
} from '@/hooks/useHardwareConfigurationState';
import {
  DgaConfigurationState,
  useSuspenseDgaConfigurationState,
} from '@features/dga/hooks/useDgaConfigurationState';

interface Props {
  deviceId: string;
}

const Component: FC<Props> = ({ deviceId }) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const hardwareConfigurationSections = useSuspenseHardwareConfigurationState(deviceId);
  const dgaConfigurationState = useSuspenseDgaConfigurationState(deviceId);

  const { data } = useSuspenseQuery(GET_DEVICE_PROFILE_STATUS, {
    variables: {
      input: {
        id: deviceId,
      },
    },
  });

  const yesterdayTimestamp = new Date().getTime() - 24 * 60 * 60 * 1000;

  const connected = [
    data.device.metrics.flow.lastData?.[0] ?? 0,
    data.device.metrics.level.lastData?.[0] ?? 0,
    data.device.metrics.velocity.lastData?.[0] ?? 0,
  ].some((timestamp) => timestamp > yesterdayTimestamp);

  const hasAlertConfigurations = Boolean(
    data.device.alerts.basicConfig.lastFlow || data.device.alerts.basicConfig.lastLevel,
  );
  const hardwareConfigurationGlobalState = hardwareConfigurationSections.every(
    ({ state }) => state === HardwareConfigurationState.Complete,
  )
    ? HardwareConfigurationState.Complete
    : hardwareConfigurationSections.every(
        ({ state }) => state === HardwareConfigurationState.NoData,
      )
    ? HardwareConfigurationState.NoData
    : HardwareConfigurationState.Incomplete;

  const openAlertsConfigurationModal = () => {
    dispatch(
      setUpdateDeviceAlertsModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openDgaConfigurationModal = () => {
    dispatch(
      setUpdateDGAConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openHardwareConfigurationModal = () => {
    dispatch(
      setSaveHardwareConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  const hardwareConfigurationIcons = hardwareConfigurationSections.map((section) => (
    <Tooltip key={section.sectionLabel} title={section.sectionLabel}>
      <section.Icon fontSize="small" sx={{ color: section.color }} />
    </Tooltip>
  ));

  const statusItems = [
    {
      title: _(msg`Conectado recientemente`),
      icon: Wifi,
      color: 'success.main',
      show: connected,
      description: (
        <Typography fontSize="inherit">
          <Trans>El dispositivo tiene datos las últimas 24 horas</Trans>
        </Typography>
      ),
    },
    {
      title: _(msg`Desconectado`),
      icon: WifiOff,
      color: 'disabled.main',
      show: !connected,
      description: (
        <Typography fontSize="inherit">
          <Trans>El dispositivo no tiene datos las últimas 24 horas</Trans>
        </Typography>
      ),
    },
    {
      title: _(msg`Alertas configuradas`),
      icon: NotificationsActive,
      color: 'primary.main',
      show: hasAlertConfigurations,
      description: (
        <Typography fontSize="inherit">
          <Trans>Alertas configuradas para este dispositivo</Trans>
        </Typography>
      ),
      action: {
        label: _(msg`Ver configuración de alertas`),
        onClick: openAlertsConfigurationModal,
      },
    },
    {
      title: _(msg`Alertas no configuradas`),
      icon: NotificationsOff,
      color: 'warning.main',
      show: !hasAlertConfigurations,
      description: (
        <Typography fontSize="inherit">
          <Trans>No hay alertas configuradas para este dispositivo</Trans>
        </Typography>
      ),
      action: {
        label: _(msg`Crear configuración de alertas`),
        onClick: openAlertsConfigurationModal,
      },
    },
    {
      title: _(msg`Configuración de hardware`),
      icon: DeveloperBoard,
      color: 'primary.main',
      show: hardwareConfigurationGlobalState === HardwareConfigurationState.Complete,
      description: (
        <>
          <Typography fontSize="inherit">
            <Trans>El dispositivo tiene configuración de hardware completa</Trans>
          </Typography>
          <Box display="flex" my={1} gap={0.5}>
            {hardwareConfigurationIcons}
          </Box>
        </>
      ),
      action: {
        label: _(msg`Actualizar configuración de hardware`),
        onClick: openHardwareConfigurationModal,
      },
    },
    {
      title: _(msg`Configuración de hardware incompleta`),
      icon: DeveloperBoard,
      color: 'warning.main',
      show: hardwareConfigurationGlobalState === HardwareConfigurationState.Incomplete,
      description: (
        <>
          <Typography fontSize="inherit">
            <Trans>El dispositivo tiene configuración de hardware incompleta</Trans>
          </Typography>
          <Box display="flex" my={1} gap={0.5}>
            {hardwareConfigurationIcons}
          </Box>
        </>
      ),
      action: {
        label: _(msg`Actualizar configuración de hardware`),
        onClick: openHardwareConfigurationModal,
      },
    },
    {
      title: _(msg`Sin configuración de hardware`),
      icon: DeveloperBoardOff,
      color: 'error.main',
      show: hardwareConfigurationGlobalState === HardwareConfigurationState.NoData,
      description: (
        <>
          <Typography fontSize="inherit">
            <Trans>El dispositivo no tiene configuración de hardware configurada</Trans>
          </Typography>
          <Box display="flex" my={1} gap={0.5}>
            {hardwareConfigurationIcons}
          </Box>
        </>
      ),
      action: {
        label: _(msg`Crear configuración de hardware`),
        onClick: openHardwareConfigurationModal,
      },
    },
    {
      title: _(msg`Configuración DGA incompleta`),
      icon: CancelScheduleSend,
      color: 'warning.main',
      show: dgaConfigurationState.state === DgaConfigurationState.Incomplete,
      description: (
        <Typography fontSize="inherit">
          <Trans>El dispositivo tiene configuración DGA creada pero faltan datos</Trans>
        </Typography>
      ),
      action: {
        label: _(msg`Actualizar configuración DGA`),
        onClick: openDgaConfigurationModal,
      },
    },
    {
      title: _(msg`Configuración DGA con envíos activos`),
      icon: Send,
      color: 'primary.main',
      show: dgaConfigurationState.state === DgaConfigurationState.CompleteWithSubmissions,
      description: (
        <>
          <Typography fontSize="inherit">
            <Trans>El dispositivo tiene la configuración DGA completa y posee envíos activos</Trans>
          </Typography>
          <Typography fontSize="inherit">
            <Trans>Frecuencia de envíos</Trans>: <b>{dgaConfigurationState.frequencyLabel}</b>
          </Typography>
        </>
      ),
      action: {
        label: _(msg`Actualizar configuración DGA`),
        onClick: openDgaConfigurationModal,
      },
    },
    {
      title: _(msg`Configuración DGA sin envíos activos`),
      icon: CancelScheduleSend,
      color: 'info.main',
      show: dgaConfigurationState.state === DgaConfigurationState.CompleteWithoutSubmissions,
      description: (
        <Typography fontSize="inherit">
          <Trans>
            El dispositivo tiene la configuración DGA completa pero no posee envíos activos
          </Trans>
        </Typography>
      ),
      action: {
        label: _(msg`Actualizar configuración DGA`),
        onClick: openDgaConfigurationModal,
      },
    },
  ];

  return (
    <Box display="flex" alignSelf={{ sx: 'start', sm: 'center' }}>
      <AvatarGroup>
        {statusItems
          .filter(({ show }) => show)
          .map((item) => (
            <Tooltip
              title={
                <Box>
                  <Typography fontWeight="bold" fontSize="inherit" lineHeight={1.2}>
                    {item.title}
                  </Typography>
                  {item.description}
                  {item.action && (
                    <Chip
                      label={item.action.label}
                      color="info"
                      onClick={item.action.onClick}
                      size="small"
                      sx={{ mt: 0.5 }}
                    />
                  )}
                </Box>
              }
            >
              <Avatar sx={{ bgcolor: item.color, width: 32, height: 32 }}>
                <item.icon fontSize="small" />
              </Avatar>
            </Tooltip>
          ))}
      </AvatarGroup>
    </Box>
  );
};

const Fallback: FC = () => {
  return (
    <Box display="flex" alignSelf={{ sx: 'start', sm: 'center' }}>
      <AvatarGroup>
        {Array.from({ length: 3 }).map(() => (
          <Skeleton variant="circular" width={32} height={32} />
        ))}
      </AvatarGroup>
    </Box>
  );
};

const DeviceProfileStatus: FC<Props> = (props) => {
  return (
    <ApolloErrorBoundary>
      <Suspense fallback={<Fallback />}>
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default DeviceProfileStatus;
