import HAS_DGA_CONFIGURATION_DATA from '@/graphql/querys/hasDgaConfigurationData';
import { useSuspenseQuery } from '@apollo/client';

const useHasDGAConfiguration = (deviceId: string) => {
  const { data } = useSuspenseQuery(HAS_DGA_CONFIGURATION_DATA, {
    variables: { deviceId },
  });
  return data.device.dga != null;
};

export default useHasDGAConfiguration;
