import { Authorization } from '../graphql/queries/getAuthorization';
import { DeviceAuthorization } from '../graphql/queries/getDeviceAuthorization';
import { Access } from './accessEnum';

function validateAccess(
  authorization: Authorization,
  authorizationDevice: DeviceAuthorization | undefined,
  access: Access | null,
  deviceId?: string | null,
) {
  if (access === Access._Test_True) return true;
  if (access === Access._Test_False) return false;
  if (access === Access._Test_Random) return Math.random() > 0.5;

  if (deviceId != null && authorizationDevice?.deviceId === deviceId) {
    if (access === Access.DeviceConfigurationModal && authorizationDevice.update) {
      return true;
    }
    if (
      access === Access.DeviceDataConfigurationModal &&
      authorizationDevice.viewDeviceConfiguration
    ) {
      return true;
    }
    if (access === Access.DeleteDeviceModal && authorizationDevice.delete) {
      return true;
    }
    if (access === Access.CloneDeviceModal && authorizationDevice.clone) {
      return true;
    }
    if (access === Access.UpdateAliasModal && authorizationDevice.updateAlias) {
      return true;
    }
    if (
      access === Access.DeviceDataConfiguration_CalibrationTab &&
      authorizationDevice.viewCalibration
    ) {
      return true;
    }
    if (
      access === Access.DeviceDataConfiguration_FlowLimitTab &&
      authorizationDevice.viewFlowLimit
    ) {
      return true;
    }
    if (access === Access.DeviceDataConfiguration_FiltersTab && authorizationDevice.viewFilter) {
      return true;
    }
    if (
      access === Access.DeviceDataConfiguration_RatingCurvesTab &&
      authorizationDevice.viewRatingCurve
    ) {
      return true;
    }
    if (
      access === Access.DeviceDataConfiguration_MathModelingTab &&
      authorizationDevice.viewMathModeling
    ) {
      return true;
    }
    if (
      access === Access.DeviceDataConfiguration_RadarTab &&
      authorizationDevice.viewRadarParameter
    ) {
      return true;
    }
    if (access === Access.UpdateRatingCurve && authorizationDevice.updateRatingCurve) {
      return true;
    }

    if (access === Access.UpdateDeviceStatus && authorizationDevice.updateState) {
      return true;
    }
  }

  if (
    access === Access.DeviceAlertsConfigurationModal &&
    authorization.authorizationAlert?.adminView
  ) {
    return true;
  }

  if (access === Access.CreateDGAConfigurationModal) {
    if (authorization.authorizationDGA?.enableDevices) {
      return true;
    }
  }
  if (
    access === Access.UpdateDGAGeneralConfiguration ||
    access === Access.UpdateDGASubmissionsConfiguration ||
    access === Access.DeleteDGAConfiguration
  ) {
    if (authorization.authorizationDGA?.updateInfoDGA) {
      return true;
    }
  }
  if (access === Access.RetryDGASubmissions) {
    if (authorization.authorizationDGA?.retrySending) {
      return true;
    }
  }
  if (access === Access.UpdateDGAMaxFlow) {
    if (authorization.authorizationDGA?.setMaxFlow) {
      return true;
    }
  }
  // DGA client access
  if (
    access === Access.UpdateDGAConfigurationsModal ||
    access === Access.SetTicketModal ||
    access === Access.UpdateDGANotificationsConfiguration
  ) {
    return true;
  }
  if (access === Access.AlertsConfiguration_TableRowAdminView) {
    if (authorization.authorizationAlert?.adminView) {
      return true;
    }
  }
  if (access === Access.UsersTable_OptionsMenu) {
    if (
      authorization.authorizationGeneral?.updateUser ||
      authorization.authorizationGeneral?.simulateUser
    ) {
      return true;
    }
  }

  if (
    access === Access.UserOptionsMenu_DeleteUserButton ||
    access === Access.SaveUserModal ||
    access === Access.DeleteUserModal
  ) {
    if (authorization.authorizationGeneral?.updateUser) {
      return true;
    }
  }

  if (access === Access.CreateTemporalUserModal) {
    if (authorization.authorizationGeneral?.temporaryUser) {
      return true;
    }
  }

  if (access === Access.UserOptionsMenu_SimulateUserButton || access === Access.SimulateUserModal) {
    if (authorization.authorizationGeneral?.simulateUser) {
      return true;
    }
  }

  if (access === Access.OrganizationsFilter) {
    if (authorization.authorizationGeneral?.showOrganizationFilter) {
      return true;
    }
  }

  if (access === Access.SaveHardwareConfigurationModal) {
    if (authorization.authorizationGeneral?.updateDeviceHardwareConfiguration) {
      return true;
    }
  }

  /** TODO */
  if (
    access === Access.UpdateDeviceUserAccess ||
    access === Access.RemoveDeviceUserAccess ||
    access === Access.AddDeviceUserAccess
  ) {
    return true;
  }

  if (access === Access.CreateDevice) {
    if (authorization.authorizationGeneral?.createDevice) return true;
  }

  if (access === Access.ViewDeletedDevices) {
    if (authorization.authorizationGeneral?.createDevice) return true;
  }

  if (access === Access.CreateSMAConfiguration) {
    if (authorization.authorizationSMA?.updateInfoSMA) return true;
  }

  if (access === Access.ViewDeviceMetricsPriorityList) {
    if (authorization.authorizationGeneral?.showOrganizationFilter) {
      return true;
    }
  }

  return false;
}

export { validateAccess };
