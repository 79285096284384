import { DgaType, UpdateDgaConfigurationMutationVariables } from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import UPDATE_DGA_CONFIGURATION from '@features/dga/graphql/mutations/updateDgaConfiguration';
import GET_DGA_CONFIGURATION from '@features/dga/graphql/queries/getDGAConfiguration';
import { getUpdateDgaConfigurationMutationSchema } from '@features/dga/utils/validationSchemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useEffect, useState, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DGATabProps } from './tabProps';

type FormValues = UpdateDgaConfigurationMutationVariables;

const GeneralTab: FC<DGATabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { i18n, _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [updateDgaConfiguration, { loading }] = useMutation(UPDATE_DGA_CONFIGURATION);
  const { data } = useSuspenseQuery(GET_DGA_CONFIGURATION, {
    variables: {
      deviceId,
    },
  });
  const [showPassword, setShowPassword] = useState(false);

  const defaultValues = {
    deviceId,
    input: {
      code: data.device.dga?.configuration?.code || '',
      rut: data.device.dga?.configuration?.rut || '',
      password: data.device.dga?.configuration?.password || '',
      type: data.device.dga?.configuration?.type || DgaType.Surface,
    },
  };

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(getUpdateDgaConfigurationMutationSchema(i18n)),
  });

  useEffect(() => {
    setIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  const resetForm = () => {
    methods.reset();
  };

  const onSubmit = async (values: FormValues) => {
    const { deviceId, input } = values;
    await updateDgaConfiguration({
      variables: {
        deviceId,
        input,
      },
      onError: (error) => {
        openModalErrorMessage(error.message);
      },
      onCompleted: (data) => {
        openModalSuccessMessage(_(msg`Configuración DGA actualizada correctamente`));
        methods.reset({
          deviceId,
          input: {
            code: data.updateDgaConfiguration.dga?.configuration?.code,
            rut: data.updateDgaConfiguration.dga?.configuration?.rut,
            password: data.updateDgaConfiguration.dga?.configuration?.password,
            type: data.updateDgaConfiguration.dga?.configuration?.type,
          },
        });
      },
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            __typename: 'Device',
            id: deviceId,
          }),
          fields: {
            dgaConfiguration() {
              return data.updateDgaConfiguration.dga?.configuration;
            },
          },
        });
      },
      onQueryUpdated(observableQuery) {
        if (observableQuery.queryName !== 'StandardAutocompleteData') {
          return observableQuery.refetch();
        }
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent sx={{ height: 360 }}>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: '1fr',
            md: '1fr 1fr',
          }}
          gap={2}
        >
          <Controller
            name="input.type"
            control={methods.control}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                options={Object.values(DgaType)}
                disableClearable
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                value={field.value ?? undefined}
                getOptionLabel={(value) => {
                  if (value === DgaType.Surface) return _(msg`Superficial`);
                  if (value === DgaType.PipeSurface) return _(msg`Superficial con flujómetro`);
                  if (value === DgaType.Underground) return _(msg`Subterránea`);
                  return value;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={_(msg`Tipo de extracción`)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
          <Controller
            name="input.code"
            control={methods.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                label={_(msg`Código DGA`)}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="input.rut"
            control={methods.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                label={_(msg`RUT`)}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="input.password"
            control={methods.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={_(msg`Contraseña`)}
                autoComplete="new-password"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword((showPassword) => !showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </DialogContent>
      <Divider />
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        onResetForm={resetForm}
        dirtyForm={methods.formState.isDirty}
        submitLoading={loading}
      />
    </form>
  );
};

export default GeneralTab;
