import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Check, Tune } from '@mui/icons-material';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState, type FC } from 'react';
import { UserOrganizationRole } from '../types';

interface FiltersMenuProps {
  accessFilter: boolean | null;
  setAccessFilter: (value: boolean | null) => void;
  roleFilter: UserOrganizationRole | null;
  setRoleFilter: (value: UserOrganizationRole | null) => void;
}

export const DevicesFiltersMenu: FC<FiltersMenuProps> = ({
  accessFilter,
  setAccessFilter,
  roleFilter,
  setRoleFilter,
}) => {
  const { _ } = useLingui();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Item = ({
    label,
    selected,
    onClick,
  }: {
    label: string;
    selected: boolean;
    onClick: () => void;
  }) => {
    return (
      <MenuItem onClick={onClick}>
        {selected ? (
          <>
            <ListItemIcon sx={{ mr: 0 }}>
              <Check fontSize="small" />
            </ListItemIcon>
            {label}
          </>
        ) : (
          <ListItemText inset>{label}</ListItemText>
        )}
      </MenuItem>
    );
  };

  return (
    <>
      <Button
        variant={accessFilter !== null || roleFilter !== null ? 'contained' : 'outlined'}
        size="large"
        color="info"
        onClick={handleClick}
      >
        <Tune />
      </Button>
      <Menu
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{ dense: true }}
      >
        <Item
          onClick={() => setAccessFilter(null)}
          label={_(msg`Todos`)}
          selected={accessFilter === null}
        />
        <Item
          onClick={() => setAccessFilter(true)}
          label={_(msg`Con acceso`)}
          selected={accessFilter === true}
        />
        <Item
          onClick={() => setAccessFilter(false)}
          label={_(msg`Sin acceso`)}
          selected={accessFilter === false}
        />
        <Divider />
        <Item
          onClick={() => setRoleFilter(null)}
          label={_(msg`Todos los roles`)}
          selected={roleFilter === null}
        />
        <Item
          onClick={() => setRoleFilter(UserOrganizationRole.Admin)}
          label={_(msg`Administradores`)}
          selected={roleFilter === UserOrganizationRole.Admin}
        />
        <Item
          onClick={() => setRoleFilter(UserOrganizationRole.Operator)}
          label={_(msg`Operadores`)}
          selected={roleFilter === UserOrganizationRole.Operator}
        />
        <Item
          onClick={() => setRoleFilter(UserOrganizationRole.Observer)}
          label={_(msg`Observadores`)}
          selected={roleFilter === UserOrganizationRole.Observer}
        />
      </Menu>
    </>
  );
};
