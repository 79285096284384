import { useMutation, useQuery, useSuspenseQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircleOutline, Delete, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import generate from '../utils/generatePassword';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import FileInputController from 'src/components/FileInputController';
import UPDATE_USER from 'src/graphql/mutations/updateUser';
import GET_USER_LIST from 'src/graphql/querys/getUserList';
import { useModalStatusMessage, useUploadS3Images } from 'src/hooks';
import { enc, iv } from 'src/utils/encrypt';
import { UserFormGetRolesQuery } from 'src/__generated__/graphql';
import CREATE_USER from '../graphql/mutations/createUser';
import USER_FORM_GET_ROLES from '../graphql/queries/userFormGetRoles';
import { schema } from '../utils/schema';
import CountryCallingAutocomplete from 'src/features/countryCallingAutocomplete';
import { callingCode } from 'src/features/countryCallingAutocomplete/utils/callingCode';
import { StandardAutocomplete, useAutocompleteOptions } from 'src/features/standardDesign';
import { useLingui } from '@lingui/react';
import { Trans, msg } from '@lingui/macro';

interface Props {
  userToSave: any;
  onClose: () => void;
  open: boolean;
}

interface CallingCode {
  country: string;
  calling_code: string;
  abbreviation: string;
}

interface UserFormData {
  organization: Organization;
  organizations: Organization[];
  name: string;
  lastnames: string;
  username: string;
  phone_number: string;
  code: CallingCode;
  password: string;
  confirmPassword: string;
  checkbox_email: boolean;
  picture: File | string;
}

interface Organization {
  data: {
    id: number;
    name: string;
  };
  role: {
    id: number;
    name: string;
  };
}

const defaultValues: UserFormData = {
  organization: {
    data: null,
    role: null,
  },
  organizations: [],
  name: '',
  lastnames: '',
  username: '',
  phone_number: '',
  code: {
    country: 'Chile',
    calling_code: '+56',
    abbreviation: 'CL',
  },
  password: '',
  confirmPassword: '',
  checkbox_email: true,
  picture: '',
};

const UPLOAD_BUCKET = 'amaru-user';

/**
 * Modal to create or update a user.
 * If selectedUser is null, then it will create a new user.
 * If selectedUser is undefined, the modal will not be shown.
 * If selectedUser is not noll and not undefined, then it will update the selected user.
 */

const UserSaveModal: FC<Props> = ({ userToSave, onClose, open }) => {
  const { _ } = useLingui();
  const editing = userToSave != null;
  const { uploadS3Images } = useUploadS3Images(UPLOAD_BUCKET);
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const { data: rolesData } = useQuery(USER_FORM_GET_ROLES);
  const resolver = useMemo(() => yupResolver(schema(userToSave != null)), [userToSave]);
  const { data: organizationOptions } = useAutocompleteOptions('organizations');
  const [createUser, { reset: createReset }] = useMutation(CREATE_USER, {
    onCompleted() {
      createReset();
      onClose();
      openModalSuccessMessage(_(msg`Usuario creado correctamente.`));
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
    refetchQueries: [GET_USER_LIST],
  });
  const [updateUser, { error: updateError, reset: updateReset }] = useMutation(UPDATE_USER, {
    onCompleted() {
      updateReset();
      onClose();
      openModalSuccessMessage(_(msg`Usuario actualizado correctamente.`));
    },
    refetchQueries: [GET_USER_LIST],
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<UserFormData>({
    defaultValues,
    resolver,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'organizations',
  });

  useEffect(() => {
    if (userToSave === undefined) return;
    remove(); // remove all organizations fields
    if (userToSave === null) {
      reset(defaultValues);
    } else {
      const sharedOrganizations = userToSave.additionalOrganizations.map((item) => ({
        data:
          organizationOptions.organizations?.find((option) => option.id === item.organization.id) ??
          null,
        role: {
          id: item.role.id,
          name: item.role.name,
        },
      }));

      reset({
        organization: {
          data: userToSave.mainOrganization.organization,
          role: {
            id: userToSave.mainOrganization.role.id,
            name: userToSave.mainOrganization.role.name,
          },
        },
        organizations: sharedOrganizations,
        name: userToSave.name,
        lastnames: userToSave.lastnames,
        username: userToSave.username,
        phone_number: userToSave.phoneNumber,
        password: '',
        code: callingCode?.find((code) => code.calling_code === userToSave.callingCode),
        confirmPassword: '',
        checkbox_email: userToSave.checkbox_email,
        picture: userToSave.picture,
      });
      replace(sharedOrganizations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToSave]);

  const submitHandler = async (formData: UserFormData) => {
    const input: any = {
      ...formData,
      calling_code: formData?.code?.calling_code,
      organizations: JSON.stringify(
        [formData.organization, ...formData.organizations].map((organization) => ({
          ...organization.data,
          role: organization.role,
        })),
      ),
      picture: '',
      iv: undefined,
    };

    delete input.organization;
    delete input.confirmPassword;
    delete input.code;

    if (formData.picture instanceof File) {
      input.picture = await uploadS3Images(formData.picture as File);
    } else {
      input.picture = formData.picture;
    }

    try {
      if (userToSave != null) {
        delete input.checkbox_email;

        if (formData.password) {
          input.password = enc(formData.password);
          input.iv = iv.toString('hex');
        }

        await updateUser({
          variables: {
            input: {
              userId: userToSave.id,
              ...input,
            }
          },
        });
      } else {
        await createUser({
          variables: {
            input,
          },
        });
      }
    } catch (error) {
      console.info(error);
    }
  };

  const generatePassword = () => {
    const password = generate({ length: 12 });
    setValue('password', password);
    setValue('confirmPassword', password);
    clearErrors('password');
    clearErrors('confirmPassword');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(submitHandler)}>
        <DialogTitle>
          {editing ? <Trans>Editar Usuario</Trans> : <Trans>Crear Usuario</Trans>}
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <FileInputController name="picture" control={control} label={_(msg`Foto de perfil`)} />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={_(msg`Nombre`)}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name="lastnames"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={_(msg`Apellidos`)}
                error={!!errors.lastnames}
                helperText={errors.lastnames?.message}
              />
            )}
          />
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(event) => {
                  event.target.value = event.target.value.toLowerCase().trim();
                  field.onChange(event);
                }}
                label={_(msg`Correo electrónico`)}
                error={!!errors.username}
                helperText={errors.username?.message}
              />
            )}
          />
          <Box display="flex" gap={2} flexDirection="row">
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <CountryCallingAutocomplete
                  {...field}
                  sx={{ minWidth: '160px' }}
                  getOptionDisabled={(option) => {
                    const { code } = getValues();
                    return option?.calling_code === code?.calling_code;
                  }}
                  error={!!errors.code}
                  helperText={errors.code?.message}
                />
              )}
            />
            <Controller
              name="phone_number"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ flexGrow: 1 }}
                  label={_(msg`Número de teléfono`)}
                  error={!!errors.phone_number}
                  helperText={errors.phone_number?.message}
                />
              )}
            />
          </Box>

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={_(msg`Contraseña`)}
                type={showPassword ? 'text' : 'password'}
                error={!!errors.password}
                autoComplete="new-password"
                helperText={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={generatePassword}>
                        <Trans>Generar</Trans>
                      </Button>
                      <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={_(msg`Confirmar contraseña`)}
                type={showConfirmPassword ? 'text' : 'password'}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Box display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
            <Controller
              name="organization.data"
              control={control}
              render={({ field }) => (
                <StandardAutocomplete
                  {...field}
                  onChange={(_, value) => field.onChange(value)}
                  dataType="organizations"
                  sx={{ flexGrow: 1 }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionDisabled={(option) => {
                    const { organizations, organization } = getValues();
                    const selectedOrganizationId = organization?.data?.id;
                    if (selectedOrganizationId === option.id) return false;
                    const organizationIds = [organization, ...organizations].map(
                      (_organization) => _organization?.data?.id,
                    );
                    return organizationIds.includes(option.id);
                  }}
                  error={!!(errors.organization as any)?.data?.message}
                  helperText={(errors.organization as any)?.data?.message}
                />
              )}
            />
            <Controller
              name="organization.role"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  sx={{ flexShrink: 1, flexBasis: 330 }}
                  loading={!rolesData}
                  onChange={(_, value: UserFormGetRolesQuery['roles'][number]) =>
                    field.onChange(value)
                  }
                  options={rolesData?.roles}
                  getOptionLabel={(option: UserFormGetRolesQuery['roles'][number]) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={_(msg`Rol`)}
                      error={!!(errors.organization as any)?.role?.message}
                      helperText={(errors.organization as any)?.role?.message}
                    />
                  )}
                />
              )}
            />
          </Box>

          <Typography>
            <Trans>Organizaciones compartidas por el usuario</Trans>
          </Typography>
          {fields.map((item, index) => (
            <Box key={item.id} display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
              <Controller
                name={`organizations.${index}.data`}
                control={control}
                render={({ field }) => (
                  <StandardAutocomplete
                    {...field}
                    dataType="organizations"
                    onChange={(_, value) => field.onChange(value)}
                    sx={{ flexGrow: 1 }}
                    getOptionDisabled={(option) => {
                      const { organizations: formOrganizations, organization: formOrganization } =
                        getValues();
                      const selectedOrganizationId = formOrganizations?.[index]?.data?.id;
                      if (selectedOrganizationId === option.id) return false;
                      const organizationIds = [formOrganization, ...formOrganizations].map(
                        (_organization) => _organization?.data?.id,
                      );
                      return organizationIds.includes(option.id);
                    }}
                    error={!!(errors.organizations?.[index] as any)?.data?.message}
                    helperText={(errors.organizations?.[index] as any)?.data?.message}
                  />
                )}
              />
              <Box display="flex" gap={2} flexBasis={330} flexShrink={1}>
                <Controller
                  name={`organizations.${index}.role`}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      sx={{ flexShrink: 1, flexBasis: 300 }}
                      loading={!rolesData}
                      onChange={(_, value: UserFormGetRolesQuery['roles'][number]) => {
                        field.onChange(value);
                      }}
                      options={rolesData?.roles}
                      getOptionLabel={(option: UserFormGetRolesQuery['roles'][number]) =>
                        option.name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={_(msg`Rol`)}
                          error={!!(errors.organizations?.[index] as any)?.role?.message}
                          helperText={(errors.organizations?.[index] as any)?.role?.message}
                        />
                      )}
                    />
                  )}
                />
                <IconButton sx={{ alignSelf: 'center' }} onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ))}
          <Button
            sx={{ alignSelf: 'flex-start' }}
            onClick={() => {
              append({
                data: null,
                role: null,
              });
            }}
          >
            <AddCircleOutline fontSize="small" sx={{ mr: 1 }} />
            <Trans>Agregar organización</Trans>
          </Button>

          {!editing && (
            <Controller
              name="checkbox_email"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={_(msg`Enviar datos de acceso por correo electrónico`)}
                  />
                </FormGroup>
              )}
            />
          )}
          {updateError && <Typography color="error">{updateError.message}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} variant="outlined" onClick={onClose}>
            <Trans>Cancelar</Trans>
          </Button>
          <LoadingButton loading={isSubmitting} type="submit" variant="contained">
            {editing ? <Trans>Editar</Trans> : <Trans>Crear</Trans>}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserSaveModal;
