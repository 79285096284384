import { createSvgIcon } from '@mui/material';

const SensorCompuertaPlana = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M150.15,298.74c-47.76,0-95.52,0-143.27,0c-0.44,0-0.88,0.01-1.32,0c-2.13-0.05-3.62-1.47-3.76-3.58
		c-0.02-0.32-0.01-0.64-0.01-0.96c0-95.92,0-191.83,0-287.75c0-0.36-0.02-0.72,0.01-1.08c0.18-2.02,1.59-3.36,3.62-3.46
		C5.85,1.89,6.29,1.9,6.73,1.9c95.68,0,191.35,0,287.03,0c0.52,0,1.04-0.01,1.56,0.03c1.65,0.13,2.91,1.26,3.23,2.89
		c0.09,0.43,0.11,0.87,0.14,1.31c0.02,0.32,0,0.64,0,0.96c0,95.48,0,190.95-0.01,286.43c0,0.76-0.01,1.53-0.14,2.27
		c-0.29,1.66-1.65,2.83-3.33,2.94c-0.48,0.03-0.96,0.01-1.44,0.01C245.9,298.74,198.02,298.74,150.15,298.74z M291.34,9.39
		C289.5,9.14,10.15,9.22,9.29,9.44c-0.24,1.23-0.2,280.89,0.06,281.87c93.92,0,187.86,0,281.98,0
		C291.34,197.31,291.34,103.37,291.34,9.39z"
    />
    <path
      d="M150.28,1.9c47.72,0,95.44,0,143.15,0c0.64,0,1.28-0.02,1.92,0.04c1.6,0.13,2.85,1.24,3.19,2.8
		c0.09,0.43,0.12,0.87,0.15,1.31c0.02,0.36,0.01,0.72,0.01,1.08c0,95.44,0,190.87,0,286.31c0,0.76-0.01,1.53-0.13,2.27
		c-0.3,1.78-1.69,2.95-3.5,3.04c-0.32,0.01-0.64,0-0.96,0c-95.92,0-191.83,0-287.75,0c-0.28,0-0.56,0.01-0.84,0
		c-2.07-0.06-3.55-1.45-3.72-3.49c-0.03-0.4-0.01-0.8-0.01-1.2c0-95.84,0-191.67,0-287.51c0-0.6,0.01-1.21,0.13-1.79
		C2.27,3.1,3.53,2.04,5.21,1.92c0.6-0.04,1.2-0.03,1.8-0.03C54.76,1.9,102.52,1.9,150.28,1.9z M9.36,9.33
		C9.28,9.47,9.22,9.53,9.2,9.61c-0.03,0.16-0.05,0.32-0.05,0.48c0,93.52,0,187.04,0,280.56c0,0.16,0.05,0.31,0.08,0.47
		c0.01,0.04,0.05,0.06,0.07,0.09c0.03,0.03,0.05,0.06,0.08,0.09c1.9,0.24,280.95,0.14,281.81-0.1c0.24-1.24,0.2-280.9-0.06-281.86
		C197.2,9.33,103.26,9.33,9.36,9.33z"
    />
    <path
      d="M146.12,87.84c0-12.14,0-24.12,0-36.25c-0.46-0.02-0.92-0.07-1.38-0.07c-6.4,0-12.8,0-19.2,0
		c-14.8,0-29.6,0-44.4,0c-0.52,0-1.05,0.01-1.55-0.07c-1.92-0.33-3.27-1.97-3.15-3.79c0.12-2.01,1.65-3.44,3.72-3.48
		c0.4-0.01,0.8,0,1.2,0c44.96,0,89.91,0,134.87,0c0.4,0,0.8-0.01,1.2,0c1.87,0.07,3.33,1.43,3.53,3.28
		c0.19,1.75-0.96,3.44-2.71,3.88c-0.61,0.15-1.26,0.18-1.9,0.18c-20.32,0.01-40.64,0.01-60.96,0.01c-0.55,0-1.1,0-1.62,0
		c-0.27,1.07-0.3,35.04-0.02,36.33c0.51,0,1.05,0,1.6,0c26.48,0,52.96,0,79.44,0c0.44,0,0.88-0.01,1.32,0
		c2.09,0.07,3.7,1.64,3.82,3.72c0.02,0.36,0.01,0.72,0.01,1.08c0,53.52,0,107.03,0,160.55c0,0.4,0.02,0.8-0.01,1.2
		c-0.15,1.96-1.71,3.5-3.67,3.62c-0.44,0.03-0.88,0.01-1.32,0.01c-56.2,0-112.39,0-168.59,0c-0.4,0-0.8,0.01-1.2-0.01
		c-2.1-0.08-3.69-1.6-3.85-3.7c-0.03-0.36-0.01-0.72-0.01-1.08c0-53.56,0-107.11,0.01-160.67c0-0.63,0.03-1.29,0.19-1.9
		c0.44-1.67,1.87-2.76,3.6-2.82c0.48-0.02,0.96-0.01,1.44-0.01c25.96,0,51.92,0,77.88,0C144.95,87.84,145.49,87.84,146.12,87.84z
		 M232.5,160.33c-54.64,0-109.17,0-163.68,0c-0.28,1.16-0.26,89.27,0.01,90.26c54.52,0,109.05,0,163.66,0
		C232.5,220.5,232.5,190.49,232.5,160.33z M153.61,152.76c1.81,0.28,78.04,0.19,78.87-0.08c0.24-1.71,0.15-56.55-0.09-57.38
		c-1.75-0.25-77.9-0.15-78.77,0.12C153.61,114.51,153.61,133.61,153.61,152.76z M68.74,95.27c-0.05,0.31-0.09,0.47-0.09,0.62
		c0,18.76,0,37.51,0,56.27c0,0.16,0.04,0.32,0.07,0.47c0.01,0.03,0.04,0.06,0.07,0.09c0.03,0.03,0.05,0.06,0.08,0.09
		c1.74,0.22,76.34,0.13,77.25-0.1c0-19.12,0-38.25,0-57.44C120.29,95.27,94.56,95.27,68.74,95.27z"
    />
    <path
      d="M150.03,183.84c19.16,0,38.32,0,57.48,0c0.44,0,0.88-0.01,1.32,0.01c1.81,0.09,3.18,1.39,3.43,3.25
		c0.22,1.67-0.89,3.39-2.55,3.88c-0.42,0.12-0.86,0.16-1.3,0.2c-0.32,0.03-0.64,0.01-0.96,0.01c-38.28,0-76.55,0-114.83-0.01
		c-0.71,0-1.45-0.02-2.14-0.19c-1.81-0.44-2.84-2.08-2.64-4.02c0.18-1.72,1.61-3.05,3.39-3.13c0.44-0.02,0.88-0.01,1.32-0.01
		C111.71,183.84,130.87,183.84,150.03,183.84z"
    />
    <path
      d="M150.01,217.42c19.12,0,38.24,0,57.35,0c0.44,0,0.88-0.01,1.32,0.01c2.01,0.09,3.48,1.51,3.6,3.47
		c0.12,1.83-1.22,3.45-3.14,3.79c-0.43,0.07-0.87,0.09-1.31,0.09c-38.56,0-77.11,0-115.67,0c-0.52,0-1.04-0.04-1.54-0.15
		c-1.83-0.41-2.93-1.98-2.78-3.92c0.13-1.79,1.54-3.16,3.38-3.27c0.48-0.03,0.96-0.02,1.44-0.02
		C111.77,217.42,130.89,217.42,150.01,217.42z"
    />
  </svg>,
  'SensorCompuertaPlana',
);

export default SensorCompuertaPlana;
