import { useGlobalModal } from '@/hooks/useGlobalModal';
import { useDispatch } from '@/store';
import { useSuspenseQuery } from '@apollo/client';
import GlobalModal from '@components/GlobalModal';
import ModalContent from '@components/modal/ModalContent';
import TruncatedTypography from '@components/TruncatedTypography';
import { Access } from '@features/authorization';
import { openDeviceProfileModal } from '@features/deviceProfile/slices/deviceProfileSlice';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Article, QueryStats, Unarchive } from '@mui/icons-material';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useMemo, useState, type FC } from 'react';
import GET_DEVICES_GRAVEYARD from '../graphql/queries/getDevicesGraveyard';
import { setModalDisplayedStationChart } from '@features/devicesAnalysis/slices/devicesAnalysisSlice';
import { FixedSizeList } from 'react-window';
import { useFormatters } from '@/hooks/useFormatters';
import StandardAutocompleteIdsWithFallback from '@features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import Fuse from 'fuse.js';

const SuspenseComponent: FC = () => {
  const { _ } = useLingui();
  const { formatMetricValue, formatRelativeTime, formatDateTime } = useFormatters();
  const [filters, setFilters] = useState({
    search: '',
    organizationId: null as number | null,
  });
  const { data } = useSuspenseQuery(GET_DEVICES_GRAVEYARD);
  const dispatch = useDispatch();

  const openDeviceProfile = (deviceId: string) => {
    dispatch(
      openDeviceProfileModal({
        deviceId,
      }),
    );
  };

  const openDevicesAnalysisModal = (deviceId: string) => {
    dispatch(setModalDisplayedStationChart(deviceId));
  };

  const filteredDevices = useMemo(() => {
    let filteredItems = data.devices;

    if (filters.organizationId != null) {
      filteredItems = filteredItems.filter(
        (device) => device.profile.organization?.id === filters.organizationId,
      );
    }

    if (filters.search) {
      const fuse = new Fuse(filteredItems, {
        keys: ['id', 'profile.name'],
      });
      filteredItems = fuse.search(filters.search).map(({ item }) => item);
    }

    return filteredItems;
  }, [filters, data.devices]);

  return (
    <Box>
      <Box mb={1} display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
        <TextField
          value={filters.search}
          onChange={(event) =>
            setFilters((filters) => ({ ...filters, search: event.target.value }))
          }
          label="Buscar"
          size="small"
        />
        <StandardAutocompleteIdsWithFallback
          value={filters.organizationId}
          onChange={(_, organizationId) =>
            setFilters((filters) => ({ ...filters, organizationId }))
          }
          size="small"
          dataType="organizations"
        />
      </Box>
      <Box border={1} borderColor="divider" borderRadius={1}>
        <FixedSizeList
          height={450}
          width="100%"
          itemCount={filteredDevices.length}
          itemSize={42}
          itemKey={(index) => filteredDevices[index].id}
        >
          {({ style, index }) => {
            const device = filteredDevices[index];
            const { flow, level, velocity } = device.metrics;
            const getDescription = (metric: typeof flow, label: string) => {
              const { lastData, unit, precision } = metric;
              return lastData ? (
                <Typography fontSize="inherit">
                  <b>{label}:</b>{' '}
                  {formatMetricValue(lastData[1], {
                    precision,
                    unit,
                  })}{' '}
                  {formatRelativeTime(lastData[0])} <i>({formatDateTime(lastData[0])})</i>
                </Typography>
              ) : null;
            };
            const lastFlowDescription = getDescription(flow, 'Caudal');
            const lastLevelDescription = getDescription(level, 'Nivel');
            const lastVelocityDescription = getDescription(velocity, 'Velocidad');
            return (
              <Box
                style={style}
                key={device.id}
                display="grid"
                gridTemplateColumns="minmax(0, 1fr) auto"
                gap={2}
                borderBottom={index < filteredDevices.length - 1 ? 1 : 0}
                borderColor="divider"
                px={1}
                py="2px"
                alignItems="center"
              >
                <Box>
                  <Box display="flex" gap={2}>
                    <TruncatedTypography fontSize="14px">{device.profile.name}</TruncatedTypography>
                    <TruncatedTypography fontSize="14px" color="text.secondary">
                      {device.profile.organization?.name}
                    </TruncatedTypography>
                  </Box>
                  <TruncatedTypography fontSize="11px" color="text.secondary">
                    {device.id}
                  </TruncatedTypography>
                </Box>
                <Box display="flex" gap={1}>
                  <Tooltip title={_(msg`Abrir perfil`)}>
                    <IconButton size="small" onClick={() => openDeviceProfile(device.id)}>
                      <Article fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <Box>
                        <Typography fontSize="inherit">
                          <Trans>Últimos datos</Trans>
                        </Typography>
                        {!lastFlowDescription &&
                          !lastLevelDescription &&
                          !lastVelocityDescription && (
                            <Typography fontSize="inherit">
                              Sin datos de caudal, nivel ni velocidad
                            </Typography>
                          )}
                        {lastFlowDescription}
                        {lastLevelDescription}
                        {lastVelocityDescription}
                      </Box>
                    }
                  >
                    <IconButton size="small">
                      <QueryStats fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'SOON! ' + _(msg`Desarchivar`)}>
                    <span>
                      <IconButton size="small" disabled>
                        <Unarchive fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            );
          }}
        </FixedSizeList>
      </Box>
    </Box>
  );
};

export const DevicesGraveyard: FC = () => {
  const { globalModalProps, onClose } = useGlobalModal('devicesGraveyardModal');

  return (
    <GlobalModal
      {...globalModalProps}
      onClose={onClose}
      dialogProps={{ maxWidth: 'md' }}
      authorization={{
        access: Access.ViewDeletedDevices,
        onClose,
      }}
      modalTitleProps={{
        title: <Trans>Cementerio de dispositivos</Trans>,
        onClose,
      }}
      // modalActionProps={{
      //   onClose,
      // }}
    >
      <ModalContent topDivider>
        <SuspenseComponent />
      </ModalContent>
    </GlobalModal>
  );
};
