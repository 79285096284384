import { InputAdornment } from '@mui/material';
import { FieldValues, ControllerProps, Control, Controller } from 'react-hook-form';
import NumericTextField from '@components/NumericTextField';

const NumberController = <T extends FieldValues>({
  name,
  control,
  label,
  endAdornment,
}: {
  name: ControllerProps<T>['name'];
  control: Control<T>;
  label: string;
  endAdornment: string;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <NumericTextField
          {...field}
          size="small"
          label={label}
          InputProps={{
            endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
          }}
          value={field.value == undefined ? '' : field.value}
          onChange={(e) => {
            const value = e.target.value;
            if (value === '') field.onChange(undefined);
            else field.onChange(value);
          }}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default NumberController;
