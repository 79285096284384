import { createSvgIcon } from '@mui/material';

const HousingBateriaIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M149.47,85.22c47.6,0,95.2,0,142.79,0c0.52,0,1.05-0.03,1.56,0.05c2.01,0.28,3.31,1.77,3.26,3.79
		c-0.06,2.2-0.19,4.39-0.31,6.58c-0.2,3.59-0.42,7.18-0.62,10.77c-0.39,7.06-0.77,14.12-1.16,21.18c-0.18,3.27-0.35,6.54-0.53,9.81
		c-0.38,7.02-0.77,14.04-1.15,21.06c-0.19,3.43-0.36,6.86-0.54,10.29c-0.37,6.86-0.76,13.72-1.14,20.58
		c-0.19,3.43-0.36,6.86-0.55,10.29c-0.37,6.86-0.76,13.72-1.13,20.58c-0.19,3.43-0.36,6.86-0.55,10.29
		c-0.32,5.98-0.64,11.97-0.98,17.95c-0.12,2.05-1.62,3.46-3.67,3.53c-0.36,0.01-0.72,0-1.08,0c-89.48,0-178.95,0-268.43-0.01
		c-0.71,0-1.46-0.01-2.13-0.21c-1.53-0.46-2.42-1.55-2.52-3.15c-0.27-4.19-0.48-8.38-0.71-12.56c-0.38-6.78-0.76-13.56-1.13-20.34
		c-0.18-3.35-0.35-6.7-0.53-10.05c-0.46-8.46-0.93-16.91-1.39-25.37c-0.18-3.23-0.34-6.46-0.52-9.69
		c-0.39-7.06-0.78-14.12-1.16-21.18c-0.17-3.19-0.34-6.38-0.51-9.57c-0.39-7.14-0.79-14.28-1.17-21.42
		c-0.27-4.99-0.52-9.97-0.79-14.96c-0.23-4.31-0.47-8.62-0.71-12.92c-0.03-0.52-0.05-1.04-0.04-1.56c0.02-2.12,1.41-3.6,3.55-3.76
		c0.44-0.03,0.88-0.01,1.32-0.01C54.36,85.22,101.92,85.22,149.47,85.22z M17.77,244.54c0.3,0.04,0.46,0.09,0.61,0.09
		c87.39,0,174.78,0,262.17,0c0.16,0,0.32-0.04,0.47-0.06c0.03-0.01,0.07-0.04,0.1-0.06c0.03-0.03,0.06-0.05,0.11-0.11
		c0.02-0.13,0.07-0.28,0.08-0.44c0.16-2.87,0.32-5.74,0.47-8.62c0.27-5.07,0.52-10.13,0.8-15.2c0.39-7.14,0.78-14.28,1.17-21.42
		c0.17-3.19,0.34-6.38,0.51-9.57c0.38-6.9,0.76-13.8,1.14-20.7c0.19-3.39,0.35-6.78,0.54-10.17c0.38-6.86,0.76-13.72,1.14-20.58
		c0.19-3.43,0.35-6.86,0.54-10.29c0.39-7.14,0.78-14.28,1.17-21.42c0.19-3.51,0.41-7.02,0.6-10.53c0.05-0.9,0.01-1.81,0.01-2.7
		c-1.2-0.26-278.86-0.24-279.87,0.04c0,0.41-0.02,0.84,0,1.28c0.17,3.43,0.35,6.86,0.53,10.29c0.17,3.19,0.34,6.38,0.52,9.57
		c0.38,6.94,0.76,13.88,1.14,20.82c0.19,3.39,0.36,6.78,0.54,10.17c0.36,6.58,0.74,13.16,1.1,19.74c0.2,3.71,0.38,7.42,0.58,11.13
		c0.36,6.7,0.74,13.4,1.11,20.1c0.2,3.59,0.37,7.18,0.57,10.77c0.36,6.5,0.74,13,1.09,19.5c0.28,5.23,0.54,10.45,0.82,15.68
		C17.59,242.72,17.69,243.59,17.77,244.54z"
    />
    <path
      d="M29.14,69.91c0.03-0.57,0.07-1.04,0.07-1.51c0-4.76,0-9.51,0.01-14.27c0-0.52,0-1.04,0.08-1.55
		c0.25-1.59,1.58-2.81,3.19-2.93c0.36-0.03,0.72-0.02,1.08-0.02c11.04,0,22.08,0,33.12,0c0.56,0,1.13-0.01,1.67,0.12
		c1.43,0.36,2.49,1.7,2.62,3.24c0.04,0.44,0.02,0.88,0.02,1.32c0,4.72,0,9.43,0,14.15c0,0.43,0,0.87,0,1.22
		c0.14,0.11,0.17,0.13,0.2,0.16c0.03,0.02,0.06,0.06,0.1,0.06c0.16,0.03,0.31,0.07,0.47,0.07c52.12,0.01,104.24,0.01,156.35,0.01
		c0.24,0,0.48-0.02,0.72-0.05c0.08-0.01,0.15-0.06,0.36-0.15c0.02-0.39,0.06-0.85,0.06-1.31c0.01-4.88,0-9.75,0.01-14.63
		c0-0.55,0.05-1.13,0.2-1.65c0.43-1.51,1.69-2.47,3.26-2.55c0.32-0.02,0.64-0.01,0.96-0.01c11,0,22,0,33,0
		c0.32,0,0.64-0.01,0.96,0.01c1.59,0.09,2.91,1.15,3.27,2.7c0.13,0.58,0.17,1.19,0.17,1.78c0.01,4.8,0.01,9.59,0.01,14.39
		c0,0.43,0,0.86,0,1.47c0.64,0,1.15,0,1.66,0c7.28,0,14.56,0,21.84,0c0.36,0,0.72,0.01,1.08,0c2.18-0.06,3.64,1.83,3.64,3.52
		c0,2.06-1.31,3.56-3.4,3.76c-0.44,0.04-0.88,0.04-1.32,0.04c-96.32,0-192.63,0-288.95,0c-0.6,0-1.2-0.01-1.79-0.11
		c-1.76-0.29-2.95-1.84-2.9-3.71c0.05-1.83,1.39-3.3,3.17-3.47c0.48-0.05,0.96-0.04,1.44-0.04c7.32,0,14.64,0,21.96,0
		C28.03,69.97,28.54,69.93,29.14,69.91z M36.73,57.06c-0.28,1.23-0.24,11.87,0.03,12.78c1.34,0.25,25.89,0.19,26.81-0.08
		c0-4.2,0-8.41,0-12.7C54.57,57.06,45.64,57.06,36.73,57.06z M236.68,69.97c8.84,0,17.58,0,26.32-0.01c0.22,0,0.44-0.12,0.63-0.18
		c0.25-1.86,0.16-11.86-0.11-12.69c-1.58-0.23-25.93-0.15-26.84,0.1C236.68,61.36,236.68,65.54,236.68,69.97z"
    />
    <path
      d="M80.72,168.19c-0.65,0-1.17,0-1.68,0c-7.04,0-14.08,0-21.11,0c-0.4,0-0.8,0.01-1.2-0.02
		c-1.99-0.16-3.41-1.68-3.41-3.64c0-1.96,1.42-3.48,3.41-3.63c0.48-0.04,0.96-0.03,1.44-0.03c6.96,0,13.92,0,20.87,0
		c0.51,0,1.02,0,1.67,0c0-0.64,0-1.11,0-1.58c0-7.19,0-14.38,0.01-21.58c0-0.59,0.04-1.21,0.22-1.77c0.5-1.62,2.14-2.64,3.82-2.45
		c1.78,0.2,3.12,1.53,3.27,3.28c0.04,0.48,0.03,0.96,0.03,1.44c0,6.99,0,13.98,0,20.98c0,0.48,0,0.95,0,1.56
		c0.59,0.04,1.09,0.11,1.58,0.11c7,0.01,14,0,20.99,0.01c0.52,0,1.04,0,1.56,0.05c1.87,0.17,3.21,1.64,3.25,3.52
		c0.04,1.96-1.26,3.49-3.17,3.71c-0.47,0.06-0.96,0.04-1.44,0.04c-7.04,0-14.08,0-21.11,0.01c-0.51,0-1.02,0.05-1.66,0.08
		c0,0.57,0,1.04,0,1.51c0,6.99,0,13.98,0,20.98c0,0.44,0.01,0.88-0.02,1.32c-0.11,2.01-1.7,3.54-3.67,3.53
		c-1.91-0.01-3.5-1.5-3.65-3.44c-0.03-0.36-0.01-0.72-0.01-1.08c0-7.11,0-14.22,0-21.34C80.72,169.28,80.72,168.82,80.72,168.19z"
    />
    <path
      d="M214.57,168.19c-8.79,0-17.59,0-26.38,0c-0.52,0-1.04,0.01-1.56-0.06c-1.81-0.23-3.18-1.84-3.15-3.65
		c0.03-1.83,1.43-3.38,3.25-3.57c0.48-0.05,0.96-0.05,1.44-0.05c17.63,0,35.26,0,52.89,0c0.44,0,0.88,0,1.32,0.04
		c1.89,0.17,3.31,1.73,3.32,3.61c0,1.84-1.35,3.39-3.18,3.62c-0.47,0.06-0.96,0.05-1.44,0.05
		C232.24,168.19,223.4,168.19,214.57,168.19z"
    />
  </svg>,
  'HousingBateriaIcon',
);

export default HousingBateriaIcon;
