import { gql } from '@/__generated__';

const GET_INTEGRATION_GROUPED_ISSUES_COUNT = gql(`#graphql
  query GetIntegrationGroupedIssuesCount($integrationId: Int!, $severity: WriteExecutionLogSeverity!) {
    integration(input: { id: $integrationId }) {
      id
      writeExecutionLogGroupedIssues(input: { severities: [$severity] }) {
        id
        pageInfo {
          totalCount
        }
      }
    }
  }
`);

export default GET_INTEGRATION_GROUPED_ISSUES_COUNT;
