import { gql } from '@/__generated__';

const USER_TOOLTIP_GET_USER_INFO = gql(`#graphql
  query UserTooltip_GetUserInfo($userId: Int!) {
    user(input: { id: $userId }) {
      id
      fullName
      email
      mainOrganization {
        organization {
          id
          name
        }
      }
    }
  }
`);

export default USER_TOOLTIP_GET_USER_INFO;
