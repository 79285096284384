import { gql } from '@/__generated__';

const GET_DEVICE_PROFILE_STATUS = gql(`#graphql
  query DeviceProfileModal_GetDeviceProfileStatus($input: DeviceInput!) {
    device(input: $input) {
      id
      alerts {
        id
        basicConfig: basicConfigurations {
          id
          lastFlow {
            min
            max
            overflow
            unit
            precision
          }
          lastLevel {
            min
            max
            overflow
            unit
            precision
          }
        }
      }
      dgaConfiguration {
        id
      }
      hardwareConfiguration {
        id
      }
      metrics {
        id
        flow {
          lastData
        }
        level {
          lastData
        }
        velocity {
          lastData
        }
      }
    }
  }
`);

export default GET_DEVICE_PROFILE_STATUS;
