import { useAuth, useModalStatusMessage } from '@/hooks';
import { useApolloClient, useMutation } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Dialog, DialogContent } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-latest';
import { AdapterDayjs } from '@mui/x-date-pickers-latest/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Suspense, useState, type FC } from 'react';
import SEND_SMA_REPORTS_EXCEL_BY_EMAIL from '../graphql/mutations/sendSmaReportsExcelByEmail';
import { SmaMetricName } from 'src/__generated__/graphql';

type Props = {
  deviceId: string;
  deviceName: string;
  open: boolean;
  onClose: () => void;
  smaUnitId: string;
  smaDeviceId: string;
  smaProcessId: string;
  smaMetricName: SmaMetricName;
};

const getStartAndStopDates = () => {
  const stop = dayjs().startOf('hour');
  const start = stop.subtract(1, 'day');
  return [start, stop];
};

const Component: FC<Props> = ({
  deviceId,
  deviceName,
  onClose,
  smaUnitId,
  smaDeviceId,
  smaProcessId,
  smaMetricName,
}) => {
  const client = useApolloClient();
  const defaultDates = getStartAndStopDates();
  const [start, setStart] = useState<Dayjs | null>(defaultDates[0]);
  const [stop, setStop] = useState<Dayjs | null>(defaultDates[1]);
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { user } = useAuth();
  const { _, i18n } = useLingui();
  const [send, { loading }] = useMutation(SEND_SMA_REPORTS_EXCEL_BY_EMAIL, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Se ha enviado el reporte a tu correo`));
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
  });

  const onSubmit = async () => {
    const userEmail = user?.email;
    if (!start || !stop || !userEmail) return;
    send({
      variables: {
        input: {
          deviceId,
          smaDeviceId,
          smaMetricName,
          smaProcessId,
          smaUnitId,
          start: start.valueOf(),
          stop: stop.valueOf(),
          userEmail,
        },
      },
    });
  };

  return (
    <>
      <ModalTitle
        onClose={onClose}
        title={_(msg`Descargar tabla de reportes SMA`)}
        subtitle={`${deviceName} - ID: ${deviceId}`}
      />
      <DialogContent dividers>
        <Trans>Selecciona el rango de fechas para generar el excel</Trans>
        <LocalizationProvider adapterLocale={i18n.locale} dateAdapter={AdapterDayjs}>
          <Box display="flex" gap={2} mt={2}>
            <DateTimePicker
              views={['year', 'month', 'day', 'hours', 'minutes']}
              ampm={false}
              label={_(msg`Desde`)}
              disableFuture
              value={start}
              onChange={(newDate) => setStart(newDate)}
              maxDateTime={stop}
            />
            <DateTimePicker
              views={['year', 'month', 'day', 'hours', 'minutes']}
              ampm={false}
              label={_(msg`Hasta`)}
              disableFuture
              minDateTime={start}
              value={stop}
              onChange={(newDate) => setStop(newDate)}
            />
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <ModalActions
        submitButton={{
          loading,
          label: _(msg`Enviar`),
          onClick: () => onSubmit(),
        }}
        onClose={onClose}
      />
    </>
  );
};

const SendReportsByEmailModal: FC<Props> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <ApolloErrorBoundary>
        <Suspense>
          <Component {...props} />
        </Suspense>
      </ApolloErrorBoundary>
    </Dialog>
  );
};

export default SendReportsByEmailModal;
