import { type FC } from 'react';
import AddDeviceUserRelationsModal from './AddDeviceUserRelationsModal';
import RemoveDeviceUserRelationsModal from './RemoveDeviceUserRelationsModal';

const DeviceUserAccessGlobalModals: FC = () => {
  return (
    <>
      <AddDeviceUserRelationsModal />
      <RemoveDeviceUserRelationsModal />
    </>
  );
};

export default DeviceUserAccessGlobalModals;
