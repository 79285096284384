import { WriteExecutionLogSeverity } from '@/__generated__/graphql';
import { useGlobalModal } from '@/hooks/useGlobalModal';
import { ModalsState } from '@/slices/modals';
import GlobalModal from '@components/GlobalModal';
import ModalContent from '@components/modal/ModalContent';
import { Trans } from '@lingui/macro';
import { useState, type FC } from 'react';
import { Filters } from './Filters';
import { GroupedLogsTable } from './GroupedLogsTable';

interface Props {
  defaultSeverity: WriteExecutionLogSeverity;
  entity: Extract<ModalsState['viewEntityGroupedIssues'], { open: true }>['entity'];
}

const Content: FC<Props> = ({ defaultSeverity, entity }) => {
  const [severityFilter, setSeverityFilter] = useState<WriteExecutionLogSeverity>(defaultSeverity);

  return (
    <ModalContent topDivider>
      <Filters entity={entity} severity={severityFilter} onSeverityChange={setSeverityFilter} />
      <GroupedLogsTable
        entity={entity}
        severity={severityFilter}
        range={{
          start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime(), // 1 week ago
        }}
      />
    </ModalContent>
  );
};

export const GroupedIssuesModal: FC = () => {
  const { globalModalProps, state, onClose } = useGlobalModal('viewEntityGroupedIssues');

  return (
    <GlobalModal
      {...globalModalProps}
      modalTitleProps={{
        title: <Trans>Entity</Trans>,
        onClose,
      }}
    >
      {state.open && (
        <Content
          defaultSeverity={state.selectedSeverityFilter ?? WriteExecutionLogSeverity.Error}
          entity={state.entity}
        />
      )}
    </GlobalModal>
  );
};
