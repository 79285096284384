import { Tooltip, Typography, type TooltipProps, type TypographyProps } from '@mui/material';
import { useEffect, useRef, useState, type FC, type ReactNode } from 'react';

interface Props extends TypographyProps {
  tooltipProps?: Omit<TooltipProps, 'title'> & {
    title?: ReactNode;
  };
}

const TruncatedTypography: FC<Props> = ({ children, tooltipProps, ...typographyProps }) => {
  const textRef = useRef<HTMLElement | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const resizeTimer = useRef<NodeJS.Timeout>();

  const checkTruncation = () => {
    if (textRef.current) {
      // console.log('SCROLL WIDTH', textRef.current.scrollWidth);
      // console.log('CLIENT WIDTH', textRef.current.clientWidth);
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  };

  useEffect(() => {
    checkTruncation();

    // apply debouncing
    const handleResize = () => {
      clearTimeout(resizeTimer.current);
      resizeTimer.current = setTimeout(() => {
        checkTruncation();
      }, 150);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      clearTimeout(resizeTimer.current);
      window.removeEventListener('resize', handleResize);
    };
  }, [children]);

  return (
    <Tooltip {...tooltipProps} title={isTruncated ? tooltipProps?.title ?? children : ''}>
      <Typography ref={textRef} noWrap {...typographyProps}>
        {children}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedTypography;
