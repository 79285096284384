import ModalContent from '@components/modal/ModalContent';
import { Box, Skeleton } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { DeviceTabProps } from './tabProps';
import ModalActions from '@components/modal/ModalActions';

export const TabContent: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ModalContent bottomDivider>
      <Box maxWidth="lg" height="55dvh" overflow="auto" mx="auto" pt={2}>
        {children}
      </Box>
    </ModalContent>
  );
};

export const TabFallback: FC<DeviceTabProps> = ({ onClose }) => {
  return (
    <>
      <TabContent>
        <Skeleton height="100%" variant="rounded" />
      </TabContent>
      <ModalActions onClose={onClose} />
    </>
  );
};
