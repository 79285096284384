import { createSvgIcon } from '@mui/material';

const HousingPanelSolarIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M114.92,261.8c-36.04,0-72.08,0-108.11,0c-0.6,0-1.21,0.01-1.79-0.11c-2.15-0.43-3.4-2.33-2.9-4.46
		c0.74-3.15,1.54-6.28,2.33-9.42c6.84-27.34,13.69-54.68,20.53-82.03c2.51-10.03,5.01-20.06,7.52-30.1
		c0.11-0.43,0.21-0.86,0.38-1.26c0.61-1.41,1.69-2.18,3.24-2.26c0.36-0.02,0.72-0.01,1.08-0.01c51.84,0,103.67,0,155.51,0
		c0.32,0,0.64,0,0.96,0c1.83,0.03,3.12,0.97,3.6,2.76c0.74,2.78,1.42,5.57,2.12,8.36c6.2,24.78,12.4,49.57,18.6,74.35
		c3.26,13.05,6.53,26.1,9.79,39.15c0.73,2.93-0.88,4.97-3.9,5.02c-0.4,0.01-0.8,0-1.2,0C186.76,261.8,150.84,261.8,114.92,261.8z
		 M111.17,229.03c-1.85-0.25-42.58-0.15-43.5,0.11c-1.04,8.35-2.09,16.73-3.15,25.22c15.63,0,31.12,0,46.6,0
		C111.39,253.42,111.44,230.35,111.17,229.03z M118.78,228.99c-0.28,3.02-0.15,24.5,0.16,25.4c0.18,0.02,0.37,0.05,0.56,0.05
		c15.11,0,30.22,0,45.32-0.01c0.12,0,0.23-0.04,0.35-0.07c0.04-0.01,0.07-0.04,0.1-0.07c0.03-0.03,0.05-0.06,0.07-0.09
		c0.07-1.51-2.89-24.39-3.26-25.22C147.68,228.99,133.27,228.99,118.78,228.99z M172.91,254.36c15.62,0,31.06,0,46.68,0
		c-2.14-8.54-4.24-16.96-6.34-25.35c-14.56,0-28.97,0-43.51,0C170.8,237.53,171.85,245.94,172.91,254.36z M57.1,254.37
		c1.06-8.47,2.11-16.88,3.17-25.38c-14.58,0-28.99,0-43.5,0c-2.12,8.44-4.25,16.85-6.34,25.38
		C26.07,254.37,41.52,254.37,57.1,254.37z M75.46,167.42c-0.76,3.92-3.26,24.86-3.08,25.75c1.32,0.22,37.89,0.18,38.79-0.05
		c0.28-1.24,0.23-24.8-0.07-25.7C99.25,167.42,87.39,167.42,75.46,167.42z M157.84,193.24c-1.09-8.69-2.15-17.23-3.23-25.8
		c-12,0-23.89,0-35.76,0c-0.27,1.46-0.2,24.89,0.08,25.8C131.8,193.24,144.69,193.24,157.84,193.24z M204.29,193.21
		c-2.17-8.69-4.32-17.26-6.45-25.78c-12,0-23.82,0-35.79,0c1.05,8.66,2.12,17.23,3.23,25.78
		C178.35,193.21,191.24,193.21,204.29,193.21z M67.97,167.44c-12.06,0-23.91,0-35.73,0c-0.91,2.55-6.35,24.83-6.29,25.77
		c12.89,0,25.78,0,38.79,0C65.82,184.62,66.89,176.08,67.97,167.44z M68.64,221.38c1.35,0.29,41.63,0.25,42.53-0.01
		c0.28-1.29,0.22-19.78-0.08-20.64c-13.25,0-26.51,0-39.87,0C70.37,207.63,69.51,214.47,68.64,221.38z M158.77,200.72
		c-13.42,0-26.68,0-40.07,0c0,6.95-0.02,13.83,0.01,20.67c1.59,0.31,41.68,0.23,42.64-0.09C160.5,214.5,159.64,207.66,158.77,200.72
		z M166.25,200.73c0.05,0.56,0.07,1.03,0.13,1.49c0.37,2.97,0.74,5.94,1.12,8.91c0.4,3.17,0.79,6.34,1.2,9.51
		c0.11,0.86,0.15,0.89,1.06,0.92c0.56,0.02,1.12,0,1.68,0c12.76,0,25.52,0,38.28,0c0.5,0,0.99-0.05,1.65-0.09
		c-1.76-7.04-3.48-13.9-5.19-20.74C192.79,200.73,179.57,200.73,166.25,200.73z M61.21,221.49c0.87-6.93,1.73-13.8,2.6-20.76
		c-13.39,0-26.64,0-39.97,0c-1.73,6.91-3.44,13.77-5.19,20.76C32.91,221.49,46.99,221.49,61.21,221.49z M78.88,139.6
		c-0.85,6.78-1.69,13.5-2.54,20.32c11.69,0,23.26,0,34.91,0c0-6.8,0-13.52,0-20.32C100.47,139.6,89.77,139.6,78.88,139.6z
		 M151.13,139.59c-10.88,0-21.58,0-32.29,0c-0.06,0.17-0.12,0.28-0.12,0.39c-0.01,6.31-0.02,12.62-0.02,18.94
		c0,0.28,0.04,0.55,0.07,0.83c0,0.03,0.04,0.07,0.06,0.09c0.03,0.03,0.06,0.05,0.08,0.07c11.54,0,23.08,0,34.75,0
		C152.82,153.1,151.98,146.39,151.13,139.59z M158.55,139.58c0.86,6.85,1.7,13.57,2.55,20.34c11.64,0,23.17,0,34.87,0
		c-1.71-6.85-3.4-13.59-5.09-20.34C180.08,139.58,169.42,139.58,158.55,139.58z M68.9,159.93c0.87-6.79,1.74-13.53,2.53-20.33
		c-10.86,0-21.56,0-32.3,0c-1.7,6.78-3.38,13.51-5.08,20.33C45.72,159.93,57.26,159.93,68.9,159.93z"
    />
    <path
      d="M209.03,66.02c28.32,0.21,49.47,18.87,54.75,42.82c6.19,28.06-10.66,56.31-38.36,64.12
		c-0.81,0.23-1.61,0.42-2.47,0.19c-1.53-0.4-2.65-1.65-2.8-3.22c-0.15-1.54,0.71-3.05,2.15-3.69c0.44-0.19,0.9-0.32,1.36-0.45
		c15.71-4.58,27.83-16.78,32.26-32.5c7.09-25.17-7.75-51.34-33.01-58.21c-26.05-7.09-52.83,9.18-58.5,35.55
		c-0.58,2.7-0.91,5.43-1.03,8.19c-0.07,1.54-0.82,2.68-2.26,3.24c-1.36,0.54-2.68,0.37-3.82-0.6c-0.9-0.78-1.36-1.8-1.25-2.98
		c0.21-2.42,0.34-4.87,0.77-7.26c1.9-10.75,6.56-20.15,14.08-28.08c8.27-8.71,18.35-14.16,30.19-16.21
		C204.21,66.4,207.37,66.21,209.03,66.02z"
    />
    <path
      d="M214.24,46.31c0,3.48,0.01,6.95-0.01,10.43c0,0.59-0.06,1.21-0.23,1.77c-0.53,1.71-2.18,2.65-4.07,2.4
		c-1.66-0.22-2.94-1.63-3.03-3.36c-0.01-0.24-0.01-0.48-0.01-0.72c0-7.07,0-14.15,0.01-21.22c0-0.55,0.02-1.13,0.17-1.66
		c0.46-1.55,1.89-2.52,3.55-2.5c1.64,0.02,3.02,1.06,3.43,2.65c0.14,0.53,0.18,1.1,0.18,1.66
		C214.24,39.27,214.24,42.79,214.24,46.31z"
    />
    <path
      d="M258.4,158.3c1.02-0.01,1.85,0.42,2.6,1.07c2.05,1.78,4.1,3.55,6.16,5.33c3.41,2.95,6.82,5.91,10.23,8.86
		c1.27,1.1,1.77,2.44,1.26,4.07c-0.72,2.31-3.41,3.28-5.45,1.98c-0.37-0.23-0.7-0.53-1.03-0.82c-5.22-4.52-10.44-9.04-15.66-13.56
		c-0.3-0.26-0.62-0.51-0.88-0.81c-1.01-1.21-1.26-2.57-0.57-4.01C255.72,159.02,256.87,158.33,258.4,158.3z"
    />
    <path
      d="M129.1,85.39c0.03-2.88,2.37-4.58,4.74-3.83c0.45,0.14,0.89,0.35,1.33,0.54c6.25,2.85,12.49,5.7,18.73,8.56
		c0.36,0.17,0.73,0.33,1.07,0.54c1.51,0.99,2.07,2.87,1.37,4.53c-0.7,1.64-2.47,2.52-4.23,2.09c-0.35-0.08-0.69-0.21-1.01-0.36
		c-6.54-2.98-13.07-5.97-19.61-8.95C129.94,87.83,129.11,86.7,129.1,85.39z"
    />
    <path
      d="M176.5,70.98c-1.6,0.04-2.65-0.64-3.41-1.84c-1.72-2.69-3.45-5.37-5.18-8.05c-2.18-3.39-4.36-6.78-6.54-10.17
		c-0.68-1.06-0.98-2.18-0.55-3.41c0.46-1.31,1.34-2.15,2.72-2.42c1.41-0.28,2.6,0.11,3.56,1.19c0.16,0.18,0.29,0.38,0.42,0.58
		c3.93,6.11,7.87,12.21,11.76,18.34c0.39,0.61,0.63,1.4,0.66,2.12C180.04,69.38,178.43,70.97,176.5,70.98z"
    />
    <path
      d="M264.46,93.98c0.01-1.23,0.73-2.39,2.17-3.06c2.13-1,4.28-1.96,6.42-2.94c4.43-2.02,8.86-4.05,13.29-6.07
		c0.8-0.37,1.63-0.67,2.54-0.5c1.68,0.32,2.89,1.58,3.1,3.23c0.2,1.53-0.64,3.06-2.18,3.79c-2.53,1.19-5.08,2.32-7.62,3.48
		c-4.03,1.84-8.06,3.68-12.09,5.52c-0.89,0.41-1.78,0.67-2.78,0.43C265.62,97.45,264.48,96.09,264.46,93.98z"
    />
    <path
      d="M260.55,48.76c-0.25,0.66-0.38,1.39-0.75,1.97c-3.94,6.19-7.92,12.36-11.91,18.53
		c-0.95,1.47-2.42,2.02-4.04,1.66c-1.41-0.32-2.54-1.6-2.74-3.14c-0.14-1.05,0.24-1.95,0.8-2.81c2.73-4.22,5.44-8.45,8.16-12.68
		c1.17-1.81,2.33-3.62,3.49-5.44c0.66-1.03,1.55-1.69,2.8-1.82C258.6,44.8,260.44,46.45,260.55,48.76z"
    />
    <path
      d="M273.91,125.95c0.98,0.13,2.48,0.33,3.98,0.55c4.86,0.7,9.72,1.4,14.57,2.1c1.07,0.15,2.13,0.31,3.2,0.47
		c2.15,0.33,3.45,2.16,3.12,4.34c-0.29,1.88-2.15,3.23-4.14,2.96c-3.4-0.47-6.8-0.97-10.19-1.46c-3.71-0.54-7.43-1.07-11.14-1.61
		c-0.28-0.04-0.55-0.08-0.82-0.15c-2.03-0.48-3.18-2.13-2.91-4.16C269.77,127.38,271.28,125.75,273.91,125.95z"
    />
  </svg>,

  'HousingPanelSolarIcon',
);

export default HousingPanelSolarIcon;
