import { useSelector } from '@/store';
import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLingui } from '@lingui/react';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { MetricField } from 'src/__generated__/graphql';
import { FormSelectMulti } from 'src/components/form/FormSelectMulti';
import ModalActions from 'src/components/modal/ModalActions';
import ModalTitle from 'src/components/modal/ModalTitle';
import StandardAutocompleteIdsWithFallback from 'src/features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import { setSnackbar } from 'src/slices/components';
import { setUpsertAlertAdvancedCreationModal } from 'src/slices/modals';
import { getUnitsPerMetric } from 'src/utils/getUnitsPerMetric';
import { getMetricDisplayName } from 'src/utils/i18n/getMetricDisplayName';
import { UPDATE_ALERT_CONFIG } from '../graphql/mutations/updateAlertConfig';
import GET_DEVICE_INFO from '../graphql/queries/getDeviceInfo';
import { FormProps, getDefaultValues, getSchema } from '../utils/alertAdvancedCreationForm';
import { AlertState } from '../utils/alertStates';
import { AlertStateRow } from './AlertStateRow';

const AlertAdvancedCreationModal = () => {
  const theme = useTheme();
  const { i18n } = useLingui();
  const { upsertAlertAdvancedCreationModal: modalState } = useSelector(
    (state) => state.modals_store,
  );
  const { mode, initialDeviceId, open } = modalState;
  const [deviceId, setDeviceId] = useState<string | undefined>(initialDeviceId);
  const [metric, setMetric] = useState<MetricField>();

  const dispatch = useDispatch();
  const closeModal = () => dispatch(setUpsertAlertAdvancedCreationModal({ open: false }));

  const [mutUpdateAlertConfig, { loading: loadingMut }] = useMutation(UPDATE_ALERT_CONFIG, {
    onCompleted: () => {
      dispatch(
        setSnackbar({
          open: true,
          severity: 'success',
          message: 'Alerta avanzada actualizada exitosamente.',
        }),
      );
    },
    onError: (error) => {
      dispatch(
        setSnackbar({
          open: true,
          severity: 'error',
          message: error.message,
        }),
      );
    },
    refetchQueries: [GET_DEVICE_INFO],
  });

  const { data, loading: loadingQuery } = useQuery(GET_DEVICE_INFO, {
    variables: { input: { id: deviceId ?? '' } },
    skip: deviceId == null,
  });

  const defaultValues = getDefaultValues(data?.device, metric);

  const { control, watch, handleSubmit, reset, formState } = useForm<FormProps>({
    resolver: zodResolver(getSchema()),
    defaultValues,
  });

  const currentForm = watch();

  const isDefaultValues = JSON.stringify(currentForm) === JSON.stringify(defaultValues);

  const selectedAlerts = watch('selectedAlerts');

  const unitOptions = metric
    ? getUnitsPerMetric(metric, i18n).map((i) => ({
        label: i.label,
        value: i.enumValue,
      }))
    : [];

  useEffect(() => {
    const defaultValues = getDefaultValues(data?.device, metric);
    reset(defaultValues);
  }, [data, metric]);

  const validMetricFields = [MetricField.Level, MetricField.Flow];
  const metricFieldsOptions =
    data?.device.metrics.availableFields
      .filter((i) => validMetricFields.includes(i))
      .map((i) => ({
        label: getMetricDisplayName(i, i18n),
        value: i,
      })) ?? [];

  const alertStateOptions = [
    {
      label: 'OK',
      value: AlertState.OK,
    },
    {
      label: 'WARNING',
      value: AlertState.WARNING,
    },
    {
      label: 'CRITICAL',
      value: AlertState.CRITICAL,
    },
  ];

  const onSubmit = (params: FormProps) => {
    if (metric == null || deviceId == null) {
      return;
    }

    let okField = undefined;
    let warningField = undefined;
    let criticalField = undefined;

    if (params.selectedAlerts.includes(AlertState.OK) && params.ok != null) {
      okField = {
        comparisonType: params.ok.comparisonType,
        eventName: params.ok.eventName,
        //@ts-ignore
        thresholdValue: params.ok?.thresholdValue,
        //@ts-ignore
        thresholdMin: params.ok?.thresholdMin,
        //@ts-ignore
        thresholdMax: params.ok?.thresholdMax,
        unit: params.unit,
      };
    }
    if (params.selectedAlerts.includes(AlertState.WARNING) && params.warning != null) {
      warningField = {
        comparisonType: params.warning.comparisonType,
        eventName: params.warning.eventName,
        //@ts-ignore
        thresholdValue: params.warning?.thresholdValue,
        //@ts-ignore
        thresholdMin: params.warning?.thresholdMin,
        //@ts-ignore
        thresholdMax: params.warning?.thresholdMax,
        unit: params.unit,
      };
    }
    if (params.selectedAlerts.includes(AlertState.CRITICAL) && params.critical != null) {
      criticalField = {
        comparisonType: params.critical.comparisonType,
        eventName: params.critical.eventName,
        //@ts-ignore
        thresholdValue: params.critical?.thresholdValue,
        //@ts-ignore
        thresholdMin: params.critical?.thresholdMin,
        //@ts-ignore
        thresholdMax: params.critical?.thresholdMax,
        unit: params.unit,
      };
    }
    const inputVar = {
      input: {
        deviceId: deviceId,
        metric: metric,
        ok: okField,
        warning: warningField,
        critical: criticalField,
      },
    };
    mutUpdateAlertConfig({ variables: inputVar });
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <ModalTitle title="Creación de Alertas Avanzadas" onClose={closeModal} />

      <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <StandardAutocompleteIdsWithFallback
          fullWidth
          disabled={mode === 'update'}
          dataType="devices"
          value={deviceId}
          onChange={(_, value) => setDeviceId(value ?? undefined)}
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="metric-dropdown">Métrica</InputLabel>
            <Select
              labelId="metric-dropdown"
              label="Métrica"
              onChange={(event) => setMetric(event.target.value as MetricField)}
              value={metric}
            >
              {metricFieldsOptions.map((o) => (
                <MenuItem id={`${o.value}`} key={o.value.toString()} value={o.value as MetricField}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Controller
            control={control}
            name="unit"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                disablePortal
                disableClearable
                getOptionLabel={(option) =>
                  unitOptions.find((i) => i.value === option)?.label ?? ''
                }
                onChange={(_, value) => onChange(value)}
                value={value}
                options={unitOptions.map((i) => i.value)}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Unidad" />}
              />
            )}
          />

          <FormSelectMulti
            control={control}
            name="selectedAlerts"
            label="Estados de Alerta"
            options={alertStateOptions}
          />
        </Box>

        <Table
          sx={{
            borderWidth: 0,
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.divider,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Estado de Alerta</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell colSpan={2}>Parámetros</TableCell>
            </TableRow>
          </TableHead>
          {selectedAlerts.includes(AlertState.OK) && (
            <AlertStateRow control={control} alertState={AlertState.OK} />
          )}
          {selectedAlerts.includes(AlertState.WARNING) && (
            <AlertStateRow control={control} alertState={AlertState.WARNING} />
          )}
          {selectedAlerts.includes(AlertState.CRITICAL) && (
            <AlertStateRow control={control} alertState={AlertState.CRITICAL} />
          )}
        </Table>
      </DialogContent>
      <ModalActions
        submitDisabled={!formState.isValid || loadingMut || loadingQuery || isDefaultValues}
        onClose={closeModal}
        onSubmit={handleSubmit(onSubmit)}
      />
    </Dialog>
  );
};

export { AlertAdvancedCreationModal };
