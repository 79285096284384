import { gql } from '@/__generated__';

const CREATE_DEVICE_USER_RELATIONS = gql(`#graphql
  mutation CreateDeviceUserRelations($inputs: [CreateDeviceUserRelationInput!]!) {
    createDeviceUserRelations(inputs: $inputs) {
      createdRecords {
        device {
          id
        }
        id
        user {
          id
        }
        hasAccess
      }
    }
  }
`);

export default CREATE_DEVICE_USER_RELATIONS;
