import { gql } from '@/__generated__';

const GET_DEVICE_RADAR_PARAMETERS = gql(`#graphql
  query GetDeviceRadarParameters($deviceId: String!) {
    deviceDataConfiguration(deviceId: $deviceId) {
      deviceId
      radarParameters {
        ratingCurve {
          active
          levelLimit
        }
        areaCurve {
          levelOffset
          type
          curve {
            functionType
            parameters {
              vMaxDepthNorm
              m
              a
              talud
              baseWidth
              A
              B
              C
            }
            min
            max
          }
          curves {
            functionType
            parameters {
              vMaxDepthNorm
              m
              a
              talud
              baseWidth
              A
              B
              C
            }
            min
            max
          }
        }
        velocityCurve {
          model
          type
          curve {
            functionType
            parameters {
              vMaxDepthNorm
              m
              a
              talud
              baseWidth
              A
              B
              C
            }
            min
            max
          }
          curves {
            functionType
            parameters {
              vMaxDepthNorm
              m
              a
              talud
              baseWidth
              A
              B
              C
            }
            min
            max
          }
        }
      }
    }
  }
`);

export default GET_DEVICE_RADAR_PARAMETERS;
