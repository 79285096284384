import { createSvgIcon } from '@mui/material';

const HousingFacilInstalar1hIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M163.1,94.05c-0.47-2.49-1.05-4.78-1.32-7.11c-1.18-9.82,1.01-18.86,6.78-26.91
		c6.37-8.9,15.07-14.17,25.9-15.75c7.29-1.06,14.35-0.04,21.08,3c0.29,0.13,0.59,0.26,0.86,0.41c2.09,1.18,2.56,3.82,1,5.65
		c-0.26,0.3-0.56,0.58-0.84,0.86c-7.86,7.92-15.73,15.83-23.6,23.75c-1.15,1.15-2.06,2.45-2.46,4.05c-1.02,4.08,1.49,8.52,5.62,9.89
		c2.86,0.95,5.55,0.62,7.87-1.41c1.53-1.34,2.98-2.78,4.42-4.22c6.57-6.56,13.12-13.13,19.67-19.69c0.31-0.31,0.61-0.63,0.95-0.92
		c1.96-1.69,4.74-1.14,5.83,1.2c1.1,2.36,1.87,4.85,2.39,7.4c3.52,17.15-4.53,34.09-20.06,42.1c-6.99,3.61-14.42,4.84-22.22,3.87
		c-1.66-0.21-3.3-0.57-4.94-0.94c-0.7-0.16-1.17-0.04-1.68,0.49c-10.99,11.18-22,22.34-33.01,33.51
		c-7.83,7.95-15.67,15.89-23.5,23.84c-3.23,3.28-6.43,6.58-9.7,9.81c-0.67,0.66-0.74,1.22-0.52,2.1
		c3.46,13.35,0.85,25.39-8.18,35.84c-6.23,7.21-14.27,11.36-23.71,12.62c-7.18,0.96-14.11-0.08-20.73-3.06
		c-0.33-0.15-0.66-0.29-0.97-0.48c-2-1.2-2.43-3.83-0.9-5.59c0.26-0.3,0.56-0.57,0.84-0.86c7.84-7.89,15.67-15.78,23.51-23.66
		c1.24-1.24,2.22-2.63,2.59-4.37c0.86-4.04-1.63-8.31-5.68-9.66c-2.81-0.94-5.45-0.61-7.78,1.32c-0.89,0.74-1.74,1.54-2.56,2.36
		c-7.36,7.35-14.71,14.7-22.06,22.06c-0.63,0.63-1.29,1.2-2.2,1.37c-1.84,0.35-3.41-0.44-4.21-2.19c-1.18-2.6-1.98-5.32-2.51-8.11
		c-2.92-15.62,3.94-31.28,17.42-39.68c5.39-3.36,11.27-5.24,17.62-5.68c4.11-0.29,8.15,0.09,12.12,1.1c0.85,0.21,1.38,0.1,1.99-0.53
		c11.52-11.72,23.07-23.42,34.62-35.12c7.61-7.72,15.23-15.43,22.84-23.14c2.98-3.02,5.96-6.03,8.93-9.05
		C162.84,94.34,163,94.16,163.1,94.05z M76.67,229.32c0.5,0.16,0.76,0.26,1.03,0.32c3.86,0.89,7.76,1.1,11.67,0.48
		c8.45-1.35,15.31-5.44,20.39-12.33c4.81-6.53,6.68-13.89,5.79-21.95c-0.28-2.56-0.9-5.03-1.82-7.43c-0.65-1.68-0.31-3.12,0.98-4.37
		c0.69-0.67,1.36-1.36,2.03-2.04c12.94-13.13,25.88-26.26,38.82-39.39c7.24-7.35,14.48-14.7,21.72-22.05
		c2.67-2.71,5.34-5.4,8.01-8.11c1.19-1.21,2.56-1.55,4.16-1.01c0.26,0.09,0.53,0.18,0.8,0.26c8.49,2.62,16.68,1.92,24.47-2.36
		c11.79-6.47,17.95-19.66,15.48-32.89c-0.06-0.33-0.18-0.65-0.32-1.14c-0.45,0.42-0.77,0.7-1.07,1.01
		c-5.69,5.68-11.37,11.37-17.06,17.05c-0.79,0.79-1.6,1.57-2.44,2.31c-3.54,3.15-7.64,4.46-12.39,3.92
		c-7.49-0.85-13.64-7.36-13.98-14.92c-0.19-4.25,1.16-7.96,4.02-11.08c2.03-2.21,4.16-4.32,6.27-6.45
		c4.57-4.6,9.15-9.18,13.72-13.77c0.29-0.29,0.55-0.61,0.9-1c-0.41-0.14-0.63-0.24-0.86-0.29c-4.02-0.95-8.08-1.12-12.15-0.45
		c-6.72,1.11-12.5,4.09-17.24,8.98c-5.84,6.03-8.74,13.29-8.8,21.66c-0.03,3.75,0.66,7.39,2,10.91c0.65,1.69,0.39,3.18-0.92,4.51
		c-11.55,11.69-23.09,23.4-34.63,35.1c-7.75,7.86-15.5,15.72-23.25,23.58c-4.24,4.3-8.49,8.59-12.72,12.9
		c-0.87,0.88-1.88,1.36-3.11,1.23c-0.59-0.06-1.16-0.28-1.73-0.47c-4.92-1.61-9.95-1.98-15.04-1.07
		c-17.11,3.06-28.35,19.52-24.97,36.53c0.05,0.25,0.17,0.49,0.31,0.89c0.42-0.4,0.73-0.69,1.03-1c5.8-5.8,11.59-11.6,17.4-17.39
		c0.82-0.82,1.67-1.61,2.55-2.36c4.06-3.42,8.7-4.41,13.81-3.18c8.02,1.92,13.3,9.95,11.89,18.03c-0.57,3.25-2.14,5.99-4.46,8.33
		c-6.4,6.44-12.79,12.89-19.18,19.33C77.46,228.49,77.14,228.84,76.67,229.32z"
    />
    <path
      d="M149.38,199.45c0.18-30.35,24.68-53.54,53.54-53.51c29.71,0.03,53.66,23.98,53.53,53.84
		c-0.13,30.23-25.02,53.49-54.04,53.22C174.22,252.73,149.56,229.99,149.38,199.45z M202.92,245.6
		c24.62,0.17,46.04-19.77,46.18-45.88c0.14-25.68-20.56-46.4-46.14-46.43c-24.92-0.03-46.18,20.02-46.18,46.15
		C156.78,225.94,178.45,245.76,202.92,245.6z"
    />
    <path
      d="M83.72,85.51c-2.08,0-3.98,0-5.84,0c-0.08-0.07-0.11-0.1-0.14-0.13c-0.03-0.03-0.06-0.06-0.06-0.1
		c-0.03-0.32-0.07-0.63-0.07-0.95c0.01-12.03,0.02-24.06,0.04-36.09c0-0.07,0.07-0.14,0.16-0.29c1.88,0,3.82,0,5.89,0
		c0,4.83,0,9.6,0,14.31c0.65,0.37,1.01,0.2,1.32-0.27c1.56-2.34,3.89-3.25,6.56-3.35c1.34-0.05,2.74,0.02,4.04,0.33
		c3.96,0.94,6.79,4.27,7.3,8.42c0.12,0.99,0.18,1.99,0.18,2.99c0.02,4.56,0.01,9.11,0.01,13.67c0,0.43,0,0.87,0,1.26
		c-0.96,0.32-4.86,0.36-6.11,0.07c0-0.41,0-0.84,0-1.27c0-4.64,0.03-9.27-0.02-13.91c-0.01-1.02-0.18-2.08-0.46-3.06
		c-0.46-1.55-1.56-2.6-3.1-3.09c-3.79-1.2-8.46,0.04-9.44,5.52c-0.15,0.86-0.22,1.75-0.22,2.62c-0.02,3.96-0.01,7.91-0.01,11.87
		C83.72,84.5,83.72,84.94,83.72,85.51z"
    />
    <path
      d="M50.59,64.6c0.18-0.45,0.29-0.78,0.43-1.09c2.31-4.87,4.64-9.73,6.93-14.62c0.35-0.74,0.76-1.07,1.62-1.05
		c3,0.06,6,0.02,8.99,0.03c0.35,0,0.7,0.04,1.14,0.07c0,12.52,0,24.97,0,37.42c-0.82,0.27-4.55,0.33-6.05,0.06
		c0-10.98,0-21.98,0-32.92c-0.64-0.3-1-0.2-1.23,0.3c-0.25,0.55-0.51,1.09-0.76,1.63c-1.4,3.05-2.82,6.09-4.16,9.16
		c-0.38,0.88-0.88,1.18-1.82,1.13c-1.31-0.08-2.64-0.01-3.96-0.02C51.4,64.69,51.06,64.64,50.59,64.6z"
    />
    <path
      d="M206.62,195.82c0.69,0,1.2,0,1.71,0c9.52,0,19.03,0,28.55,0c0.4,0,0.8,0.02,1.2,0
		c1.9-0.08,3.56,1.54,3.64,3.41c0.09,1.99-1.13,3.53-3.16,3.86c-0.43,0.07-0.87,0.09-1.31,0.09c-11.16,0-22.31,0.01-33.47,0
		c-0.52,0-1.04-0.03-1.55-0.13c-1.72-0.33-2.87-1.68-2.96-3.44c-0.02-0.28-0.01-0.56-0.01-0.84c0-10.72,0-21.43,0-32.15
		c0-0.32-0.01-0.64,0.01-0.96c0.13-1.96,1.68-3.4,3.64-3.41c1.98-0.01,3.53,1.4,3.68,3.36c0.03,0.44,0.02,0.88,0.02,1.32
		c0,9.08,0,18.15,0,27.23C206.62,194.63,206.62,195.11,206.62,195.82z"
    />
    <path
      d="M199.27,234.36c0-2.12-0.04-4.24,0.01-6.36c0.04-1.54,0.84-2.66,2.25-3.25c1.37-0.58,2.67-0.35,3.81,0.61
		c0.88,0.74,1.27,1.72,1.27,2.86c0,2,0,4,0,6c0,2.12,0.03,4.24-0.02,6.36c-0.04,1.79-1.18,3.13-2.88,3.51
		c-2.27,0.5-4.38-1.12-4.44-3.49C199.22,238.52,199.27,236.44,199.27,234.36z"
    />
    <path
      d="M170.98,194.89c2.12,0,4.24-0.03,6.36,0.01c1.6,0.03,2.93,1.11,3.34,2.62c0.42,1.53-0.1,3.19-1.44,4
		c-0.65,0.39-1.49,0.67-2.24,0.68c-4.04,0.07-8.08,0.05-12.11,0.02c-2-0.02-3.51-1.34-3.76-3.2c-0.24-1.72,0.78-3.4,2.45-3.95
		c0.44-0.15,0.94-0.19,1.41-0.19C166.98,194.87,168.98,194.89,170.98,194.89C170.98,194.89,170.98,194.89,170.98,194.89z"
    />
  </svg>,
  'HousingFacilInstalar1hIcon',
);

export default HousingFacilInstalar1hIcon;
