import { gql } from '@/__generated__';
import { type GetUpdateHardwareConfigurationDataQuery } from '@/__generated__/graphql';

export type Device = GetUpdateHardwareConfigurationDataQuery['device'];

const GET_UPDATE_HARDWARE_CONFIGURATION_DATA = gql(`#graphql
  query GetUpdateHardwareConfigurationData($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        name
        type: type
        sensor
        source
        sourceInfo
        status
        organization {
          id
          name
        }
        project
        created
        dateAction
      }
      hardwareConfiguration {
        id
        account {
          id
          username
          name
          lastnames
        }
        images
        googleDriveUrl
        dateAction
        sensorLevelOption {
          id
          name
        }
        integrationDetailLevel
        serialNumberLevel
        modbusIdLevel
        batchNumberLevelSensor
        sensorVelocityOption {
          id
          name
        }
        integrationDetailVelocity
        serialNumberVelocity
        modbusIdVelocity
        batchNumberVelocitySensor
        sensorFlowOption {
          id
          name
        }
        integrationDetailFlow
        serialNumberFlow
        modbusIdFlow
        batchNumberFlowSensor
        energizationOption {
          id
          name
        }
        powerSupplyVoltageOption {
          id
          name
        }
        powerSupplyModel
        powerSupplySerialNumber
        powerSupplyBrand
        solarPanelsControllers
        numberSolarPanelsControllers
        quantityBattery
        modelBattery
        technologyOption {
          id
          name
        }
        batteryCapacityOption {
          id
          name
        }
        batteryVoltageOption {
          id
          name
        }
        quantitySun
        modelsSun
        watts
        numberSun
        cpuOption {
          id
          name
        }
        batchNumberCpu
        auxiliaryOption {
          id 
          name
        }
        numberAuxiliary
        batchNumberAuxiliary
        internetConnectionOption {
          id
          name
        }
        modelsConnectionOption {
          id
          name
        }
        imei
        approved
        mobileOperatorOption {
          id
          name
        }
        phoneNumber
        dataPlanMobileOption {
          id
          name
        }
        iccid
        antennaOption {
          id
          name
        }
        mac
        configurationIp
        administrator
        networkName
        observation
        lockNumber
        ip
        imageVersion
        idEmnify
        satellitalTypeOption { 
          id
          name
        }
        satellitalSv
        satellitalHv
        satellitalHardwareName
        satellitalSerialName
        codeNumberWidgetlord
        serialNumberWidgetlord
        batchNumberWidgetlord
        batchNumberSimCard
        usbExtraction
        dataConnectionOption {
          id
          name
        }
      }
      deviceTypeMeasurement {
        level
        velocity
        flow
      }
      sensors {
        id
        type: type {
          id
          name
          label: label
        }
        configuration {
          sensorId
          batchNumber
          serialNumber
          modbusId
          sensorModelOption {
            id
            name
          }
        }
      }
    }
  }
`);

export default GET_UPDATE_HARDWARE_CONFIGURATION_DATA;
