// TODO: improve order structure and standarize naming
export enum Access {
  // test
  _Test_True,
  _Test_False,
  _Test_Random,

  // components
  OrganizationsFilter,
  AlertsConfiguration_TableRowAdminView,
  DeviceDataConfiguration_CalibrationTab,
  DeviceDataConfiguration_FlowLimitTab,
  DeviceDataConfiguration_FiltersTab,
  DeviceDataConfiguration_RatingCurvesTab,
  DeviceDataConfiguration_MathModelingTab,
  DeviceDataConfiguration_RadarTab,

  // modals
  DeviceConfigurationModal,
  DeviceDataConfigurationModal,
  DeviceAlertsConfigurationModal,
  DeleteDeviceModal,
  CloneDeviceModal,
  SaveUserModal,
  SimulateUserModal,
  DeleteUserModal,
  CreateTemporalUserModal,
  SaveHardwareConfigurationModal,
  SetTicketModal,
  UpdateAliasModal,

  // DGA
  CreateDGAConfigurationModal,
  UpdateDGAConfigurationsModal,
  UpdateDGAGeneralConfiguration,
  UpdateDGASubmissionsConfiguration,
  RetryDGASubmissions,
  UpdateDGAMaxFlow,
  UpdateDGANotificationsConfiguration,
  DeleteDGAConfiguration,

  // buttons
  UsersTable_OptionsMenu,
  UserOptionsMenu_SimulateUserButton,
  UserOptionsMenu_DeleteUserButton,
  UpdateRatingCurve,

  // actions
  UpdateDeviceUserAccess,
  AddDeviceUserAccess,
  RemoveDeviceUserAccess,
  CreateDevice,
  CreateSMAConfiguration,
  ViewDeletedDevices,
  UpdateDeviceStatus,
  ViewDeviceMetricsPriorityList,
}
