import { createSvgIcon } from '@mui/material';

const HousingPlusIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 600">
    <path
      d="M297.38,270.15c0,87.85,0,175.7,0,263.54c0,0.48,0.01,0.96-0.01,1.44c-0.1,2.05-1.53,3.51-3.58,3.64
			c-0.4,0.03-0.8,0.01-1.2,0.01c-94.88,0-189.76,0-284.65-0.01c-0.63,0-1.29-0.01-1.9-0.16c-1.62-0.38-2.68-1.68-2.79-3.33
			c-0.03-0.36-0.01-0.72-0.01-1.08c0-176.1,0-352.19,0-528.29c0-0.36-0.01-0.72,0.01-1.08C3.37,3,4.63,1.63,6.47,1.38
			c0.43-0.06,0.88-0.06,1.31-0.06c95,0,190,0,285.01,0c0.28,0,0.56-0.01,0.84,0.01c2.28,0.12,3.69,1.57,3.74,3.84
			c0.01,0.52,0,1.04,0,1.56C297.38,94.53,297.38,182.34,297.38,270.15z M10.69,8.75c-0.05,0.29-0.09,0.45-0.09,0.6
			c0,173.77,0,347.55,0,521.32c0,0.16,0.04,0.31,0.07,0.47c0.01,0.03,0.04,0.07,0.06,0.1c0.03,0.03,0.06,0.06,0.11,0.1
			c93,0,186.02,0,279.03,0c0.26-1.22,0.23-521.58-0.03-522.59C196.83,8.75,103.81,8.75,10.69,8.75z"
    />
    <path
      d="M183.13,581.27c2,0,4.01,0,6.02,0c0.1,1.07,0.14,2.07,0.28,3.05c0.35,2.34,1.69,3.95,3.84,4.82
			c2.85,1.16,5.77,1.15,8.65,0.08c1.57-0.58,2.7-1.7,3.21-3.33c0.74-2.39-0.03-4.49-2.3-5.52c-1.69-0.77-3.52-1.3-5.33-1.74
			c-2.41-0.59-4.82-1.11-7.1-2.12c-0.55-0.24-1.1-0.49-1.62-0.78c-3.75-2.11-5.22-5.03-4.89-9.48c0.41-5.47,3.98-8.01,7.64-9.08
			c3.73-1.09,7.49-1.06,11.21,0.13c5.05,1.61,7.88,5.41,8.01,10.71c0.02,0.67,0,1.35,0,2.07c-1.09,0.28-2.12,0.1-3.14,0.13
			c-1,0.03-1.99,0.01-3.12,0.01c-0.02-0.49-0.03-0.91-0.06-1.33c-0.05-0.75-0.05-1.52-0.19-2.26c-0.37-1.98-1.58-3.3-3.44-3.96
			c-2.44-0.86-4.95-0.9-7.41-0.1c-2.47,0.81-3.66,2.91-3.18,5.45c0.26,1.35,1.05,2.38,2.26,2.92c1.37,0.61,2.83,1.07,4.27,1.5
			c2.52,0.75,5.1,1.33,7.57,2.2c1.41,0.49,2.75,1.29,3.97,2.17c2.62,1.9,3.57,4.65,3.36,7.78c-0.34,5.01-3.13,8.52-8.02,10.07
			c-4.2,1.33-8.43,1.29-12.58-0.21c-5.06-1.83-8.45-6.16-8.04-12.76C183.01,581.58,183.07,581.47,183.13,581.27z"
    />
    <path
      d="M170.91,557.28c2.18,0,4.2,0,6.31,0c0.02,0.45,0.06,0.84,0.06,1.22c0,7.67,0.03,15.35-0.02,23.02
			c-0.01,1.47-0.14,2.96-0.47,4.39c-1.03,4.51-3.83,7.47-8.24,8.82c-3.88,1.2-7.81,1.2-11.67-0.06c-4.68-1.53-7.38-4.86-8.28-9.66
			c-0.2-1.05-0.26-2.14-0.27-3.22c-0.02-7.71-0.01-15.43-0.01-23.14c0-0.43,0-0.86,0-1.39c2.17-0.14,4.22-0.09,6.37-0.02
			c0,0.62,0,1.1,0,1.57c0,7.67-0.01,15.35,0.01,23.02c0,0.95,0.08,1.93,0.32,2.84c0.74,2.79,2.67,4.38,5.44,4.95
			c1.7,0.35,3.41,0.33,5.11-0.1c3.09-0.78,4.99-3.01,5.28-6.2c0.06-0.68,0.06-1.36,0.06-2.04c0-7.47,0-14.95,0-22.42
			C170.91,558.4,170.91,557.92,170.91,557.28z"
    />
    <path
      d="M94.16,580.72c0,4.79,0,9.36,0,14c-2.15,0-4.16,0-6.26,0c0-12.49,0-24.9,0-37.43
			c0.39-0.03,0.77-0.09,1.16-0.09c4.71-0.01,9.43-0.02,14.14,0c1.89,0.01,3.75,0.3,5.47,1.13c3.82,1.83,5.97,4.85,6.24,9.09
			c0.09,1.43,0.11,2.9-0.15,4.3c-0.89,4.87-4.79,8.43-9.75,8.8c-3.06,0.23-6.14,0.15-9.22,0.2
			C95.31,580.73,94.84,580.72,94.16,580.72z M94.27,574.76c0.17,0.06,0.28,0.13,0.39,0.13c2.91,0,5.83,0.06,8.74-0.06
			c0.91-0.04,1.86-0.45,2.69-0.88c3.36-1.75,3.22-8,0.35-9.81c-1.08-0.68-2.21-1.1-3.48-1.12c-2.67-0.03-5.35-0.03-8.02-0.03
			c-0.22,0-0.44,0.09-0.67,0.14C94.27,567.04,94.27,570.9,94.27,574.76z"
    />
    <path
      d="M126.64,589.01c5.97,0,11.76,0,17.63,0c0,1.91,0,3.72,0,5.68c-0.36,0.04-0.74,0.12-1.12,0.12
			c-7.23,0.01-14.47,0.01-21.7,0c-0.35,0-0.7-0.06-1.11-0.1c0-12.49,0-24.9,0-37.43c2.08-0.16,4.13-0.11,6.3-0.03
			C126.64,567.84,126.64,578.34,126.64,589.01z"
    />
    <path
      d="M83.04,139.81c0-19.46,0-38.93,0-58.39c0-0.4,0.01-0.8,0-1.2c-0.02-1.65,0.73-2.81,2.19-3.56
			c2.99-1.53,5.97-3.06,8.95-4.59c16.98-8.71,33.97-17.41,50.94-26.15c1.65-0.85,3.11-0.86,4.76-0.01
			c19.85,10.21,39.71,20.4,59.58,30.56c1.77,0.91,2.55,2.2,2.55,4.2c-0.02,39.49-0.02,78.97,0,118.46c0,2.04-0.81,3.34-2.64,4.26
			c-19.71,9.94-39.4,19.92-59.1,29.9c-0.25,0.13-0.51,0.24-0.75,0.38c-1.48,0.85-2.91,0.8-4.43,0.01
			c-6.2-3.21-12.43-6.38-18.65-9.56c-13.61-6.97-27.22-13.94-40.83-20.91c-0.25-0.13-0.5-0.24-0.74-0.39
			c-1.13-0.7-1.77-1.7-1.81-3.05c-0.01-0.44-0.01-0.88-0.01-1.32C83.04,178.9,83.04,159.35,83.04,139.81z M204.57,86
			c-0.54,0.24-0.98,0.41-1.39,0.61c-10.71,5.16-21.43,10.33-32.14,15.49c-6.29,3.03-12.58,6.08-18.88,9.08
			c-0.71,0.34-1.05,0.71-1.01,1.53c0.06,1.2-0.01,2.4-0.01,3.59c-0.02,17.34-0.04,34.68-0.06,52.02
			c-0.02,16.38-0.03,32.76-0.04,49.14c0,1.96-0.05,3.91-0.06,5.87c0,0.37,0.06,0.74,0.11,1.27c17.96-9.09,35.73-18.08,53.49-27.07
			C204.57,160.37,204.57,123.28,204.57,86z M90.39,85.99c0,0.63,0,1.03,0,1.42c0,36.25,0,72.5-0.02,108.74
			c0,0.86,0.23,1.34,1.04,1.76c17.04,8.69,34.05,17.41,51.08,26.13c0.3,0.16,0.63,0.27,1.07,0.46c0.03-0.51,0.08-0.9,0.08-1.28
			c0.02-3.16,0.04-6.31,0.05-9.47c0.02-10.07,0.04-20.14,0.05-30.21c0.03-23.54,0.05-47.08,0.1-70.62c0-0.93-0.26-1.4-1.12-1.81
			c-16.99-8.15-33.95-16.34-50.93-24.52C91.4,86.4,90.99,86.25,90.39,85.99z M199.92,79.96c-0.46-0.28-0.72-0.45-1-0.6
			c-16.83-8.64-33.67-17.26-50.49-25.92c-0.69-0.36-1.22-0.32-1.89,0.02c-16.75,8.61-33.51,17.21-50.27,25.81
			c-0.35,0.18-0.68,0.38-1.15,0.65c0.45,0.25,0.74,0.44,1.05,0.59c16.75,8.08,33.51,16.15,50.25,24.24
			c0.81,0.39,1.44,0.35,2.23-0.03c16.56-8.01,33.14-15.99,49.71-23.98C198.83,80.53,199.28,80.29,199.92,79.96z"
    />
    <path
      d="M228.7,377.34c0.72,0.03,1.14,0.07,1.56,0.07c4.8,0,9.59,0,14.39,0.01c0.63,0,1.3,0.01,1.89,0.2
			c1.61,0.49,2.56,2.06,2.42,3.84c-0.13,1.64-1.38,2.97-3.05,3.21c-0.47,0.07-0.96,0.07-1.43,0.07c-56.91,0-113.81,0-170.72,0
			c-0.36,0-0.72,0.01-1.08-0.03c-1.97-0.18-3.48-1.73-3.43-3.64c0.06-2.39,1.93-3.66,3.54-3.65c2.4,0.02,4.8,0,7.19-0.01
			c1.88,0,3.76-0.01,5.63,0c0.56,0,0.99-0.1,1.32-0.62c0.26-0.39,0.64-0.71,0.98-1.05c8.05-8.05,16.11-16.11,24.16-24.16
			c0.33-0.33,0.66-0.67,1.16-1.19c-0.59-0.04-0.97-0.08-1.34-0.08c-4.64,0-9.27,0.02-13.91-0.02c-0.83-0.01-1.41,0.23-1.99,0.82
			c-6.65,6.69-13.33,13.35-19.99,20.02c-0.84,0.85-1.75,1.51-3.01,1.51c-1.5,0-2.63-0.66-3.31-1.97c-0.69-1.31-0.58-2.63,0.28-3.85
			c0.27-0.39,0.63-0.72,0.97-1.06c4.74-4.75,9.49-9.5,14.24-14.24c0.31-0.31,0.6-0.62,1.08-1.12c-0.6-0.04-0.97-0.08-1.34-0.08
			c-3.96-0.01-7.91,0.01-11.87-0.01c-1.96-0.01-3.38-1.21-3.72-3.07c-0.38-2.06,1.12-4.06,3.2-4.26c0.4-0.04,0.8-0.02,1.2-0.02
			c56.95,0,113.89,0,170.84,0c0.52,0,1.05-0.02,1.55,0.08c1.81,0.38,2.96,1.99,2.85,3.89c-0.11,1.77-1.51,3.2-3.32,3.37
			c-0.36,0.03-0.72,0.02-1.08,0.02c-5.08,0-10.15,0.02-15.23-0.02c-0.78,0-1.33,0.23-1.88,0.78c-8.38,8.41-16.78,16.8-25.17,25.19
			c-0.27,0.27-0.53,0.57-0.97,1.05c0.62,0.04,1,0.08,1.38,0.08c4.76,0,9.51-0.02,14.27,0.02c0.88,0.01,1.49-0.29,2.09-0.9
			c6.88-6.91,13.78-13.8,20.67-20.7c0.85-0.86,1.78-1.44,3.04-1.41c1.52,0.04,2.62,0.73,3.24,2.07c0.62,1.33,0.49,2.65-0.41,3.84
			c-0.24,0.32-0.54,0.59-0.82,0.87c-5,5-10,10-15,15.01C229.48,376.51,229.2,376.82,228.7,377.34z M166.1,350.32
			c-0.69,0-1.11,0-1.53,0c-4.75,0-9.51,0.02-14.26-0.02c-0.91-0.01-1.55,0.26-2.2,0.91c-8.34,8.38-16.71,16.73-25.07,25.1
			c-0.27,0.27-0.51,0.56-0.9,0.99c0.51,0.06,0.81,0.12,1.11,0.12c4.95,0.01,9.91-0.01,14.86,0.02c0.75,0,1.27-0.25,1.78-0.77
			c8.41-8.43,16.83-16.84,25.24-25.26C165.4,351.12,165.64,350.82,166.1,350.32z M149.75,377.3c0.62,0.05,0.96,0.11,1.31,0.11
			c4.39,0.01,8.79-0.01,13.18,0.02c0.74,0,1.27-0.24,1.78-0.76c3.34-3.38,6.71-6.73,10.07-10.09c5.05-5.05,10.11-10.11,15.16-15.17
			c0.28-0.28,0.7-0.51,0.73-1.09c-0.46,0-0.85,0-1.25,0c-4.27,0-8.55,0.03-12.82-0.02c-0.92-0.01-1.55,0.29-2.18,0.93
			c-8.31,8.35-16.65,16.67-24.98,25C150.47,376.5,150.21,376.8,149.75,377.3z M138.46,350.77c-0.08-0.15-0.17-0.3-0.25-0.45
			c-0.38,0-0.77,0-1.15,0c-4.03,0-8.07,0.02-12.1-0.02c-0.82-0.01-1.41,0.23-1.99,0.81c-8.37,8.4-16.76,16.79-25.15,25.18
			c-0.27,0.27-0.52,0.56-0.96,1.05c0.67,0.03,1.09,0.07,1.51,0.07c3.71,0,7.43-0.06,11.14,0.03c1.41,0.03,2.4-0.35,3.42-1.39
			c8.08-8.19,16.24-16.3,24.38-24.42C137.64,351.29,138.07,351.05,138.46,350.77z M217.51,350.58c-0.03-0.09-0.07-0.17-0.1-0.26
			c-0.39,0-0.78,0-1.17,0c-3.76,0-7.51,0.07-11.26-0.04c-1.56-0.05-2.65,0.4-3.75,1.53c-4.85,4.98-9.8,9.86-14.72,14.77
			c-3.22,3.22-6.44,6.44-9.66,9.66c-0.29,0.29-0.54,0.6-0.98,1.1c0.73,0.03,1.16,0.07,1.59,0.07c3.95,0,7.91-0.03,11.86,0.02
			c1,0.01,1.71-0.27,2.42-0.99c8.31-8.36,16.65-16.68,24.98-25.02C216.98,351.16,217.24,350.86,217.51,350.58z"
    />
    <path
      d="M138.41,480.98c-2.07,0-3.98,0-5.88,0c-0.29-0.85-0.35-24.99-0.06-26.38c1.87,0,3.77,0,5.78,0
			c0.05,0.93,0.09,1.83,0.13,2.74c0.63,0.4,0.96,0.05,1.29-0.41c0.93-1.26,2.13-2.15,3.67-2.54c2.15-0.54,4.28-0.5,6.35,0.33
			c1.41,0.57,2.46,1.6,3.3,2.86c0.63,0.95,1.07,0.98,1.65,0.04c1.8-2.9,4.56-3.73,7.73-3.55c2.58,0.15,4.8,1.12,6.44,3.21
			c1.29,1.64,1.85,3.56,1.86,5.59c0.05,5.87,0.02,11.75,0.02,17.62c0,0.11-0.05,0.23-0.09,0.42c-0.95,0.25-1.95,0.09-2.93,0.12
			c-0.96,0.03-1.91,0.01-3.02,0.01c-0.02-0.48-0.06-0.9-0.06-1.32c0-5.16,0-10.31-0.01-15.47c0-0.63-0.06-1.28-0.19-1.9
			c-0.34-1.59-1.35-2.63-2.92-3c-3.77-0.88-6.31,1.09-6.81,4.32c-0.11,0.71-0.15,1.43-0.16,2.15c-0.01,4.56-0.01,9.11-0.01,13.67
			c0,0.47,0,0.94,0,1.55c-1.09,0-2.04,0-2.99,0c-0.95,0-1.9,0-3.01,0c0-0.54,0-0.96,0-1.39c0-5.04,0.01-10.07-0.01-15.11
			c0-0.67-0.05-1.36-0.18-2.02c-0.39-1.93-1.77-3.17-3.73-3.31c-0.75-0.06-1.54-0.07-2.26,0.09c-2.04,0.46-3.22,1.85-3.67,3.84
			c-0.18,0.81-0.25,1.66-0.26,2.49c-0.02,4.64-0.01,9.27-0.01,13.91C138.41,479.97,138.41,480.41,138.41,480.98z"
    />
    <path
      d="M200.54,480.97c-2,0-3.89,0-5.95,0c0-0.47,0-0.9,0-1.32c0-5.08,0.01-10.15-0.01-15.23
			c0-0.67-0.06-1.36-0.19-2.02c-0.33-1.59-1.32-2.61-2.89-3.03c-1.26-0.34-2.54-0.33-3.77,0.14c-1.76,0.68-2.69,2.08-3.03,3.87
			c-0.15,0.82-0.21,1.67-0.22,2.5c-0.02,4.56-0.01,9.11-0.01,13.67c0,0.43,0,0.87,0,1.28c-0.98,0.27-4.67,0.3-5.97,0.06
			c0-8.71,0-17.44,0-26.35c1.94-0.02,3.83-0.02,5.87,0c0,0.99,0,1.89,0,2.73c0.73,0.48,1.05,0.12,1.39-0.33
			c0.94-1.25,2.13-2.15,3.67-2.54c2.11-0.53,4.2-0.5,6.24,0.28c1.47,0.56,2.55,1.6,3.41,2.9c0.63,0.95,1.08,0.98,1.66,0.04
			c1.8-2.91,4.55-3.73,7.73-3.55c2.35,0.13,4.43,0.96,6.06,2.75c1.46,1.62,2.2,3.56,2.22,5.69c0.07,6.03,0.03,12.07,0.02,18.1
			c0,0.07-0.04,0.15-0.12,0.4c-1.91,0.03-3.87,0.04-5.96-0.02c0-0.55,0-0.98,0-1.41c0-4.72,0-9.43,0-14.15c0-0.4,0-0.8,0-1.2
			c-0.01-3.14-1.41-4.86-4.05-5.07c-3.82-0.31-5.5,2.06-5.87,4.5c-0.11,0.75-0.14,1.51-0.14,2.27c-0.01,4.52,0,9.03-0.01,13.55
			C200.61,479.96,200.57,480.43,200.54,480.97z"
    />
    <path
      d="M105.1,475.56c6.87,0,13.68,0,20.57,0c0,1.88,0,3.61,0,5.41c-8.97,0-17.87,0-26.76,0
			c-0.06-0.08-0.14-0.14-0.14-0.21c-0.07-2.59-0.22-5.19,0.31-7.76c0.62-3.02,2.27-5.33,4.82-7.01c1.78-1.18,3.74-1.97,5.76-2.61
			c1.29-0.41,2.61-0.75,3.88-1.24c1.11-0.43,2.22-0.91,3.23-1.54c3.02-1.88,3.72-5.17,2.88-8.06c-0.57-1.96-1.98-3.17-3.84-3.82
			c-2.14-0.75-4.32-0.74-6.47,0.01c-2.67,0.93-4.19,3.04-4.32,5.88c-0.02,0.47,0,0.95,0,1.38c-0.92,0.33-4.67,0.39-6.09,0.12
			c-0.21-1.79,0.02-3.55,0.62-5.25c1.24-3.5,3.71-5.84,7.14-7.12c3.88-1.44,7.85-1.47,11.72-0.01c5.22,1.96,8.07,6.09,7.67,12.18
			c-0.29,4.52-2.55,7.69-6.47,9.81c-2.37,1.28-4.94,2.01-7.48,2.86c-1.58,0.53-3.16,1.13-4.62,1.92
			C105.61,471.52,104.85,473.25,105.1,475.56z"
    />
    <path
      d="M64.2,84.93c0-4.79-0.02-9.59,0.02-14.38c0.01-0.85,0.15-1.78,0.52-2.54c0.31-0.63,0.95-1.22,1.59-1.55
			c9.18-4.75,18.39-9.45,27.58-14.18c2.06-1.06,4.27-0.09,5.09,1.65c0.85,1.79,0.21,3.84-1.61,4.83c-2.21,1.2-4.46,2.32-6.7,3.47
			c-5.96,3.06-11.92,6.14-17.91,9.15c-0.91,0.46-1.25,0.97-1.25,2c0.04,8.59,0.03,17.18,0.02,25.77c0,0.4,0.01,0.8-0.01,1.2
			c-0.11,1.86-1.53,3.3-3.37,3.43c-1.82,0.13-3.47-1.11-3.83-2.93c-0.11-0.54-0.14-1.11-0.14-1.67
			C64.19,94.44,64.2,89.69,64.2,84.93z"
    />
    <path
      d="M64.2,195.68c0-4.75-0.01-9.51,0.01-14.26c0-0.63,0.04-1.29,0.18-1.9c0.39-1.67,2.06-2.84,3.76-2.72
			c1.8,0.13,3.24,1.54,3.38,3.3c0.03,0.44,0.02,0.88,0.02,1.32c0,8.59,0.02,17.18-0.02,25.77c0,1.02,0.31,1.55,1.23,2.01
			c7.97,4.04,15.92,8.14,23.87,12.22c0.28,0.15,0.57,0.28,0.85,0.45c1.75,1.05,2.37,3.12,1.47,4.88c-0.89,1.75-2.98,2.51-4.81,1.65
			c-1.84-0.86-3.63-1.84-5.44-2.77c-7.28-3.73-14.54-7.5-21.84-11.18c-1.89-0.95-2.68-2.34-2.66-4.39
			C64.22,205.27,64.2,200.47,64.2,195.68z"
    />
    <path
      d="M230.93,84.9c0,5.11,0.01,10.22,0,15.34c-0.01,2.3-1.63,3.89-3.84,3.82c-1.78-0.06-3.27-1.43-3.47-3.21
			c-0.05-0.43-0.04-0.88-0.04-1.32c-0.02-8.55-0.03-17.09-0.05-25.64c0-2.42-0.01-2.41-2.13-3.49c-7.54-3.83-15.08-7.67-22.62-11.5
			c-1.43-0.73-2.32-1.79-2.32-3.47c0-2.64,2.57-4.38,5.02-3.4c0.37,0.15,0.72,0.33,1.08,0.51c8.57,4.36,17.13,8.74,25.72,13.06
			c1.73,0.87,2.56,2.13,2.54,4.07c-0.04,5.07-0.01,10.14-0.01,15.21C230.86,84.9,230.9,84.9,230.93,84.9z"
    />
    <path
      d="M230.82,195.81c0,5.07-0.02,10.14,0.01,15.21c0.01,1.78-0.72,3.02-2.33,3.84
			c-8.83,4.47-17.64,8.96-26.46,13.46c-1.06,0.54-2.14,0.67-3.24,0.27c-1.35-0.5-2.15-1.51-2.32-2.94
			c-0.18-1.52,0.36-2.77,1.68-3.58c0.81-0.5,1.69-0.9,2.54-1.34c7.25-3.69,14.5-7.39,21.77-11.05c0.75-0.38,1.04-0.82,1.04-1.65
			c-0.02-3.55,0.03-7.11,0.04-10.66c0.01-5.39,0.01-10.78,0.02-16.17c0-0.36,0-0.72,0.02-1.08c0.11-2.1,1.68-3.6,3.73-3.57
			c2.02,0.03,3.59,1.6,3.6,3.7c0.02,5.19,0.01,10.38,0.01,15.58C230.9,195.81,230.86,195.81,230.82,195.81z"
    />
    <path
      d="M163.03,313.44c0.5-0.44,0.79-0.7,1.09-0.95c1.51-1.29,3.62-1.24,5,0.11c1.42,1.39,1.52,3.56,0.17,5.09
			c-0.68,0.78-1.46,1.47-2.2,2.21c-1.67,1.66-3.33,3.33-5.02,4.98c-1.78,1.74-3.82,1.72-5.6-0.03c-2.28-2.24-4.54-4.5-6.81-6.74
			c-1.07-1.06-1.59-2.28-1.18-3.79c0.71-2.6,3.71-3.6,5.83-1.94c0.34,0.27,0.65,0.57,1.02,0.89c0.43-0.45,0.28-0.94,0.28-1.38
			c0.01-4.39,0.01-8.79,0.01-13.18c0-1.88-0.02-3.75,0.01-5.63c0.01-0.59,0.05-1.21,0.23-1.77c0.55-1.65,2.16-2.55,4-2.32
			c1.58,0.2,2.83,1.45,3.03,3.09c0.06,0.47,0.06,0.96,0.06,1.43c0,6.11,0,12.22,0.01,18.33C162.96,312.28,163,312.74,163.03,313.44z
			"
    />
    <path
      d="M155.33,414.3c-0.29,0.26-0.58,0.53-0.88,0.79c-1.56,1.32-3.7,1.22-5.08-0.23c-1.34-1.4-1.43-3.57-0.06-4.99
			c2.44-2.53,4.94-5,7.47-7.44c1.48-1.42,3.6-1.38,5.08,0.06c2.46,2.39,4.9,4.81,7.3,7.27c1.44,1.47,1.42,3.64,0.06,5.09
			c-1.39,1.48-3.57,1.56-5.18,0.18c-0.27-0.23-0.52-0.47-0.83-0.75c-0.42,0.39-0.27,0.85-0.27,1.24c-0.01,4.39-0.01,8.79-0.01,13.18
			c0,1.96,0.02,3.91-0.01,5.87c-0.01,0.59-0.06,1.21-0.25,1.76c-0.58,1.64-2.21,2.51-4.04,2.26c-1.55-0.21-2.79-1.52-2.97-3.15
			c-0.05-0.47-0.05-0.96-0.05-1.44c0-6.11,0-12.22,0-18.33c0-0.43,0-0.85,0-1.28C155.53,414.35,155.43,414.33,155.33,414.3z"
    />
    <path
      d="M158.51,337.63c-4.43,0-8.87-0.04-13.3,0.02c-1.89,0.02-3.32-1.36-3.66-2.7c-0.57-2.24,0.88-4.37,3.24-4.65
			c0.36-0.04,0.72-0.03,1.08-0.03c8.47,0,16.93,0,25.4,0c0.52,0,1.04,0.01,1.54,0.12c1.84,0.42,3.07,2.07,2.91,3.85
			c-0.18,1.95-1.68,3.37-3.68,3.38C167.54,337.65,163.03,337.63,158.51,337.63z"
    />
    <path
      d="M158.45,397.33c-4.43,0-8.87,0.01-13.3,0c-1.77-0.01-3.12-1.02-3.58-2.63c-0.65-2.28,0.9-4.51,3.28-4.71
			c0.32-0.03,0.64-0.01,0.96-0.01c8.51,0,17.01,0,25.52,0c0.48,0,0.97-0.01,1.43,0.09c1.87,0.39,3.16,2.13,2.96,3.93
			c-0.22,1.95-1.72,3.32-3.73,3.33C167.48,397.34,162.97,397.33,158.45,397.33z"
    />
  </svg>,
  'HousingPlusIcon',
);

export default HousingPlusIcon;
