import { createSvgIcon } from '@mui/material';

const ConnectivityWifiIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M295,121.68c-0.62,1.74-2.17,2.74-3.47,3.91c-4.15,3.75-8.34,7.45-12.53,11.17c-1,0.89-1.99,1.77-3.01,2.63
		c-1.24,1.05-3.33,1-4.45-0.18c-1.23-1.29-2.34-2.68-3.55-3.98c-2.48-2.66-4.92-5.36-7.51-7.91c-2.73-2.69-5.57-5.28-8.45-7.82
		c-1.91-1.69-3.92-3.28-5.96-4.82c-2.9-2.18-5.84-4.32-8.81-6.4c-1.72-1.21-3.52-2.33-5.33-3.4c-2.94-1.74-5.89-3.46-8.89-5.11
		c-2.02-1.11-4.08-2.16-6.2-3.09c-4.23-1.86-8.49-3.67-12.79-5.41c-2-0.81-4.09-1.42-6.15-2.1c-3.75-1.23-7.54-2.35-11.42-3.18
		c-1.97-0.42-3.93-0.93-5.91-1.3c-3.4-0.63-6.8-1.27-10.22-1.76c-2.81-0.4-5.65-0.69-8.49-0.85c-4.05-0.23-8.1-0.41-12.16-0.41
		c-6.51,0.01-13.02,0.34-19.47,1.22c-2.89,0.39-5.78,0.8-8.64,1.37c-4.41,0.88-8.82,1.8-13.12,3.12c-2.3,0.7-4.63,1.29-6.89,2.08
		c-3.61,1.26-7.19,2.6-10.75,4.01c-3.88,1.54-7.65,3.29-11.32,5.24c-2.07,1.1-4.18,2.14-6.2,3.32c-2.82,1.64-5.58,3.37-8.34,5.1
		c-1.62,1.02-3.23,2.07-4.76,3.21c-2.92,2.15-5.83,4.33-8.65,6.59c-2.44,1.96-4.82,3.98-7.11,6.1c-2.76,2.56-5.45,5.18-8.05,7.89
		c-2.34,2.43-4.5,5.01-6.75,7.51c-0.58,0.65-1.13,1.32-2.08,1.54c-1.22,0.28-2.32,0.12-3.26-0.71c-3.78-3.36-7.56-6.73-11.34-10.1
		c-2.1-1.87-4.23-3.72-6.3-5.63c-1.53-1.41-1.73-3.39-0.5-5.05c0.92-1.24,1.94-2.42,2.98-3.56c2.44-2.69,4.88-5.39,7.4-8.01
		c2.03-2.1,4.14-4.13,6.29-6.12c2.46-2.27,4.96-4.5,7.51-6.67c1.94-1.66,3.95-3.24,5.99-4.79c2.86-2.16,5.76-4.28,8.69-6.37
		c1.62-1.15,3.29-2.24,4.99-3.29c2.83-1.76,5.68-3.5,8.56-5.19c1.95-1.14,3.95-2.23,5.98-3.24c3.58-1.79,7.2-3.51,10.82-5.23
		c1.11-0.53,2.25-1.03,3.4-1.47c3.17-1.23,6.34-2.47,9.54-3.61c2.91-1.03,5.85-2.01,8.82-2.89c3.45-1.02,6.94-1.93,10.43-2.82
		c4.36-1.12,8.79-1.94,13.24-2.67c4.69-0.78,9.41-1.19,14.13-1.69c7.12-0.76,14.25-0.66,21.38-0.57c2.84,0.04,5.68,0.33,8.51,0.56
		c2.56,0.2,5.13,0.41,7.68,0.72c2.89,0.35,5.78,0.76,8.65,1.26c3.25,0.56,6.51,1.15,9.72,1.89c3.82,0.88,7.61,1.87,11.37,2.93
		c2.76,0.77,5.49,1.64,8.17,2.63c4.13,1.52,8.22,3.15,12.3,4.8c2.32,0.94,4.62,1.96,6.86,3.07c3.52,1.74,7,3.55,10.46,5.39
		c2,1.06,3.98,2.19,5.9,3.37c2.58,1.59,5.11,3.24,7.64,4.9c1.67,1.09,3.32,2.21,4.92,3.38c2.25,1.64,4.48,3.32,6.67,5.04
		c1.98,1.55,3.96,3.12,5.86,4.76c2.45,2.11,4.86,4.27,7.22,6.48c2.14,2,4.25,4.03,6.25,6.15c2.86,3.03,5.6,6.16,8.4,9.24
		c0.03,0.03,0.04,0.06,0.07,0.09c0.84,0.87,1.6,1.79,2.01,2.93C295,120.49,295,121.08,295,121.68z M26.16,131.11
		c0.18-0.12,0.28-0.17,0.36-0.25c1.68-1.8,3.29-3.66,5.03-5.4c3.11-3.11,6.24-6.21,9.48-9.2c2.35-2.16,4.82-4.21,7.35-6.17
		c3.41-2.64,6.87-5.23,10.41-7.7c2.54-1.77,5.19-3.4,7.88-4.96c3.68-2.14,7.41-4.22,11.2-6.18c2.9-1.5,5.89-2.86,8.92-4.12
		c3.67-1.52,7.39-2.94,11.14-4.27c2.88-1.02,5.8-1.93,8.76-2.71c4.01-1.06,8.05-2,12.11-2.89c2.5-0.55,5.04-1,7.58-1.32
		c4.13-0.53,8.27-1.03,12.42-1.35c3.61-0.28,7.24-0.39,10.87-0.39c4.56,0.01,9.12,0.04,13.66,0.57c2.47,0.29,4.98,0.38,7.44,0.75
		c4.11,0.62,8.2,1.34,12.28,2.14c3.12,0.61,6.23,1.33,9.3,2.14c4.09,1.07,8.12,2.31,12.07,3.78c2.21,0.82,4.39,1.69,6.6,2.52
		c3.81,1.42,7.46,3.16,11.1,4.93c2.08,1.01,4.15,2.04,6.15,3.18c2.94,1.66,5.84,3.4,8.72,5.15c1.74,1.06,3.46,2.14,5.11,3.32
		c2.93,2.08,5.84,4.19,8.68,6.39c2.59,2.01,5.15,4.08,7.59,6.26c2.89,2.58,5.7,5.26,8.45,7.98c2.07,2.05,4,4.24,5.99,6.37
		c0.46,0.49,0.92,0.98,1.43,1.51c4.08-3.63,8.11-7.14,12.05-10.75c-0.18-0.25-0.26-0.37-0.36-0.48c-1.29-1.44-2.54-2.9-3.88-4.3
		c-2.52-2.62-5.04-5.25-7.66-7.79c-2.27-2.19-4.61-4.31-7.04-6.33c-3.66-3.05-7.37-6.04-11.15-8.94c-2.33-1.79-4.84-3.36-7.26-5.03
		c-4-2.76-8.21-5.22-12.47-7.6c-2.61-1.46-5.28-2.85-7.98-4.15c-3.09-1.49-6.2-2.93-9.37-4.25c-3.6-1.49-7.23-2.94-10.93-4.17
		c-4.62-1.54-9.29-2.97-14.02-4.21c-4.31-1.13-8.7-2.02-13.08-2.85c-3.32-0.63-6.68-1.12-10.05-1.44
		c-5.43-0.52-10.87-1.03-16.32-1.21c-9.41-0.32-18.78,0.26-28.09,1.63c-3.27,0.48-6.53,1.03-9.78,1.57
		c-3.95,0.67-7.82,1.64-11.69,2.63c-3.72,0.95-7.39,2.08-11.03,3.26c-1.99,0.65-3.98,1.28-5.91,2.06c-4.01,1.61-8,3.28-11.96,5
		c-2.37,1.03-4.71,2.14-6.99,3.34c-3.33,1.75-6.61,3.6-9.89,5.45c-1.7,0.96-3.38,1.98-5.01,3.05c-2.5,1.64-4.97,3.31-7.41,5.02
		c-2.25,1.59-4.5,3.18-6.64,4.9c-3.13,2.5-6.2,5.07-9.21,7.69c-2.33,2.02-4.6,4.11-6.78,6.28c-2.69,2.67-5.29,5.44-7.86,8.21
		c-0.78,0.84-1.71,1.6-2.22,2.74C18.21,124.05,22.18,127.58,26.16,131.11z"
    />
    <path
      d="M150.24,105.11c3.19,0.12,6.4,0.12,9.58,0.39c3.95,0.33,7.9,0.83,11.83,1.37c2.31,0.32,4.6,0.77,6.87,1.31
		c3.69,0.89,7.38,1.83,11.02,2.89c2.6,0.76,5.15,1.68,7.68,2.63c4.53,1.69,8.94,3.63,13.25,5.79c2.48,1.24,4.93,2.54,7.28,3.97
		c3.27,1.99,6.49,4.08,9.66,6.22c2.19,1.48,4.31,3.05,6.36,4.7c2.61,2.1,5.19,4.26,7.65,6.52c2.68,2.47,5.26,5.06,7.79,7.66
		c1.61,1.65,3.04,3.47,4.61,5.16c1.34,1.45,1.5,3.9-0.39,5.6c-2.8,2.52-5.61,5.02-8.41,7.54c-2.88,2.6-5.77,5.19-8.63,7.81
		c-1.7,1.56-3.98,1.42-5.5-0.34c-2.82-3.26-5.78-6.4-8.97-9.35c-3.33-3.07-6.82-5.95-10.53-8.6c-2.71-1.94-5.51-3.73-8.38-5.44
		c-4.03-2.4-8.26-4.43-12.58-6.31c-1.63-0.71-3.32-1.27-4.98-1.89c-3.95-1.48-8.03-2.59-12.14-3.58c-1.77-0.43-3.59-0.68-5.38-1.04
		c-3.25-0.66-6.53-1.05-9.84-1.19c-3.5-0.15-7.01-0.27-10.52-0.25c-4.13,0.02-8.26,0.31-12.34,0.98c-3.03,0.5-6.04,1.1-9.02,1.79
		c-2.72,0.63-5.42,1.36-8.07,2.21c-4.2,1.34-8.32,2.91-12.25,4.88c-2.2,1.1-4.45,2.13-6.58,3.36c-2.79,1.61-5.52,3.32-8.19,5.1
		c-2.2,1.46-4.3,3.05-6.39,4.66c-3.05,2.36-5.94,4.9-8.61,7.65c-2.33,2.39-4.54,4.88-6.79,7.33c-1.27,1.38-3.55,1.52-5.03,0.21
		c-2.28-2.01-4.51-4.08-6.77-6.12c-1.78-1.61-3.57-3.2-5.35-4.8c-1.73-1.55-3.49-3.07-5.18-4.65c-1.78-1.66-1.94-3.72-0.31-5.58
		c2.42-2.76,4.86-5.5,7.45-8.11c2.67-2.69,5.49-5.25,8.32-7.79c1.81-1.62,3.74-3.13,5.69-4.6c2.38-1.79,4.8-3.54,7.26-5.23
		c1.73-1.19,3.53-2.31,5.36-3.37c2.99-1.73,6.02-3.4,9.05-5.06c1.11-0.61,2.28-1.13,3.44-1.65c3.98-1.78,7.97-3.52,12.11-4.95
		c3-1.04,6.01-2,9.08-2.86c4.26-1.2,8.58-2.1,12.94-2.92c3.76-0.71,7.55-1.01,11.34-1.47C142.53,105.24,146.38,105.2,150.24,105.11z
		 M66.36,166.87c0.75-0.78,1.4-1.42,2.01-2.09c2.95-3.21,6.13-6.21,9.51-9c2.68-2.2,5.49-4.26,8.28-6.32
		c3.88-2.87,8.06-5.32,12.36-7.55c2.9-1.5,5.88-2.88,8.89-4.17c3.86-1.66,7.86-3.03,11.91-4.21c3.83-1.11,7.73-2.04,11.67-2.7
		c5.38-0.9,10.79-1.56,16.26-1.61c3.39-0.04,6.8-0.13,10.17,0.17c4.64,0.41,9.3,0.82,13.88,1.81c3.68,0.8,7.33,1.66,10.94,2.71
		c4.74,1.38,9.3,3.16,13.79,5.13c2.08,0.91,4.15,1.88,6.13,2.98c2.99,1.66,5.92,3.41,8.83,5.2c1.67,1.02,3.3,2.11,4.85,3.29
		c2.7,2.06,5.39,4.14,7.94,6.36c2.41,2.09,4.67,4.34,6.97,6.56c1.12,1.08,2.16,2.22,3.33,3.44c4.02-3.64,8.04-7.2,12.07-10.9
		c-1.56-1.65-2.96-3.24-4.49-4.71c-2.63-2.53-5.25-5.07-8.02-7.45c-2.8-2.4-5.76-4.63-8.7-6.88c-1.42-1.09-2.92-2.09-4.45-3.06
		c-2.69-1.7-5.37-3.4-8.14-4.99c-2.16-1.24-4.44-2.3-6.66-3.45c-3.8-1.96-7.78-3.55-11.8-5.05c-1.99-0.74-4.04-1.34-6.05-2.02
		c-3.97-1.34-8.05-2.29-12.14-3.17c-2.62-0.56-5.27-1.03-7.93-1.36c-3.24-0.41-6.51-0.69-9.77-0.92c-3.69-0.26-7.38-0.43-11.09-0.24
		c-5.33,0.26-10.67,0.48-15.93,1.44c-2.63,0.48-5.27,0.98-7.9,1.49c-3.7,0.72-7.33,1.7-10.89,2.89c-2.2,0.74-4.41,1.45-6.6,2.22
		c-1.31,0.46-2.62,0.95-3.88,1.53c-3.64,1.68-7.27,3.36-10.86,5.14c-2.03,1-4.03,2.1-5.94,3.3c-3.18,2-6.33,4.05-9.37,6.23
		c-2.83,2.02-5.57,4.16-8.23,6.38c-3.63,3.03-7.02,6.3-10.27,9.71c-0.91,0.95-1.78,1.94-2.76,3.01
		C58.33,159.64,62.3,163.21,66.36,166.87z"
    />
    <path
      d="M150.3,156.61c4.41-0.04,8.81,0.23,13.16,0.92c4.07,0.64,8.1,1.51,12.02,2.72c2.66,0.82,5.3,1.75,7.86,2.8
		c4.18,1.72,8.25,3.67,12.03,6.1c2.52,1.61,5.05,3.22,7.45,4.98c3.18,2.33,6.17,4.87,8.91,7.67c1.49,1.52,2.96,3.04,4.41,4.6
		c1.53,1.65,1.39,3.97-0.25,5.52c-3.89,3.68-7.76,7.38-11.65,11.06c-1.79,1.7-3.6,3.39-5.42,5.06c-1.39,1.27-3.36,1.25-4.69-0.05
		c-1.3-1.27-2.5-2.65-3.86-3.87c-1.92-1.72-3.9-3.4-5.94-4.99c-2.53-1.98-5.36-3.55-8.21-5.07c-1.12-0.6-2.28-1.14-3.47-1.59
		c-2.06-0.77-4.16-1.44-6.24-2.17c-3.09-1.07-6.34-1.52-9.57-1.98c-3.56-0.5-7.15-0.48-10.74-0.25c-3.12,0.2-6.18,0.75-9.21,1.44
		c-3.48,0.8-6.84,1.96-10.09,3.41c-2.26,1.01-4.44,2.15-6.54,3.41c-2.47,1.48-4.78,3.17-7,4.98c-2.46,2.01-4.67,4.25-6.81,6.55
		c-0.84,0.9-1.88,1.19-3.05,0.99c-0.58-0.1-1.22-0.35-1.64-0.73c-2.47-2.25-4.89-4.56-7.32-6.86c-1.77-1.67-3.52-3.35-5.29-5.02
		c-1.6-1.52-3.23-3-4.8-4.55c-1.43-1.4-1.5-3.7-0.17-5.19c2.76-3.12,5.74-6.03,8.93-8.77c3.24-2.79,6.68-5.33,10.31-7.63
		c2.13-1.35,4.4-2.52,6.62-3.74c3.57-1.98,7.35-3.54,11.23-4.89c2.37-0.82,4.81-1.49,7.23-2.15
		C135.61,157.39,142.89,156.54,150.3,156.61z M103.72,200.05c0.2-0.17,0.29-0.24,0.37-0.32c3.82-3.78,8.03-7.11,12.69-9.92
		c3.89-2.35,8.02-4.24,12.33-5.77c3.29-1.17,6.7-1.97,10.13-2.6c3.76-0.7,7.58-0.94,11.41-0.87c4.01,0.07,7.99,0.37,11.95,1.17
		c4.37,0.88,8.56,2.19,12.66,3.81c1.08,0.42,2.13,0.9,3.16,1.42c3.13,1.58,6.18,3.29,9.01,5.32c1.52,1.09,3,2.24,4.43,3.43
		c1.63,1.36,3.19,2.8,4.85,4.27c3.89-3.69,7.79-7.39,11.71-11.11c-0.29-0.34-0.47-0.58-0.68-0.79c-3-3.02-6.22-5.81-9.67-8.36
		c-3.54-2.61-7.29-4.93-11.24-6.95c-3.12-1.6-6.33-3.05-9.67-4.16c-3.23-1.07-6.5-2.07-9.83-2.81c-3.1-0.69-6.28-1.13-9.45-1.46
		c-6.18-0.64-12.37-0.58-18.53,0.41c-2.76,0.44-5.53,0.84-8.24,1.54c-3.05,0.78-6.04,1.68-8.98,2.76c-3.34,1.23-6.6,2.64-9.68,4.38
		c-2.34,1.32-4.65,2.69-6.92,4.13c-4.16,2.64-7.9,5.77-11.42,9.14c-0.72,0.69-1.6,1.28-2.04,2.18
		C95.98,192.63,99.82,196.31,103.72,200.05z"
    />
    <path
      d="M166.6,224.05c-0.06,4.81-1.77,9.08-5.42,12.55c-1.69,1.6-3.68,2.82-6,3.53c-2.49,0.76-4.97,1.16-7.58,0.63
		c-5.34-1.09-9.29-3.94-11.7-8.55c-2.94-5.62-2.83-11.31,0.26-16.86c1.64-2.95,4.06-5.2,7.25-6.7c3.76-1.78,7.64-1.93,11.58-0.81
		c2.78,0.79,5.12,2.35,7.02,4.42C165.08,215.6,166.54,219.55,166.6,224.05z M149.95,233.46c3.05,0,5.29-1.08,7.08-3.09
		c4.18-4.7,2.62-12.34-3-14.97c-3.27-1.53-6.93-0.99-9.49,1.16c-2.9,2.44-3.99,5.59-3.36,9.23
		C141.92,230.04,145.64,233.56,149.95,233.46z"
    />
  </svg>,
  'ConnectivityWifiIcon',
);

export default ConnectivityWifiIcon;
