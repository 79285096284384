import { createSvgIcon } from '@mui/material';

const ConnectivityMobileIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M114.16,153.36c0-7.94,0-15.87,0.01-23.81c0-0.41-0.12-0.7-0.4-0.99c-3.49-3.67-6.98-7.34-10.46-11.02
		c-1.11-1.17-1.42-2.53-0.82-4.02c0.61-1.52,1.82-2.28,3.45-2.3c2.85-0.03,5.7-0.01,8.54-0.01c4.99,0,9.98,0.01,14.97,0
		c1.7,0,2.98,0.66,3.69,2.22c0.67,1.47,0.28,2.99-0.97,4.3c-3.48,3.65-6.95,7.31-10.41,10.99c-0.16,0.17-0.24,0.49-0.24,0.74
		c-0.02,1.96-0.01,3.93-0.01,5.89c0,13.85,0,27.7,0,41.55c0,2.14-1.27,3.6-3.34,3.87c-1.91,0.25-3.84-1.32-3.99-3.25
		c-0.02-0.23-0.01-0.47-0.01-0.71C114.16,169,114.16,161.18,114.16,153.36z M117.82,122.12c1.11-1.18,2.19-2.31,3.31-3.5
		c-2.26,0-4.4,0-6.63,0C115.63,119.81,116.69,120.93,117.82,122.12z"
    />
    <path
      d="M207.4,296c-38.22,0-76.45,0-114.67,0c-0.13-0.03-0.25-0.08-0.38-0.1c-1.5-0.17-3.02-0.23-4.5-0.5
		c-13.27-2.41-23.19-14.34-23.19-27.83c0-78.25,0-156.5,0.02-234.75c0-1.82,0.17-3.65,0.49-5.44C67.56,14.13,79.52,4.18,92.99,4.18
		c38.04,0,76.09,0,114.13,0.01c1.39,0,2.79,0.11,4.16,0.32c13.97,2.18,24.18,14.15,24.18,28.31c0,78.09,0,156.19,0,234.28
		c0,0.94-0.03,1.89-0.12,2.82c-0.64,6.87-3.4,12.78-8.27,17.64c-4.82,4.82-10.68,7.56-17.48,8.23
		C208.86,295.87,208.13,295.93,207.4,296z M72,150.01c0,39.14,0,78.27,0.02,117.41c0,1.29,0.13,2.6,0.37,3.86
		c1.97,10.14,10.68,17.29,21.03,17.29c37.75,0,75.5,0,113.25-0.01c1.17,0,2.36-0.08,3.52-0.27c10.38-1.67,17.92-10.56,17.92-21.1
		c0-78.02,0-156.04-0.02-234.06c0-1.54-0.16-3.1-0.45-4.62c-1.83-9.68-10.71-16.98-20.55-16.98c-37.95,0-75.89,0-113.84,0.01
		c-1.02,0-2.04,0.08-3.05,0.22C79.87,13.2,72,22.24,72,32.66C72,71.78,72,110.9,72,150.01z"
    />
    <path
      d="M82.24,148.77c0-35.27,0-70.53,0-105.8c0-2.39,1.51-3.91,3.89-3.91c42.62,0,85.24,0,127.86,0
		c2.37,0,3.89,1.54,3.89,3.91c0,70.53,0,141.06,0,211.59c0,2.37-1.54,3.92-3.9,3.92c-42.62,0-85.24,0-127.86,0
		c-2.34,0-3.88-1.54-3.88-3.86C82.24,219.35,82.24,184.06,82.24,148.77z M210.47,251.08c0-68.24,0-136.42,0-204.6
		c-40.31,0-80.56,0-120.81,0c0,68.22,0,136.4,0,204.6C129.95,251.08,170.19,251.08,210.47,251.08z"
    />
    <path
      d="M163.22,273.2c0,7.25-5.89,13.14-13.15,13.14c-7.67-0.01-13.32-6.38-13.19-13.47
		c0.13-7.03,6.01-12.89,13.17-12.91C157.33,259.93,163.22,265.85,163.22,273.2z M149.88,278.96c3.04,0.18,5.79-2.35,5.99-5.52
		c0.2-3.05-2.31-5.9-5.35-6.09c-3.3-0.2-6.1,2.23-6.28,5.44C144.07,276.06,146.54,278.77,149.88,278.96z"
    />
    <path
      d="M150.04,30.17c-7.85,0-15.71,0-23.56,0c-1.88,0-3.45-1.36-3.68-3.16c-0.24-1.89,0.88-3.6,2.7-4.08
		c0.37-0.1,0.78-0.13,1.16-0.13c15.59-0.01,31.18-0.01,46.77,0c2.01,0,3.45,1.12,3.85,2.96c0.36,1.68-0.52,3.42-2.14,4.09
		c-0.49,0.2-1.06,0.3-1.6,0.31C165.7,30.18,157.87,30.17,150.04,30.17z"
    />
    <path
      d="M184.55,145.93c0-10.35,0-20.7,0-31.05c0-1.82,1.35-3.37,3.09-3.61c1.91-0.26,3.64,0.82,4.12,2.62
		c0.12,0.47,0.16,0.97,0.16,1.46c0.01,20.45,0.01,40.89,0.01,61.34c0,2.24-1.21,3.76-3.21,4.07c-2.2,0.33-4.17-1.39-4.18-3.65
		c-0.01-5.62,0-11.24,0-16.85C184.55,155.48,184.55,150.71,184.55,145.93z"
    />
    <path
      d="M169.07,152.04c0,8.35,0,16.7,0,25.05c0,1.94-1.31,3.4-3.28,3.68c-1.7,0.24-3.42-0.89-3.91-2.61
		c-0.13-0.46-0.18-0.97-0.18-1.45c-0.01-16.44-0.01-32.89-0.01-49.33c0-2.03,0.95-3.44,2.62-3.95c2.38-0.72,4.76,1.02,4.76,3.52
		C169.08,135.3,169.07,143.67,169.07,152.04z"
    />
    <path
      d="M146.64,162.14c0,4.99,0.01,9.98,0,14.96c0,1.88-1.29,3.35-3.12,3.64c-1.79,0.28-3.49-0.75-4.04-2.49
		c-0.13-0.4-0.19-0.85-0.19-1.27c-0.01-9.9-0.01-19.79-0.01-29.69c0-2.01,1.29-3.49,3.25-3.78c1.74-0.25,3.47,0.87,3.96,2.62
		c0.11,0.39,0.14,0.81,0.15,1.22C146.65,152.28,146.64,157.21,146.64,162.14z"
    />
  </svg>,
  'ConnectivityMobileIcon',
);

export default ConnectivityMobileIcon;
