import { createSvgIcon } from '@mui/material';

const SensorNivelPiezorresistivo = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M150.15,298.74c-47.76,0-95.52,0-143.27,0c-0.44,0-0.88,0.01-1.32,0c-2.13-0.05-3.62-1.47-3.76-3.58
		c-0.02-0.32-0.01-0.64-0.01-0.96c0-95.92,0-191.83,0-287.75c0-0.36-0.02-0.72,0.01-1.08c0.18-2.02,1.59-3.36,3.62-3.46
		C5.85,1.89,6.29,1.9,6.73,1.9c95.68,0,191.35,0,287.03,0c0.52,0,1.04-0.01,1.56,0.03c1.65,0.13,2.91,1.26,3.23,2.89
		c0.09,0.43,0.11,0.87,0.14,1.31c0.02,0.32,0,0.64,0,0.96c0,95.48,0,190.95-0.01,286.43c0,0.76-0.01,1.53-0.14,2.27
		c-0.29,1.66-1.65,2.83-3.33,2.94c-0.48,0.03-0.96,0.01-1.44,0.01C245.9,298.74,198.02,298.74,150.15,298.74z M291.34,9.39
		C289.5,9.14,10.15,9.22,9.29,9.44c-0.24,1.23-0.2,280.89,0.06,281.87c93.92,0,187.86,0,281.98,0
		C291.34,197.31,291.34,103.37,291.34,9.39z"
    />
    <path
      d="M119.76,138.71c1.58,0.23,2.91,0.01,4.23-0.33c2.3-0.59,4.45-1.55,6.54-2.65c3.47-1.82,6.91-3.7,10.39-5.48
		c2.5-1.28,5.13-2.23,7.9-2.74c3.58-0.66,7.08-0.31,10.53,0.83c3.74,1.24,7.16,3.16,10.58,5.07c2.89,1.62,5.8,3.22,8.75,4.74
		c1.78,0.92,3.69,1.55,5.69,1.79c2.4,0.29,4.72-0.15,6.92-1.08c2.06-0.87,4.08-1.84,6.06-2.87c2.84-1.47,5.61-3.07,8.45-4.55
		c2.63-1.37,5.4-2.41,8.32-2.99c3.68-0.73,7.32-0.44,10.95,0.34c4.04,0.86,7.9,2.3,11.8,3.6c2.92,0.97,5.85,1.9,8.9,2.39
		c1.21,0.19,2.44,0.29,3.76,0.45c0.06-0.44,0.12-0.75,0.14-1.05c0.02-0.4,0.01-0.8,0.01-1.2c0-29.84,0-59.68,0.01-89.52
		c0-0.71,0.03-1.45,0.23-2.13c0.49-1.71,2.15-2.67,4.04-2.44c1.65,0.2,2.96,1.62,3.06,3.34c0.02,0.4,0.01,0.8,0.01,1.2
		c0,70,0,140,0,210c0,0.32,0,0.64,0,0.96c-0.04,2.05-1.31,3.47-3.34,3.72c-0.43,0.05-0.88,0.03-1.32,0.03
		c-67.28,0-134.56,0-201.84,0c-0.52,0-1.04,0-1.55-0.09c-1.86-0.32-3.03-1.69-3.1-3.57c-0.01-0.28,0-0.56,0-0.84
		c0-70.12,0-140.24,0-210.36c0-0.52-0.02-1.05,0.08-1.55c0.37-1.82,1.95-2.97,3.87-2.86c1.79,0.1,3.21,1.48,3.37,3.3
		c0.04,0.48,0.02,0.96,0.02,1.44c0,31.84,0,63.68,0,95.52c0,0.55,0,1.1,0,1.67c1,0.25,1.83,0.02,2.59-0.24
		c1.55-0.53,3.12-1.05,4.55-1.83c2.28-1.24,4.44-2.69,6.67-4.03c0.54-0.33,0.76-0.73,0.73-1.37c-0.05-1-0.01-2-0.01-3
		c0-17.96,0-35.92,0-53.88c0-0.4-0.02-0.8,0.01-1.2c0.16-1.93,1.59-3.33,3.52-3.44c0.32-0.02,0.64-0.01,0.96-0.01
		c14.32,0,28.64,0,42.96,0c0.4,0,0.8-0.01,1.2,0.02c1.86,0.16,3.24,1.6,3.33,3.47c0.02,0.44,0.01,0.88,0.01,1.32
		c0,20.12,0,40.24,0,60.36C119.76,137.51,119.76,138.06,119.76,138.71z M160.42,250.56c0-0.39,0-0.82,0-1.25c0-4.48,0-8.96,0-13.44
		c0-1.48-0.02-2.96,0.01-4.44c0.05-2.51,1.48-3.93,3.97-3.98c0.24,0,0.48,0,0.72,0c12.8,0,25.6,0,38.4,0c0.48,0,0.96-0.01,1.44,0.04
		c1.57,0.16,2.76,1.17,3.13,2.71c0.12,0.5,0.14,1.03,0.14,1.54c0.01,5.84,0,11.68,0.01,17.52c0,0.46,0.05,0.92,0.07,1.42
		c13.83,0,27.53,0,41.21,0c0.07-0.19,0.14-0.3,0.14-0.41c0.01-35.52,0.02-71.04,0.02-106.56c0-0.32-0.04-0.64-0.07-0.95
		c0-0.03-0.05-0.06-0.07-0.09c-0.03-0.03-0.06-0.06-0.14-0.14c-5.82-0.16-11.33-1.94-16.82-3.82c-1.89-0.65-3.78-1.32-5.7-1.83
		c-2.04-0.54-4.12-0.99-6.2-1.33c-3.17-0.52-6.25,0.05-9.11,1.41c-3.14,1.48-6.21,3.13-9.27,4.77c-2.93,1.56-5.84,3.15-8.98,4.26
		c-3.57,1.26-7.21,1.76-10.97,1.08c-2.78-0.5-5.41-1.46-7.89-2.77c-2.97-1.56-5.86-3.28-8.83-4.85c-2.51-1.32-5.04-2.61-7.63-3.76
		c-2.06-0.92-4.28-1.31-6.56-1.12c-1.77,0.15-3.48,0.6-5.08,1.34c-2.61,1.21-5.16,2.54-7.73,3.84c-2.67,1.35-5.3,2.81-8.01,4.07
		c-3.18,1.48-6.52,2.39-10.08,2.36c-0.22,0-0.45,0.08-0.69,0.13c0,34.8,0,69.54,0,104.25C120.93,250.85,159.24,250.85,160.42,250.56
		z M75.2,191.34c0.51,0,0.94,0,1.37,0c2.12,0,4.24,0,6.36,0c1.04,0,2.08-0.04,3.12,0.01c2.51,0.13,4.14,2.45,3.4,4.81
		c-0.48,1.52-1.83,2.51-3.55,2.52c-3.12,0.02-6.24,0-9.36,0.01c-0.43,0-0.85,0.04-1.25,0.06c-0.28,1.37-0.23,23.38,0.07,24.42
		c1.26,0,2.57,0,3.88,0c2.32,0.01,4.64-0.04,6.96,0.04c1.88,0.06,3.25,1.49,3.43,3.39c0.16,1.73-1.06,3.39-2.83,3.79
		c-0.46,0.11-0.95,0.14-1.42,0.14c-2.96,0.01-5.92,0-8.88,0.01c-0.42,0-0.84,0.05-1.29,0.08c0,6.74,0,13.36,0,19.96
		c1.15,0.28,36.11,0.27,37.13-0.01c0-35.33,0-70.67,0-105.81c-3.31-1.76-6.49-3.43-9.66-5.14c-1.79-0.97-3.52-2.07-5.34-2.98
		c-1.89-0.95-3.82-1.82-5.8-2.57c-3.08-1.17-6.22-1.12-9.22,0.26c-2.14,0.98-4.17,2.19-6.27,3.25c-0.75,0.38-0.95,0.92-0.95,1.73
		c0.03,6.32,0.02,12.64,0.02,18.96c0,0.38,0.05,0.77,0.09,1.3c0.57,0,1.04,0,1.51,0c3.16,0,6.32-0.03,9.48,0.03
		c1.87,0.04,3.25,1.48,3.44,3.37c0.17,1.72-1.06,3.4-2.82,3.8c-0.5,0.11-1.03,0.15-1.54,0.15c-2.92,0.01-5.84,0-8.76,0.01
		c-0.42,0-0.85,0.05-1.3,0.08C75.2,175.15,75.2,183.17,75.2,191.34z M75.3,104.14c-0.27,1.35-0.24,24.21,0.02,25.25
		c0.37-0.17,0.72-0.33,1.07-0.49c1.13-0.51,2.24-1.06,3.39-1.52c2.47-0.99,5.05-1.54,7.72-1.48c2.58,0.05,5.06,0.64,7.48,1.54
		c2.97,1.11,5.75,2.6,8.5,4.15c2.54,1.43,5.08,2.86,7.63,4.28c0.36,0.2,0.71,0.54,1.21,0.36c0-19,0-37.97,0-56.93
		c-1.3-0.25-36.14-0.21-37.12,0.04c0,5.76,0,11.53,0,17.39c0.47,0.02,0.86,0.06,1.25,0.06c1.88,0.01,3.76,0,5.64,0
		c1.32,0,2.64-0.03,3.96,0.01c2.49,0.08,4.14,2.44,3.4,4.8c-0.48,1.51-1.83,2.51-3.56,2.53c-3.08,0.03-6.16,0.01-9.24,0.01
		C76.22,104.14,75.78,104.14,75.3,104.14z M67.76,250.68c0-35.46,0-70.77,0-106.07c0-0.4-0.05-0.79-0.08-1.19
		c0-0.03-0.04-0.07-0.07-0.09c-0.03-0.02-0.07-0.03-0.23-0.09c-4.29,2.59-8.78,4.92-14.08,5.08c0,34.17,0,68.22,0,102.36
		C58.12,250.68,62.85,250.68,67.76,250.68z M167.86,250.59c0.27,0.06,0.52,0.16,0.79,0.17c3.76,0.23,31.16,0.07,32.15-0.2
		c0-5.19,0-10.4,0-15.58c-1.2-0.28-31.88-0.26-32.93,0.02C167.86,240.16,167.86,245.34,167.86,250.59z"
    />
    <path
      d="M219.01,210.69c0.56-0.54,0.91-0.86,1.24-1.2c5.69-5.68,11.37-11.36,17.05-17.06c0.83-0.83,1.73-1.4,2.94-1.38
		c1.45,0.02,2.56,0.66,3.22,1.93c0.68,1.3,0.61,2.63-0.24,3.86c-0.29,0.42-0.68,0.78-1.05,1.15c-5.6,5.6-11.2,11.2-16.79,16.8
		c-0.31,0.31-0.6,0.62-1.05,1.09c0.49,0.07,0.78,0.14,1.06,0.14c3.4,0.01,6.8,0.02,10.2,0.03c0.48,0,0.97-0.02,1.43,0.07
		c1.92,0.36,3.12,2.03,2.95,4.04c-0.15,1.8-1.67,3.21-3.56,3.23c-3.08,0.03-6.16,0.01-9.24,0.01c-3.6,0-7.2-0.01-10.8-0.02
		c-0.44,0-0.88,0.01-1.32-0.03c-1.95-0.16-3.32-1.56-3.44-3.51c-0.05-0.8-0.08-1.6-0.08-2.4c-0.01-6.08-0.02-12.16-0.02-18.24
		c0-0.48,0.01-0.97,0.11-1.43c0.41-1.83,2.02-3,3.89-2.85c1.82,0.14,3.27,1.61,3.32,3.48c0.06,2.2,0.02,4.4,0.03,6.6
		c0.01,1.52,0.02,3.04,0.03,4.56C218.91,209.84,218.96,210.11,219.01,210.69z"
    />
    <path
      d="M180.63,205.22c0.03-0.76,0.07-1.22,0.07-1.67c0-7.88,0-15.75,0-23.63c0-0.36,0.01-0.72,0-1.08
		c-0.07-2.03,1.67-3.52,3.29-3.62c2.01-0.12,3.59,1.06,3.95,3.05c0.09,0.51,0.11,1.03,0.11,1.55c0.01,7.88,0,15.75,0,23.63
		c0,0.47,0,0.93,0,1.71c0.54-0.49,0.86-0.75,1.16-1.04c2.44-2.42,4.89-4.84,7.32-7.26c1.05-1.05,2.28-1.47,3.72-1.09
		c2.58,0.69,3.57,3.77,1.88,5.85c-0.55,0.68-1.21,1.27-1.84,1.89c-4.28,4.26-8.57,8.51-12.85,12.76c-0.14,0.14-0.28,0.29-0.43,0.42
		c-1.65,1.55-3.72,1.57-5.36,0c-1.56-1.49-3.07-3.04-4.6-4.56c-3.37-3.36-6.74-6.71-10.11-10.07c-1.04-1.04-1.49-2.25-1.1-3.71
		c0.66-2.44,3.45-3.51,5.56-2.11c0.4,0.26,0.74,0.61,1.08,0.94c2.33,2.31,4.65,4.63,6.97,6.94
		C179.76,204.42,180.08,204.7,180.63,205.22z"
    />
    <path
      d="M150.74,210.38c0-0.42,0-0.85,0-1.27c0.01-3.44,0.02-6.87,0.02-10.31c0-1.24,0.4-2.29,1.37-3.06
		c1.16-0.92,2.48-1.1,3.83-0.49c1.35,0.61,2.1,1.72,2.14,3.21c0.05,1.56,0.02,3.12,0.01,4.68c-0.01,5.32-0.02,10.63-0.04,15.95
		c0,0.44-0.02,0.88-0.1,1.31c-0.35,1.68-1.63,2.8-3.33,2.96c-0.36,0.03-0.72,0.03-1.08,0.03c-6.64,0.01-13.27,0.02-19.91,0.02
		c-0.44,0-0.89-0.01-1.31-0.12c-1.75-0.45-2.86-2.07-2.7-3.89c0.15-1.73,1.5-3.12,3.26-3.31c0.44-0.05,0.88-0.03,1.32-0.03
		c3.28-0.01,6.56-0.01,9.83-0.02c0.34,0,0.68-0.04,1.29-0.08c-0.46-0.5-0.76-0.85-1.09-1.17c-5.71-5.71-11.41-11.42-17.13-17.13
		c-0.83-0.82-1.38-1.73-1.36-2.95c0.02-1.51,0.71-2.62,2.04-3.27c1.32-0.65,2.65-0.51,3.84,0.38c0.35,0.26,0.65,0.59,0.96,0.9
		c5.6,5.59,11.19,11.19,16.79,16.79c0.34,0.33,0.68,0.66,1.02,0.99C150.53,210.44,150.64,210.41,150.74,210.38z"
    />
  </svg>,
  'SensorNivelPiezorresistivo',
);

export default SensorNivelPiezorresistivo;
