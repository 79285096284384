import { gql } from '@/__generated__';

const GET_ENTITY_GROUPED_ISSUES = gql(`#graphql
  query GetEntityGroupedIssues($input: WriteExecutionLogsInput) {
    writeExecutionLogGroupedIssues(input: $input) {
      id
      items {
        id
        createdAt
        code
        updatedAt
        severity
        logs {
          id
          pageInfo {
            totalCount
          }
        }
      }
      pageInfo {
        totalCount
        pageNumber
        limit
      }
    }
  }
`);

export default GET_ENTITY_GROUPED_ISSUES;
