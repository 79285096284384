import { WriteExecutionLogEntityType, WriteExecutionLogSeverity } from '@/__generated__/graphql';
import { useSuspenseQuery } from '@apollo/client';
import GET_DEVICE_INTEGRATION_GROUPED_ISSUES_COUNT from '@features/integrations/graphql/queries/getDeviceIntegrationGroupedIssuesCount';
import GET_INTEGRATION_GROUPED_ISSUES_COUNT from '@features/integrations/graphql/queries/getIntegrationGroupedIssuesCount';
import { getWriteExecutionLogSeverityAttributes } from '@features/integrations/utils/writeExecutionLogSeverityAttributes';
import { useLingui } from '@lingui/react';
import { ButtonBase, ButtonBaseProps, Skeleton, Typography } from '@mui/material';
import { Suspense, type FC } from 'react';
import { IssuesCount } from './common';

interface CommonProps {
  severity: WriteExecutionLogSeverity;
}

interface IntegrationProps extends CommonProps {
  integrationId: number;
}

const IntegrationSuspenseComponent: FC<IntegrationProps> = ({ integrationId, severity }) => {
  const { data } = useSuspenseQuery(GET_INTEGRATION_GROUPED_ISSUES_COUNT, {
    variables: {
      integrationId,
      severity,
    },
  });
  const count = data.integration.writeExecutionLogGroupedIssues.pageInfo.totalCount;
  if (severity === WriteExecutionLogSeverity.Info) {
  }
  return <IssuesCount severity={severity} count={count} />;
};

interface DeviceIntegrationProps extends CommonProps {
  deviceIntegrationId: number;
}

const DeviceIntegrationSuspenseComponent: FC<DeviceIntegrationProps> = ({
  deviceIntegrationId,
  severity,
}) => {
  const { data } = useSuspenseQuery(GET_DEVICE_INTEGRATION_GROUPED_ISSUES_COUNT, {
    variables: {
      deviceIntegrationId,
      severity,
    },
  });

  const count = data.deviceIntegrationRelation.writeExecutionLogGroupedIssues.pageInfo.totalCount;

  return <IssuesCount severity={severity} count={count} />;
};

interface Props extends CommonProps {
  active?: boolean;
  onClick: (severity: WriteExecutionLogSeverity) => void;
  label?: JSX.Element;
  entity: {
    id: number;
    type: WriteExecutionLogEntityType;
  };
  ButtonProps?: ButtonBaseProps;
}

export const SeverityButton: FC<Props> = ({
  entity,
  active,
  severity,
  onClick,
  ButtonProps,
  label: defaultLabel,
}) => {
  const { i18n } = useLingui();
  const { label } = getWriteExecutionLogSeverityAttributes(severity, i18n);
  return (
    <ButtonBase
      onClick={() => onClick(severity)}
      {...ButtonProps}
      sx={{
        height: 48,
        bgcolor: (theme) => theme.palette.background.paper,
        border: 1,
        borderColor: active ? 'info' : 'divider',
        py: 1,
        px: 1.5,
        borderRadius: 1,
        gap: 2,
        justifyContent: 'space-between',
        ':hover': {
          borderColor: (theme) => theme.palette.info.main,
        },
        transition: `border-color 0.15s ease-in-out`,
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr) auto',
        textAlign: 'left',
        ...ButtonProps?.sx,
      }}
    >
      <Typography noWrap fontSize="small" fontWeight="bold" sx={{ textTransform: 'uppercase' }}>
        {defaultLabel ?? label}
      </Typography>
      <Suspense fallback={<Skeleton width="100%" />}>
        {entity.type === WriteExecutionLogEntityType.Integration && (
          <IntegrationSuspenseComponent integrationId={entity.id} severity={severity} />
        )}
        {entity.type === WriteExecutionLogEntityType.DeviceIntegration && (
          <DeviceIntegrationSuspenseComponent deviceIntegrationId={entity.id} severity={severity} />
        )}
      </Suspense>
    </ButtonBase>
  );
};
