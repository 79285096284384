import { I18n } from '@lingui/core';
import { DeviceStatus } from '@/__generated__/graphql';
import { t } from '@lingui/macro';

export interface DeviceStatusOption {
  status: DeviceStatus;
  displayName: string;
}

export const getDeviceStatusOptions = (i18n: I18n): DeviceStatusOption[] => {
  return [
    {
      status: DeviceStatus.Active,
      displayName: t(i18n)`Activo`,
    },
    {
      status: DeviceStatus.Maintenance,
      displayName: t(i18n)`En mantenimiento`,
    },
    {
      status: DeviceStatus.Testing,
      displayName: t(i18n)`En prueba`,
    },
  ];
};

// nota para corregir error de merge cuando se elimine este archivo
export const getDeviceStatusOption = (status: DeviceStatus, i18n: I18n) => {
  const options = getDeviceStatusOptions(i18n);
  return options.find((option) => option.status === status)!;
};
