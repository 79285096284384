import { createSvgIcon } from '@mui/material';

const SensorCompuertaRadial = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M150.27,1.9c47.72,0,95.44,0,143.15,0c0.64,0,1.28-0.02,1.92,0.04c1.6,0.13,2.86,1.23,3.19,2.79
		c0.09,0.43,0.12,0.87,0.16,1.31c0.02,0.32,0.01,0.64,0.01,0.96c0,95.52,0,191.03-0.01,286.55c0,0.76-0.02,1.53-0.15,2.27
		c-0.3,1.65-1.66,2.81-3.34,2.93c-0.36,0.02-0.72,0.01-1.08,0.01c-95.92,0-191.83,0-287.75,0c-0.28,0-0.56,0.01-0.84,0
		c-2.12-0.06-3.61-1.49-3.74-3.6c-0.02-0.32-0.01-0.64-0.01-0.96c0-95.92,0-191.83,0-287.75c0-0.32-0.01-0.64,0.01-0.96
		C1.94,3.39,3.35,2,5.44,1.91C6,1.88,6.56,1.9,7.12,1.9C54.83,1.9,102.55,1.9,150.27,1.9z M291.17,9.37
		C289.5,9.14,10.15,9.22,9.29,9.44c-0.24,1.23-0.2,280.89,0.06,281.87c93.92,0,187.86,0,281.77,0
		C291.39,290.33,291.43,10.66,291.17,9.37z"
    />
    <path
      d="M106.23,85.3c0.41,0.29,0.76,0.55,1.12,0.79c40.12,27.18,80.24,54.36,120.34,81.56
		c0.8,0.54,1.45,0.69,2.41,0.38c7.99-2.57,16.35,2.45,17.67,10.75c1.44,9.02-5.36,15.32-12.13,15.88
		c-4.24,0.35-7.89-0.91-10.94-3.84c-0.53-0.51-1.01-0.64-1.7-0.49c-11.29,2.59-22.58,5.2-33.88,7.73
		c-16.06,3.6-32.13,7.15-48.2,10.71c-10.49,2.32-20.99,4.61-31.49,6.92c-0.19,0.04-0.38,0.09-0.6,0.15
		c-0.09,0.58,0.32,0.91,0.57,1.28c3.98,5.97,8.43,11.58,13.45,16.7c2.66,2.71,5.47,5.27,8.25,7.86c1.22,1.14,1.81,2.44,1.3,4.07
		c-0.19,0.59-0.56,1.15-0.97,1.63c-3.74,4.38-7.5,8.74-11.27,13.09c-1.72,1.98-3.77,2.1-5.77,0.36
		c-13.55-11.76-24.62-25.53-33.19-41.29c-7.55-13.87-12.73-28.59-15.58-44.12c-1.25-6.81-1.99-13.68-2.29-20.6
		c-0.23-5.08-0.2-10.16,0.14-15.23c1.94-29.15,11.5-55.55,28.77-79.12c6.36-8.68,13.64-16.52,21.74-23.6
		c0.33-0.29,0.66-0.59,1.02-0.84c1.5-1.06,3.4-0.91,4.73,0.36c0.2,0.19,0.38,0.41,0.56,0.62c3.71,4.3,7.41,8.61,11.13,12.9
		c0.99,1.14,1.46,2.37,1.01,3.86c-0.25,0.84-0.81,1.45-1.46,2.01c-3.36,2.91-6.55,6-9.57,9.26c-5.48,5.92-10.33,12.31-14.56,19.17
		C106.64,84.53,106.46,84.88,106.23,85.3z M123.67,245.11c-27.62-26.37-41.96-58.38-42.44-96.48c0.51-38.09,14.86-70.1,42.44-96.41
		c-2.28-2.64-4.55-5.28-6.83-7.93c-62.9,56.69-60.34,155.39,0.03,208.71C119.13,250.38,121.39,247.76,123.67,245.11z M99.9,97.95
		c-0.12-0.01-0.23-0.03-0.35-0.04c-8.27,18.43-11.92,37.74-10.74,57.93c1.11,18.97,6.37,36.73,15.65,53.25
		c1.71-0.11,16.88-3.51,17.69-3.97C114.74,169.41,107.32,133.68,99.9,97.95z M106.9,94.68c0.12,1.91,20.1,97.81,20.63,98.88
		c7.73-23.18,15.42-46.19,23.11-69.26C136.05,114.42,121.56,104.61,106.9,94.68z M154.99,135.52c-0.11,0-0.22,0-0.33,0
		c-7.49,22.46-14.99,44.92-22.58,67.67c14.76-3.27,29.24-6.47,43.88-9.71C168.93,174.05,161.96,154.78,154.99,135.52z
		 M161.61,131.73c0.07,0.47,0.07,0.62,0.12,0.76c6.43,17.71,12.86,35.43,19.29,53.14c0.01,0.03,0.06,0.05,0.09,0.06
		c0.04,0.01,0.08-0.01,0.25-0.04c4.49-10.39,9-20.85,13.54-31.35C183.8,146.77,172.83,139.33,161.61,131.73z M220.49,183.37
		c0.16-1.94,0.11-3.69,0.48-5.34c0.37-1.64,1.15-3.19,1.78-4.86c-7.12-4.83-14.29-9.69-21.62-14.66
		c-4.66,10.8-9.28,21.48-14.02,32.45C198.48,188.43,209.55,185.91,220.49,183.37z M240.58,181.04c0-3.51-2.81-6.34-6.3-6.35
		c-3.47-0.01-6.33,2.86-6.33,6.33c0,3.48,2.85,6.34,6.32,6.35C237.76,187.39,240.58,184.55,240.58,181.04z"
    />
    <path
      d="M166.02,215.3c2.69,0,5.1,0.6,7.36,1.64c2.21,1.01,4.36,2.18,6.52,3.31c1.91,1,3.79,2.06,5.71,3.04
		c0.85,0.43,1.76,0.75,2.67,1.06c2,0.68,4.02,0.71,5.99-0.04c1.56-0.59,3.11-1.26,4.61-2c2.43-1.21,4.8-2.55,7.24-3.74
		c2.56-1.25,5.24-2.15,8.13-2.3c3.11-0.16,6.04,0.57,8.82,1.87c1.99,0.93,3.92,1.99,5.86,3.01c1.91,1.01,3.78,2.09,5.7,3.06
		c1.61,0.81,3.3,1.37,5.13,1.53c1.86,0.16,3.18,1.53,3.33,3.22c0.16,1.84-0.79,3.47-2.48,3.89c-0.9,0.22-1.91,0.17-2.84,0.05
		c-2.48-0.33-4.8-1.23-7-2.37c-2.09-1.09-4.13-2.28-6.21-3.38c-1.73-0.91-3.48-1.79-5.26-2.6c-2.81-1.28-5.67-1.26-8.48,0.02
		c-1.96,0.89-3.87,1.89-5.81,2.84c-2.26,1.11-4.48,2.31-6.78,3.31c-4.72,2.05-9.51,2.01-14.19-0.09c-2.66-1.19-5.2-2.63-7.78-3.98
		c-1.38-0.72-2.72-1.52-4.12-2.2c-1.07-0.52-2.2-0.94-3.33-1.33c-1.97-0.69-3.97-0.66-5.85,0.2c-1.85,0.84-3.65,1.81-5.4,2.84
		c-2.2,1.3-4.3,2.77-6.48,4.1c-2.22,1.35-4.55,2.53-7.12,3.07c-1.13,0.23-2.29,0.39-3.44,0.39c-2,0.01-3.57-1.6-3.62-3.57
		c-0.05-1.89,1.41-3.63,3.35-3.72c2.24-0.09,4.22-0.87,6.09-1.96c2-1.17,3.92-2.47,5.91-3.66c2.12-1.27,4.24-2.57,6.44-3.69
		C161.02,215.92,163.53,215.25,166.02,215.3z"
    />
  </svg>,
  'SensorCompuertaRadial',
);

export default SensorCompuertaRadial;
