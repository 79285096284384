import { type FC } from 'react';
import DeviceConfigurationModal from './DeviceConfigurationModal';
import DeviceAliasModal from './aliasModal/DeviceAliasModal';
import DeleteDeviceModal from './deleteDeviceModal/DeleteDeviceModal';
import CloneDeviceModal from './cloneDeviceModal/CloneDeviceModal';
import UpdateDeviceStatusModal from './updateDeviceStatusModal';

const DeviceConfigurationGlobalModals: FC = () => {
  return (
    <>
      <DeviceConfigurationModal />
      <DeviceAliasModal />
      <DeleteDeviceModal />
      <CloneDeviceModal />
      <UpdateDeviceStatusModal />
    </>
  );
};

export default DeviceConfigurationGlobalModals;
