export type DeviceUserValue = {
  id: number;
  hasAccess: boolean;
};

export enum UserOrganizationRole {
  NoRole = 0,
  Capta = 1,
  Admin = 2,
  Operator = 3,
  Observer = 4,
}
