import { setSnackbar } from '@/slices/components';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import NumericTextField from '@components/NumericTextField';
import { DeviceTabProps } from '@features/deviceDataConfigurationModal/components/tabs/types';
import UPDATE_DEVICE_RADAR_PARAMETERS from '@features/deviceDataConfigurationModal/graphql/mutations/updateDeviceRadarParameters';
import GET_DEVICE_RADAR_PARAMETERS from '@features/deviceDataConfigurationModal/graphql/queries/getDeviceRadarParameters';
import { radarFormSelectOptions } from '@features/deviceDataConfigurationModal/utils/radarFormSelectOptions';
import {
  radarInputMapper,
  radarOutputMapper,
} from '@features/deviceDataConfigurationModal/utils/radarInputOutputMapper';
import { radarSchema } from '@features/deviceDataConfigurationModal/utils/radarSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Help } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import FormCurveSection from './FormCurveSection';

export type CurveFunction =
  | {
      functionType: 'chiu';
      parameters: {
        vMaxDepthNorm: number;
        m: number;
      };
    }
  | {
      functionType: 'polynomial';
      parameters: {
        a: { value: number }[];
      };
    }
  | {
      functionType: 'trapezoidal';
      parameters: {
        talud: number;
        baseWidth: number;
      };
    }
  | {
      functionType: 'power';
      parameters: {
        A: number;
        B: number;
        C: number;
      };
    };

export type Curve =
  | {
      type: 'function';
      curve: CurveFunction;
    }
  | {
      type: 'interval';
      curve: CurveFunction & {
        min: number;
        max: number;
      };
    }
  | {
      type: 'piecewise';
      curves: (CurveFunction & {
        min: number;
        max: number;
      })[];
    };

export interface FormValues {
  deviceId: string;
  ratingCurve: {
    active: boolean;
    levelLimit?: number | null;
  };
  areaCurve: Curve & {
    levelOffset: number;
  };
  velocityCurve: Curve & {
    model: string;
  };
}

const RadarParametersTab: FC<DeviceTabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { data } = useSuspenseQuery(GET_DEVICE_RADAR_PARAMETERS, { variables: { deviceId } });
  const dispatch = useDispatch();

  const defaultValues: FormValues | undefined = radarInputMapper(
    data?.deviceDataConfiguration,
    deviceId,
  );

  const [update, { loading: updateLoading }] = useMutation(UPDATE_DEVICE_RADAR_PARAMETERS, {
    onCompleted: ({ updateDeviceRadarParameters }) => {
      const values = radarInputMapper(updateDeviceRadarParameters, deviceId);
      methods.reset(values);
      dispatch(
        setSnackbar({
          open: true,
          message: 'Dato Actualizado correctamente.',
          severity: 'success',
        }),
      );
    },
    onError: (errors) => {
      dispatch(
        setSnackbar({
          open: true,
          message: `Error al actualizar el dato: ${errors.message}`,
          severity: 'error',
        }),
      );
    },
  });

  const methods = useForm({
    // TODO: change schema to zod
    resolver: yupResolver<FormValues>(radarSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting, isDirty },
  } = methods;

  const submitHandler = async (formData: FormValues) => {
    const input = radarOutputMapper(JSON.parse(JSON.stringify(formData)));

    update({
      variables: {
        input,
      },
    });
  };

  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (value && type === 'change' && name === 'velocityCurve.model') {
        if (
          value.velocityCurve?.model === 'N(h)' &&
          (value.velocityCurve.type === 'function' || value.velocityCurve.type === 'interval')
        )
          if (value.velocityCurve.curve?.functionType === 'chiu')
            setValue(
              'velocityCurve.curve',
              { functionType: 'polynomial', parameters: { a: [] } },
              { shouldValidate: true },
            );
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const ratingCurveDisplayHeightLimitInput = watch('ratingCurve.active');
  const areaDisplaySaturationCheckbox = watch('areaCurve.type') !== 'function';
  const velocityDisplaySaturationCheckbox = watch('velocityCurve.type') !== 'function';

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <DialogContent
        sx={{
          maxWidth: 'md',
          margin: 'auto',
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <Typography variant="h5" mb={2}>
          Parámetros Radar
        </Typography>
        {/* TOP ROW */}
        <Box display="flex" fontSize="small" gap={2} mb={3} pl={1}>
          {/* ACTIVAR LIMITE DE AFORO CHECKBOX */}
          <Controller
            name="ratingCurve.active"
            control={control}
            render={({ field: { value, ref, ...rest } }) => (
              <FormGroup>
                <FormControlLabel
                  {...rest}
                  checked={value}
                  inputRef={ref}
                  componentsProps={{ typography: { fontSize: 'small' } }}
                  control={<Checkbox size="small" />}
                  label="Activar límite de aforo según límite de nivel"
                />
              </FormGroup>
            )}
          />
          {/* LIMITE DE NIVEL */}
          {ratingCurveDisplayHeightLimitInput && (
            <Controller
              name="ratingCurve.levelLimit"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumericTextField
                  {...field}
                  size="small"
                  label="Límite de Nivel"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        </Box>
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          {/* SECCION IZQUIERDA: CURVA DE VELOCIDAD */}
          <Box borderRight="1px solid" borderColor="divider" pr={2} pb={2}>
            <Typography variant="h6" mb={2}>
              Curva de Velocidad
            </Typography>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap="4px 8px">
              {/* CURVA DE VELOCIDAD / TIPO DE CURVA */}
              <Controller
                name="velocityCurve.type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel htmlFor="velocity-curve-type">Tipo de Curva</InputLabel>
                    <Select
                      {...field}
                      label="Tipo de Curva"
                      inputProps={{ id: 'velocity-curve-type' }}
                      error={!!error}
                    >
                      {radarFormSelectOptions.curveType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
              {/* CURVA DE VELOCIDAD / TIPO DE MODELO */}
              <Controller
                name="velocityCurve.model"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel htmlFor="velocity-velocity-model">Tipo de Modelo</InputLabel>
                    <Select
                      {...field}
                      label="Tipo de Modelo"
                      inputProps={{ id: 'velocity-velocity-model' }}
                      error={!!error}
                    >
                      {radarFormSelectOptions.velocityModel.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
              {velocityDisplaySaturationCheckbox && (
                <FormGroup>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      checked
                      disabled
                      control={<Checkbox checked />}
                      label="Saturación"
                    />
                    <Tooltip title="Por ahora SIEMPRE está encendida. En una próxima actualización se podrá apagar. Saturación si está encendida, significa que si se sube un valor mayor al intervalo, se ingresa el valor igual a la función evaluada en el mayor valor del intervalo. Viceversa si es menor al intervalo.">
                      <Help fontSize="small" color="disabled" />
                    </Tooltip>
                  </Box>
                </FormGroup>
              )}
            </Box>
            <FormCurveSection
              control={control}
              path="velocityCurve"
              setValue={setValue}
              getValues={getValues}
            />
          </Box>
          {/* SECCION DERECHA: CURVA DE AREA */}
          <Box pl={2} pb={4}>
            <Typography variant="h6" mb={2}>
              Curva de Área
            </Typography>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap="4px 8px">
              {/* CURVA DE AREA / TIPO DE CURVA */}
              <Controller
                name="areaCurve.type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel htmlFor="area-curve-type">Tipo de Curva</InputLabel>
                    <Select
                      {...field}
                      error={!!error}
                      label="Tipo de Curva"
                      inputProps={{ id: 'area-curve-type' }}
                    >
                      {radarFormSelectOptions.curveType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
              {/* CURVA DE AREA / OFFSET NIVEL */}
              <Controller
                name="areaCurve.levelOffset"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    inputMode="decimal"
                    label="Offset Nivel"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              {/* CURVA DE AREA / SATURACION */}
              {areaDisplaySaturationCheckbox && (
                <FormGroup>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      checked
                      disabled
                      control={<Checkbox checked />}
                      label="Saturación"
                    />
                    <Tooltip title="Por ahora SIEMPRE esta encendida. En una próxima actualización se podrá apagar. Saturación si esta encendida, significa que si se sube un valor mayor al intervalo, se ingresa el valor igual a la función evaluada en el mayor valor del intervalo. Viceversa si es menor al intervalo.">
                      <Help fontSize="small" color="disabled" />
                    </Tooltip>
                  </Box>
                </FormGroup>
              )}
            </Box>
            <FormCurveSection
              control={control}
              path="areaCurve"
              setValue={setValue}
              getValues={getValues}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ maxWidth: 'md', margin: 'auto', py: 2 }}>
        <Button
          disabled={!isDirty}
          color="info"
          variant="outlined"
          onClick={() => reset(defaultValues)}
        >
          Restablecer Formulario
        </Button>
        <Box flexGrow={1} />
        <Button disabled={isSubmitting} color="info" variant="outlined" onClick={() => onClose()}>
          Cerrar
        </Button>
        <LoadingButton
          disabled={!isDirty}
          loading={isSubmitting || updateLoading}
          type="submit"
          variant="contained"
        >
          Guardar
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default RadarParametersTab;
