import { gql } from '@/__generated__';
import { DevicesAccess_GetDevicesAccessForUserQuery } from '@/__generated__/graphql';

export type DeviceUserRelation =
  DevicesAccess_GetDevicesAccessForUserQuery['user']['devices'][number];

const GET_DEVICES_ACCESS_FOR_USER = gql(`#graphql
  query DevicesAccess_GetDevicesAccessForUser($userId: Int!, $devicesInput: DeviceUsersInput) {
    user(input: { id: $userId }) {
      id
      devices(input: $devicesInput) {
        id
        hasAccess
        device {
          id
          profile {
            id
            name
            irrigation {
              id
              name
            }
            organization {
              id
              name
            }
          }
        }
        user {
          id
        }
      }
    }
  }
`);

export default GET_DEVICES_ACCESS_FOR_USER;
