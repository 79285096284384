import { useModalStatusMessage } from '@/hooks';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import NumericTextField from '@components/NumericTextField';
import ModalActions from '@components/modal/ModalActions';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, DialogContent, Divider } from '@mui/material';
import { useEffect, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import UPDATE_DGA_MAX_FLOW from '@features/dga/graphql/mutations/updateDgaMaxFlow';
import GET_DGA_MAX_FLOW from '@features/dga/graphql/queries/getDGAMaxFlow';
import { DGATabProps } from './tabProps';

interface FormValues {
  maxFlow: string;
}

const MaxFlowTab: FC<DGATabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [updateMaxFlow] = useMutation(UPDATE_DGA_MAX_FLOW, {
    onCompleted(data) {
      const success = data?.UpdateMaxFlowConfiguration?.success || false;
      if (success) {
        openModalSuccessMessage(_(msg`Caudal máximo actualizado correctamente`));
      } else {
        openModalErrorMessage(_(msg`No se pudo actualizar el caudal máximo`));
      }
    },
    onError(error) {
      openModalErrorMessage(_(msg`No se pudo actualizar el caudal máximo`) + ' ' + error.message);
    },
  });
  const { data } = useSuspenseQuery(GET_DGA_MAX_FLOW, {
    variables: {
      deviceId,
      deviceIdString: deviceId,
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      maxFlow: data?.DGADeviceConfiguration?.maxFlow?.toString() ?? '',
    },
  });

  useEffect(() => {
    setIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  const onSubmit = async (data: FormValues) => {
    try {
      await updateMaxFlow({
        variables: {
          deviceId,
          maxFlow: parseFloat(data.maxFlow),
        },
      });
    } catch {}
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent sx={{ height: 360 }}>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: '1fr',
            md: '1fr 1fr',
          }}
          gap={2}
        >
          <Controller
            control={methods.control}
            name="maxFlow"
            render={({ field, fieldState }) => (
              <NumericTextField
                {...field}
                label={_(msg`Caudal máximo en L/s`)}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                required
                fullWidth
                autoFocus
              />
            )}
          />
        </Box>
      </DialogContent>
      <Divider />
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        submitLoading={methods.formState.isLoading}
        dirtyForm={methods.formState.isDirty}
        onResetForm={() => methods.reset()}
      />
    </form>
  );
};

export default MaxFlowTab;
