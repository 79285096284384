import { setSnackbar } from '@/slices/components';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { DeviceTabProps } from '@features/deviceDataConfigurationModal/components/tabs/types';
import UPDATE_DEVICE_MATH_MODELING from '@features/deviceDataConfigurationModal/graphql/mutations/updateMathModeling';
import GET_CHART_PREVIEW_DATA from '@features/deviceDataConfigurationModal/graphql/queries/getMathModelingChartPreviewData';
import GET_DEVICE_DATA from '@features/deviceDataConfigurationModal/graphql/queries/getMathModelingDeviceData';
import Echarts, { EChartsOptionBuilder } from '@features/echarts';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import * as math from 'mathjs';
import { useDeferredValue, useEffect, useMemo, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

interface FormData {
  flowModel: {
    expression: string;
  };
}

const MathModelingTab: FC<DeviceTabProps> = (props) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const { data } = useSuspenseQuery(GET_DEVICE_DATA, {
    variables: { input: { id: props.deviceId } },
  });
  const mathModelingData = data.device.dataConfiguration.mathModeling;
  const defaultValues = {
    flowModel: {
      expression: mathModelingData?.flowModel?.expression ?? '',
    },
  };
  const [updateMathModeling] = useMutation(UPDATE_DEVICE_MATH_MODELING, {
    onCompleted: () => {
      dispatch(
        setSnackbar({
          open: true,
          message: _(msg`Dato actualizado correctamente`),
          severity: 'success',
        }),
      );
    },
    onError: (errors) => {
      dispatch(
        setSnackbar({
          open: true,
          message: `${_(msg`Error al actualizar el dato`)}: ${errors.message}`,
          severity: 'error',
        }),
      );
    },
    refetchQueries: [GET_DEVICE_DATA],
  });

  const methods = useForm<FormData>({
    defaultValues,
  });

  useEffect(() => {
    props.setIsDirty?.(methods.formState.isDirty);
  }, [methods.formState.isDirty, props.setIsDirty]);

  const expression = methods.watch('flowModel.expression');
  const sensors =
    data.device.frankieSensors?.flatMap((sensor) => {
      return sensor.fields.map((field) => ({
        deviceId: sensor.deviceId,
        name: field.name,
        metric: field.accessor,
      }));
    }) ?? [];

  const submitHandler = async (data: FormData) => {
    try {
      const scope = sensors.reduce((acc, sensor) => {
        const sensorMetrics = acc[sensor.deviceId] ?? {};
        acc[sensor.deviceId] = {
          ...sensorMetrics,
          [sensor.metric]: 0,
        };
        return acc;
      }, {} as any);
      math.evaluate(data.flowModel.expression, {
        data: scope,
      });
    } catch (e: any) {
      methods.setError('flowModel.expression', {
        type: 'manual',
        message: _(msg`La expresión matemática es inválida: ${e.message}`),
      });
      return;
    }
    await updateMathModeling({
      variables: {
        input: {
          deviceId: props.deviceId,
          mathModeling: {
            flowModel: {
              expression: data.flowModel.expression,
            },
          },
        },
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(submitHandler)}>
      <DialogContent
        sx={{
          maxWidth: 'lg',
          minHeight: 500,
          margin: 'auto',
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <Typography variant="h5" mb={2}>
          <Trans>Modelamientos matemáticos</Trans>
        </Typography>
        <Box display="grid" gridTemplateColumns="minmax(0, 1fr) minmax(0, 1fr)">
          <Box
            borderRight={1}
            borderTop={1}
            borderColor="divider"
            py={2}
            pr={2}
            height={480}
            overflow="auto"
            className="custom-scrollbar"
          >
            <Typography variant="h6">
              <Trans>Modelamiento de caudal</Trans>
            </Typography>
            <Typography>
              <Trans>Expresión matemática para calcular el caudal</Trans>
            </Typography>
            <Typography>
              <Trans>Variables disponibles:</Trans>
            </Typography>
            <TableContainer>
              <Table size="small" padding="checkbox" sx={{ border: 1, borderColor: 'divider' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Trans>ID dispositivo</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Métrica</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Accesor</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sensors.map((sensor) => (
                    <TableRow>
                      <TableCell>{sensor.deviceId}</TableCell>
                      <TableCell>{sensor.name}</TableCell>
                      <TableCell>
                        data["{sensor.deviceId}"].{sensor.metric}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Controller
              name="flowModel.expression"
              control={methods.control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  multiline
                  minRows={12}
                  maxRows={30}
                  label={_(msg`Expresión`)}
                  error={!!error}
                  helperText={error?.message}
                  sx={{ mt: 2 }}
                />
              )}
            />
          </Box>
          <Box borderTop={1} borderColor="divider" py={2} pl={2}>
            <ChartPreview masterId={props.deviceId} sensors={sensors} expression={expression} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ maxWidth: 'lg', margin: 'auto', py: 2 }}>
        <Button
          disabled={!methods.formState.isDirty}
          color="info"
          variant="outlined"
          onClick={() => methods.reset(defaultValues)}
        >
          <Trans>Restablecer Formulario</Trans>
        </Button>
        <Box flexGrow={1} />
        <Button color="info" variant="outlined" onClick={props.onClose}>
          <Trans>Cerrar</Trans>
        </Button>
        <LoadingButton
          disabled={!methods.formState.isDirty}
          loading={methods.formState.isSubmitting}
          type="submit"
          variant="contained"
        >
          <Trans>Guardar</Trans>
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

interface ChartPreviewProps {
  masterId: string;
  sensors: {
    deviceId: string;
    metric: string;
  }[];
  expression: string;
}

const ChartPreview: FC<ChartPreviewProps> = (props) => {
  const { data } = useSuspenseQuery(GET_CHART_PREVIEW_DATA, {
    variables: {
      input: {
        ids: [props.masterId, ...props.sensors.map((sensor) => sensor.deviceId)],
      },
      telemetryInput: {
        start: '-12h',
      },
    },
  });
  const expression = useDeferredValue(props.expression);

  const option = useMemo(() => {
    const option = new EChartsOptionBuilder({
      toolbox: {
        disabled: true,
      },
    });
    option
      .addXAxis({
        id: 'time',
        type: 'time',
      })
      .addYAxis({
        id: 'level',
        type: 'value',
        unit: 'cm',
      })
      .addYAxis({
        id: 'flow',
        type: 'value',
        unit: 'L/s',
      })
      .addYAxis({
        id: 'angle',
        type: 'value',
        unit: '°',
      });

    const telemetryData = {} as {
      [timestamp: number]: {
        data: {
          [deviceId: string]: Record<'level' | 'position' | 'flow', number>;
        };
        result?: number;
      };
    };

    [{ deviceId: props.masterId, metric: 'flow' } as const, ...props.sensors].forEach((sensor) => {
      const device = data.devices.find((device) => device.id === sensor.deviceId);
      const telemetry = device?.telemetry[sensor.metric as 'level' | 'position' | 'flow'] ?? [];
      telemetry.forEach((data) => {
        const [timestamp, value] = data;
        telemetryData[timestamp] = {
          ...(telemetryData[timestamp] ?? {}),
          data: {
            ...(telemetryData[timestamp]?.data ?? {}),
            [sensor.deviceId]: {
              ...(telemetryData[timestamp]?.data?.[sensor.deviceId] ?? {}),
              [sensor.metric]: value,
            },
          },
        };
      });
    });

    const dimensions = [] as {
      name: string;
      type: 'time' | 'float';
    }[];

    const source = Object.entries(telemetryData).reduce(
      (acc, [timestamp, { data }]) => {
        if (acc.length === 0) {
          dimensions.push({ name: 'timestamp', type: 'time' });
          acc.push([]);
          dimensions.push({ name: 'result-flow', type: 'float' });
          acc.push([]);
          Object.entries(data).forEach(([deviceId, metrics]) => {
            Object.keys(metrics).forEach((metric) => {
              acc.push([]);
              dimensions.push({ name: `${deviceId}-${metric}`, type: 'float' });
            });
          });
        }
        acc[0].push(Number(timestamp));
        let i = 2;
        Object.entries(data).forEach(([_, metrics]) => {
          Object.values(metrics).forEach((value) => {
            acc[i].push(value);
            i++;
          });
        });
        return acc;
      },
      [] as (number | string)[][],
    );

    try {
      const scopes = Object.entries(telemetryData);
      scopes.forEach(([timestampString, scope]) => {
        const timestamp = Number(timestampString);
        math.evaluate(expression, scope);
        source[1].push(scope.result as number);
        return [timestamp, scope.result];
      });
    } catch (e) {
      console.error(e);
    }

    option.setInitOption((base) => ({
      ...base,
      override(option) {
        return {
          ...option,
          dataset: {
            source,
            dimensions,
          },
        };
      },
    }));

    option.addSeries(
      dimensions.slice(1).map((dimension) => {
        const [deviceId, metric] = dimension!.name.split('-');
        return {
          meta: {
            name: `${deviceId} - ${metric}`,
            dataType: metric,
            type: 'line',
            xAxisId: 'time',
            yAxisId: metric === 'flow' ? 'flow' : metric === 'angle' ? 'angle' : 'level',
          },
          value(value) {
            return {
              ...value,
              symbol: 'circle',
              encode: {
                x: 'timestamp',
                y: dimension.name,
              },
              seriesLayoutBy: 'row',
            };
          },
        };
      }),
    );

    return option;
  }, [data, props.sensors, expression]);

  return (
    <Box height={300}>
      <Typography variant="h6">
        <Trans>Previsualización</Trans>
      </Typography>
      <Echarts option={option} renderer="canvas" />
    </Box>
  );
};

export default MathModelingTab;
