import { DeviceAlertComparisonType, MetricField, MetricUnit } from 'src/__generated__/graphql';
import { z } from 'zod';
import { DeviceInfo } from '../graphql/queries/getDeviceInfo';
import { AlertState } from '../utils/alertStates';

type AlertStateConfig =
  | {
      eventName: string;
      comparisonType: DeviceAlertComparisonType.Above | DeviceAlertComparisonType.Below;
      thresholdValue: number;
    }
  | {
      eventName: string;
      comparisonType:
        | DeviceAlertComparisonType.InsideRange
        | DeviceAlertComparisonType.OutsideRange;
      thresholdMin: number;
      thresholdMax: number;
    };

type FormProps = {
  selectedAlerts: AlertState[];
  ok: AlertStateConfig;
  warning: AlertStateConfig;
  critical: AlertStateConfig;
  unit: MetricUnit;
};

const schemaAlertStateConfig = z
  .object({
    eventName: z.string().min(1),
    comparisonType: z.nativeEnum(DeviceAlertComparisonType),
    thresholdValue: z.number().nullish(),
    thresholdMin: z.number().nullish(),
    thresholdMax: z.number().nullish(),
  })
  .refine((data) => {
    if (
      [DeviceAlertComparisonType.Above, DeviceAlertComparisonType.Below].includes(
        data.comparisonType,
      )
    ) {
      return data.thresholdValue != null;
    }
    if (
      [DeviceAlertComparisonType.InsideRange, DeviceAlertComparisonType.OutsideRange].includes(
        data.comparisonType,
      )
    ) {
      return (
        data.thresholdMin != null &&
        data.thresholdMax != null &&
        data.thresholdMin <= data.thresholdMax
      );
    }
    return false;
  });

function getSchema() {
  const baseSchema = z.object({
    unit: z.nativeEnum(MetricUnit),
    selectedAlerts: z.array(z.nativeEnum(AlertState)).nonempty(),
  });
  const criticalSchema = baseSchema
    .extend({
      critical: schemaAlertStateConfig,
    })
    .refine((i) => i.selectedAlerts.includes(AlertState.CRITICAL))
    .or(baseSchema.refine((i) => !i.selectedAlerts.includes(AlertState.CRITICAL)));

  const warningSchema = baseSchema
    .extend({
      warning: schemaAlertStateConfig,
    })
    .refine((i) => i.selectedAlerts.includes(AlertState.WARNING))
    .or(baseSchema.refine((i) => !i.selectedAlerts.includes(AlertState.WARNING)));

  const okSchema = baseSchema
    .extend({
      ok: schemaAlertStateConfig,
    })
    .refine((i) => i.selectedAlerts.includes(AlertState.OK))
    .or(baseSchema.refine((i) => !i.selectedAlerts.includes(AlertState.OK)));

  return okSchema.and(warningSchema).and(criticalSchema);
}

function getDefaultValues(
  device: DeviceInfo | undefined,
  metric: MetricField | undefined,
): FormProps {
  const defaultAlertStateConfig = {
    eventName: '',
    comparisonType: DeviceAlertComparisonType.Above,
    thresholdValue: 0,
  };
  const emptyDefaultValues = {
    selectedAlerts: [AlertState.OK],
    unit: MetricField.Level,
    ok: defaultAlertStateConfig,
    warning: defaultAlertStateConfig,
    critical: defaultAlertStateConfig,
  };
  if (device == null || metric == null) {
    return emptyDefaultValues;
  }

  const alertConfig = device.alerts.configs.find((i) => i.metric === metric);
  if (alertConfig == null) {
    return emptyDefaultValues;
  }

  const selectedAlerts: AlertState[] = [];
  const currUnitsArr: MetricUnit[] = [];

  const getConfig = (alert: AlertState) => {
    const alertStringMap: Record<AlertState, 'ok' | 'warning' | 'critical'> = {
      [AlertState.OK]: 'ok',
      [AlertState.WARNING]: 'warning',
      [AlertState.CRITICAL]: 'critical',
    };
    const alertString = alertStringMap[alert];
    const config = alertConfig[alertString];
    if (config == null) {
      return defaultAlertStateConfig;
    }
    selectedAlerts.push(alert);
    currUnitsArr.push(config.unit!);
    return {
      eventName: config.eventName ?? '',
      comparisonType: config.comparisonType!,
      thresholdValue: config.thresholdValue ?? 0,
      thresholdMin: config.thresholdMin ?? 0,
      thresholdMax: config.thresholdMax ?? 0,
    };
  };

  const okDefaultConfig = getConfig(AlertState.OK);
  const warningDefaultConfig = getConfig(AlertState.WARNING);
  const criticalDefaultConfig = getConfig(AlertState.CRITICAL);

  const unit = currUnitsArr[0];
  const areAllUnitsEqual = currUnitsArr.reduce((p, c) => unit === c && p, true);
  if (!areAllUnitsEqual) {
    throw new Error('Alert config has non equal units');
  }
  return {
    ok: okDefaultConfig,
    warning: warningDefaultConfig,
    critical: criticalDefaultConfig,
    selectedAlerts,
    unit,
  };
}

export { getDefaultValues, getSchema };
export type { FormProps };
