import {
  Autocomplete,
  Box,
  type BoxProps,
  Pagination,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { type FC } from 'react';

interface Props extends BoxProps {
  totalItems: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
}

const StandardListPagination: FC<Props> = ({
  totalItems,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  ...boxProps
}) => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box display="flex" justifyContent="end" alignItems="center" gap={2} {...boxProps}>
      <Autocomplete
        size="small"
        options={[5, 10, 15, 20, 30, 50]}
        value={rowsPerPage}
        disableClearable
        getOptionLabel={(option) => option.toString()}
        renderInput={(params) => <TextField {...params} label="Filas" />}
        onChange={(_, value) => {
          onPageChange(1);
          onRowsPerPageChange(value);
        }}
      />
      <Pagination
        size={upMd ? 'large' : 'small'}
        variant="text"
        showFirstButton={upMd}
        showLastButton={upMd}
        siblingCount={upMd ? 1 : 0}
        count={Math.ceil(totalItems / rowsPerPage)}
        page={page}
        onChange={(_, page) => onPageChange(page)}
      />
    </Box>
  );
};

export default StandardListPagination;
