import { useQuery, useSuspenseQuery } from '@apollo/client';
import STANDARD_AUTOCOMPLETE_DATA from '../queries/getStandardAutocompleteData';
import {
  DevicesInput,
  DevicesOrderBy,
  InputMaybe,
  OrganizationsInput,
  UsersInput,
} from '@/__generated__/graphql';

export const useAutocompleteOptions = (
  dataType:
    | 'devices'
    | 'irrigations'
    | 'organizations'
    | 'users'
    | 'projects'
    | 'currencies'
    | 'billingServices'
    | 'serviceTypes',
  queryOptions?: {
    includeGates?: boolean;
    withDgaDevices?: boolean;
    projectId?: string;
  },
) => {
  return useQuery(STANDARD_AUTOCOMPLETE_DATA, {
    variables: {
      includeOrganizations: dataType === 'organizations',
      includeDevices: dataType === 'devices',
      devicesInput: {
        orderBy: DevicesOrderBy.IdAsc,
        includeGates: queryOptions?.includeGates,
        project: queryOptions?.projectId,
      },
      organizationsInput: {
        withDgaDevices: queryOptions?.withDgaDevices,
      },
      includeIrrigations: dataType === 'irrigations',
      includeUsers: dataType === 'users',
      includeProjects: dataType === 'projects',
      includeCurrencies: dataType === 'currencies',
      includeServiceTypes: dataType === 'serviceTypes',
    },
  });
};

export const useSuspenseAutocompleteOptions = (
  dataType:
    | 'devices'
    | 'irrigations'
    | 'organizations'
    | 'users'
    | 'projects'
    | 'currencies'
    | 'serviceTypes',
  queryOptions?: {
    includeGates?: boolean;
    withDgaDevices?: boolean;
    users?: InputMaybe<UsersInput> | undefined;
    projectId?: string;
    projects?: {
      organizationId: number;
    };
    devices?: InputMaybe<DevicesInput> | undefined;
    organizations?: InputMaybe<OrganizationsInput> | undefined;
  },
) => {
  return useSuspenseQuery(STANDARD_AUTOCOMPLETE_DATA, {
    variables: {
      includeOrganizations: dataType === 'organizations',
      includeDevices: dataType === 'devices',
      includeIrrigations: dataType === 'irrigations',
      includeUsers: dataType === 'users',
      includeProjects: dataType === 'projects',
      includeCurrencies: dataType === 'currencies',
      includeServiceTypes: dataType === 'serviceTypes',

      devicesInput: {
        orderBy: DevicesOrderBy.IdAsc,
        includeGates: queryOptions?.includeGates,
        project: queryOptions?.projectId,
        ...queryOptions?.devices,
      },
      organizationsInput: {
        withDgaDevices: queryOptions?.withDgaDevices,
        ...queryOptions?.organizations,
      },
      usersInput: {
        ...queryOptions?.users,
      },
      projectsInput: {
        organizationId: queryOptions?.projects?.organizationId,
      },
    },
  });
};

export const useSuspenseAutocompleteSpecificValues = (
  dataType:
    | 'devices'
    | 'irrigations'
    | 'organizations'
    | 'users'
    | 'projects'
    | 'currencies'
    | 'serviceTypes',
  value: string | number | string[] | number[] | (number | string)[] | null,
) => {
  const getIds = <T extends 'string' | 'number'>(entity: typeof dataType, target: T) => {
    type EntityId = T extends 'string' ? string : number;
    let entityIds: EntityId[] | null = null;
    if (dataType === entity) {
      if (typeof value === target) entityIds = [value as EntityId];
      if (Array.isArray(value))
        entityIds = value.filter((id) => typeof id === target) as EntityId[];
    }
    return entityIds;
  };

  let deviceIds = getIds('devices', 'string');
  let userIds = getIds('users', 'number');
  let organizationIds = getIds('organizations', 'number');

  return useSuspenseQuery(STANDARD_AUTOCOMPLETE_DATA, {
    variables: {
      includeOrganizations: dataType === 'organizations',
      includeDevices: dataType === 'devices',
      includeIrrigations: dataType === 'irrigations',
      includeUsers: dataType === 'users',
      includeProjects: dataType === 'projects',
      includeCurrencies: dataType === 'currencies',
      includeServiceTypes: dataType === 'serviceTypes',

      devicesInput: { ids: deviceIds },
      organizationsInput: { ids: organizationIds },
      usersInput: { ids: userIds },
    },
  });
};
