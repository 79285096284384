import { gql } from '@/__generated__';

const UPDATE_BASIC_DEVICE_ALERT_CONFIG = gql(`#graphql
  mutation UpdateBasicDeviceAlertConfig(
    $deviceId: ID!
    $alertType: DeviceAlertMetricName!
    $min: Float!
    $max: Float!
    $overflow: Float!
    $unit: MetricUnit!
  ) {
    updateBasicAlertConfiguration(
      input: {
        deviceId: $deviceId
        alertType: $alertType
        min: $min
        max: $max
        overflow: $overflow
        unit: $unit
      }
    ) {
      id
    }
  }
`);

export default UPDATE_BASIC_DEVICE_ALERT_CONFIG;
