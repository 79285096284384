import { createSvgIcon } from '@mui/material';

const SensorFlujometroNoInvasivo = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M150.25,1.9c47.76,0,95.52,0,143.27,0c0.6,0,1.2-0.01,1.8,0.03c1.65,0.13,2.91,1.26,3.23,2.89
		c0.08,0.39,0.11,0.79,0.14,1.19c0.02,0.32,0.01,0.64,0.01,0.96c0,95.56,0,191.11-0.01,286.67c0,0.72-0.02,1.45-0.14,2.15
		c-0.3,1.66-1.65,2.82-3.33,2.94c-0.36,0.03-0.72,0.01-1.08,0.01c-95.96,0-191.91,0-287.87,0c-0.24,0-0.48,0-0.72,0
		c-2.12-0.05-3.62-1.47-3.76-3.58c-0.02-0.32-0.01-0.64-0.01-0.96c0-95.92,0-191.83,0-287.75c0-0.32-0.01-0.64,0.01-0.96
		C1.93,3.4,3.34,2.01,5.42,1.91C5.98,1.88,6.54,1.9,7.1,1.9C54.82,1.9,102.53,1.9,150.25,1.9z M9.31,291.28
		c1.79,0.23,281.01,0.15,281.87-0.08c0.24-1.17,0.21-280.87-0.05-281.87c-93.92,0-187.87,0-281.79,0
		C9.09,10.33,9.06,290.03,9.31,291.28z"
    />
    <path
      d="M169.98,99.05c0,2.93,0,5.68,0,8.5c0.27,0.06,0.53,0.15,0.79,0.17c0.32,0.03,0.64,0.01,0.96,0.01
		c20.08,0,40.16,0,60.24,0c0.55,0,1.1-0.05,1.73-0.08c0.02-0.53,0.06-0.96,0.06-1.39c0.01-2.8,0-5.6,0-8.4
		c0.01-2.66,1.36-4.06,4.06-4.09c3.88-0.03,7.76-0.01,11.64-0.01c2.56,0,5.12-0.02,7.68,0.01c0.67,0.01,1.38,0.06,2,0.28
		c1.47,0.52,2.17,1.69,2.34,3.2c0.04,0.4,0.01,0.8,0.01,1.2c0,34.92,0,69.84-0.01,104.75c0,0.67-0.02,1.37-0.18,2.02
		c-0.36,1.46-1.6,2.46-3.1,2.6c-0.4,0.04-0.8,0.03-1.2,0.03c-6.28,0-12.56,0-18.84,0c-0.48,0-0.96,0.01-1.44-0.06
		c-1.7-0.26-2.94-1.71-2.96-3.54c-0.03-2.84-0.01-5.68-0.01-8.52c0-0.43,0-0.85,0-1.36c-21.34,0-42.51,0-63.77,0
		c0,2.85,0,5.62,0,8.55c0.64-0.04,1.22-0.06,1.8-0.13c3.58-0.41,7.18-0.5,10.78-0.43c7.79,0.17,15.45,1.26,22.98,3.23
		c3.63,0.95,7.23,2.02,10.81,3.16c3.68,1.17,7.05,3.02,10.25,5.16c3.13,2.09,6.43,3.88,9.85,5.46c5.05,2.33,10.36,3.49,15.9,3.66
		c3.56,0.11,7.11-0.04,10.63-0.71c0.72-0.14,1.54-0.08,2.24,0.13c1.52,0.46,2.39,1.87,2.36,3.52c-0.03,1.6-1,2.98-2.56,3.38
		c-1.31,0.34-2.66,0.56-4.01,0.71c-11.06,1.23-21.6-0.42-31.43-5.85c-1.99-1.1-4.03-2.15-5.9-3.45c-4.8-3.34-10.1-5.45-15.7-6.95
		c-5.1-1.36-10.22-2.61-15.46-3.3c-7.08-0.94-14.18-1.12-21.29-0.28c-0.24,0.03-0.47,0.08-0.71,0.13c-0.15,0.03-0.3,0.09-0.62,0.19
		c0,0.44,0,0.9,0,1.37c0,4.36,0,8.72,0,13.08c0,0.4,0.01,0.8-0.02,1.2c-0.13,1.59-1.22,2.86-2.78,3.21
		c-0.54,0.12-1.11,0.15-1.66,0.15c-7.16,0.01-14.32,0.01-21.48,0.01c-0.32,0-0.64,0.01-0.96-0.02c-2.08-0.23-3.41-1.64-3.49-3.74
		c-0.03-0.63,0-1.27,0-1.87c-0.92-0.31-11.79-0.4-13.46-0.12c-0.02,0.55-0.06,1.13-0.07,1.71c-0.03,2.48-1.45,4.01-3.92,4.02
		c-7.52,0.03-15.04,0.03-22.56,0c-2.45-0.01-3.91-1.57-3.92-4.04c-0.01-9.84,0-19.68,0-29.52c0-0.51,0-1.02,0-1.62
		c-9.74,0-19.34,0-29.11,0c0,0.5,0,0.93,0,1.36c0,2.8,0,5.6,0,8.4c0,1.44-0.6,2.58-1.88,3.24c-0.54,0.28-1.22,0.45-1.83,0.45
		c-6.76,0.03-13.52,0.04-20.28,0.01c-2.26-0.01-3.7-1.53-3.76-3.81c-0.01-0.28,0-0.56,0-0.84c0-34.92,0-69.84,0.01-104.75
		c0-0.63,0.01-1.29,0.15-1.9c0.36-1.54,1.54-2.55,3.12-2.72c0.48-0.05,0.96-0.06,1.44-0.06c6.12,0,12.24,0,18.36,0
		c0.32,0,0.64,0,0.96,0.02c2.31,0.14,3.68,1.54,3.7,3.85c0.03,2.88,0,5.76,0.01,8.64c0,0.43,0.04,0.86,0.06,1.25
		c1.02,0.26,27.54,0.3,28.97,0.05c0.02-0.42,0.07-0.89,0.07-1.35c0-9.76,0-19.52,0-29.28c0-0.4-0.02-0.8,0.02-1.2
		c0.2-1.82,1.58-3.14,3.41-3.27c0.36-0.03,0.72-0.02,1.08-0.02c7.12,0,14.24,0,21.36,0c0.32,0,0.64,0,0.96,0.01
		c2.07,0.11,3.5,1.55,3.55,3.62c0.02,0.67,0,1.34,0,1.97c1.06,0.27,12.18,0.3,13.53,0.04c0-0.51,0.02-1.06,0-1.6
		c-0.09-2.19,1.44-4.08,4.03-4.06c7.48,0.05,14.96,0.05,22.44,0c2.49-0.02,4.01,1.88,3.97,3.98c-0.08,4.52-0.02,9.04-0.02,13.56
		c0,0.47,0,0.95,0,1.55c1.16,0.15,2.22,0.32,3.28,0.41c5.07,0.46,10.15,0.46,15.21-0.02c9.2-0.87,18.12-2.97,26.8-6.12
		c2.57-0.93,4.93-2.29,7.2-3.8c3.64-2.42,7.45-4.51,11.44-6.3c4.94-2.21,10.1-3.48,15.49-3.95c4.73-0.41,9.42-0.25,14.1,0.52
		c0.39,0.06,0.79,0.14,1.18,0.23c2.13,0.5,3.32,2.29,2.93,4.38c-0.37,1.95-2.25,3.19-4.34,2.81c-6.9-1.24-13.77-1.12-20.57,0.67
		c-4.41,1.16-8.43,3.21-12.37,5.44c-0.94,0.53-1.87,1.08-2.75,1.7c-5.07,3.55-10.7,5.86-16.64,7.5c-5.71,1.57-11.45,2.97-17.32,3.76
		c-6.48,0.86-12.98,1.11-19.51,0.62C172.74,99.28,171.43,99.17,169.98,99.05z M233.68,115.26c-0.19-0.06-0.3-0.13-0.41-0.13
		c-55.23-0.01-110.45-0.02-165.68-0.02c-0.28,0-0.55,0.05-0.83,0.08c-0.04,0-0.07,0.03-0.1,0.06c-0.03,0.03-0.05,0.06-0.09,0.11
		c0,23.79,0,47.6,0,71.59c0.67,0,1.26,0,1.85,0c54.47,0,108.93,0,163.4,0c0.32,0,0.64,0.02,0.96-0.01c0.3-0.02,0.6-0.09,0.9-0.14
		C233.68,162.87,233.68,139.06,233.68,115.26z M59.05,200.43c0-33.09,0-66.11,0-99.27c-1.1-0.02-2.17-0.04-3.24-0.05
		c-1.08-0.01-2.16,0-3.24,0c-1.08,0-2.16-0.02-3.24,0.01c-1.06,0.03-2.13-0.15-3.18,0.14c-0.03,0.3-0.07,0.5-0.07,0.69
		c0,32.64,0,65.28,0.01,97.92c0,0.12,0.03,0.24,0.06,0.35c0.01,0.04,0.05,0.07,0.07,0.1c0.03,0.03,0.05,0.06,0.11,0.11
		C50.52,200.43,54.75,200.43,59.05,200.43z M241.19,101.2c0,33.14,0,66.15,0,99.14c1.34,0.26,11.93,0.22,12.86-0.05
		c0-32.99,0-66,0-99.09C249.74,101.2,245.53,101.2,241.19,101.2z M118.55,107.66c0-9.34,0-18.52,0-27.76c-5.21,0-10.34,0-15.45,0
		c-0.27,1.46-0.21,26.84,0.07,27.76C108.24,107.66,113.34,107.66,118.55,107.66z M162.48,107.65c0-9.28,0-18.48,0-27.67
		c-1.48-0.29-14.71-0.22-15.55,0.07c0,9.17,0,18.34,0,27.59C152.14,107.65,157.27,107.65,162.48,107.65z M118.56,194.41
		c-2.03-0.27-14.7-0.16-15.54,0.11c0,9.17,0,18.35,0,27.5c1.19,0.28,14.53,0.25,15.54-0.02
		C118.56,212.84,118.56,203.66,118.56,194.41z M146.94,194.38c0,9.28,0,18.48,0,27.75c5.22,0,10.35,0,15.54,0
		c0-9.29,0-18.49,0-27.75C157.27,194.38,152.14,194.38,146.94,194.38z M126.12,85.7c-0.24,1.48-0.19,20.99,0.04,21.88
		c1.22,0.26,12.33,0.21,13.25-0.06c0-7.25,0-14.5,0-21.82C134.94,85.7,130.56,85.7,126.12,85.7z M126.02,216.17
		c1.38,0.25,12.47,0.2,13.36-0.03c0.25-1.58,0.17-20.92-0.09-21.75c-2.18-0.25-12.42-0.14-13.27,0.16
		C126.02,201.72,126.02,208.91,126.02,216.17z"
    />
  </svg>,
  'SensorFlujometroNoInvasivo',
);

export default SensorFlujometroNoInvasivo;
