import { gql } from '@/__generated__';

export const GET_UPDATE_DEVICE_STATUS_FIELDS = gql(`#graphql
query GetUpdateDeviceStatusFields($deviceId: ID!) {
  device(input: { id: $deviceId }) {
    id
    profile {
      id
      status: status2
    }
  }
}`);
