import { gql } from '@/__generated__';

const GET_DEVICE_INTEGRATION_GROUPED_ISSUES_COUNT = gql(`#graphql
  query GetDeviceIntegrationGroupedIssuesCount($deviceIntegrationId: Int!, $severity: WriteExecutionLogSeverity!) {
    deviceIntegrationRelation(input: { id: $deviceIntegrationId }) {
      id
      writeExecutionLogGroupedIssues(input: { severities: [$severity] }) {
        id
        pageInfo {
          totalCount
        }
      }
    }
  }
`);

export default GET_DEVICE_INTEGRATION_GROUPED_ISSUES_COUNT;
