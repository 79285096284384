import { gql } from '@/__generated__';

export const GET_DEVICE_NAME = gql(`#graphql
  query GetDeviceName($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        name
      }
    }
  }
`);
