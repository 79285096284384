import { useModalStatusMessage } from '@/hooks';
import { useGlobalModal } from '@/hooks/useGlobalModal';
import { setUpdateDGAConfigurationModal } from '@/slices/modals';
import { useDispatch } from '@/store';
import { useMutation } from '@apollo/client';
import GlobalModal from '@components/GlobalModal';
import ModalTitle from '@components/modal/ModalTitle';
import { Access } from '@features/authorization';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Skeleton } from '@mui/material';
import { FormEventHandler, type FC } from 'react';
import DELETE_DGA_CONFIGURATION from '../../graphql/mutations/deleteDgaConfiguration';

interface Props {
  onClose: () => void;
  deviceId: string;
}

const Component: FC<Props> = ({ onClose, deviceId }) => {
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const dispatch = useDispatch();

  const [deleteDgaConfiguration, { loading }] = useMutation(DELETE_DGA_CONFIGURATION, {
    variables: { deviceId },
    onError: (error) => {
      openModalErrorMessage(error.message);
    },
    onCompleted: () => {
      openModalSuccessMessage(_(msg`Configuración DGA eliminada correctamente`));
      onClose();
      onSuccesfulDelete();
    },
    update(cache) {
      cache.modify({
        fields: {
          devicesConnection(_, { INVALIDATE }) {
            return INVALIDATE;
          },
          devices(_, { INVALIDATE }) {
            return INVALIDATE;
          },
        },
      });
      cache.modify({
        id: cache.identify({ __typename: 'Device', id: deviceId }),
        fields: {
          dga(_, { DELETE }) {
            return DELETE;
          },
          dgaConfiguration(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
    awaitRefetchQueries: true,
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
  });

  const onSuccesfulDelete = () => {
    dispatch(setUpdateDGAConfigurationModal({ open: false }));
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    deleteDgaConfiguration();
  };

  return (
    <form onSubmit={onSubmit}>
      <DialogContent dividers>
        <Trans>
          ¿Estás seguro que deseas eliminar la configuración DGA para el dispositivo {deviceId}?
        </Trans>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={onClose}>
          <Trans>Cancelar</Trans>
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="error"
          onClick={() => deleteDgaConfiguration()}
        >
          <Trans>Confirmar</Trans>
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { _ } = useLingui();
  return (
    <DialogContent dividers>
      <ModalTitle title={_(msg`Eliminar configuración DGA`)} onClose={onClose} />
      <Skeleton height={100} variant="rounded" />
    </DialogContent>
  );
};

const DeleteDGAConfigurationModal: FC = () => {
  const { _ } = useLingui();
  const { globalModalProps, state, onClose } = useGlobalModal('deleteDGAConfigurationModal');

  return (
    <GlobalModal
      {...globalModalProps}
      dialogProps={{ maxWidth: 'sm' }}
      authorization={{
        access: Access.DeleteDGAConfiguration,
        deviceId: state.open ? state.deviceId : undefined,
        onClose,
      }}
      suspenseFallback={<Fallback onClose={onClose} />}
    >
      {state.open && (
        <>
          <ModalTitle title={_(msg`Eliminar configuración DGA`)} onClose={onClose} />
          <Component deviceId={state.deviceId} onClose={onClose} />
        </>
      )}
    </GlobalModal>
  );
};

export default DeleteDGAConfigurationModal;
