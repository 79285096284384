import { gql } from '@/__generated__';

const HAS_DGA_CONFIGURATION_DATA = gql(`#graphql
  query HasDGAConfiguration($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      dga {
        id
      }
    }
  }
`);

export default HAS_DGA_CONFIGURATION_DATA;
