import { createSvgIcon } from '@mui/material';

const SensorFlujometroInvasivo = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M150.27,1.9c47.72,0,95.44,0,143.15,0c0.64,0,1.28-0.02,1.92,0.04c1.6,0.13,2.86,1.23,3.19,2.79
		c0.09,0.43,0.12,0.87,0.16,1.31c0.02,0.32,0.01,0.64,0.01,0.96c0,95.52,0,191.03-0.01,286.55c0,0.76-0.02,1.53-0.15,2.27
		c-0.3,1.65-1.66,2.81-3.34,2.93c-0.36,0.02-0.72,0.01-1.08,0.01c-95.92,0-191.83,0-287.75,0c-0.28,0-0.56,0.01-0.84,0
		c-2.12-0.06-3.61-1.49-3.74-3.6c-0.02-0.32-0.01-0.64-0.01-0.96c0-95.92,0-191.83,0-287.75c0-0.32-0.01-0.64,0.01-0.96
		C1.94,3.39,3.35,2,5.44,1.91C5.99,1.88,6.56,1.9,7.12,1.9C54.83,1.9,102.55,1.9,150.27,1.9z M9.14,291.26
		c1.96,0.26,281.18,0.17,282.04-0.06c0.24-1.17,0.21-280.87-0.05-281.87c-93.92,0-187.87,0-281.99,0
		C9.14,103.33,9.14,197.26,9.14,291.26z"
    />
    <path
      d="M167.22,152.16c0.62,0,1.08,0,1.55,0c3.6,0,7.2-0.01,10.8,0.01c1.84,0.01,3.21,1.05,3.63,2.73
		c0.11,0.46,0.11,0.95,0.11,1.43c0.01,4.56,0,9.12,0,13.68c0,0.47,0,0.95,0,1.39c0.92,0.29,43.47,0.39,45.26,0.12
		c0.03-0.44,0.07-0.91,0.07-1.38c0.01-2.48-0.02-4.96,0.02-7.44c0.03-1.88,1.04-3.2,2.72-3.64c0.46-0.12,0.95-0.13,1.42-0.13
		c5.96-0.01,11.92,0.05,17.88-0.03c2.36-0.03,4.16,1.63,4.15,4.2c-0.05,15.96-0.03,31.92-0.03,47.88c0,2.2-0.06,4.4,0.02,6.6
		c0.07,1.93-1.32,4.01-3.98,3.99c-6.08-0.05-12.16-0.05-18.24,0c-2.24,0.02-4.02-1.55-3.97-3.98c0.05-2.36,0.01-4.72,0-7.08
		c0-0.43-0.04-0.86-0.06-1.26c-1.09-0.28-44.07-0.29-45.27,0c0,0.43,0,0.9,0,1.36c0,4.6,0,9.2,0,13.8c0,0.4,0.01,0.81-0.06,1.2
		c-0.29,1.55-1.54,2.69-3.12,2.88c-0.44,0.05-0.88,0.05-1.32,0.05c-20.84,0-41.68,0-62.52,0c-0.48,0-0.96,0-1.44-0.07
		c-1.49-0.2-2.67-1.26-2.98-2.73c-0.12-0.58-0.16-1.19-0.17-1.78c-0.01-4.56-0.01-9.12-0.01-13.68c0-0.43,0-0.87,0-1.26
		c-0.98-0.26-39.82-0.34-41.47-0.09c-0.03,0.44-0.08,0.9-0.08,1.37c-0.01,2.4-0.07,4.8,0.01,7.2c0.09,2.45-1.68,4.09-4,4.07
		c-6.04-0.06-12.08-0.06-18.12,0c-2.62,0.03-4.08-1.86-4.08-4.14c0.03-18.12,0.03-36.24,0-54.36c0-2.58,1.82-4.2,4.19-4.17
		c6,0.09,12,0.04,18,0.02c1.18,0,2.21,0.31,3.03,1.18c0.73,0.77,0.97,1.71,0.97,2.75c-0.01,2.36,0,4.72,0,7.08c0,0.43,0,0.87,0,1.26
		c0.99,0.29,39.2,0.4,41.49,0.14c0.02-0.43,0.06-0.89,0.07-1.35c0.01-4.4,0-8.8,0.01-13.2c0-0.52,0.03-1.04,0.12-1.55
		c0.32-1.8,1.65-2.96,3.48-3.04c0.4-0.02,0.8-0.01,1.2-0.01c3.64,0,7.28,0,10.92,0c0.43,0,0.86-0.04,1.38-0.06
		c0.03-0.48,0.08-0.87,0.08-1.25c0.01-2.84-0.03-5.68,0.02-8.52c0.02-0.9-0.32-1.32-1.1-1.72c-12.61-6.49-20.82-16.6-23.95-30.44
		c-5.6-24.74,9.89-48.06,33.22-53.72c24.36-5.91,48.65,8.91,54.51,33.28c4.81,20.02-4.45,40.52-22.58,50.19
		c-1.42,0.76-1.91,1.55-1.82,3.11c0.14,2.55,0.04,5.12,0.04,7.68C167.22,151.14,167.22,151.57,167.22,152.16z M185.5,100.19
		c-0.19-21.95-18.16-37.84-38-37.67c-20.61,0.17-37.45,17.02-37.42,37.74c0.04,20.83,16.94,37.71,37.73,37.67
		C168.37,137.89,185.38,121.37,185.5,100.19z M119.27,159.59c-0.31,1.17-0.28,60.58,0.02,61.48c1.3,0.24,55.64,0.19,56.6-0.06
		c0-20.44,0-40.89,0-61.42C156.95,159.59,138.1,159.59,119.27,159.59z M228.45,179.11c-1.59-0.24-44.2-0.16-45.03,0.09
		c-0.22,1.51-0.15,21.5,0.08,22.36c1.52,0.24,44.18,0.16,45.02-0.08C228.76,200.09,228.7,180.01,228.45,179.11z M70.22,201.37
		c13.86,0,27.6,0,41.48,0c0-2.58,0-5.08,0-7.59c0-2.48,0.02-4.96-0.01-7.44c-0.03-2.49,0.11-4.99-0.08-7.46
		c-2.16-0.25-40.46-0.15-41.4,0.11C70.22,186.39,70.22,193.81,70.22,201.37z M51.42,166.35c0,15.98,0,31.83,0,47.67
		c1.28,0.26,10.38,0.22,11.31-0.04c0-15.84,0-31.7,0-47.63C58.94,166.35,55.24,166.35,51.42,166.35z M247.29,214.11
		c0.28-1.33,0.22-46.86-0.05-47.75c-3.69,0-7.39,0-11.07,0c-0.3,1.31-0.25,46.82,0.05,47.75
		C239.91,214.11,243.61,214.11,247.29,214.11z M159.78,143.85c-2.69,0.43-5.27,1-7.88,1.22c-2.58,0.21-5.2,0.22-7.78,0.03
		c-2.6-0.18-5.17-0.71-7.81-1.09c0,2.7,0,5.35,0,8.07c7.85,0,15.62,0,23.46,0C159.78,149.3,159.78,146.6,159.78,143.85z"
    />
    <path
      d="M241.82,95.96c-0.22,14.29-5.5,26.33-16.15,35.94c-1.11,1-2.33,1.53-3.84,1.09c-2.62-0.76-3.54-3.93-1.73-5.97
		c0.32-0.36,0.7-0.66,1.05-0.98c7.16-6.56,11.63-14.61,12.9-24.25c1.89-14.25-2.57-26.3-13.09-36.1c-0.29-0.27-0.61-0.53-0.87-0.82
		c-1.26-1.42-1.23-3.54,0.04-4.92c1.22-1.33,3.34-1.61,4.8-0.51c0.96,0.72,1.83,1.55,2.68,2.39c7.7,7.68,12.34,16.9,13.82,27.69
		c0.16,1.19,0.3,2.38,0.38,3.57C241.87,94.03,241.82,95,241.82,95.96z"
    />
    <path
      d="M227.49,95.73c-0.24,10.07-3.8,18.43-11.07,25.14c-0.57,0.53-1.28,1-2.01,1.24c-1.6,0.52-3.28-0.22-4.12-1.65
		c-0.82-1.38-0.65-3.14,0.46-4.34c0.49-0.53,1.05-0.99,1.55-1.5c4.42-4.44,7.03-9.76,7.61-16.01c0.82-8.78-2.09-16.18-8.51-22.2
		c-0.88-0.83-1.57-1.7-1.6-2.96c-0.04-1.44,0.54-2.58,1.78-3.31c1.28-0.76,2.59-0.72,3.86,0.06c0.34,0.21,0.64,0.48,0.93,0.75
		c6.41,5.92,10.08,13.2,10.9,21.9C227.38,93.89,227.44,94.92,227.49,95.73z"
    />
    <path
      d="M213.99,95.89c-0.13,5.84-2.17,10.85-6.34,14.96c-0.77,0.76-1.63,1.28-2.74,1.29
		c-1.55,0.02-2.74-0.62-3.41-2.01c-0.68-1.41-0.51-2.8,0.49-4.04c0.2-0.25,0.45-0.45,0.67-0.68c5.28-5.32,5.28-13.66-0.05-18.96
		c-0.89-0.89-1.53-1.82-1.49-3.13c0.04-1.41,0.67-2.46,1.88-3.13c1.21-0.68,2.46-0.64,3.66,0.06c0.34,0.2,0.65,0.47,0.93,0.75
		C211.78,85.07,213.84,90.08,213.99,95.89z"
    />
    <path
      d="M158.4,91.85c0.29,0.45,0.53,0.82,0.76,1.18c4.57,7.23,1.34,16.86-6.66,19.85
		c-8.21,3.07-17.08-2.38-18.13-11.04c-0.8-6.65,3.58-13.37,10.75-14.82c1.99-0.4,3.99-0.41,5.93,0.18c0.97,0.3,1.53,0.02,2.18-0.64
		c3-3.05,6.04-6.05,9.06-9.09c0.82-0.83,1.74-1.4,2.95-1.38c1.5,0.03,2.62,0.7,3.28,2.03c0.69,1.38,0.51,2.71-0.42,3.94
		c-0.22,0.28-0.49,0.52-0.75,0.78c-2.77,2.77-5.54,5.54-8.31,8.31C158.85,91.36,158.67,91.56,158.4,91.85z M153.88,100.27
		c0.01-3.35-2.73-6.12-6.07-6.16c-3.3-0.04-6.13,2.74-6.18,6.07c-0.05,3.36,2.77,6.21,6.15,6.2
		C151.14,106.37,153.88,103.63,153.88,100.27z"
    />
  </svg>,
  'SensorFlujometroInvasivo',
);

export default SensorFlujometroInvasivo;
