import { gql } from '@/__generated__';
import { GetDeviceInfo_AlertsQuery } from 'src/__generated__/graphql';

type DeviceInfo = GetDeviceInfo_AlertsQuery['device'];

const GET_DEVICE_INFO = gql(`#graphql
query GetDeviceInfo_Alerts($input: DeviceInput! ) {
  device(input: $input) {
    id
    metrics {
      id
      availableFields
    }
    alerts {
      id
      configs: activeConfigurations {
        metric
        ok {
          unit
          comparisonType
          eventName
          thresholdValue
          thresholdMax
          thresholdMin
          notificationDelay
        }
        info {
          unit
          comparisonType
          eventName
          thresholdValue
          thresholdMax
          thresholdMin
          notificationDelay
        }
        warning {
          unit
          comparisonType
          eventName
          thresholdValue
          thresholdMax
          thresholdMin
          notificationDelay
        }
        critical {
          unit
          comparisonType
          eventName
          thresholdValue
          thresholdMax
          thresholdMin
          notificationDelay
        }
      }
    }
  }
}`);

export default GET_DEVICE_INFO;
export type { DeviceInfo };
