import useConfirmationModal from '@/hooks/useConfirmationModal';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import { AuthorizationWrapper, AuthorizationWrapperProps } from '@features/authorization';
import { Dialog, DialogProps, Skeleton } from '@mui/material';
import { ComponentType, PropsWithChildren, ReactNode, Suspense, type FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import ApolloErrorBoundary from './ApolloErrorBoundary';
import ModalActions, { type ModalActionsProps } from './modal/ModalActions';
import ModalTitle, { type ModalTitleProps } from './modal/ModalTitle';

export interface GlobalModalProps extends PropsWithChildren {
  onClose?: () => void;
  onExited?: () => void;
  open: boolean;
  dialogProps?: Partial<DialogProps>;
  confirmationModal?: ReturnType<typeof useConfirmationModal> | null;
  modalTitleProps?: ModalTitleProps | null;
  modalActionProps?: ModalActionsProps | null;
  authorization?: AuthorizationWrapperProps | null;
  suspenseFallback?: ReactNode | null;
  errorFallback?: ComponentType<FallbackProps> | null;
}

const GlobalModal: FC<GlobalModalProps> = ({
  confirmationModal,
  onExited,
  onClose,
  open,
  children,
  dialogProps,
  modalTitleProps,
  modalActionProps,
  authorization,
  suspenseFallback,
  errorFallback,
}) => {
  return (
    <Dialog
      TransitionProps={{ onExited }}
      onClose={onClose}
      open={open}
      maxWidth="md"
      fullWidth
      {...dialogProps}
    >
      {modalTitleProps != null && <ModalTitle {...modalTitleProps} />}
      <ApolloErrorBoundary variant="modal" fallback={errorFallback}>
        <Suspense fallback={suspenseFallback ?? <DefaultSuspenseFallback />}>
          <AuthorizationWrapper {...{ onClose, ...authorization }}>
            {confirmationModal && (
              <ConfirmationModal {...confirmationModal.confirmationModalProps} />
            )}
            {children}
          </AuthorizationWrapper>
        </Suspense>
      </ApolloErrorBoundary>
      {modalActionProps != null && <ModalActions {...modalActionProps} />}
    </Dialog>
  );
};

const DefaultSuspenseFallback = () => {
  return <Skeleton variant="rounded" width="100%" height={400} />;
};

export default GlobalModal;
