import { useEffect, type FC } from 'react';
import {
  DeviceRatingCurvesCurveFormulaInput,
  DeviceRatingCurvesCurveInput,
} from '@/__generated__/graphql';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NumericTextField from '@components/NumericTextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-latest';
import { AdapterDayjs } from '@mui/x-date-pickers-latest/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Access, AuthorizationWrapper } from '@features/authorization';
import { curveSchema } from '@features/deviceDataConfigurationModal/utils/ratingCurvesSchema';

interface Props {
  deviceId: string;
  open: boolean;
  defaultValues?: DeviceRatingCurvesCurveInput;
  handleClose: () => void;
  handleSave: (curve: DeviceRatingCurvesCurveInput) => void;
}

// type FormValues = DeviceRatingCurvesCurveInput & {
//   created: Date;
//   updated: Date;
//   realizationDate: Dayjs;
// };

interface FormValues extends DeviceRatingCurvesCurveInput {
  created: Date;
  updated: Date;
  realizationDate: Dayjs;
  formula: Omit<DeviceRatingCurvesCurveFormulaInput, 'coef'> & {
    a0: number | null;
    a1: number | null;
    a2: number | null;
    a3: number | null;
    a4: number | null;
  };
}

const SaveCurveModal: FC<Props> = ({ deviceId, open, defaultValues, handleClose, handleSave }) => {
  const formulaTypes = [
    {
      value: 'power',
      label: t`Potencial`,
    },
    {
      value: 'polynomial',
      label: t`Polinomial (hasta orden 4)`,
    },
  ];

  const { _ } = useLingui();
  const editMode = !!defaultValues;
  const methods = useForm<FormValues>({
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          created: new Date(defaultValues.created),
          updated: new Date(),
          realizationDate: dayjs(defaultValues.realizationDate),
          formula: {
            ...defaultValues.formula,

            ...(defaultValues.formula.coef?.length === 5 && {
              a0: defaultValues.formula.coef[0],
              a1: defaultValues.formula.coef[1],
              a2: defaultValues.formula.coef[2],
              a3: defaultValues.formula.coef[3],
              a4: defaultValues.formula.coef[4],
            }),
          },
        }
      : {
          active: false,
          created: new Date(),
          updated: new Date(),
          realizationDate: dayjs(),
          method: '',
          notes: '',
          rSquared: 0,
          formula: {
            type: 'power',
            a: 0,
            b: 0,
          },
        },
    resolver: yupResolver(curveSchema()),
  });

  useEffect(() => {
    methods.reset(
      defaultValues
        ? {
            ...defaultValues,
            created: new Date(defaultValues.created),
            updated: new Date(),
            realizationDate: dayjs(defaultValues.realizationDate),
            formula: {
              ...defaultValues.formula,
              ...(defaultValues.formula.coef?.length === 5 && {
                a0: defaultValues.formula.coef[0],
                a1: defaultValues.formula.coef[1],
                a2: defaultValues.formula.coef[2],
                a3: defaultValues.formula.coef[3],
                a4: defaultValues.formula.coef[4],
              }),
            },
          }
        : {
            active: false,
            created: new Date(),
            updated: new Date(),
            realizationDate: dayjs(),
            rSquared: 0,
            formula: {
              type: 'power',
              a: 0,
              b: 0,
            },
          },
    );
  }, [defaultValues, methods, open]);

  const submitHandler = async (formData: FormValues) => {
    const formula =
      formData.formula.type === 'power'
        ? {
            type: formData.formula.type,
            a: formData.formula.a,
            b: formData.formula.b,
            offset: formData.formula.offset,
            constant: formData.formula.constant,
          }
        : {
            type: formData.formula.type,
            coef: [
              formData.formula.a0,
              formData.formula.a1,
              formData.formula.a2,
              formData.formula.a3,
              formData.formula.a4,
            ],
            offset: formData.formula.offset,
          };
    const submitData = {
      created: formData.created,
      updated: formData.updated,
      realizationDate: formData.realizationDate,
      active: formData.active,
      formula,
      rSquared: formData.rSquared,
      levelMin: formData.levelMin,
      levelMax: formData.levelMax,
      method: formData.method,
      notes: formData.notes,
    };

    handleSave(submitData);
  };

  const formulaType = methods.watch('formula.type');

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <AuthorizationWrapper
        access={Access.UpdateRatingCurve}
        deviceId={deviceId}
        onClose={handleClose}
      >
        <form onSubmit={methods.handleSubmit(submitHandler)}>
          <DialogTitle>
            {editMode ? <Trans>Editar curva</Trans> : <Trans>Crear curva de aforo</Trans>}
          </DialogTitle>
          <DialogContent dividers>
            <Typography mb={2} variant="h6">
              <Trans>Configuración de curva</Trans>
            </Typography>
            <Controller
              name="formula.type"
              control={methods.control}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="velocity-curve-type">
                    <Trans>Tipo de fórmula</Trans>
                  </InputLabel>
                  <Select
                    {...field}
                    label={_(msg`Tipo de curva`)}
                    inputProps={{ id: 'velocity-curve-type' }}
                    error={!!error}
                  >
                    {formulaTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
            {formulaType === 'power' && (
              <>
                <Box height={25} maxWidth={250} mx="auto" my={2}>
                  <img
                    width="100%"
                    src="/static/icons/power.svg"
                    alt={_(msg`Ecuación curva potencial`)}
                  />
                </Box>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                  <Controller
                    name="formula.a"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                      <NumericTextField
                        {...field}
                        size="small"
                        label={_(msg`Coeficiente A`)}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="formula.b"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                      <NumericTextField
                        {...field}
                        size="small"
                        label={_(msg`Coeficiente B`)}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="formula.offset"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                      <NumericTextField
                        {...field}
                        size="small"
                        label={_(msg`Offset (H0)`)}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="formula.constant"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                      <NumericTextField
                        {...field}
                        size="small"
                        label={_(msg`Constante (C)`)}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Box>
              </>
            )}
            {formulaType === 'polynomial' && (
              <>
                <Box height={25} maxWidth={800} mx="auto" my={2}>
                  <img
                    width="100%"
                    src="/static/icons/polynomial.svg"
                    alt={_(msg`Ecuación curva polinomial`)}
                  />
                </Box>
                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
                  {(['a0', 'a1', 'a2', 'a3', 'a4'] as const).map((coef) => (
                    <Controller
                      key={coef}
                      name={`formula.${coef}`}
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <NumericTextField
                          {...field}
                          size="small"
                          label={coef}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  ))}
                  <Controller
                    name="formula.offset"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                      <NumericTextField
                        {...field}
                        size="small"
                        label={_(msg`Offset (H0)`)}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Box>
              </>
            )}
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2} my={2}>
              <Controller
                name="rSquared"
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <NumericTextField
                    {...field}
                    size="small"
                    label="R²"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="levelMin"
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <NumericTextField
                    {...field}
                    size="small"
                    label={_(msg`Nivel mínimo`)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="levelMax"
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <NumericTextField
                    {...field}
                    size="small"
                    label={_(msg`Nivel máximo`)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography mb={2} variant="h6">
              <Trans>Especificaciones</Trans>
            </Typography>
            <Box display="grid" mb={2} gridTemplateColumns="1fr auto" gap={2}>
              <Controller
                name="method"
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={_(msg`Método utilizado`)}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="realizationDate"
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      {...field}
                      slotProps={{
                        textField: {
                          helperText: error?.message,
                        },
                      }}
                      label={_(msg`Fecha de realización`)}
                    />
                  </LocalizationProvider>
                )}
              />
            </Box>
            <Controller
              name="notes"
              control={methods.control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  multiline
                  rows={4}
                  fullWidth
                  label={_(msg`Notas`)}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="info" onClick={handleClose}>
              <Trans>Cancelar</Trans>
            </Button>
            <Button type="submit" variant="contained">
              <Trans>Guardar curva</Trans>
            </Button>
          </DialogActions>
        </form>
      </AuthorizationWrapper>
    </Dialog>
  );
};

export default SaveCurveModal;
