import type {
  DeviceRatingCurvesCurveInput,
  UpdateDeviceRatingCurvesInput,
} from '@/__generated__/graphql';
import { setSnackbar } from '@/slices/components';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { Access, AuthorizationWrapper } from '@features/authorization';
import { DeviceTabProps } from '@features/deviceDataConfigurationModal/components/tabs/types';
import UPDATE_DEVICE_RATING_CURVES from '@features/deviceDataConfigurationModal/graphql/mutations/updateDeviceRatingCurves';
import GET_DEVICE_RATING_CURVES from '@features/deviceDataConfigurationModal/graphql/queries/getDeviceRatingCurves';
import { ratingCurvesSchema } from '@features/deviceDataConfigurationModal/utils/ratingCurvesSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AddCircle, ExpandLess, ExpandMore } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  Collapse,
  DialogActions,
  DialogContent,
  Divider,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Fragment, useEffect, useState, type FC } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CurvesPreview from './CurvesPreview';
import DeleteCurveModal from './DeleteCurveModal';
import SaveCurveModal from './SaveCurveModal';

type FormValues = UpdateDeviceRatingCurvesInput;

type OperationModalState =
  | {
      operation: 'delete';
      curveIndex: number;
    }
  | {
      operation: 'update';
      curveIndex: number;
      defaultValues: DeviceRatingCurvesCurveInput;
    }
  | {
      operation: 'create';
    };

const RatingCurvesTab: FC<DeviceTabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const [selectedCurves, setSelectedCurves] = useState<number[]>([]);
  // const [unableToSetActiveCurves, setUnableToSetActiveCurves] = useState(false);
  const [operationModal, setOperationModal] = useState<OperationModalState | null>(null);

  const { data } = useSuspenseQuery(GET_DEVICE_RATING_CURVES, {
    variables: { deviceId: deviceId },
  });

  const defaultValues: FormValues = {
    deviceId,
    ratingCurves: data?.deviceDataConfiguration.ratingCurves ?? {
      curves: [],
    },
  };

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver<FormValues>(ratingCurvesSchema),
  });

  const { fields, update, append, remove } = useFieldArray({
    control: methods.control,
    name: 'ratingCurves.curves',
  });

  const [updateRatingCurves, { loading: updateLoading }] = useMutation(
    UPDATE_DEVICE_RATING_CURVES,
    {
      onCompleted: (data) => {
        methods.reset({
          deviceId,
          ratingCurves: data.updateDeviceRatingCurves.ratingCurves ?? {
            curves: [],
          },
        });
        dispatch(
          setSnackbar({
            open: true,
            message: _(msg`Dato actualizado correctamente`),
            severity: 'success',
          }),
        );
      },
      onError: (errors) => {
        dispatch(
          setSnackbar({
            open: true,
            message: `${_(msg`Error al actualizar el dato`)}: ${errors.message}`,
            severity: 'error',
          }),
        );
      },
    },
  );

  useEffect(() => setIsDirty(methods.formState.isDirty), [methods.formState.isDirty, setIsDirty]);

  // check intersections of curves, set unableToSetActiveCurves to true if there are intersections
  // useEffect(() => {
  //   if (!data || selectedCurves.length === 0) {
  //     setUnableToSetActiveCurves(false);
  //     return;
  //   }
  //   const curves = selectedCurves.map((index) => data.ratingCurves[index]);
  //   const sortedCurves = curves.sort((a, b) => a.level_min! - b.level_min!);
  //   for (let i = 0; i < sortedCurves.length - 1; i++) {
  //     const curve = sortedCurves[i];
  //     const nextCurve = sortedCurves[i + 1];
  //     if (curve.level_max! > nextCurve.level_min!) {
  //       setUnableToSetActiveCurves(true);
  //       return;
  //     }
  //   }
  //   setUnableToSetActiveCurves(false);
  // }, [selectedCurves, data, data?.ratingCurves]);
  //
  const curves = methods.watch('ratingCurves.curves');

  useEffect(() => {
    if (curves && selectedCurves.some((index) => index > curves.length)) {
      setSelectedCurves(selectedCurves.filter((index) => index < curves.length));
    }
  }, [curves, selectedCurves]);

  const submitHandler = (data: FormValues) => {
    updateRatingCurves({
      variables: {
        input: {
          deviceId,
          ratingCurves: {
            curves: data.ratingCurves.curves,
          },
        },
      },
    });
  };

  return (
    <>
      <SaveCurveModal
        deviceId={deviceId}
        open={operationModal?.operation === 'create' || operationModal?.operation === 'update'}
        defaultValues={
          operationModal?.operation === 'update' ? operationModal?.defaultValues : undefined
        }
        handleClose={() => setOperationModal(null)}
        handleSave={(curve) => {
          if (operationModal?.operation === 'update') {
            update(operationModal.curveIndex, curve);
          } else if (operationModal?.operation === 'create') {
            append(curve);
          }
          setOperationModal(null);
        }}
      />
      <DeleteCurveModal
        deviceId={deviceId}
        open={operationModal?.operation === 'delete'}
        handleClose={() => setOperationModal(null)}
        handleDelete={() => {
          if (operationModal?.operation === 'delete') remove(operationModal.curveIndex);
          setOperationModal(null);
        }}
      />
      <DialogContent
        sx={{
          maxWidth: 'md',
          margin: 'auto',
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <Typography variant="h5" mb={2}>
          <Trans>Curvas de Aforo</Trans>{' '}
        </Typography>
        <Divider />
        <Box
          display="grid"
          gap={2}
          gridTemplateColumns="1fr auto 1fr"
          minHeight={500}
          alignItems="start"
        >
          <Box pt={2}>
            <Typography variant="h6" mb={2}>
              <Trans>Curvas de aforo creadas</Trans>
            </Typography>
            {!curves?.length ? (
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 4,
                  overflow: 'hidden',
                  color: 'text.disabled',
                  p: 2,
                }}
              >
                <i>
                  <Trans>No existen curvas de aforo creadas.</Trans>
                </i>
              </Box>
            ) : (
              <Box className="custom-scrollbar" maxHeight={360} overflow="auto">
                <List
                  disablePadding
                  sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 4,
                    overflow: 'hidden',
                  }}
                >
                  {fields.map((field, index) => (
                    <Fragment key={field.id}>
                      <RatingCurveListItem
                        deviceId={deviceId}
                        selected={selectedCurves.includes(index)}
                        handleSelect={() =>
                          setSelectedCurves((selected) => {
                            if (selected.includes(index)) {
                              return selected.filter((selectedId) => selectedId !== index);
                            } else {
                              return [...selected, index];
                            }
                          })
                        }
                        curve={field}
                        key={field.id}
                        handleEdit={() =>
                          setOperationModal({
                            operation: 'update',
                            defaultValues: field,
                            curveIndex: index,
                          })
                        }
                        handleDelete={() =>
                          setOperationModal({ operation: 'delete', curveIndex: index })
                        }
                        handleToggleActive={() => {
                          const { id: _, ...curve } = field;
                          update(index, { ...curve, active: !field.active });
                        }}
                      />
                      {index !== curves.length - 1 && <Divider />}
                    </Fragment>
                  ))}
                </List>
              </Box>
            )}

            <AuthorizationWrapper
              access={Access.UpdateRatingCurve}
              fallback={null}
              deviceId={deviceId}
            >
              <Button
                sx={{ mt: 1 }}
                onClick={() => setOperationModal({ operation: 'create' })}
                size="medium"
                startIcon={<AddCircle />}
              >
                <Trans>Agregar curva</Trans>
              </Button>
            </AuthorizationWrapper>
          </Box>
          <Divider orientation="vertical" />
          <Box pt={2}>
            <Typography variant="h6" mb={2}>
              <Trans>Visualización de curvas de aforo</Trans>
            </Typography>
            <Box
              minHeight={48}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gap="8px 4px"
              pb={2}
            >
              {curves && selectedCurves.length > 0 ? (
                selectedCurves
                  .sort((a, b) => (curves[a]?.levelMin ?? 0) - (curves[b]?.levelMin ?? 1))
                  .map((index) => {
                    // set color red if the curve intersects with another selected curve
                    const intersects =
                      selectedCurves
                        .filter((selected) => selected !== index)
                        .filter((selected) => {
                          const curve = curves[index];
                          const selectedCurve = curves[selected];
                          return (
                            curve.levelMin! <= selectedCurve.levelMax! &&
                            curve.levelMax! >= selectedCurve.levelMin!
                          );
                        }).length > 0;

                    return (
                      <Chip
                        color={intersects ? 'error' : 'default'}
                        sx={{ mr: 1 }}
                        label={`${curves[index].levelMin} cm - ${curves[index].levelMax} cm`}
                        onDelete={() =>
                          setSelectedCurves((selected) =>
                            selected.filter((selectedId) => selectedId !== index),
                          )
                        }
                      />
                    );
                  })
              ) : (
                <Typography>
                  <Trans>Todas las curvas activadas</Trans>
                </Typography>
              )}
            </Box>
            <CurvesPreview selectedCurvesIndexes={selectedCurves} curves={curves ?? []} />
            {/* <Tooltip */}
            {/*   title={ */}
            {/*     <> */}
            {/*       Establece las curvas seleccionadas como activas. Se desactivará cualquier curva */}
            {/*       que no esté seleccionada. */}
            {/*       <br /> */}
            {/*       <ul> */}
            {/*         <li>El conjunto de curvas seleccionadas debe ser continua</li> */}
            {/*         <li>Los intervalos de las curvas seleccionadas no deben superponerse</li> */}
            {/*       </ul> */}
            {/*     </> */}
            {/*   } */}
            {/* > */}
            {/*   <span> */}
            {/*     <Button disabled={unableToSetActiveCurves}> */}
            {/*       Establecer curvas seleccionadas como activas */}
            {/*     </Button> */}
            {/*   </span> */}
            {/* </Tooltip> */}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ maxWidth: 'md', margin: 'auto', py: 2 }}>
        <Button
          disabled={!methods.formState.isDirty}
          color="info"
          variant="outlined"
          onClick={() => methods.reset(defaultValues)}
        >
          <Trans>Restablecer Formulario</Trans>
        </Button>
        <Box flexGrow={1} />
        <Button color="info" variant="outlined" onClick={() => onClose()}>
          <Trans>Cerrar</Trans>
        </Button>
        <LoadingButton
          disabled={!methods.formState.isDirty}
          loading={methods.formState.isSubmitting || updateLoading}
          onClick={methods.handleSubmit(submitHandler)}
          type="submit"
          variant="contained"
        >
          <Trans>Guardar</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

interface RatingCurveListItemProps {
  deviceId: string;
  curve: DeviceRatingCurvesCurveInput;
  selected: boolean;
  handleSelect: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  handleToggleActive: () => void;
}

const RatingCurveListItem: FC<RatingCurveListItemProps> = ({
  deviceId,
  curve,
  selected,
  handleSelect,
  handleEdit,
  handleToggleActive,
  handleDelete,
}) => {
  const [open, setOpen] = useState(false);

  const active = curve.active;

  /**
   * Formats the date to the following format: dd MMM
   * @param dateString
   * @returns
   * @example
   * formatDate('2021-09-20T15:00:00.000Z') // 20 sep
   */
  const formatDate = (dateValue: string) => {
    const months = [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic',
    ];

    const date = new Date(dateValue);
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0');

    const formattedDate = `${day} ${month}`;
    return formattedDate;
  };

  return (
    <>
      <ListItem
        disablePadding
        sx={{ position: 'relative', height: 68 }}
        secondaryAction={
          <IconButton edge="end" onClick={() => setOpen((open) => !open)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      >
        <ListItemButton
          sx={{
            px: 3,
            display: 'grid',
            gridTemplateColumns: '1fr 80px 60px',
            alignItems: 'center',
          }}
          onClick={handleSelect}
          selected={selected}
        >
          <Box>
            <Switch
              color="success"
              size="small"
              checked={active}
              sx={{ mr: 1 }}
              onChange={handleToggleActive}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            <FormLabel sx={{ pointerEvents: 'none' }}>
              {active ? (
                <b style={{ color: 'rgb(111, 188, 179)' }}>
                  <Trans>Activada</Trans>
                </b>
              ) : (
                <b>
                  <Trans>Desactivada</Trans>
                </b>
              )}
            </FormLabel>
          </Box>
          <Box>
            <Typography>{curve.levelMin ?? '-'} cm</Typography>
            <Typography>{curve.levelMax ?? '-'} cm</Typography>
          </Box>
          <ListItemText
            sx={{ textAlign: 'center' }}
            primary={formatDate(curve.realizationDate!)}
            secondary={new Date(curve.realizationDate!).getFullYear()}
          />
        </ListItemButton>
      </ListItem>
      <Collapse
        sx={{ borderTop: '1px solid', borderColor: 'divider', backgroundColor: '#ffffff08' }}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2} pb={0}>
          <Typography>
            <Trans>Detalles</Trans>
          </Typography>
          <Box display="flex">
            <AuthorizationWrapper
              access={Access.UpdateRatingCurve}
              deviceId={deviceId}
              fallback={null}
            >
              <IconButton color="info" onClick={handleEdit}>
                <Edit />
              </IconButton>
            </AuthorizationWrapper>
            <AuthorizationWrapper
              access={Access.UpdateRatingCurve}
              deviceId={deviceId}
              fallback={null}
            >
              <IconButton color="info" onClick={handleDelete}>
                <Delete />
              </IconButton>
            </AuthorizationWrapper>
          </Box>
        </Box>
        <Box p={1} pb={2}>
          <Table size="small" padding="checkbox" sx={{ border: 1, borderColor: 'divider' }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Propiedad</Trans>
                </TableCell>
                <TableCell align="right">
                  <Trans>Valor</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Trans>Nivel mínimo</Trans>
                </TableCell>
                <TableCell align="right">{curve.levelMin} cm</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Trans>Nivel máximo</Trans>
                </TableCell>
                <TableCell align="right">{curve.levelMax} cm</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Trans>Actualizado el </Trans>
                </TableCell>
                <TableCell align="right">{new Date(curve.updated).toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Trans>Realizado el</Trans>
                </TableCell>
                <TableCell align="right">
                  {new Date(curve.realizationDate).toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Trans>Método</Trans>
                </TableCell>
                <TableCell align="right">{curve.method}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Trans>Notas</Trans>
                </TableCell>
                <TableCell align="right">{curve.notes}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding="none" sx={{ px: 2, pt: 0.5, pb: 1 }} colSpan={2}>
                  <Typography variant="subtitle2">
                    <Trans>Fórmula</Trans>
                  </Typography>
                  <Table
                    size="small"
                    padding="checkbox"
                    sx={{
                      border: 1,
                      borderColor: 'divider',
                      backgroundColor: '#ffffff08',
                    }}
                  >
                    <TableRow>
                      <TableCell>
                        <Trans>Tipo</Trans>
                      </TableCell>
                      <TableCell align="right">
                        {curve.formula.type === 'power' ? 'potencial' : ''}
                        {curve.formula.type === 'polynomial' ? 'polinomial' : ''}
                      </TableCell>
                    </TableRow>
                    {curve.formula.type === 'power' &&
                      (['a', 'b', 'offset', 'constant'] as const).map((key) => (
                        <TableRow>
                          <TableCell>{key}</TableCell>
                          <TableCell align="right">
                            {typeof curve.formula[key] === 'object'
                              ? JSON.stringify(curve.formula[key])
                              : curve.formula[key]}
                          </TableCell>
                        </TableRow>
                      ))}
                    {curve.formula.type === 'polynomial' && (
                      <>
                        <TableRow>
                          <TableCell>offset</TableCell>
                          <TableCell align="right">{curve.formula.offset ?? 'null'}</TableCell>
                        </TableRow>
                        {curve.formula.coef?.map((coef, index) => (
                          <TableRow>
                            <TableCell>{`a_${index + 1}`}</TableCell>
                            <TableCell align="right">{coef}</TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </Table>
                </TableCell>
              </TableRow>
              {/* {Object.entries(curve).map(([key, value]) => { */}
              {/*   if (key === '__typename') return null; */}
              {/*   return ( */}
              {/*     <> */}
              {/*       <TableRow> */}
              {/*         <TableCell>{key}</TableCell> */}
              {/*         <TableCell align="right"> */}
              {/*           {typeof value === 'boolean' && (value ? 'true' : 'false')} */}
              {/*           {key === 'created' || key === 'updated' || key === 'realization_date' */}
              {/*             ? new Date(Number(value)).toLocaleString('es-CL') */}
              {/*             : (typeof value === 'string' || typeof value === 'number') && value} */}
              {/*           {value == null && '--'} */}
              {/*           {value != null && */}
              {/*             typeof value === 'object' && */}
              {/*             `${Object.entries(value).length} campos`} */}
              {/*         </TableCell> */}
              {/*       </TableRow> */}
              {/*       {value != null && typeof value === 'object' && ( */}
              {/*         <TableRow> */}
              {/*           <TableCell padding="none" sx={{ p: 2 }} colSpan={2}> */}
              {/*             <Table */}
              {/*               size="small" */}
              {/*               padding="checkbox" */}
              {/*               sx={{ */}
              {/*                 border: 1, */}
              {/*                 borderColor: 'divider', */}
              {/*                 backgroundColor: '#ffffff08', */}
              {/*               }} */}
              {/*             > */}
              {/*               {Object.entries(value).map(([key, value]) => { */}
              {/*                 if (key === '__typename') return null; */}
              {/**/}
              {/*                 return ( */}
              {/*                   <TableRow> */}
              {/*                     <TableCell>{key}</TableCell> */}
              {/*                     <TableCell align="right"> */}
              {/*                       {typeof value === 'object' ? JSON.stringify(value) : value} */}
              {/*                     </TableCell> */}
              {/*                   </TableRow> */}
              {/*                 ); */}
              {/*               })} */}
              {/*             </Table> */}
              {/*           </TableCell> */}
              {/*         </TableRow> */}
              {/*       )} */}
              {/*     </> */}
              {/*   ); */}
              {/* })} */}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </>
  );
};

export default RatingCurvesTab;
