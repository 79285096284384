import { createSvgIcon } from '@mui/material';

const SensorNivelSinContacto = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M1.78,150c0-47.8,0-95.6,0.01-143.4c0-0.64,0-1.29,0.14-1.9c0.35-1.56,1.34-2.52,2.93-2.77
		C5.6,1.81,6.37,1.8,7.13,1.8c43.08-0.01,86.16,0,129.24,0c52.36,0,104.72,0,157.07,0.01c0.76,0,1.53,0,2.27,0.14
		c1.53,0.28,2.47,1.24,2.83,2.74c0.1,0.42,0.12,0.87,0.16,1.31c0.02,0.32,0.01,0.64,0.01,0.96c0,95.44,0,190.88,0,286.32
		c0,0.68,0,1.36-0.11,2.03c-0.28,1.81-1.58,2.99-3.4,3.15c-0.4,0.04-0.8,0.01-1.2,0.01c-95.84,0-191.67-0.01-287.51,0.03
		c-3.13,0-4.73-1.64-4.73-4.74C1.8,245.84,1.78,197.92,1.78,150z M9.3,9.24c-0.26,1.46-0.2,280.81,0.03,281.7
		c0.07,0.04,0.13,0.1,0.2,0.11c0.16,0.03,0.32,0.05,0.47,0.05c93.51,0,187.03,0,280.54,0c0.16,0,0.31-0.04,0.47-0.07
		c0.04-0.01,0.07-0.03,0.1-0.06c0.03-0.03,0.06-0.06,0.08-0.09c0.23-1.28,0.18-280.65-0.07-281.65C197.22,9.24,103.29,9.24,9.3,9.24
		z"
    />
    <path
      d="M53.24,168.83c1.44,0.16,2.59-0.2,3.72-0.57c2.37-0.79,4.52-2.01,6.63-3.32c1.12-0.7,2.2-1.47,3.34-2.13
		c0.69-0.4,0.95-0.91,0.85-1.67c-0.04-0.31-0.01-0.64-0.01-0.96c0-27.3,0-54.59,0-81.89c0-0.44-0.02-0.88,0.01-1.32
		c0.14-1.8,1.37-3.13,3.15-3.4c0.43-0.07,0.88-0.08,1.31-0.08c14.36,0,28.72,0,43.08,0c0.52,0,1.04,0.03,1.55,0.12
		c1.62,0.31,2.76,1.62,2.87,3.25c0.03,0.48,0.02,0.96,0.02,1.44c0,28.9,0,57.79,0,86.69c0,0.55,0,1.1,0,1.73
		c1.27,0.35,2.4,0.05,3.51-0.16c2.25-0.44,4.36-1.32,6.38-2.37c2.56-1.32,5.05-2.75,7.62-4.06c2.13-1.09,4.29-2.15,6.49-3.09
		c5.98-2.56,12-2.46,17.91,0.2c2.87,1.3,5.64,2.83,8.43,4.3c2.79,1.47,5.53,3.03,8.33,4.5c0.91,0.48,1.91,0.81,2.88,1.17
		c2.92,1.09,5.89,1.12,8.8,0.06c1.95-0.71,3.87-1.53,5.71-2.47c3.56-1.82,7.03-3.8,10.58-5.63c2.64-1.35,5.43-2.34,8.37-2.81
		c3.32-0.54,6.61-0.31,9.89,0.36c4.08,0.83,7.98,2.27,11.92,3.59c3.37,1.13,6.76,2.2,10.31,2.65c0.86,0.11,1.74,0.13,2.68,0.19
		c0.05-0.42,0.1-0.72,0.11-1.03c0.02-0.44,0.01-0.88,0.01-1.32c0-38.53,0-77.06,0-115.58c0-0.6,0-1.21,0.13-1.78
		c0.41-1.89,2.12-3.09,3.98-2.87c1.82,0.21,3.16,1.69,3.23,3.58c0.01,0.4,0.01,0.8,0.01,1.2c0,69.82,0,139.64,0,209.46
		c0,0.48,0.02,0.96-0.01,1.44c-0.12,1.91-1.6,3.33-3.52,3.4c-0.36,0.01-0.72,0-1.08,0c-67.32,0-134.63-0.01-201.95,0.04
		c-3.23,0-4.62-1.92-4.62-4.57c0.05-70.02,0.04-140.04,0.04-210.06c0-0.36-0.01-0.72,0.01-1.08c0.13-1.89,1.65-3.37,3.52-3.43
		c1.93-0.07,3.59,1.35,3.79,3.27c0.06,0.55,0.03,1.12,0.03,1.68c0,40.53,0,81.05,0,121.58C53.24,167.66,53.24,168.21,53.24,168.83z
		 M249.6,170.59c-1.06-0.08-2.05-0.13-3.03-0.23c-3.79-0.41-7.43-1.46-11.05-2.61c-3.88-1.24-7.75-2.51-11.66-3.62
		c-2.79-0.79-5.7-1.05-8.54-0.33c-1.92,0.48-3.83,1.16-5.62,2.02c-2.88,1.38-5.65,2.98-8.46,4.51c-2.71,1.47-5.44,2.86-8.37,3.84
		c-3.59,1.21-7.24,1.62-10.98,0.85c-2.64-0.54-5.16-1.46-7.52-2.72c-3.49-1.86-6.92-3.82-10.39-5.73c-2.2-1.22-4.44-2.36-6.84-3.16
		c-2.31-0.76-4.66-1.16-7.07-0.59c-1.2,0.29-2.43,0.58-3.54,1.09c-2.61,1.2-5.17,2.51-7.74,3.8c-2.82,1.42-5.59,2.93-8.44,4.27
		c-2.73,1.28-5.62,2.04-8.65,2.23c-0.59,0.04-1.18,0.07-1.75,0.11c-0.28,1.29-0.23,77.03,0.03,77.91
		c1.64,0.23,128.78,0.15,129.64-0.09C249.6,224.98,249.6,197.83,249.6,170.59z M112.4,252.27c0-0.68,0-1.24,0-1.79
		c0-25.41,0-50.82,0-76.23c0-0.28-0.03-0.56,0-0.84c0.06-0.61-0.2-0.92-0.76-1.16c-1.61-0.71-3.22-1.42-4.76-2.26
		c-2.92-1.58-5.75-3.3-8.69-4.84c-2.04-1.08-4.15-2.06-6.29-2.92c-3.35-1.34-6.74-1.25-10.01,0.3c-1.95,0.92-3.8,2.03-5.72,3.01
		c-0.82,0.42-1.09,0.97-1.08,1.9c0.04,8.03,0.02,16.06,0.02,24.09c0,0.43,0,0.86,0,1.39c0.57,0.04,0.99,0.09,1.42,0.09
		c2.16,0.01,4.32,0,6.48,0.01c1.12,0,2.24-0.03,3.36,0.06c1.75,0.13,3.04,1.48,3.24,3.3c0.18,1.56-0.88,3.28-2.39,3.76
		c-0.56,0.18-1.18,0.2-1.78,0.21c-2.96,0.02-5.92,0-8.88,0.01c-0.46,0-0.92,0.07-1.37,0.1c0,8.16,0,16.17,0,24.32
		c0.51,0,0.93,0,1.36,0c3.12,0,6.24-0.03,9.36,0.01c2.54,0.03,4.22,2.29,3.56,4.68c-0.43,1.59-1.79,2.64-3.57,2.66
		c-3.12,0.03-6.24,0.01-9.36,0.01c-0.43,0-0.85,0.04-1.25,0.06c-0.28,1.24-0.26,19.11,0.02,20.09
		C87.61,252.27,99.91,252.27,112.4,252.27z M112.28,164.44c0.27-2.15,0.17-82.58-0.07-83.44c-1.49-0.28-36.14-0.19-37.03,0.1
		c0,5.72,0,11.46,0,17.32c0.48,0.03,0.91,0.09,1.34,0.09c2.08,0.01,4.16,0,6.24,0.01c1.2,0,2.4-0.04,3.6,0.04
		c1.75,0.12,3.04,1.47,3.26,3.28c0.19,1.61-0.92,3.36-2.5,3.8c-0.6,0.17-1.26,0.19-1.9,0.19c-2.88,0.02-5.76,0-8.64,0.01
		c-0.46,0-0.92,0.06-1.38,0.09c0,8.31,0,16.49,0,24.8c0.51,0,0.94,0,1.37,0c3.12,0,6.24-0.03,9.36,0.01
		c2.53,0.03,4.21,2.29,3.55,4.69c-0.44,1.58-1.82,2.64-3.58,2.65c-3.12,0.02-6.24,0.01-9.36,0.01c-0.43,0-0.85,0.04-1.25,0.06
		c-0.27,1.33-0.23,18.29,0.04,19.24c0.29-0.12,0.58-0.23,0.86-0.36c1.05-0.48,2.09-1,3.15-1.45c4.6-1.93,9.28-2.15,14.05-0.65
		c3.22,1.01,6.21,2.52,9.14,4.16c2.86,1.59,5.71,3.2,8.58,4.8C111.46,164.08,111.82,164.23,112.28,164.44z M67.67,252.25
		c0.25-2.36,0.14-80.07-0.13-81.06c-4.41,2.65-8.92,5.02-14.22,5.15c0,25.39,0,50.61,0,75.91
		C58.13,252.25,62.86,252.25,67.67,252.25z"
    />
    <path
      d="M204.98,45.52c6.28,0,12.55,0,18.83,0c0.32,0,0.64,0.02,0.96,0c2.67-0.14,4.11,1.84,4.1,4.09
		c-0.04,6.39-0.01,12.78-0.01,19.17c0,0.92,0.02,1.84,0,2.76c-0.05,2.47-1.53,3.97-3.98,3.97c-13.27,0.01-26.54,0.01-39.81,0
		c-2.53,0-4.01-1.5-4.01-4.05c-0.01-7.31-0.01-14.62,0-21.93c0-2.55,1.45-4,4.03-4.01C191.71,45.51,198.35,45.52,204.98,45.52z
		 M221.42,53c-1.86-0.31-32.09-0.2-32.94,0.09c0,4.97,0,9.95,0,14.9c1.26,0.25,31.95,0.21,32.94-0.04
		C221.42,63,221.42,58.02,221.42,53z"
    />
    <path
      d="M205.53,122.33c-14.66-0.32-26.44-5.41-35.87-15.75c-0.77-0.85-1.4-1.74-1.37-2.96
		c0.04-1.54,0.72-2.67,2.11-3.35c1.28-0.63,2.96-0.36,4.02,0.61c0.32,0.3,0.6,0.64,0.9,0.96c6.19,6.8,13.82,11.12,22.9,12.49
		c14.36,2.16,26.56-2.2,36.48-12.83c0.81-0.87,1.62-1.65,2.89-1.73c1.59-0.1,2.82,0.51,3.57,1.92c0.76,1.41,0.63,2.82-0.39,4.03
		c-1.05,1.25-2.19,2.44-3.37,3.57c-7.18,6.78-15.69,10.9-25.43,12.42C209.61,122.09,207.2,122.18,205.53,122.33z"
    />
    <path
      d="M205.05,108.15c-9.92-0.26-18.09-3.74-24.68-10.82c-0.76-0.81-1.34-1.68-1.34-2.85
		c-0.01-1.53,0.78-2.87,2.14-3.45c1.46-0.63,2.82-0.4,4.01,0.66c0.54,0.48,0.99,1.04,1.5,1.55c4.24,4.2,9.31,6.69,15.25,7.31
		c8.79,0.91,16.19-1.96,22.23-8.36c0.83-0.88,1.7-1.55,2.97-1.58c1.5-0.02,2.66,0.57,3.35,1.9c0.7,1.35,0.66,2.73-0.29,3.95
		c-0.68,0.88-1.47,1.69-2.29,2.45c-5.58,5.24-12.16,8.28-19.79,9.01C206.99,108.03,205.87,108.09,205.05,108.15z"
    />
    <path
      d="M204.8,94.89c-5.61-0.15-10.55-2.15-14.58-6.28c-1.05-1.08-1.53-2.31-1.09-3.82c0.74-2.52,3.73-3.49,5.79-1.87
		c0.28,0.22,0.52,0.49,0.78,0.74c5.14,5.08,13.14,5.14,18.38,0.15c0.26-0.25,0.49-0.52,0.76-0.76c1.48-1.33,3.58-1.33,5.01-0.01
		c1.45,1.34,1.62,3.55,0.29,5.08c-1.72,1.99-3.82,3.51-6.18,4.66C211.2,94.13,207.91,94.9,204.8,94.89z"
    />
  </svg>,
  'SensorNivelSinContacto',
);

export default SensorNivelSinContacto;
