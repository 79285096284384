import { Box } from '@mui/material';
import { type FC } from 'react';
import KaTeXComponent from '@components/KaTeXComponent';
import { t } from '@lingui/macro';

const PositionFromAngleFormula: FC = () => {
  const defTheta = t`ángulo respecto a la horizontal (en grados)`;
  const defK = t`correción ángulo (en grados)`;
  const defAlpha = t`ángulo medido por el inclinómetro (en grados)`;
  const defA = t`abertura de la compuerta (en cm)`;
  const defR = t`radio de la compuerta (en cm)`;
  const defP = t`altura del pivote (en cm)`;

  const latexTheta = `
\\begin{equation*}
  \\theta = K - \\alpha
\\end{equation*}`;

  const latexThetaDescription = `
\\begin{aligned}
  \\theta =& \\text{ ${defTheta}} \\\\
  K =& \\text{ ${defK} } \\\\
  \\alpha =& \\text{ ${defAlpha} }
\\end{aligned}`;

  const latexA = `
\\begin{equation*}
\\begin{split}
  a = P - R \\cdot \\sin(\\theta)
\\end{split}
\\end{equation*}`;

  const latexADescription = `
\\begin{aligned}
a =& \\text{ ${defA}} \\\\
R =& \\text{ ${defR}} \\\\
P =& \\text{ ${defP}} \\\\
\\theta =& \\text{ ${defTheta}}
\\end{aligned}`;

  return (
    <>
      <Box fontSize="medium" mt={2} display="flex" flexDirection="column" gap={1}>
        <KaTeXComponent latex={latexTheta} />
        <KaTeXComponent latex={latexThetaDescription} displayMode={false} />
      </Box>
      <Box fontSize="medium" mt={3} display="flex" flexDirection="column" gap={1}>
        <KaTeXComponent latex={latexA} />
        <KaTeXComponent latex={latexADescription} displayMode={false} />
      </Box>
    </>
  );
};

export default PositionFromAngleFormula;
