import { gql } from '@/__generated__';

const GET_DEVICES_GRAVEYARD = gql(`#graphql
  query GetDevicesGraveyard {
    devices(input: { deleted: ONLY_TRUE, orderBy: ID_ASC }) {
      id
      profile {
        id
        name
        organization {
          id
          name
        }
      }
      metrics {
        flow {
          lastData
          unit
          precision
        }
        level {
          lastData
          unit
          precision
        }
        velocity {
          lastData
          unit
          precision
        }
      }
    }
  }
`);

export default GET_DEVICES_GRAVEYARD;
