import { ActionType } from '@/__generated__/graphql';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { DisplayActionType } from '../types/displayActionType';

export const getDisplayNameActionType = (
  actionType: ActionType,
  i18n: I18n,
): { displayName: string; displayType: string } => {
  const actionTypeDict: DisplayActionType = {
    [ActionType.UpdateCalibration]: {
      displayName: t(i18n)`actualizó la calibración`,
      displayType: 'table',
    },
    [ActionType.UpdateFlowLimits]: {
      displayName: t(i18n)`actualizó los límites de caudal`,
      displayType: 'table',
    },
    [ActionType.UpdateDeviceFilters]: {
      displayName: t(i18n)`actualizó los filtros del dispositivo`,
      displayType: 'table',
    },
    [ActionType.UpdateRadarParameters]: {
      displayName: t(i18n)`actualizó los parámetros del radar`,
      displayType: 'table',
    },
    [ActionType.UpdateRatingCurves]: {
      displayName: t(i18n)`actualizó las curvas de aforo`,
      displayType: 'table',
    },
    [ActionType.UpdateMathModeling]: {
      displayName: t(i18n)`actualizó el modelado matemático`,
      displayType: 'table',
    },
    [ActionType.UpdateInfo]: {
      displayName: t(i18n)`actualizó la información`,
      displayType: 'table',
    },
    [ActionType.UpdateDeviceStatus]: {
      displayName: t(i18n)`actualizó el estado del dispositivo`,
      displayType: 'table',
    },
    [ActionType.DeleteDevice]: {
      displayName: t(i18n)`eliminó el dispositivo`,
      displayType: 'table',
    },
    [ActionType.CreateDevice]: {
      displayName: t(i18n)`creó el dispositivo`,
      displayType: 'table',
    },
    [ActionType.UpdateDeviceAccess]: {
      displayName: t(i18n)`actualizó el acceso al dispositivo`,
      displayType: 'paragraph',
    },
    [ActionType.UpdateDeviceAlerts]: {
      displayName: t(i18n)`actualizó las alertas del dispositivo`,
      displayType: 'table',
    },
    [ActionType.DisableDeviceAlerts]: {
      displayName: t(i18n)`desactivó las alertas del dispositivo`,
      displayType: 'table',
    },
    [ActionType.UpdateDeviceAlarms]: {
      displayName: t(i18n)`actualizó las alarmas del dispositivo`,
      displayType: 'table',
    },
    [ActionType.UpdateDeviceAlertNotifications]: {
      displayName: t(i18n)`actualizó las notificaciones de alerta del dispositivo`,
      displayType: 'table',
    },
    [ActionType.UpdateDeviceCalendarConfiguration]: {
      displayName: t(i18n)`actualizó la configuración del calendario del dispositivo`,
      displayType: 'table',
    },
    [ActionType.DeleteDeviceCalendarConfiguration]: {
      displayName: t(i18n)`eliminó la configuración del calendario del dispositivo`,
      displayType: 'table',
    },
    [ActionType.CreateDeviceCalendarConfiguration]: {
      displayName: t(i18n)`creó la configuración del calendario del dispositivo`,
      displayType: 'table',
    },
    [ActionType.CreateDeviceHardwareConfiguration]: {
      displayName: t(i18n)`creó la configuración del hardware del dispositivo`,
      displayType: 'table',
    },
    [ActionType.UpdateDeviceHardwareConfiguration]: {
      displayName: t(i18n)`actualizó la configuración del hardware del dispositivo`,
      displayType: 'table',
    },
    [ActionType.DeleteDeviceHardwareConfiguration]: {
      displayName: t(i18n)`eliminó la configuración del hardware del dispositivo`,
      displayType: 'table',
    },
    [ActionType.SendConfigCommandToDevice]: {
      displayName: t(i18n)`envió un comando al dispositivo`,
      displayType: 'table',
    },
    [ActionType.UpdateStatusSupport]: {
      displayName: t(i18n)`actualizó el estado de soporte del dispositivo`,
      displayType: 'table',
    },
    [ActionType.CreateDeviceAliases]: {
      displayName: t(i18n)`creó el alias del dispositivo`,
      displayType: 'table',
    },
    [ActionType.UpdateDeviceAliases]: {
      displayName: t(i18n)`actualizó el alias del dispositivo`,
      displayType: 'table',
    },
    [ActionType.DeleteDeviceAliases]: {
      displayName: t(i18n)`eliminó el alias del dispositivo`,
      displayType: 'table',
    },
    [ActionType.CloneDevice]: {
      displayName: t(i18n)`clonó el dispositivo`,
      displayType: 'table',
    },
    [ActionType.CreateDeviceAccess]: {
      displayName: t(i18n)`creó accesos de usuario para el dispositivo`,
      displayType: 'table',
    },
    [ActionType.DeleteDeviceAccess]: {
      displayName: t(i18n)`eliminó accesos de usuario para el dispositivo`,
      displayType: 'table',
    },
    // User
    [ActionType.CreateUser]: {
      displayName: t(i18n)`creó el usuario`,
      displayType: 'table',
    },
    [ActionType.UpdateUser]: {
      displayName: t(i18n)`actualizó el usuario`,
      displayType: 'table',
    },
    [ActionType.DeleteUser]: {
      displayName: t(i18n)`eliminó el usuario`,
      displayType: 'table',
    },
    [ActionType.UpdateUserAccess]: {
      displayName: t(i18n)`actualizó el acceso del usuario`,
      displayType: 'paragraph',
    },
    [ActionType.UpdateUserAlerts]: {
      displayName: t(i18n)`actualizó las alertas del usuario`,
      displayType: 'table',
    },
    [ActionType.UpdateUserAlarms]: {
      displayName: t(i18n)`actualizó las alarmas del usuario`,
      displayType: 'table',
    },
    [ActionType.UpdateUserSupportFilters]: {
      displayName: t(i18n)`actualizó los filtros de configuración de soporte del usuario`,
      displayType: 'table',
    },
    [ActionType.UpdateUserSupportFields]: {
      displayName: t(i18n)`actualizó los campos de configuración de soporte del usuario`,
      displayType: 'table',
    },
    [ActionType.ImpersonateUser]: {
      displayName: t(i18n)`suplantó al usuario`,
      displayType: 'table',
    },
    [ActionType.UpdateUserPreferences]: {
      displayName: t(i18n)`actualizó las preferencias del usuario`,
      displayType: 'table',
    },
    [ActionType.CreateUserAccess]: {
      displayName: t(i18n)`creó accesos de dispositivos al usuario`,
      displayType: 'table',
    },
    [ActionType.DeleteUserAccess]: {
      displayName: t(i18n)`eliminó accesos de dispositivos al usuario`,
      displayType: 'table',
    },
    // Irrigation
    [ActionType.CreateIrrigation]: {
      displayName: t(i18n)`creó la irrigación`,
      displayType: 'table',
    },
    [ActionType.DeleteIrrigation]: {
      displayName: t(i18n)`eliminó la irrigación`,
      displayType: 'table',
    },
    [ActionType.UpdateIrrigation]: {
      displayName: t(i18n)`actualizó la irrigación`,
      displayType: 'table',
    },
    // Sira Distribution
    [ActionType.UpdateSiraDistribution]: {
      displayName: t(i18n)`actualizó la distribución de SIRA`,
      displayType: 'table',
    },
    [ActionType.CreateNewSiraDistribution]: {
      displayName: t(i18n)`creó una nueva distribución de SIRA`,
      displayType: 'table',
    },
    // Sira Canal
    [ActionType.CreateSiraCanalClass]: {
      displayName: t(i18n)`creó la clase de canal de SIRA`,
      displayType: 'table',
    },
    [ActionType.UpdateSiraCanalClass]: {
      displayName: t(i18n)`actualizó la clase de canal de SIRA`,
      displayType: 'table',
    },
    [ActionType.DeleteSiraCanalClass]: {
      displayName: t(i18n)`eliminó la clase de canal de SIRA`,
      displayType: 'table',
    },
    [ActionType.CreateSiraUserToNotify]: {
      displayName: t(i18n)`creó el usuario de SIRA para notificar`,
      displayType: 'table',
    },
    [ActionType.UpdateSiraUserToNotify]: {
      displayName: t(i18n)`actualizó el usuario de SIRA para notificar`,
      displayType: 'table',
    },
    [ActionType.DeleteSiraUserToNotify]: {
      displayName: t(i18n)`eliminó el usuario de SIRA para notificar`,
      displayType: 'table',
    },
    // Organization
    [ActionType.CreateOrganization]: {
      displayName: t(i18n)`creó la organización`,
      displayType: 'table',
    },
    [ActionType.DeleteOrganization]: {
      displayName: t(i18n)`eliminó la organización`,
      displayType: 'table',
    },
    [ActionType.UpdateOrganization]: {
      displayName: t(i18n)`actualizó la organización`,
      displayType: 'table',
    },
    [ActionType.UpdateOrganizationAccess]: {
      displayName: t(i18n)`actualizó el acceso a la organización`,
      displayType: 'table',
    },
    [ActionType.UpdateOrganizationSetting]: {
      displayName: t(i18n)`actualizó la configuración de la organización`,
      displayType: 'table',
    },
    [ActionType.CreateApiForOrganization]: {
      displayName: t(i18n)`creó la API para la organización`,
      displayType: 'table',
    },
    [ActionType.DeleteApiForOrganization]: {
      displayName: t(i18n)`eliminó la API para la organización`,
      displayType: 'table',
    },
    [ActionType.UpdateApiForOrganization]: {
      displayName: t(i18n)`actualizó la API para la organización`,
      displayType: 'table',
    },
    [ActionType.UpdateApiPasswordForOrganization]: {
      displayName: t(i18n)`actualizó la contraseña de la API para la organización`,
      displayType: 'table',
    },
    [ActionType.CreateSiraMaipoConfig]: {
      displayName: t(i18n)`creó la configuración de SIRA Maipo`,
      displayType: 'table',
    },
    [ActionType.CreateSiraMaipoConfigDevice]: {
      displayName: t(i18n)`creó la configuración del dispositivo para SIRA Maipo`,
      displayType: 'table',
    },
    [ActionType.CreateSiraMaipoDevOrgConfig]: {
      displayName: t(i18n)`creó la configuración del dispositivo u organización para SIRA Maipo`,
      displayType: 'table',
    },
    [ActionType.CreateSiraMaipoConfigOrg]: {
      displayName: t(i18n)`creó la configuración de la organización para SIRA Maipo`,
      displayType: 'table',
    },
    [ActionType.CreateNewSiraMaipoDistribution]: {
      displayName: t(i18n)`creó una nueva distribución para SIRA Maipo`,
      displayType: 'table',
    },
    [ActionType.DeleteSiraMaipoDistribution]: {
      displayName: t(i18n)`eliminó la distribución de SIRA Maipo`,
      displayType: 'table',
    },
    [ActionType.UpdateSiraMaipoDistribution]: {
      displayName: t(i18n)`actualizó la distribución de SIRA Maipo`,
      displayType: 'table',
    },
    [ActionType.UpdateSiraMaipoDistributionShares]: {
      displayName: t(i18n)`actualizó las acciones de la distribución de SIRA Maipo`,
      displayType: 'table',
    },
    [ActionType.UpdateSiraMaipoDistributionMinDotation]: {
      displayName: t(i18n)`actualizó la dotación mínima de la distribución de SIRA Maipo`,
      displayType: 'table',
    },
    // Gates
    [ActionType.SendMovementCommandToGate]: {
      displayName: t(i18n)`envió un comando de movimiento a la compuerta`,
      displayType: 'table',
    },
    [ActionType.SendStopMovementCommandToGate]: {
      displayName: t(i18n)`envió un comando de detención de movimiento a la compuerta`,
      displayType: 'table',
    },
    [ActionType.DeleteGateEnqueueMoveRequest]: {
      displayName: t(i18n)`eliminó la solicitud de movimiento en cola de la compuerta`,
      displayType: 'table',
    },
    // Reports
    [ActionType.CreateReport]: {
      displayName: t(i18n)`creó el reporte`,
      displayType: 'table',
    },
    [ActionType.DeleteReport]: {
      displayName: t(i18n)`eliminó el reporte`,
      displayType: 'table',
    },
    [ActionType.UpdateReport]: {
      displayName: t(i18n)`actualizó el reporte`,
      displayType: 'table',
    },
    [ActionType.CreateReportSchedule]: {
      displayName: t(i18n)`creó el cronograma del reporte`,
      displayType: 'table',
    },
    [ActionType.DeleteReportSchedule]: {
      displayName: t(i18n)`eliminó el cronograma del reporte`,
      displayType: 'table',
    },
    [ActionType.UpdateReportSchedule]: {
      displayName: t(i18n)`actualizó el cronograma del reporte`,
      displayType: 'table',
    },
    // Project renewals
    [ActionType.UpdateProjectRenewalDueDate]: {
      displayName: t(i18n)`actualizó la fecha de vencimiento del cobro recurrente del proyecto`,
      displayType: 'table',
    },
    [ActionType.UpdateProjectRenewalPeriodicity]: {
      displayName: t(i18n)`actualizó la periodicidad del cobro recurrente del proyecto`,
      displayType: 'table',
    },
    [ActionType.CreateProjectRenewalPayment]: {
      displayName: t(i18n)`creó un nuevo pago para un proyecto.`,
      displayType: 'table',
    },
    [ActionType.UpdateProjectRenewalPayment]: {
      displayName: t(i18n)`actualizó la información del pago de un proyecto.`,
      displayType: 'table',
    },
    [ActionType.DeleteProjectRenewalPayment]: {
      displayName: t(i18n)`eliminó un pago del proyecto.`,
      displayType: 'table',
    },
    [ActionType.CreateProjectRenewalService]: {
      displayName: t(i18n)`creó un nuevo servicio para el proyecto.`,
      displayType: 'table',
    },
    [ActionType.UpdateProjectRenewalService]: {
      displayName: t(i18n)`actualizó un servicio del proyecto.`,
      displayType: 'table',
    },
    [ActionType.DeleteProjectRenewalService]: {
      displayName: t(i18n)`eliminó un servicio del proyecto.`,
      displayType: 'table',
    },
    [ActionType.CreateBillingService]: {
      displayName: t(i18n)`creó un nuevo servicio de facturación.`,
      displayType: 'table',
    },
    [ActionType.UpdateBillingService]: {
      displayName: t(i18n)`actualizó un nuevo servicio de facturación.`,
      displayType: 'table',
    },
    [ActionType.DeleteBillingService]: {
      displayName: t(i18n)`eliminó un nuevo servicio de facturación.`,
      displayType: 'table',
    },
    [ActionType.RequestPaymentsReport]: {
      displayName: t(i18n)`solicitó un reporte de pagos.`,
      displayType: 'table',
    },
    // dga
    [ActionType.UpdateDgaInfo]: {
      displayName: t(i18n)`actualizó la información de DGA`,
      displayType: 'table',
    },
    [ActionType.CreateDgaConfiguration]: {
      displayName: t(i18n)`creó la configuración de DGA`,
      displayType: 'table',
    },

    [ActionType.DeleteDgaConfiguration]: {
      displayName: t(i18n)`eliminó la configuración de DGA`,
      displayType: 'table',
    },
    [ActionType.UpdateDgaConfiguration]: {
      displayName: t(i18n)`actualizó la configuración de DGA`,
      displayType: 'table',
    },
    [ActionType.DeleteDgaSchedule]: {
      displayName: t(i18n)`eliminó la configuración de la recurrencia de envío de DGA`,
      displayType: 'table',
    },
    [ActionType.EnableDgaSchedule]: {
      displayName: t(i18n)`habilitó el envío de DGA`,
      displayType: 'table',
    },
    [ActionType.DisableDgaSchedule]: {
      displayName: t(i18n)`deshabilitó el envío de DGA`,
      displayType: 'table',
    },
    [ActionType.UpdateDgaSchedule]: {
      displayName: t(i18n)`actualizó la configuración de recurrencia de envío de DGA`,
      displayType: 'table',
    },
    [ActionType.UpdateDgaNotificationConfiguration]: {
      displayName: t(i18n)`actualizó la configuración de notificación de DGA`,
      displayType: 'table',
    },
  };
  const result = actionTypeDict[actionType];

  if (result) {
    return {
      displayName: result.displayName,
      displayType: result.displayType,
    };
  } else {
    return { displayName: actionType, displayType: 'table' };
  }
};
