import TruncatedTypography from '@components/TruncatedTypography';
import DeviceTooltip from '@components/tooltips/DeviceTooltip';
import { Trans } from '@lingui/macro';
import {
  Box,
  ListItem,
  ListItemButton,
  Typography,
  ListItemIcon,
  Checkbox,
  CheckboxProps,
} from '@mui/material';
import { type FC } from 'react';

interface Props {
  style?: React.CSSProperties;
  lastItem?: boolean;
  deviceId: string;
  deviceName: string;
  deviceOrganization?: {
    id: number;
    name: string;
  } | null;
  deviceIrrigationName?: string;
  onClick?: () => void;
  hasAccess?: boolean;
  modified?: boolean;
  selected?: boolean;
  checkboxColor?: CheckboxProps['color'];
}

const DevicesListItem: FC<Props> = ({
  lastItem,
  style,
  deviceId,
  deviceName,
  deviceOrganization,
  deviceIrrigationName,
  onClick,
  hasAccess: checked,
  modified,
  selected,
  checkboxColor,
}) => {
  const hasCheckbox = checked != null;
  return (
    <ListItem
      disablePadding
      style={style}
      sx={{
        borderBottom: lastItem ? null : '1px solid',
        borderColor: 'divider',
      }}
    >
      <DeviceTooltip deviceId={deviceId} placement="right-end">
        <ListItemButton
          selected={selected}
          onClick={onClick}
          sx={{ height: '100%', width: '100%', display: 'flex' }}
        >
          <Box width={hasCheckbox ? 'calc(100% - 125px)' : '100%'}>
            <Box display="flex" alignItems="center" gap={2}>
              <TruncatedTypography fontSize={14} maxWidth="45%">
                {deviceName}
              </TruncatedTypography>
              {deviceOrganization ? (
                <TruncatedTypography fontSize={12} maxWidth="40%" color="text.secondary">
                  {deviceOrganization.name}
                </TruncatedTypography>
              ) : (
                <Typography
                  alignSelf="center"
                  fontSize="small"
                  color="text.secondary"
                  fontStyle="italic"
                >
                  <Trans>Sin organización</Trans>
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <TruncatedTypography maxWidth="30%" fontSize={12} color="text.secondary">
                ID: {deviceId}
              </TruncatedTypography>
              <TruncatedTypography maxWidth="40%" fontSize={12} color="text.secondary">
                ICN: {deviceIrrigationName}
              </TruncatedTypography>
            </Box>
          </Box>
          {hasCheckbox && (
            <Box display="flex" flex={1} justifyContent="right" alignItems="center">
              {modified && (
                <Typography fontSize="small" fontStyle="italic" color="text.secondary">
                  <Trans>(editado)</Trans>
                </Typography>
              )}
              {checked != null && (
                <ListItemIcon>
                  <Checkbox edge="end" checked={checked} tabIndex={-1} color={checkboxColor} />
                </ListItemIcon>
              )}
            </Box>
          )}
        </ListItemButton>
      </DeviceTooltip>
    </ListItem>
  );
};

export default DevicesListItem;
