import { gql } from '@/__generated__';

const GET_DEVICE_OBSERVATIONS = gql(`#graphql
  query DeviceProfileModal_GetDeviceObservations(
    $deviceId: ID!,
    $pagination: PaginationInput
  ) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        observations(pagination: $pagination) {
          id
          pageInfo {
            count
            totalCount
            pageNumber
            limit
            offset
          }
          items {
            id
            date
            updatedAt
            content
            author {
              id
              name
              lastnames
              fullName
              picture
            }
            device {
              id
            }
          }
        }
      }
    }
  }
`);

export default GET_DEVICE_OBSERVATIONS;
