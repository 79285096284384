import { Access, AuthorizationWrapper } from '@features/authorization';
import { Trans } from '@lingui/macro';
import { Divider, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import {
  Suspense,
  useEffect,
  useState,
  type FC,
  type PropsWithChildren,
  type ReactNode,
} from 'react';

export type StandardMenuItem =
  | {
      id: string | number;
      label: ReactNode;
      onClick?: () => void;
      Wrapper?: FC<PropsWithChildren>;
      access?: Access;
    }
  | {
      id: string | number;
      type: 'divider';
    };

interface StandardMenuProps extends PropsWithChildren {
  items?: StandardMenuItem[];
  deviceId?: string;
}

const NoopWrapper: FC<PropsWithChildren> = ({ children }) => children;

const StandardMenu: FC<StandardMenuProps> = ({ children, items, deviceId }) => {
  const [menuElement, setMenuElement] = useState<HTMLUListElement | null>(null);
  const [hasItems, setHasItems] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!menuElement || menuElement.hasChildNodes()) setHasItems(true);
    else setHasItems(false);
  }, [menuElement]);

  return (
    <>
      <IconButton onClick={handleClick}>{children}</IconButton>
      <Menu
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          ref: (node) => {
            setMenuElement(node);
          },
        }}
      >
        {!items || items.length === 0 || !hasItems ? (
          <MenuItem disabled>
            <Trans>No hay acciones disponibles</Trans>
          </MenuItem>
        ) : (
          items.map((item) => {
            if ('type' in item) {
              if (item.type === 'divider') return <Divider key={item.id} />;
              else throw new Error('Standard menu item `type` not supported');
            }

            const Wrapper = item.Wrapper ?? NoopWrapper;

            return (
              <Suspense
                key={item.id}
                fallback={
                  <MenuItem>
                    <Skeleton variant="text">
                      <span>{item.label}</span>
                    </Skeleton>
                  </MenuItem>
                }
              >
                <Wrapper>
                  <AuthorizationWrapper
                    deviceId={deviceId}
                    key={item.id}
                    access={item.access}
                    fallback={null}
                  >
                    <MenuItem onClick={item.onClick}>{item.label}</MenuItem>
                  </AuthorizationWrapper>
                </Wrapper>
              </Suspense>
            );
          })
        )}
      </Menu>
    </>
  );
};

export default StandardMenu;
