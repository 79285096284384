import { EntityType } from '@/__generated__/graphql';
import { useFormatters } from '@/hooks/useFormatters';
import { useSuspenseQuery } from '@apollo/client';
import DeviceTooltip from '@components/tooltips/DeviceTooltip';
import UserTooltip from '@components/tooltips/UserTooltip';
import { getDisplayNameActionType, getDisplayNameEntity } from '@features/audit/utils';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Edit } from '@mui/icons-material';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { PropsWithChildren, ReactElement, type FC } from 'react';
import GET_USER_PROFILE_AUDIT_LOGS from '../graphql/queries/getUserProfileAuditLogs';

interface Props {
  userId: number;
}

const UserProfileAuditLogs: FC<Props> = ({ userId }) => {
  const { _, i18n } = useLingui();
  const { formatDateTime } = useFormatters();
  const { data, error } = useSuspenseQuery(GET_USER_PROFILE_AUDIT_LOGS, {
    errorPolicy: 'all',
    variables: {
      input: {
        id: userId,
      },
    },
  });

  if (error) {
    if (error.graphQLErrors.length === 1) {
      if (error.graphQLErrors[0].extensions?.code !== 'UNAUTHORIZED') {
        throw error;
      }
    } else {
      throw error;
    }
  }

  if (!data) return null;
  const { user } = data;

  if (user.auditLogs == null) return null;

  return (
    <>
      <Typography variant="h6" mt={1}>
        <Trans>Actividad reciente</Trans>
      </Typography>
      <List>
        {user.auditLogs.length === 0 ? (
          <ListItem>
            <ListItemText secondary={_(msg`No hay actividad reciente`)} />
          </ListItem>
        ) : (
          user.auditLogs.map((log) => {
            const Tooltip: FC<{ children: ReactElement }> =
              log.entity.type === EntityType.Device
                ? ({ children }) => (
                    <DeviceTooltip deviceId={log.entity.id}>{children}</DeviceTooltip>
                  )
                : log.entity.type === EntityType.User
                ? ({ children }) => (
                    <UserTooltip userId={Number(log.entity.id)}>{children}</UserTooltip>
                  )
                : ({ children }: PropsWithChildren) => children;
            return (
              <ListItem key={log?.id} sx={{ py: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 34, height: 34, bgcolor: 'primary.main' }}>
                    <Edit fontSize="small" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography component="span">
                        <Trans>{getDisplayNameActionType(log.action.type, i18n).displayName} en</Trans>
                      </Typography>{' '}
                      <Tooltip>
                        <Typography
                          component="span"
                          fontWeight="bold"
                          sx={{
                            textDecoration: 'underline',
                            textDecorationStyle: 'dotted',
                            textDecorationColor: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          {getDisplayNameEntity(log.entity.type, i18n)} {log.entity.id}
                        </Typography>
                      </Tooltip>
                    </>
                  }
                  secondary={formatDateTime(log.timestamp)}
                />
              </ListItem>
            );
          })
        )}
      </List>
    </>
  );
};

export default UserProfileAuditLogs;
