import { DialogContent, type DialogContentProps } from '@mui/material';
import { type FC } from 'react';

export type ModalContentProps = DialogContentProps & {
  /**
   * Display the top and bottom dividers.
   * @default true
   */
  dividers?: boolean;
  topDivider?: boolean;
  bottomDivider?: boolean;
};

const ModalContent: FC<ModalContentProps> = ({
  dividers = true,
  topDivider,
  bottomDivider,
  ...props
}) => {
  const hideTopBorder = topDivider === false || (bottomDivider && !topDivider);
  const hideBottomBorder = bottomDivider === false || (topDivider && !bottomDivider);
  return (
    <DialogContent
      dividers={dividers}
      {...props}
      sx={{
        borderTopWidth: hideTopBorder ? 0 : 1,
        pt: hideTopBorder ? 0 : undefined,
        borderBottomWidth: hideBottomBorder ? 0 : 1,
        ...props?.sx,
      }}
    />
  );
};

export default ModalContent;
