import * as yup from 'yup';
import { t } from '@lingui/macro';
import { OutlierDataType } from '../components/tabs/filtersTab';

const validationMessages = () => ({
  required: t`Este campo es obligatorio`,
  number: {
    typeError: t`El valor debe ser un número`,
    min: t`El valor debe ser menor al del caudal máximo`,
    max: t`El valor debe ser mayor al del caudal mínimo`,
    moreThan: (number: number) => t`El valor debe ser mayor a ${number}`,
  },
  oneOf: (values: string[]) => t`El valor debe ser uno de: ${values.join(', ')}`,
  testPiecewise: t`Los elementos de Piecewise deben tener un valor mínimo que coincida con el valor máximo del elemento anterior`,
  requiredCurveType: t`El tipo de curva es obligatorio y debe ser function, interval o piecewise`,
});

const dataTypes: OutlierDataType[] = ['wf', 'wh', 'radar', 'dp'];

export const filtersSchema = yup.object().shape({
  deviceId: yup.string().required(),
  topicVersion: yup
    .string()
    .oneOf(['v1', 'v2', ''], validationMessages().oneOf(['v1', 'v2', '"nulo"']))
    .defined(),
  filters: yup.object({
    outOfRangeVelocity: yup.boolean().required(validationMessages().required),
    outliers: yup.object({
      version: yup
        .string()
        .oneOf(['v1', 'v2', 'v3', ''], validationMessages().oneOf(['v1', 'v2', 'v3', '"nulo"']))
        .defined(),
      list: yup
        .array()
        .of(
          yup.object({
            dataType: yup
              .string()
              .oneOf(dataTypes, validationMessages().oneOf(dataTypes))
              .defined(),
            name: yup
              .string()
              .oneOf(['h', 's', 'f'], validationMessages().oneOf(['h', 's', 'f']))
              .defined(),
            windowSize: yup.number().required(validationMessages().required),
            sdThreshold: yup.number().required(validationMessages().required),
            smoothing: yup.boolean().defined().required(validationMessages().required),
          }),
        )
        .test(
          'unique-dataType-name-pair',
          t`Cada par ("Tipo de Mensaje", "Variable") debe ser único`,
          (value) => {
            if (!value || !Array.isArray(value)) return true;

            const pairsSet = new Set();

            for (let i = 0; i < value.length; i++) {
              const { dataType, name } = value[i];
              const pairKey = `${dataType}-${name}`;

              if (pairsSet.has(pairKey)) {
                return false; // Return false if a duplicate pair is found
              }

              pairsSet.add(pairKey);
            }

            return true; // Return true if all pairs are unique
          },
        )
        .required(),
    }),
  }),
});
