import { FormValues } from 'src/features/createDeviceConfiguration/type/FormValues';

const initialStateOptions = {
  powerSupply: false
};

const defaultFormValues: FormValues = {
  //general
  images: null,
  googleDriveUrl: "",
  dateAction: new Date(),
  nameProject: "",
  lockNumber: "",

  // level
  sensorLevelOption: null,
  integrationDetailLevel: "",
  serialNumberLevel: "",
  modbusIdLevel: "",
  batchNumberLevelSensor: "",

  // velocity
  sensorVelocityOption: null,
  integrationDetailVelocity: "",
  serialNumberVelocity: "",
  modbusIdVelocity: "",
  batchNumberVelocitySensor: "",

  // flow
  sensorFlowOption: null,
  integrationDetailFlow: "",
  serialNumberFlow: "",
  modbusIdFlow: "",
  batchNumberFlowSensor: "",
  
  // type of energization
  energizationOption: null,

  // solar panel
  solarPanelsControllers: null,
  numberSolarPanelsControllers: "",

  // has battery
  quantityBattery: 1,
  modelBattery: null,
  technologyOption: null,
  batteryCapacityOption: null,
  batteryVoltageOption: null,

  // has solar panel
  quantitySun: 1,
  modelsSun: "",
  watts: null,
  numberSun: "",

  // ac dc
  powerSupplyVoltageOption: null,
  powerSupplyModel: "",
  powerSupplySerialNumber: "",
  powerSupplyBrand: "",
  
  // CPU
  cpuOption: null,
  batchNumberCpu: "",
  // auxiliary plate
  auxiliaryOption: null,
  numberAuxiliary: "",
  batchNumberAuxiliary: "",

  // connection internet
  internetConnectionOption: null,
  dataConnectionOption: null,

  //  if "Redes Móviles"
  modelsConnectionOption: null,
  imei: "",
  approved: false,
  mobileOperatorOption: null,
  phoneNumber: "",
  dataPlanMobileOption: null,
  iccid: "",
  antennaOption: null,

  // if "Red Cableada"
  mac: "",
  configurationIp: "",
  administrator: "",

  // if "Red Inalámbrica"
  networkName: "",
  observation: "",
  ip: "",
  imageVersion: "",
  idEmnify: "",
  satellitalHv: "",
  satellitalSv: "",
  satellitalHardwareName: "",
  satellitalSerialName: "",
  codeNumberWidgetlord: "",
  serialNumberWidgetlord: "",
  batchNumberWidgetlord: "",
  batchNumberSimCard: "",
  satellitalTypeOption: null,
  sensors: [],
  usbExtraction: false,
};

export { initialStateOptions, defaultFormValues };
