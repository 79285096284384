import { Box, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { type FC } from 'react';
import { Cached, InfoOutlined } from '@mui/icons-material';
import TimeElapsed from '@components/TimeElapsed';
import { DevicesAnalysisDevice } from './DevicesAnalysis';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useFormatters } from '@/hooks/useFormatters';
import { Access, AuthorizationWrapper } from '@features/authorization';
import { getMetricsPriorityList } from '@/utils/metricsPriority';
import { MetricUnit } from '@/__generated__/graphql';

interface Props {
  refetch: () => void;
  isPending: boolean;
  device: DevicesAnalysisDevice;
}

const LastData: FC<Props> = (props) => {
  const { formatMetricValue } = useFormatters();
  const { _ } = useLingui();
  const priorityList = getMetricsPriorityList(props.device.metrics.availableFields);

  const metricField = priorityList[0];

  const { lastData, unit, precision } = (Object.values(props.device.metrics).find(
    (metric) =>
      typeof metric === 'object' && 'metricField' in metric && metric.metricField === metricField,
  ) as { lastData: [number, number]; unit: MetricUnit; precision: number } | undefined) ?? {
    lastData: null,
    unit: MetricUnit.None,
    precision: 0,
  };
  const lastDataValue = lastData
    ? formatMetricValue(lastData?.[1], {
        unit,
        precision,
      })
    : null;

  const preciseLastDataValue = lastData
    ? formatMetricValue(lastData?.[1], {
        unit,
        precision: 7,
      })
    : null;

  return (
    <>
      <Tooltip title={preciseLastDataValue}>
        <Box flexGrow={1}>
          <Typography variant="body2" color="text.secondary">
            <Trans comment="Análisis de estaciones - texto en widget de último dato">
              Último dato
            </Trans>
          </Typography>
          <Typography
            fontSize={20}
            fontWeight="bold"
            sx={{
              // finetuning fonts
              lineHeight: 1,
              mt: 0.5,
              mb: 0.1,
            }}
          >
            {props.isPending ? <Skeleton width="70%" /> : lastDataValue ?? <Trans>Sin datos</Trans>}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.isPending ? (
              <Skeleton width="50%" />
            ) : lastData != null ? (
              <TimeElapsed timestamp={lastData[0]} />
            ) : null}
          </Typography>
        </Box>
      </Tooltip>
      <Box
        flexGrow={0}
        display="grid"
        pr={1}
        position="relative"
        sx={{ placeItems: 'center' }}
        alignSelf="stretch"
      >
        <AuthorizationWrapper access={Access.ViewDeviceMetricsPriorityList} fallback={null}>
          <Tooltip
            title={
              <Box>
                <b>Prioridad métricas:</b>
                {priorityList.map((metric) => {
                  return (
                    <Typography fontSize="inherit" key={metric}>
                      - {metric}
                    </Typography>
                  );
                })}
              </Box>
            }
          >
            <IconButton size="small" sx={{ position: 'absolute', top: -4, right: -4 }}>
              <InfoOutlined color="disabled" sx={{ fontSize: 12 }} />
            </IconButton>
          </Tooltip>
        </AuthorizationWrapper>
        <Tooltip
          title={_(
            msg({
              comment: 'Análisis de estaciones - tooltip de botón para actualizar datos',
              message: 'Actualizar datos',
            }),
          )}
        >
          <IconButton onClick={props.refetch}>
            <Cached />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

export default LastData;
