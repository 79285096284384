import { Access, useSuspenseAuthorization } from '@features/authorization';
import { Trans } from '@lingui/macro';
import { BeachAccess } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Skeleton, Tab, Typography } from '@mui/material';
import { Suspense, useMemo, useState, type FC } from 'react';

export type Panel = {
  key?: string;
  label: string;
  component: JSX.Element | string;
  onEnterMiddleware?: (next: () => void) => void;
  onExitMiddleware?: (next: () => void) => void;
  access?: Access;
};

export type ChangeTabMiddleware = (next: () => void) => void;

interface Props {
  /**
   * Array of panels to render.
   */
  panels: Panel[];
  /**
   * Function to execute when the user changes the tab.
   * This function will be executed before the tab changes.
   * @default undefined
   **/
  changeTabMiddleware?: ChangeTabMiddleware;
  value?: string;
  setValue?: (newValue: string) => void;
  /**
   * Used to check access on each panel
   */
  deviceId?: string;
}

const getKey = (panel?: Pick<Panel, 'key' | 'label'> | null) => panel?.key ?? panel?.label ?? '';

const Component: FC<Props> = ({
  panels,
  changeTabMiddleware,
  value: valueControlled,
  setValue: setValueControlled,
  deviceId,
}) => {
  const { validateAccess } = useSuspenseAuthorization(deviceId);

  const authorizedPanels = useMemo(() => {
    return panels.filter((panel) => {
      const authorized = panel.access ? validateAccess(panel.access) : true;
      return authorized;
    });
  }, [panels, validateAccess]);

  const [value, setValue] = useState(getKey(authorizedPanels[0]));

  const valueFinal = valueControlled == null ? value : valueControlled;
  const setValueFinal = setValueControlled == null ? setValue : setValueControlled;

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    const previousValue = valueFinal;
    const previousPanel = authorizedPanels.find((panel) => {
      const value = getKey(panel);
      return value === previousValue;
    });
    const panel = authorizedPanels.find((panel) => {
      const value = getKey(panel);
      return value === newValue;
    });
    if (panel?.onEnterMiddleware || previousPanel?.onExitMiddleware) {
      if (panel?.onEnterMiddleware) panel.onEnterMiddleware(() => setValueFinal(newValue));
      if (previousPanel?.onExitMiddleware)
        previousPanel.onExitMiddleware(() => setValueFinal(newValue));
    } else if (changeTabMiddleware) {
      changeTabMiddleware(() => setValueFinal(newValue));
    } else {
      setValueFinal(newValue);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'visible',
      }}
    >
      <TabContext value={valueFinal}>
        <Box
          sx={{
            mx: -1,
            backgroundColor: '#343c47',
            '& .MuiTabs-indicator': {
              height: '100%',
              backgroundColor: 'background.paper',
              borderTop: '4px solid #eee',
              borderLeft: '1px solid #aaa',
              borderRight: '1px solid #aaa',
              zIndex: 1,
            },
          }}
        >
          <TabList variant="fullWidth" onChange={handleChange} aria-label="Modal tabs">
            {authorizedPanels.map(({ key, label }) => (
              <Tab
                wrapped
                key={getKey({ key, label })}
                label={
                  <Typography
                    zIndex={2}
                    variant="h6"
                    fontSize="16px !important"
                    textTransform="unset"
                  >
                    {label}
                  </Typography>
                }
                value={getKey({ key, label })}
                sx={{
                  flexGrow: 1,
                  maxWidth: 'unset',
                  textTransform: 'unset',
                  borderBottom: '1px solid #aaa',
                  '&:not(:last-child)': {
                    borderRight: '1px solid #cccccc22',
                  },
                  '&.Mui-selected': {
                    color: 'white',
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        {authorizedPanels.length > 0 ? (
          authorizedPanels.map(({ key, label, component }) => (
            <TabPanel
              sx={{ p: 0, flexGrow: 1 }}
              key={getKey({ key, label })}
              value={getKey({ key, label })}
            >
              {component}
            </TabPanel>
          ))
        ) : (
          <Box height={300} p={4} pb={6} display="grid" alignContent="center" justifyItems="center">
            <BeachAccess sx={{ fontSize: 72 }} />
            <Typography color="text.secondary" fontSize="large">
              <Trans>No tienes acceso a ninguna de las pestañas</Trans>
            </Typography>
          </Box>
        )}
      </TabContext>
    </Box>
  );
};

const Fallback: FC<Pick<Props, 'panels'>> = ({ panels }) => {
  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'visible',
      }}
    >
      <TabContext value={0}>
        <Box
          sx={{
            mx: -1,
            backgroundColor: '#343c47',
            '& .MuiTabs-indicator': {
              height: '100%',
              backgroundColor: 'background.paper',
              borderTop: '4px solid #eee',
              borderLeft: '1px solid #aaa',
              borderRight: '1px solid #aaa',
              zIndex: 1,
            },
          }}
        >
          <TabList variant="fullWidth" aria-label="Modal tabs">
            {panels.map(({ key, label }) => (
              <Tab
                wrapped
                disabled
                key={getKey({ key, label })}
                label={
                  <Skeleton>
                    <Typography
                      zIndex={2}
                      variant="h6"
                      fontSize="16px !important"
                      textTransform="unset"
                    >
                      {label}
                    </Typography>
                  </Skeleton>
                }
                value={getKey({ key, label })}
                sx={{
                  flexGrow: 1,
                  maxWidth: 'unset',
                  textTransform: 'unset',
                  borderBottom: '1px solid #aaa',
                  '&:not(:last-child)': {
                    borderRight: '1px solid #cccccc22',
                  },
                  '&.Mui-selected': {
                    color: 'white',
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        <Skeleton variant="rounded" height={360} />
      </TabContext>
    </Box>
  );
};

const ModalTabs: FC<Props> = (props) => {
  return (
    <Suspense fallback={<Fallback panels={props.panels} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default ModalTabs;
