// react
import React, { useEffect, useMemo, useState } from 'react';
// material-ui
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Dialog,
  Button,
  Box,
  Grid,
  Typography,
  TextField,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material';

import _, { isNil } from 'lodash';
// redux
import { useDispatch } from 'src/store';
import { setSnackbar } from 'src/slices/components';

// components
import TablePaginationActions from 'src/components/TablePaginationActions';
import useAccessAndAlarm from './hooks/useAccessAndAlarm';
import { DeviceDialogProps } from '../../dialogInterfaces';
import UPDATE_ACCOUNT_ACCESS_AND_ALERTS from './graphql/mutation/updateAccountAccessAndAlerts';
import { useMutation } from '@apollo/client';
import { DeviceAlertMetricName, User } from '@/__generated__/graphql';

const AccessEndAlarm = ({ show, device, handleClose }: DeviceDialogProps) => {
  // states
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [updateAccountAccessAndAlerts, { data, called, loading: loadingUpdate }] = useMutation(
    UPDATE_ACCOUNT_ACCESS_AND_ALERTS,
  );
  // users state
  const [search, setSearch] = useState('');
  const { users, loading, refetch } = useAccessAndAlarm(device.id);

  useEffect(() => {
    const success = data?.updateAccountAccessAndAlertsConfig?.success ?? null;
    if (success && called) {
      dispatch(
        setSnackbar({
          open: true,
          message: 'Acceso actualizado',
          severity: 'success',
        }),
      );
      refetch();
    } else if (!isNil(success) && called) {
      dispatch(
        setSnackbar({
          open: true,
          message: 'Algo salió mal, por favor, intente nuevamente.',
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, data]);

  const handleSearch = (givenUsers: User[], value: string) => {
    return givenUsers.filter((u) => {
      const name = u?.name?.toLowerCase() ?? '';
      const lastnames = u?.lastnames?.toLowerCase() ?? '';
      const email = u?.username?.toLowerCase() ?? '';
      const phone = u?.phone?.toLowerCase() ?? '';
      const longName = `${name} ${lastnames}`;
      return (
        longName.includes(value.toLowerCase().trim()) ||
        email.includes(value.toLowerCase().trim()) ||
        phone.includes(value.toLowerCase().trim())
      );
    });
  };

  const filteredUsers = useMemo(() => {
    return handleSearch(users, search);
  }, [search, users]);

  useEffect(() => {
    setPage(0);
  }, [search]);

  const handleInternalClose = () => {
    setPage(0);
    setSearch('');
    handleClose(false);
  };

  const handleUpdate = (value: any, userIds: number[]) => {
    updateAccountAccessAndAlerts({
      variables: {
        input: {
          ...value,
          deviceId: device.id,
          userIds,
        },
      },
    });
  };

  return (
    <Dialog open={show} onClose={handleInternalClose} maxWidth={false} fullWidth>
      <DialogTitle>Usuarios del dispositivo {_.get(device, 'name', '')}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container flexDirection="column" justifyContent="center" alignItems="center" p={2}>
            <Typography
              variant="h5"
              align="center"
              color="textPrimary"
              sx={{
                mb: 2,
              }}
            >
              Cargando usuarios...
            </Typography>
            <CircularProgress />
          </Grid>
        ) : (
          <Box p={2}>
            <Grid container>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Buscar usuario"
                  size="small"
                  value={search}
                  fullWidth
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid>
            </Grid>
            <Table sx={{ border: '1px solid #919eab3d', mt: 2 }}>
              <TableHead>
                <TableRow sx={{ p: 0 }}>
                  <TableCell />
                  <TableCell>INFORMACIÓN DE USUARIO</TableCell>
                  <TableCell sx={{ borderRight: '1px solid #919eab3d' }} />
                  <TableCell align="center" sx={{ borderRight: '1px solid #919eab3d' }}>
                    ACCESO DE DISPOSITIVO
                  </TableCell>
                  <TableCell align="center">ALERTAS</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>
                    <Typography>Correo Electrónico</Typography>
                  </TableCell>
                  <TableCell sx={{ borderRight: '1px solid #919eab3d' }}>Teléfono</TableCell>
                  <TableCell align="center" sx={{ borderRight: '1px solid #919eab3d' }}>
                    <FlexBox>
                      <Checkbox
                        disabled={loadingUpdate}
                        checked={users.filter((u) => u?.hasAccess ?? false).length === users.length}
                        onClick={() => {
                          handleUpdate(
                            {
                              hasAccess: !(
                                users.filter((u) => u?.hasAccess).length === users.length
                              ),
                            },
                            filteredUsers
                              .filter((u) => u?.id !== undefined)
                              .map((u) => u!.id)
                              .filter(Boolean) as number[],
                          );
                        }}
                      />
                      <Typography>Accesos</Typography>
                    </FlexBox>
                  </TableCell>
                  <TableCell align="center">
                    <Box display="flex">
                      <>
                        <FlexBox>
                          <Checkbox
                            disabled={loadingUpdate}
                            checked={
                              users.filter((u) => u?.alertsNotificationSettings?.notifyEmail)
                                .length === users.length
                            }
                            onClick={() =>
                              handleUpdate(
                                {
                                  notifyEmail: !(
                                    users.filter((u) => u?.alertsNotificationSettings?.notifyEmail)
                                      .length === users.length
                                  ),
                                  alertType: DeviceAlertMetricName.Level,
                                },
                                filteredUsers
                                  .filter((u) => u?.id !== undefined)
                                  .map((u) => u!.id)
                                  .filter(Boolean) as number[],
                              )
                            }
                          />
                          <Typography>Correo</Typography>
                        </FlexBox>
                        <FlexBox>
                          <Checkbox
                            disabled={loadingUpdate}
                            checked={
                              users.filter((u) => u?.alertsNotificationSettings?.notifySms)
                                .length === users.length
                            }
                            onClick={() =>
                              handleUpdate(
                                {
                                  notifySms: !(
                                    users.filter((u) => u?.alertsNotificationSettings?.notifySms)
                                      .length === users.length
                                  ),
                                  alertType: DeviceAlertMetricName.Level,
                                },
                                filteredUsers
                                  .filter((u) => u?.id !== undefined)
                                  .map((u) => u!.id)
                                  .filter(Boolean) as number[],
                              )
                            }
                          />
                          <Typography>SMS</Typography>
                        </FlexBox>
                        <FlexBox>
                          <Checkbox
                            checked={
                              users.filter((u) => u?.alertsNotificationSettings?.notifyWhatsapp)
                                .length === users.length
                            }
                            onClick={() =>
                              handleUpdate(
                                {
                                  notifyWhatsapp: !(
                                    users.filter(
                                      (u) => u?.alertsNotificationSettings?.notifyWhatsapp,
                                    ).length === users.length
                                  ),
                                  alertType: DeviceAlertMetricName.Level,
                                },

                                filteredUsers
                                  .filter((u) => u?.id !== undefined)
                                  .map((u) => u!.id)
                                  .filter(Boolean) as number[],
                              )
                            }
                          />
                          <Typography>WhatsApp</Typography>
                        </FlexBox>
                      </>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((user) => {
                  const alertSettings = user?.alertsNotificationSettings ?? null;
                  return (
                    <TableRow key={user?.id}>
                      <TableCell>
                        {user?.name ?? ''} {user?.lastnames ?? ''}
                      </TableCell>
                      <TableCell>{user?.username ?? '-'}</TableCell>
                      <TableCell sx={{ borderRight: '1px solid #919eab3d' }}>
                        {user?.phone}
                      </TableCell>
                      <TableCell align="center" sx={{ borderRight: '1px solid #919eab3d' }}>
                        <Checkbox
                          disabled={loadingUpdate}
                          checked={user?.hasAccess ? user?.hasAccess : false}
                          onClick={() =>
                            handleUpdate({ hasAccess: !user?.hasAccess }, [user.id] as number[])
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="space-between" px={8}>
                          <>
                            <Checkbox
                              disabled={loadingUpdate}
                              checked={alertSettings?.notifyEmail ?? false}
                              onClick={() =>
                                handleUpdate(
                                  {
                                    notifyEmail: !user.alertsNotificationSettings?.notifyEmail,
                                    alertType: DeviceAlertMetricName.Intruder,
                                  },
                                  [user.id] as number[],
                                )
                              }
                            />
                            <Checkbox
                              disabled={loadingUpdate}
                              checked={alertSettings?.notifySms ?? false}
                              onClick={() =>
                                handleUpdate(
                                  {
                                    notifySms: !user?.alertsNotificationSettings?.notifySms,
                                    alertType: DeviceAlertMetricName.Intruder,
                                  },
                                  [user.id] as number[],
                                )
                              }
                            />
                            <Checkbox
                              disabled={loadingUpdate}
                              checked={alertSettings?.notifyWhatsapp ?? false}
                              onClick={() =>
                                handleUpdate(
                                  {
                                    notifyWhatsapp:
                                      !user?.alertsNotificationSettings?.notifyWhatsapp,
                                    alertType: DeviceAlertMetricName.Intruder,
                                  },
                                  [user.id] as number[],
                                )
                              }
                            />
                          </>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {filteredUsers.length === 0 && (
              <Grid container justifyContent="center">
                <Typography variant="h4" color="textSecondary">
                  No se encontraron usuarios...
                </Typography>
              </Grid>
            )}
            <Grid container mt={2}>
              <Grid item xs={12}>
                <TablePaginationActions
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={filteredUsers.length}
                  onChangePage={(p) => setPage(p)}
                  onChangeRowsPerPage={(r) => setRowsPerPage(r)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    color="info"
                    variant="outlined"
                    onClick={handleInternalClose}
                    sx={{
                      mr: 2,
                    }}
                  >
                    Cerrar
                  </Button>
                  {/* <Button
                    color="success"
                    variant="contained"
                    onClick={handleSave}
                  >
                    Guardar
                  </Button> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AccessEndAlarm;

const FlexBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      {children}
    </Box>
  );
};
