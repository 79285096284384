import { WriteExecutionLogSeverity } from '@/__generated__/graphql';
import { useLingui } from '@lingui/react';
import { Typography, type TypographyProps } from '@mui/material';
import { type FC, type PropsWithChildren } from 'react';
import { getWriteExecutionLogSeverityAttributes } from '../utils/writeExecutionLogSeverityAttributes';

export const SectionTitle: FC<PropsWithChildren & { TypographyProps?: TypographyProps }> = ({
  children,
  TypographyProps,
}) => {
  return (
    <Typography
      mt={3}
      mb={1}
      fontSize="small"
      fontWeight="medium"
      color={({ palette }) => palette.text.secondary}
      {...TypographyProps}
    >
      {children}
    </Typography>
  );
};

export const IssuesCount: FC<{
  severity: WriteExecutionLogSeverity;
  count: number;
}> = ({ severity, count }) => {
  const { i18n } = useLingui();
  const { Icon, color } = getWriteExecutionLogSeverityAttributes(severity, i18n);
  return (
    <Typography
      component="span"
      display="flex"
      fontSize={12}
      lineHeight={1.3}
      fontWeight="bold"
      gap={0.5}
      alignItems="center"
      sx={{ color }}
    >
      <Icon sx={{ fontSize: 15 }} />
      <Typography
        component="span"
        fontSize={13}
        fontWeight="bold"
        sx={{ textRendering: 'geometricPrecision' }}
      >
        {count}
      </Typography>
    </Typography>
  );
};
