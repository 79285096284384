import { useGlobalModal } from '@/hooks/useGlobalModal';
import { setDeleteDGAConfigurationModal } from '@/slices/modals';
import { useDispatch } from '@/store';
import DeviceModalTitle, { DeviceModalTitleAction } from '@components/DeviceModalTitle';
import GlobalModal from '@components/GlobalModal';
import ModalTabs, { Panel } from '@components/ModalTabs';
import { Access } from '@features/authorization';
import { type I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Clear, ConfirmationNumber, Replay } from '@mui/icons-material';
import { type FC } from 'react';
import { setRetryReportsModal, setUpdateDGATicketModal } from '../../slices/dgaSlice';
import GeneralTab from './tabs/GeneralTab';
import MaxFlowTab from './tabs/MaxFlowTab';
import NotificationsTab from './tabs/NotificationsTab';
import SubmissionsTab from './tabs/SubmissionsTab';

const UpdateDGAConfigurationModal: FC = () => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const { globalModalProps, state, onClose, changeTabMiddleware, setIsDirty } = useGlobalModal(
    'updateDGAConfigurationModal',
    {
      closeConfirmation: true,
    },
  );

  return (
    <GlobalModal
      {...globalModalProps}
      dialogProps={{ maxWidth: 'md' }}
      authorization={{
        access: Access.UpdateDGAConfigurationsModal,
        deviceId: state.open ? state.deviceId : undefined,
        onClose,
      }}
    >
      {state.open && (
        <>
          <DeviceModalTitle
            deviceId={state.deviceId}
            onClose={onClose}
            actions={getActions(state.deviceId, dispatch, i18n)}
          />
          <ModalTabs
            deviceId={state.deviceId}
            changeTabMiddleware={changeTabMiddleware}
            panels={getTabs(state.deviceId, onClose, setIsDirty, i18n)}
          />
        </>
      )}
    </GlobalModal>
  );
};

export default UpdateDGAConfigurationModal;

const getTabs = (
  deviceId: string,
  onClose: () => void,
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>,
  i18n: I18n,
): Array<Panel> => {
  const props = {
    deviceId,
    onClose,
    setIsDirty,
  };
  return [
    {
      key: 'general',
      label: t(i18n)`General`,
      component: <GeneralTab {...props} />,
      access: Access.UpdateDGAGeneralConfiguration,
    },
    {
      key: 'submissions',
      label: t(i18n)`Envíos`,
      component: <SubmissionsTab {...props} />,
      access: Access.UpdateDGASubmissionsConfiguration,
    },
    {
      key: 'max-flow',
      label: t(i18n)`Caudal máximo`,
      component: <MaxFlowTab {...props} />,
      access: Access.UpdateDGAMaxFlow,
    },
    {
      key: 'alerts-configuration',
      label: t(i18n)`Notificaciones`,
      component: <NotificationsTab {...props} />,
      access: Access.UpdateDGANotificationsConfiguration,
    },
  ];
};

const getActions = (
  deviceId: string,
  dispatch: ReturnType<typeof useDispatch>,
  i18n: I18n,
): Array<DeviceModalTitleAction> => [
  {
    key: 'ticket',
    label: t(i18n)`Configurar ticket`,
    color: 'secondary',
    icon: <ConfirmationNumber />,
    access: Access.SetTicketModal,
    onClick: () => {
      dispatch(
        setUpdateDGATicketModal({
          open: true,
          deviceId,
        }),
      );
    },
  },
  {
    key: 'retry',
    label: t(i18n)`Reintentar envío de datos`,
    color: 'success',
    icon: <Replay />,
    access: Access.RetryDGASubmissions,
    onClick: () => {
      dispatch(
        setRetryReportsModal({
          open: true,
          deviceId,
        }),
      );
    },
  },
  {
    key: 'delete',
    label: t(i18n)`Eliminar configuración DGA`,
    color: 'warning',
    icon: <Clear />,
    access: Access.DeleteDGAConfiguration,
    onClick: () => {
      dispatch(
        setDeleteDGAConfigurationModal({
          open: true,
          deviceId,
        }),
      );
    },
  },
];
