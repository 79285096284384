import { t } from '@lingui/macro';
import { z } from 'zod';

const validationMessages = () => ({
  required: t`Este campo es obligatorio`,
  number: {
    typeError: t`El valor debe ser un número`,
    min: t`El valor debe ser menor al del caudal máximo`,
    max: t`El valor debe ser mayor al del caudal mínimo`,
    moreThan: (number: number) => t`El valor debe ser mayor a ${number}`,
  },
  oneOf: (values: string[]) => t`El valor debe ser uno de: ${values.join(', ')}`,
  testPiecewise: t`Los elementos de Piecewise deben tener un valor mínimo que coincida con el valor máximo del elemento anterior`,
  requiredCurveType: t`El tipo de curva es obligatorio y debe ser function, interval o piecewise`,
});

export const flowLimitsSchema = z.object({
  deviceId: z.string().min(1, "El campo 'deviceId' no puede estar vacío"),
  state: z.object({
    isDry: z.boolean().refine(value => value !== undefined, validationMessages().required),
  }),
  flowLimits: z.object({
    min: z
      .any()
      .transform((v) => v !== "" && v != null ? parseFloat(v) : null),
    max: z
      .any()
      .transform((v) => v !== "" && v != null ? parseFloat(v) : null),
  })
  .refine((obj) => {
    if (obj.min != null && obj.max != null) {
      if (obj.max <= obj.min) return false
    }
    return true
  }, { message: t`El mínimo no puede ser mayor o igual al máximo.`, path: ['min'] })
  .refine((obj) => {
    if (obj.max != null) {
      if (obj.max <= 0) return false
    }
    return true
  }, { message: t`El máximo no puede ser menor a 0`, path: ['max'] })
  .refine((obj) => {
    if (obj.min != null) {
      if (obj.min <= 0) return false
    }
    return true
  }, { message: t`El mínimo no puede ser menor o igual a 0`, path: ['min'] }),
});
