import { createSvgIcon } from '@mui/material';

const ConnectivityEthernetIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M8.52,248.36c0.15-0.78,0.38-1.55,0.45-2.34c0.59-6.85,2.57-13.31,5.48-19.5c3.52-7.49,8.4-13.94,14.94-19.07
		c6.17-4.83,13.19-7.74,20.85-9.14c7.59-1.39,15.17-1.27,22.74,0.16c0.66,0.12,1.25,0.26,1.85-0.37
		c5.44-5.65,11.43-10.7,17.39-15.78c0.94-0.8,1.88-1.61,2.89-2.47c-0.25-0.33-0.45-0.62-0.67-0.89c-1.83-2.11-3.6-4.29-5.53-6.31
		c-1.8-1.88-1.64-4.45,0.46-6.25c7.11-6.06,14.12-12.25,21.17-18.4c0.32-0.28,0.63-0.58,1.01-0.93c-0.34-0.41-0.62-0.74-0.9-1.07
		c-2.47-2.83-4.9-5.7-7.41-8.49c-1.27-1.41-1.5-2.89-0.94-4.65c4.18-13.12,8.35-26.25,12.5-39.38c0.35-1.11,0.95-1.98,1.82-2.74
		c20.95-18.23,41.88-36.47,62.83-54.7c0.61-0.53,1.37-1.03,2.14-1.23c1.48-0.39,2.73,0.19,3.73,1.34c2.46,2.84,4.93,5.67,7.4,8.5
		c0.37,0.42,0.74,0.83,1.2,1.36c0.4-0.32,0.76-0.6,1.11-0.91c12.71-11.07,25.43-22.15,38.14-33.22c2.56-2.23,5.11-4.47,7.69-6.69
		c1.52-1.31,3.25-1.42,4.72-0.34c0.41,0.3,0.76,0.71,1.1,1.1c13.09,15.04,26.18,30.08,39.27,45.12c2.41,2.77,4.83,5.54,7.24,8.32
		c0.56,0.64,0.97,1.31,1.05,2.22c0.14,1.59-0.47,2.72-1.65,3.75c-15.17,13.18-30.32,26.39-45.47,39.59
		c-0.33,0.28-0.64,0.58-1.04,0.94c0.32,0.4,0.58,0.74,0.86,1.06c2.52,2.9,5.03,5.79,7.56,8.68c0.65,0.74,1.14,1.52,1.19,2.55
		c0.07,1.35-0.46,2.39-1.46,3.25c-3.17,2.74-6.33,5.51-9.49,8.26c-17.83,15.54-35.67,31.07-53.49,46.62
		c-0.94,0.82-1.95,1.3-3.18,1.51c-8.66,1.46-17.31,2.96-25.96,4.44c-4.96,0.85-9.92,1.66-14.87,2.54c-1.73,0.31-3.02-0.22-4.13-1.52
		c-2.5-2.91-5.03-5.79-7.55-8.69c-0.26-0.3-0.53-0.58-0.87-0.94c-0.41,0.34-0.78,0.63-1.13,0.94c-6.96,6.06-13.92,12.12-20.88,18.19
		c-0.24,0.21-0.48,0.43-0.73,0.63c-1.71,1.36-3.78,1.23-5.25-0.41c-1.94-2.17-3.84-4.39-5.75-6.59c-0.28-0.33-0.59-0.63-0.94-1.02
		C94,190.51,88,195.45,82.31,201.01c0.6,0.26,1.02,0.45,1.45,0.63c5.6,2.35,10.57,5.63,14.56,10.26c4.3,5,7.08,10.72,7.86,17.3
		c0.78,6.59-0.76,12.63-4.86,17.91c-3.94,5.07-8.95,8.49-15.34,9.7c-3.41,0.65-6.77,0.34-10.05-0.73
		c-7.03-2.31-12.49-6.67-16.37-12.96c-3.18-5.17-3.77-10.77-2.49-16.63c0.85-3.86,2.43-7.43,4.32-10.87
		c1.77-3.23,3.82-6.28,6.08-9.19c0.22-0.28,0.43-0.57,0.64-0.87c0.04-0.06,0.02-0.15,0.05-0.34c-0.4-0.08-0.8-0.2-1.22-0.24
		c-8.19-0.81-16.19-0.08-23.88,3c-7.63,3.05-13.55,8.19-18.13,14.94c-4.54,6.69-7.37,14.06-8.49,22.06
		c-0.96,6.86,0.17,13.47,2.92,19.78c1.19,2.74,2.59,5.41,4.13,7.96c1.64,2.71,3.94,4.92,6.26,7.07c4.54,4.19,9.75,7.34,15.49,9.59
		c0.22,0.09,0.45,0.17,0.67,0.26c2.02,0.84,2.93,2.85,2.19,4.79c-0.72,1.89-2.73,2.84-4.72,2.08c-2.05-0.78-4.07-1.64-6.03-2.62
		c-7.51-3.76-13.84-9-18.99-15.63c-0.95-1.22-1.74-2.58-2.51-3.94c-3.12-5.49-5.43-11.29-6.47-17.55c-0.24-1.46-0.36-2.93-0.56-4.4
		c-0.06-0.47-0.21-0.93-0.31-1.39C8.52,250.12,8.52,249.24,8.52,248.36z M181.99,43.59c-19.5,16.99-38.85,33.85-58.19,50.7
		c0.94,1.47,64.12,73.95,64.89,74.45c19.34-16.85,38.7-33.71,58.04-50.57c-1.11-1.69-22.59-26.22-23.36-26.67
		c-1.49,1.3-3,2.6-4.5,3.91c-4.88,4.25-9.75,8.51-14.64,12.75c-1.87,1.62-3.94,1.47-5.6-0.37c-0.51-0.56-1-1.15-1.49-1.72
		c-3.8-4.37-7.6-8.74-11.41-13.1c-0.73-0.84-1.26-1.73-1.23-2.89c0.03-1.31,0.69-2.24,1.64-3.06c5.97-5.19,11.93-10.38,17.89-15.58
		c0.39-0.34,0.76-0.69,1.23-1.11C197.49,61.39,189.79,52.55,181.99,43.59z M198.76,51.63c4.06,4.67,8.04,9.23,12.05,13.85
		c0.45-0.38,0.79-0.66,1.12-0.94c3.44-2.99,6.84-6.02,10.31-8.96c5.06-4.29,12.38-3.76,16.82,1.16c0.94,1.04,1.86,2.09,2.75,3.17
		c1.79,2.18,2.72,4.7,2.73,7.51c0.01,3.77-1.45,6.9-4.3,9.38c-3.38,2.94-6.75,5.88-10.12,8.82c-0.32,0.28-0.62,0.58-0.98,0.93
		c4.03,4.63,7.99,9.18,12.03,13.82c14.79-12.89,29.5-25.7,44.29-38.59c-2.18-2.51-4.27-4.91-6.41-7.37
		c-0.43,0.35-0.77,0.62-1.09,0.9c-2.53,2.2-5.06,4.41-7.59,6.61c-0.33,0.29-0.66,0.59-1.03,0.82c-2.16,1.4-4.99,0.25-5.56-2.26
		c-0.34-1.52,0.17-2.74,1.32-3.74c2.68-2.33,5.36-4.67,8.04-7.01c0.32-0.28,0.63-0.58,1.02-0.94c-1.16-1.32-2.25-2.56-3.38-3.84
		c-0.46,0.39-0.79,0.67-1.12,0.95c-2.53,2.2-5.06,4.41-7.59,6.61c-0.33,0.29-0.66,0.59-1.03,0.82c-2.14,1.32-4.93,0.18-5.5-2.26
		c-0.35-1.5,0.14-2.75,1.3-3.75c2.68-2.33,5.36-4.67,8.04-7.01c0.33-0.29,0.64-0.58,1.05-0.96c-1.14-1.31-2.22-2.56-3.34-3.85
		c-0.42,0.35-0.73,0.59-1.03,0.85c-2.59,2.25-5.18,4.51-7.77,6.77c-0.3,0.26-0.6,0.54-0.94,0.74c-1.22,0.75-2.5,0.81-3.75,0.1
		c-1.21-0.68-1.83-1.74-1.86-3.14c-0.03-1.25,0.51-2.22,1.44-3.02c2.69-2.33,5.36-4.67,8.04-7.01c0.32-0.28,0.62-0.58,0.98-0.92
		c-1.15-1.32-2.22-2.55-3.33-3.83c-0.39,0.32-0.7,0.56-1,0.82c-2.56,2.23-5.12,4.46-7.68,6.69c-0.3,0.26-0.59,0.54-0.92,0.76
		c-2.17,1.49-5.07,0.37-5.67-2.2c-0.36-1.52,0.18-2.74,1.32-3.74c2.68-2.34,5.36-4.67,8.04-7.01c0.33-0.28,0.64-0.59,1.02-0.94
		c-2.15-2.47-4.24-4.87-6.42-7.37C228.29,25.9,213.6,38.7,198.76,51.63z M120.07,101.42c-0.21,0.59-0.34,0.92-0.45,1.25
		c-3.18,10-6.35,20.01-9.55,30.01c-0.25,0.79-0.15,1.31,0.39,1.93c12.16,13.94,24.3,27.89,36.43,41.86c0.56,0.64,1.1,0.77,1.87,0.64
		c9.24-1.6,18.48-3.18,27.72-4.76c1.44-0.25,2.88-0.52,4.6-0.82C160.69,148.09,140.47,124.85,120.07,101.42z M99.01,231.84
		c-0.04-0.51-0.06-1.43-0.2-2.33c-0.73-4.65-2.69-8.75-5.69-12.35c-4.17-5.02-9.63-8.03-15.73-10.03c-0.6-0.2-0.96,0.03-1.33,0.47
		c-2.27,2.64-4.4,5.38-6.26,8.32c-2.27,3.59-4.26,7.31-5.35,11.45c-1.14,4.33-0.86,8.47,1.62,12.31c2.68,4.15,6.31,7.16,10.9,9
		c2.61,1.04,5.3,1.45,8.09,0.87C91.77,248.15,99.18,241.21,99.01,231.84z M201.94,100.36c0.48-0.41,0.81-0.69,1.14-0.97
		c5.03-4.38,10.06-8.76,15.09-13.15c5.66-4.93,11.29-9.91,17.01-14.78c2.3-1.96,2.78-5.07,0.47-7.45c-0.64-0.66-1.21-1.39-1.81-2.07
		c-2.09-2.37-4.77-2.55-7.17-0.46c-10.82,9.42-21.63,18.84-32.45,28.26c-0.27,0.23-0.59,0.39-0.97,0.63
		C196.25,93.83,199.04,97.04,201.94,100.36z M129.82,168.06c-4.51-5.17-8.91-10.25-13.41-15.39c-6.71,5.85-13.3,11.59-19.97,17.4
		c4.5,5.17,8.91,10.24,13.4,15.4C116.55,179.62,123.13,173.89,129.82,168.06z"
    />
  </svg>,
  'ConnectivityEthernetIcon',
);

export default ConnectivityEthernetIcon;
