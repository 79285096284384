import { gql } from '@/__generated__';
import { UseAuthorization_GetAuthorizationQuery } from '@/__generated__/graphql';

export type Authorization = UseAuthorization_GetAuthorizationQuery['authorization'];

const GET_AUTHORIZATION = gql(`#graphql
  query UseAuthorization_GetAuthorization {
    authorization {
      authorizationGeneral {
        showOrganizationFilter
        updateUser
        simulateUser
        temporaryUser
        createDevice
        updateDeviceHardwareConfiguration
      }
      authorizationDGA {
        updateInfoDGA
        retrySending
        setMaxFlow
        enableDevices
      }
      authorizationSMA {
        updateInfoSMA
      }
      authorizationAlert {
        adminView
      }
    }
  }
`);

export default GET_AUTHORIZATION;
