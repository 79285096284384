import {
  RangeInput,
  WriteExecutionLogEntityType,
  WriteExecutionLogOrderBy,
  WriteExecutionLogSeverity,
} from '@/__generated__/graphql';
import { useFormatters } from '@/hooks/useFormatters';
import { ModalsState } from '@/slices/modals';
import { useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import TruncatedTypography from '@components/TruncatedTypography';
import GET_ENTITY_GROUPED_ISSUES from '@features/integrations/graphql/queries/getEntityGroupedIssues';
import { getWriteExecutionLogSeverityAttributes } from '@features/integrations/utils/writeExecutionLogSeverityAttributes';
import StandardListPagination from '@features/standardDesign/components/listView/StandardListPagination';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Card, Divider, Skeleton, Typography } from '@mui/material';
import { type FC, Fragment, Suspense, useState } from 'react';

interface Props {
  severity: WriteExecutionLogSeverity;
  entity: Extract<ModalsState['viewEntityGroupedIssues'], { open: true }>['entity'];
  range?: RangeInput;
}

const SuspenseComponent: FC<Props> = ({ entity, severity, range }) => {
  const { i18n } = useLingui();
  const { formatDateTimeRange } = useFormatters();
  const [pagination, setPagination] = useState({
    rowsPerPage: 10,
    page: 1,
  });

  const { data } = useSuspenseQuery(GET_ENTITY_GROUPED_ISSUES, {
    variables: {
      input: {
        entities: [{ id: entity.id, entityType: entity.type }],
        entityTypes: [entity.type],
        severities: [severity],
        range,
        orderBy: WriteExecutionLogOrderBy.CreatedAtDesc,
        pagination: {
          limit: pagination.rowsPerPage,
          offset: (pagination.page - 1) * pagination.rowsPerPage,
        },
      },
    },
  });

  const issues = data.writeExecutionLogGroupedIssues.items;
  const headers = [
    <Trans>Rango</Trans>,
    <Trans>Código</Trans>,
    <Trans>Severidad</Trans>,
    <Trans>Contador</Trans>,
  ];

  const rows = issues.map((issue) => {
    const severityAttributes = getWriteExecutionLogSeverityAttributes(issue.severity, i18n);
    return {
      id: issue.id,
      color: severityAttributes.color,
      cells: [
        formatDateTimeRange(issue.createdAt, issue.updatedAt),
        issue.code,
        severityAttributes.label,
        issue.logs.pageInfo.totalCount,
      ],
    };
  });

  const pageInfo = data.writeExecutionLogGroupedIssues.pageInfo;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Card elevation={0} sx={{ border: 1, borderColor: 'divider' }}>
        {rows.length === 0 ? (
          <Box p={2} display="flex" justifyContent="center">
            <Typography component="i" fontSize="small" color="text.secondary">
              {entity.type === WriteExecutionLogEntityType.Integration ? (
                <Trans>No hay logs disponibles para esta integracion </Trans>
              ) : entity.type === WriteExecutionLogEntityType.DeviceIntegration ? (
                <Trans>No hay logs disponibles para este dispositivo </Trans>
              ) : (
                <Trans>No hay logs disponibles para esta entidad </Trans>
              )}
            </Typography>
          </Box>
        ) : (
          <Box
            position="relative"
            display="grid"
            gridTemplateColumns={`repeat(${headers.length}, minmax(0, auto) )`}
            columnGap={1.5}
            overflow="hidden"
          >
            {headers.map((header, index) => {
              return (
                <TruncatedTypography pl={2} key={index} fontSize="small" fontWeight="medium">
                  {header}
                </TruncatedTypography>
              );
            })}
            <Divider sx={{ gridColumn: '1 / -1' }} />

            {rows.map(({ id, color, cells }) => {
              return (
                <Fragment key={id}>
                  {cells.map((cell, index) => {
                    return (
                      <TruncatedTypography
                        pl={2}
                        key={`${id}-${index}`}
                        fontSize="small"
                        position="relative"
                      >
                        {index === 0 && (
                          <Box
                            bgcolor={color}
                            position="absolute"
                            top={0}
                            bottom={0}
                            left={0}
                            width={4}
                          />
                        )}
                        {cell}
                      </TruncatedTypography>
                    );
                  })}
                </Fragment>
              );
            })}
          </Box>
        )}
      </Card>
      {rows.length > 0 && (
        <StandardListPagination
          totalItems={pageInfo.totalCount}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          onPageChange={(page) => setPagination((prev) => ({ ...prev, page }))}
          onRowsPerPageChange={(rowsPerPage) => setPagination((prev) => ({ ...prev, rowsPerPage }))}
        />
      )}
    </Box>
  );
};

const SuspenseFallback: FC = () => {
  return <Skeleton width="100%" />;
};

export const GroupedLogsTable: FC<Props> = (props) => {
  return (
    <ApolloErrorBoundary variant="section-alert">
      <Suspense fallback={<SuspenseFallback />}>
        <SuspenseComponent {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};
