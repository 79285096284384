import { actions, type ModalsState } from '@/slices/modals';
import { useDispatch, useSelector } from '@/store';
import { useEffect, useState } from 'react';

type ModalKey = keyof ModalsState;

/**
 * Used to close modal after Dialog transition end
 */
const useReduxModal = <TKey extends ModalKey>(
  /**
   * Key from `./slices/modals` slice.
   * To add another key follow the instruction of the slice file.
   */
  key: TKey,
) => {
  type ActionKey = `set${Capitalize<TKey>}`;
  const actionKey = `set${key.charAt(0).toUpperCase()}${key.slice(1)}` as ActionKey;
  const { [key]: modalState } = useSelector((state) => state.modals_store);
  const setModalState = actions[actionKey];

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(modalState.open);
  }, [modalState.open]);

  const setReduxClose = () => {
    dispatch(setModalState({ open: false }));
  };

  // TODO: remove type errors
  // @ts-expect-error need fix types
  const setReduxState: typeof setModalState = (...args) => {
    // @ts-expect-error need fix types
    dispatch(setModalState(...args));
  };

  const closeModal = () => {
    setOpen(false);
  };

  return {
    setModalState: setReduxState,
    modalState,
    isOpenModal: open,
    closeModal,
    dialogProps: {
      TransitionProps: {
        onExited: setReduxClose,
      },
    },
  };
};

export default useReduxModal;
