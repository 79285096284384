import { createSvgIcon } from '@mui/material';

const HousingCerraduraCajaFuerteIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M152.96,263.76c-35.76,0-71.52,0-107.28,0c-0.52,0-1.04,0.02-1.56-0.01c-1.93-0.09-3.44-1.6-3.55-3.55
			c-0.02-0.4-0.01-0.8-0.01-1.2c0-71.76,0.01-143.52-0.04-215.28c0-3.44,2.09-4.8,4.84-4.79c71.72,0.05,143.44,0.05,215.16,0
			c3.2,0,4.89,1.75,4.89,4.89c-0.05,71.72-0.03,143.44-0.04,215.16c0,0.67-0.02,1.37-0.19,2.02c-0.43,1.66-1.8,2.7-3.5,2.76
			c-0.44,0.02-0.88,0.01-1.32,0.01C224.56,263.76,188.76,263.76,152.96,263.76z M257.94,256.33c0-70.05,0-139.94,0-209.93
			c-70.01,0-139.93,0-209.94,0c0,70,0,139.92,0,209.93C118,256.33,187.93,256.33,257.94,256.33z"
    />
    <path
      d="M230.7,101.15c0.39,0.02,0.86,0.06,1.33,0.06c3.52,0.01,7.04,0,10.56,0c0.44,0,0.88,0,1.32,0.04
			c1.94,0.17,3.25,1.37,3.58,3.28c0.07,0.43,0.08,0.87,0.09,1.31c0.01,6.24,0.01,12.48,0,18.72c0,0.48-0.02,0.96-0.11,1.43
			c-0.38,1.94-1.79,3.12-3.81,3.14c-2.84,0.03-5.68,0.01-8.52,0.01c-1.48,0-2.95,0-4.39,0c-0.33,0.96-0.41,39.33-0.1,40.95
			c0.39,0.02,0.81,0.07,1.23,0.07c3.84,0.01,7.68,0.02,11.52,0c1.32-0.01,2.43,0.41,3.28,1.43c0.7,0.84,0.9,1.85,0.9,2.91
			c0,3.16,0,6.32,0,9.48c0,3.24,0,6.48,0,9.72c0,1.07-0.18,2.07-0.86,2.93c-0.86,1.09-2.02,1.51-3.38,1.51c-3.8,0-7.6,0-11.4,0
			c-0.43,0-0.86,0-1.47,0c0,1,0,1.87,0,2.74c0.02,4.31-0.84,8.44-2.79,12.28c-4.42,8.69-11.48,13.85-21.1,15.46
			c-1.41,0.24-2.87,0.29-4.3,0.29c-39.76,0.01-79.52,0.01-119.28,0.02c-1.78,0-3.42-0.35-4.84-1.46c-1.75-1.36-2.65-3.16-2.74-5.37
			c-0.01-0.28,0-0.56,0-0.84c0-46.56,0-93.12,0.01-139.68c0-0.83,0.04-1.69,0.23-2.49c0.66-2.84,3.14-4.93,6.06-5.17
			c0.6-0.05,1.2-0.06,1.8-0.06c39.4,0,78.8,0.01,118.2-0.02c6.99-0.01,13.26,1.95,18.63,6.45c6.12,5.13,9.5,11.73,10.05,19.73
			c0.02,0.28,0.06,0.55,0.11,0.83C230.52,100.91,230.59,100.98,230.7,101.15z M82.82,221.56c0.68,0,1.23,0,1.78,0
			c39.04,0,78.08,0,117.12,0c0.44,0,0.88,0,1.32-0.01c3.33-0.1,6.51-0.87,9.39-2.55c6.97-4.06,10.52-10.16,10.7-18.22
			c0.02-0.83,0-1.66,0-2.64c-0.59,0-1.02,0-1.45,0c-2.64,0-5.28,0-7.92,0c-1.2,0-2.4,0.02-3.6-0.01c-2.42-0.05-3.96-1.52-4.13-3.93
			c-0.03-0.36-0.01-0.72-0.01-1.08c0-5.68,0-11.36,0-17.04c0-0.48,0-0.96,0-1.44c0-0.28,0-0.56,0.04-0.84
			c0.28-2.25,1.76-3.61,4.02-3.63c3.84-0.03,7.68-0.01,11.52-0.01c0.46,0,0.93-0.04,1.35-0.07c0.31-1.58,0.23-39.99-0.1-40.95
			c-0.44,0-0.91,0-1.39,0c-3.8,0-7.6-0.05-11.4,0.02c-1.75,0.03-3.76-1.14-4.02-3.64c-0.03-0.32-0.03-0.64-0.03-0.96
			c0-6.24-0.01-12.48,0.01-18.72c0-0.56,0.05-1.12,0.17-1.66c0.34-1.54,1.59-2.68,3.15-2.88c0.51-0.07,1.04-0.07,1.56-0.08
			c3.56-0.01,7.12,0,10.68-0.01c0.46,0,0.92-0.06,1.47-0.09c-0.04-0.76-0.01-1.4-0.11-2.02c-1.26-7.7-5.44-13.17-12.59-16.33
			c-2.83-1.25-5.84-1.58-8.91-1.58c-38.92,0-77.84,0-116.76,0c-0.32,0-0.64-0.02-0.96,0.01c-0.3,0.03-0.6,0.1-0.91,0.16
			C82.82,128.08,82.82,174.71,82.82,221.56z M213.47,121.7c8.97,0,17.78,0,26.67,0c0-4.37,0-8.66,0-12.94
			c-1.07-0.29-25.53-0.3-26.67-0.01C213.47,113.03,213.47,117.32,213.47,121.7z M240.14,177.61c-8.97,0-17.79,0-26.58,0
			c-0.31,1.48-0.23,12.23,0.07,13.05c1.53,0.24,25.57,0.16,26.5-0.11C240.14,186.28,240.14,181.99,240.14,177.61z"
    />
    <path
      d="M164.58,140.92c0.63,0,1.06,0,1.49,0c2.92,0,5.84,0.08,8.76-0.02c2.32-0.08,4.15,1.66,4.15,4.1
			c-0.03,15.11-0.01,30.23-0.02,45.34c0,2.58-1.5,4.07-4.11,4.08c-4.8,0.02-9.6,0-14.39,0c-15.31,0-30.63,0-45.94,0
			c-0.52,0-1.04,0.01-1.56-0.04c-1.69-0.16-3.05-1.49-3.24-3.18c-0.05-0.48-0.05-0.96-0.05-1.44c0-14.71,0.04-29.43-0.04-44.14
			c-0.02-3.29,1.83-4.89,4.73-4.73c2.75,0.15,5.52,0.03,8.28,0.03c0.43,0,0.85,0,1.43,0c0-0.63,0-1.1,0-1.57c0-6,0.02-12-0.01-17.99
			c-0.04-8.38,5.41-16.52,14.26-19.26c11.72-3.62,23.91,3.9,25.97,16c0.2,1.18,0.27,2.39,0.28,3.58c0.03,5.88,0.01,11.76,0.01,17.63
			C164.58,139.79,164.58,140.26,164.58,140.92z M171.52,148.36c-18.18,0-36.26,0-54.33,0c-0.26,1.31-0.22,37.72,0.03,38.61
			c1.85,0.24,53.4,0.14,54.3-0.11C171.52,174.05,171.52,161.24,171.52,148.36z M157.22,140.85c0-0.56,0-1,0-1.43
			c0-5.8,0.01-11.59-0.01-17.39c0-0.88-0.06-1.76-0.2-2.62c-1.01-6.62-7.41-12.03-15.14-10.6c-6.42,1.19-10.54,6.93-10.48,12.69
			c0.07,6.08,0.01,12.15,0.02,18.23c0,0.35,0.04,0.7,0.07,1.12C140.05,140.85,148.54,140.85,157.22,140.85z"
    />
    <path
      d="M140.65,165.8c0-1.48-0.01-2.95,0-4.43c0.03-2.19,1.55-3.8,3.6-3.84c2.02-0.04,3.7,1.58,3.73,3.73
			c0.05,3.03,0.07,6.07-0.01,9.1c-0.07,2.68-2.69,4.37-5.08,3.35c-1.41-0.6-2.21-1.69-2.24-3.24
			C140.62,168.91,140.65,167.35,140.65,165.8C140.65,165.8,140.65,165.8,140.65,165.8z"
    />
  </svg>,
  'HousingCerraduraCajaFuerteIcon',
);

export default HousingCerraduraCajaFuerteIcon;
