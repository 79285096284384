import { useGlobalModal } from '@/hooks/useGlobalModal';
import DeviceModalTitle, { DeviceModalTitleAction } from '@components/DeviceModalTitle';
import GlobalModal from '@components/GlobalModal';
import ModalTabs, { Panel } from '@components/ModalTabs';
import { Access } from '@features/authorization';
import { type I18n } from '@lingui/core';
import { msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type FC } from 'react';
import AccessTab from './accessTab/AccessTab';
import GeneralTab from './generalTab/GeneralTab';
import { ContentCopy, Delete, PublishedWithChanges } from '@mui/icons-material';
import StatusTab from './statusTab';

const DeviceConfigurationModal: FC = () => {
  const { i18n } = useLingui();
  const { globalModalProps, state, onClose, changeTabMiddleware, setIsDirty } = useGlobalModal(
    'updateDeviceConfigurationModal',
    {
      closeConfirmation: true,
    },
  );

  const actions = useActions((onActionClick) => {
    if (state.open) onActionClick(state.deviceId);
  });

  return (
    <GlobalModal
      {...globalModalProps}
      dialogProps={{ maxWidth: 'xl' }}
      authorization={{
        access: Access.DeviceConfigurationModal,
        deviceId: state.open ? state.deviceId : undefined,
        onClose,
      }}
    >
      {state.open && (
        <>
          <DeviceModalTitle actions={actions} deviceId={state.deviceId} onClose={onClose} />
          <ModalTabs
            deviceId={state.deviceId}
            changeTabMiddleware={changeTabMiddleware}
            panels={getTabs(state.deviceId, onClose, setIsDirty, i18n)}
          />
        </>
      )}
    </GlobalModal>
  );
};

const getTabs = (
  deviceId: string,
  onClose: () => void,
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>,
  i18n: I18n,
): Array<Panel> => {
  const props = {
    deviceId,
    onClose,
    setIsDirty,
  };
  return [
    {
      key: 'general',
      label: t(i18n)`General`,
      component: <GeneralTab {...props} />,
      access: Access.DeviceConfigurationModal,
    },
    {
      key: 'state',
      label: t(i18n)`Estado`,
      component: <StatusTab {...props} />,
      access: Access.DeviceConfigurationModal,
    },
    {
      key: 'access',
      label: t(i18n)`Accesos`,
      component: <AccessTab {...props} />,
      access: Access.DeviceConfigurationModal,
    },
  ];
};

const useActions = (onActionClickCallback: (onActionClick: (deviceId: string) => void) => void) => {
  const { _ } = useLingui();
  const { setModalState: setUpdateDeviceStatusModal } = useGlobalModal('updateDeviceStatusModal');
  const { setModalState: setCloneDeviceModal } = useGlobalModal('cloneDeviceModal');
  const { setModalState: setDeleteDeviceModal } = useGlobalModal('deleteDeviceModal');

  const onUpdateStatusClick = () => {
    onActionClickCallback((deviceId) => {
      setUpdateDeviceStatusModal({ open: true, deviceId });
    });
  };

  const onCloneDeviceClick = () => {
    onActionClickCallback((deviceId) => {
      setCloneDeviceModal({ open: true, deviceId });
    });
  };

  const onDeleteDeviceClick = () => {
    onActionClickCallback((deviceId) => {
      setDeleteDeviceModal({ open: true, deviceId });
    });
  };

  const actions: Array<DeviceModalTitleAction> = [
    // {
    //   key: 'update-status',
    //   label: _(msg`Cambiar estado`),
    //   color: 'primary',
    //   icon: <PublishedWithChanges />,
    //   access: Access.UpdateDeviceStatus,
    //   onClick: onUpdateStatusClick,
    // },
    {
      key: 'clone',
      label: _(msg`Clonar dispositivo`),
      color: 'primary',
      icon: <ContentCopy />,
      access: Access.CloneDeviceModal,
      onClick: onCloneDeviceClick,
    },
    {
      key: 'delete',
      label: _(msg`Eliminar dispositivo`),
      color: 'error',
      icon: <Delete />,
      access: Access.DeleteDeviceModal,
      onClick: onDeleteDeviceClick,
    },
  ];
  return actions;
};

export default DeviceConfigurationModal;
