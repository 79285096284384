import { createSvgIcon } from '@mui/material';

const SensorVelocidadRadarSinContacto = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M1.78,150c0-47.8,0-95.6,0.01-143.4c0-0.64,0-1.29,0.14-1.9c0.35-1.56,1.34-2.52,2.93-2.77
		C5.6,1.81,6.37,1.8,7.13,1.8c43.72-0.01,87.44,0,131.16,0c51.72,0,103.44,0,155.15,0.01c0.76,0,1.53,0,2.27,0.14
		c1.53,0.28,2.47,1.24,2.83,2.74c0.1,0.42,0.12,0.87,0.16,1.31c0.02,0.32,0.01,0.64,0.01,0.96c0,95.44,0,190.88,0,286.32
		c0,0.68,0,1.36-0.11,2.03c-0.28,1.81-1.58,2.99-3.4,3.15c-0.4,0.04-0.8,0.01-1.2,0.01c-95.84,0-191.67-0.01-287.51,0.03
		c-3.13,0-4.73-1.64-4.73-4.74C1.8,245.84,1.78,197.92,1.78,150z M291.09,291.03c0.09-0.15,0.15-0.21,0.17-0.29
		c0.03-0.11,0.06-0.23,0.06-0.35c0-93.4,0.01-186.8,0.01-280.19c0-0.24-0.05-0.47-0.07-0.71c0-0.04-0.03-0.07-0.05-0.1
		c-0.02-0.03-0.06-0.06-0.25-0.23c-0.43,0-1.02,0-1.61,0c-92.75,0-185.51,0-278.26,0c-0.36,0-0.72-0.02-1.08,0.01
		C9.75,9.19,9.49,9.28,9.24,9.33C9.2,9.57,9.16,9.72,9.16,9.88c0,93.44-0.01,186.88-0.01,280.31c0,0.2,0.05,0.39,0.08,0.59
		c0.01,0.04,0.03,0.07,0.06,0.1c0.02,0.03,0.05,0.06,0.13,0.14C103.29,291.03,197.22,291.03,291.09,291.03z"
    />
    <path
      d="M52.3,137.86c1.37,0.06,2.44-0.25,3.51-0.58c2.08-0.65,3.99-1.64,5.83-2.78c2.41-1.5,4.78-3.07,7.15-4.62
		c3.02-1.97,6.06-3.88,9.4-5.26c2.69-1.11,5.47-1.8,8.39-1.74c2.58,0.05,5.06,0.64,7.48,1.54c2.74,1.02,5.33,2.37,7.87,3.8
		c2.82,1.58,5.61,3.23,8.49,4.71c1.69,0.88,3.49,1.58,5.3,2.21c2.73,0.95,5.49,0.85,8.24-0.02c2.45-0.78,4.74-1.89,7-3.1
		c2.79-1.48,5.55-3.01,8.36-4.45c2.71-1.39,5.53-2.51,8.54-3.06c3.91-0.72,7.71-0.24,11.43,1.11c3.13,1.14,6.05,2.71,8.94,4.34
		c2.4,1.36,4.77,2.78,7.22,4.04c1.73,0.89,3.56,1.63,5.39,2.31c2.64,0.98,5.33,0.87,8.01,0.07c2.46-0.74,4.76-1.85,7.01-3.07
		c2.67-1.44,5.31-2.94,8-4.36c3.16-1.67,6.44-3.02,9.99-3.6c1.09-0.18,2.2-0.27,3.43-0.42c0-0.56,0-1.07,0-1.58
		c0-16.75,0-33.49,0-50.24c0-0.4-0.02-0.8,0.01-1.2c0.12-1.91,1.56-3.36,3.47-3.49c0.36-0.02,0.72-0.01,1.08-0.01
		c8.44,0,16.88,0,25.32,0c0.47,0,0.95,0,1.54,0c0.02-0.56,0.06-1.02,0.06-1.48c0-7.31,0-14.63,0.01-21.94c0-0.64,0.03-1.29,0.17-1.9
		c0.39-1.78,2.06-2.91,3.95-2.75c1.71,0.15,3.09,1.58,3.21,3.33c0.02,0.28,0.01,0.56,0.02,0.84c0.04,17.58,0.12,35.16,0.13,52.75
		c0.02,50.52,0.01,101.03,0,151.55c0,2.36-0.07,4.71-0.14,7.07c-0.06,2-1.51,3.45-3.49,3.56c-0.36,0.02-0.72,0.01-1.08,0.01
		c-67.32,0-134.63,0-201.95,0c-0.56,0-1.13,0.02-1.67-0.09c-1.66-0.34-2.79-1.66-2.94-3.36c-0.03-0.36-0.02-0.72-0.02-1.08
		c0-70.02,0-140.04,0.01-210.06c0-0.63,0.02-1.29,0.19-1.89c0.48-1.79,2.18-2.85,4.05-2.61c1.69,0.22,3.01,1.69,3.1,3.45
		c0.02,0.44,0.01,0.88,0.01,1.32c0,30.33,0,60.67,0,91C52.3,136.66,52.3,137.21,52.3,137.86z M52.36,251.91
		c1.47,0.26,164.28,0.2,165.01-0.01c-0.04-1.26-0.1-2.49-0.1-3.72c-0.02-2.32,0-4.64,0-6.95c0-35.69,0-71.38,0-107.06
		c0-0.55,0-1.09,0-1.76c-0.55,0.03-0.94,0.03-1.32,0.07c-2.05,0.2-4.02,0.77-5.85,1.68c-3.04,1.5-6.04,3.1-9.03,4.7
		c-2.85,1.53-5.71,3.04-8.77,4.14c-5.02,1.8-10.04,1.88-14.99-0.11c-2.55-1.03-5.02-2.27-7.46-3.54c-2.55-1.33-4.98-2.9-7.53-4.21
		c-2.09-1.07-4.25-2.03-6.45-2.86c-2.82-1.06-5.73-1.02-8.58-0.03c-1.43,0.5-2.86,1.03-4.21,1.7c-2.57,1.28-5.09,2.67-7.63,4.03
		c-2.68,1.43-5.38,2.82-8.25,3.83c-3.98,1.4-8.02,1.79-12.14,0.8c-2.54-0.61-4.97-1.52-7.27-2.74c-3.45-1.85-6.85-3.79-10.28-5.68
		c-2.2-1.22-4.44-2.38-6.85-3.15c-2.04-0.65-4.11-1.12-6.24-0.61c-1.39,0.33-2.82,0.69-4.08,1.33c-2.45,1.25-4.82,2.66-7.17,4.09
		c-3.14,1.92-6.18,3.98-9.34,5.87c-2.55,1.53-5.28,2.65-8.22,3.2c-1.05,0.2-2.12,0.3-3.23,0.46
		C52.36,180.9,52.36,216.38,52.36,251.91z M224.67,187.85c1.85,0,3.56,0,5.27,0c1.76,0,3.52-0.03,5.28,0.01
		c1.97,0.04,3.44,1.19,3.78,2.87c0.49,2.4-1.11,4.44-3.56,4.46c-3.12,0.03-6.24,0.01-9.36,0.01c-0.46,0-0.92,0.05-1.36,0.08
		c-0.27,2.04-0.17,23.46,0.14,24.38c0.44,0,0.91,0,1.38,0c3.12,0,6.24-0.03,9.36,0.02c1.84,0.03,3.25,1.41,3.45,3.2
		c0.2,1.86-0.82,3.45-2.59,3.94c-0.49,0.14-1.02,0.18-1.54,0.18c-3,0.02-6,0.01-9,0.01c-0.42,0-0.84,0.05-1.33,0.08
		c0,0.62,0,1.1,0,1.57c0,5.99,0.01,11.99,0,17.98c0,1.75-0.05,3.5-0.09,5.32c7.95,0,16,0,24.04,0c0.28-1.24,0.24-111.51-0.04-112.46
		c-4.45-0.16-8.75-1.15-12.98-2.54c-3.15-1.03-6.29-2.07-9.44-3.09c-0.44-0.14-0.89-0.23-1.4-0.36c0,8.04,0,15.92,0,23.9
		c0.52,0.03,0.95,0.07,1.38,0.07c2.8,0.01,5.6,0,8.4,0.01c0.48,0,0.96,0,1.44,0.05c1.7,0.18,3,1.52,3.15,3.24
		c0.16,1.75-0.82,3.36-2.43,3.83c-0.56,0.17-1.18,0.19-1.78,0.19c-2.92,0.02-5.84,0.01-8.76,0.01c-0.47,0-0.93,0.05-1.44,0.07
		C224.67,172.58,224.67,180.12,224.67,187.85z M224.68,100.73c0,8.49,0,16.78,0,25.09c0.27,0.09,0.49,0.18,0.71,0.25
		c2.86,0.89,5.73,1.74,8.57,2.66c3.64,1.18,7.26,2.47,11.06,3.07c1.17,0.18,2.35,0.27,3.5,0.4c0.29-1.24,0.28-55.31,0.02-56.34
		c-7.92,0-15.85,0-23.85,0c0,5.85,0,11.62,0,17.46c0.47,0.02,0.86,0.06,1.25,0.06c1.96,0.01,3.92,0,5.88,0
		c1.24,0,2.48-0.04,3.72,0.01c1.88,0.09,3.29,1.39,3.51,3.16c0.23,1.85-0.77,3.5-2.5,4.01c-0.52,0.15-1.1,0.17-1.66,0.17
		c-2.92,0.02-5.84,0.01-8.76,0.01C225.71,100.73,225.28,100.73,224.68,100.73z"
    />
    <path
      d="M179.17,71.55c-6.36,0-12.71,0-19.07,0c-0.44,0-0.88,0.01-1.32-0.03c-2.08-0.18-3.43-1.61-3.45-3.71
		c-0.02-1.92-0.01-3.83-0.01-5.75c0-5.43,0.08-10.86-0.03-16.29c-0.05-2.67,1.84-4.27,4.21-4.26c13.15,0.05,26.3,0.03,39.45,0.02
		c1.46,0,2.67,0.44,3.52,1.66c0.45,0.65,0.6,1.38,0.6,2.16c0,7.47,0.01,14.94-0.01,22.4c-0.01,2.39-1.47,3.77-3.98,3.79
		c-3.72,0.03-7.43,0.01-11.15,0.01C185,71.55,182.08,71.55,179.17,71.55z M195.57,64.14c0.26-1.72,0.17-14.31-0.08-15.17
		c-10.88,0-21.77,0-32.73,0c0,5.08,0,10.08,0,15.17C173.73,64.14,184.62,64.14,195.57,64.14z"
    />
    <path
      d="M144.91,106.84c0.01,2.09-1.63,3.78-3.74,3.65c-1.3-0.08-2.6-0.38-3.87-0.71c-11.96-3.07-21.4-9.71-28.3-19.96
		c-0.51-0.75-0.81-1.54-0.78-2.46c0.05-1.5,0.95-2.82,2.32-3.33c1.43-0.54,3.03-0.17,4.05,0.97c0.21,0.24,0.39,0.51,0.57,0.77
		c6.42,9.45,15.24,15.26,26.47,17.41C143.65,103.56,144.91,104.95,144.91,106.84z"
    />
    <path
      d="M179.29,115.96c-6.58-0.05-12.81-1.27-18.76-3.82c-1.87-0.8-2.71-2.09-2.58-3.88
		c0.16-2.19,2.27-3.76,4.41-3.25c0.46,0.11,0.91,0.29,1.35,0.48c4.96,2.08,10.15,3.12,15.52,3.12c5.41-0.01,10.63-1.06,15.62-3.19
		c0.93-0.4,1.86-0.69,2.9-0.38c1.48,0.45,2.41,1.39,2.66,2.92c0.25,1.56-0.27,2.84-1.61,3.72c-0.33,0.22-0.71,0.37-1.07,0.52
		C191.79,114.72,185.6,115.91,179.29,115.96z"
    />
    <path
      d="M145.87,92.62c0.01,2.38-2.1,4.14-4.43,3.61c-8.12-1.86-14.65-6.17-19.63-12.83c-1.01-1.35-1.11-2.8-0.25-4.26
		c0.77-1.3,2.24-1.91,3.83-1.64c1,0.17,1.71,0.77,2.35,1.52c1.14,1.33,2.25,2.71,3.53,3.9c3.28,3.06,7.17,5.03,11.52,6.1
		C144.73,89.48,145.87,90.82,145.87,92.62z"
    />
    <path
      d="M179.3,101.92c-3.77-0.14-7.42-0.75-10.96-1.97c-0.26-0.09-0.53-0.17-0.79-0.28
		c-2.02-0.82-2.95-2.84-2.22-4.78c0.71-1.89,2.67-2.76,4.72-2.16c1.68,0.49,3.37,1.01,5.09,1.32c4.38,0.79,8.67,0.32,12.86-1.13
		c0.56-0.19,1.16-0.33,1.75-0.35c1.76-0.05,3.2,1.09,3.6,2.75c0.41,1.71-0.37,3.53-2.02,4.17c-1.74,0.67-3.55,1.25-5.38,1.6
		C183.76,101.51,181.52,101.66,179.3,101.92z"
    />
    <path
      d="M148.29,79.72c-0.04,2.26-2.08,4.02-4.29,3.5c-1.42-0.33-2.82-0.87-4.14-1.49c-2.75-1.29-5.07-3.18-6.92-5.59
		c-1.82-2.38-0.49-5.58,2.46-5.96c1.37-0.17,2.49,0.38,3.35,1.45c1.38,1.71,3.08,3,5.1,3.86c0.62,0.27,1.28,0.44,1.92,0.67
		C147.36,76.73,148.32,78.09,148.29,79.72z"
    />
    <path
      d="M179.38,88.52c-2.24,0.02-4.4-0.29-6.49-1.02c-1.4-0.49-2.27-1.46-2.46-2.95c-0.35-2.76,2-4.8,4.7-4.03
		c1.83,0.52,3.67,0.79,5.55,0.57c0.98-0.12,1.95-0.37,2.92-0.61c2.18-0.53,4.14,0.66,4.62,2.84c0.39,1.76-0.6,3.53-2.39,4.17
		C183.73,88.23,181.57,88.55,179.38,88.52z"
    />
    <path
      d="M121.34,189.84c-0.3-0.31-0.59-0.63-0.89-0.94c-2.37-2.38-4.73-4.77-7.11-7.13c-0.87-0.86-1.38-1.85-1.31-3.09
		c0.08-1.39,0.73-2.45,1.97-3.07c1.23-0.61,2.48-0.56,3.65,0.18c0.33,0.21,0.63,0.49,0.91,0.77c4.74,4.75,9.47,9.52,14.21,14.27
		c0.66,0.66,1.23,1.37,1.36,2.33c0.18,1.31-0.16,2.44-1.12,3.39c-1.36,1.35-2.7,2.72-4.06,4.08c-3.44,3.46-6.88,6.91-10.31,10.37
		c-1.12,1.13-2.38,1.74-3.99,1.27c-2.47-0.73-3.47-3.62-1.95-5.69c0.28-0.38,0.63-0.72,0.97-1.06c2.28-2.3,4.57-4.59,6.85-6.88
		c0.27-0.27,0.53-0.57,0.97-1.05c-0.64-0.03-1.05-0.07-1.46-0.07c-6.88,0-13.75,0-20.63,0c-9.32,0-18.63,0-27.95-0.01
		c-0.56,0-1.12-0.03-1.66-0.15c-1.81-0.41-2.97-2.09-2.78-3.93c0.19-1.79,1.62-3.15,3.43-3.25c0.48-0.03,0.96-0.01,1.44-0.01
		c15.99,0,31.98,0,47.98,0c0.46,0,0.93,0,1.39,0C121.28,190.05,121.31,189.94,121.34,189.84z"
    />
    <path
      d="M96.97,229.19c-0.69,0-1.11,0-1.53,0c-7.92,0-15.84,0-23.75,0c-0.52,0-1.05,0.01-1.55-0.08
		c-1.98-0.34-3.26-1.98-3.12-3.92c0.14-1.93,1.67-3.35,3.69-3.35c8.24-0.01,16.48-0.01,24.71-0.01c0.43,0,0.86,0,1.6,0
		c-0.43-0.5-0.66-0.79-0.92-1.06c-2.22-2.24-4.46-4.48-6.68-6.71c-0.39-0.4-0.8-0.79-1.15-1.22c-1.2-1.48-1.08-3.58,0.26-4.91
		c1.28-1.28,3.36-1.42,4.83-0.32c0.29,0.22,0.54,0.47,0.79,0.73c4.71,4.73,9.41,9.47,14.13,14.19c0.89,0.89,1.45,1.89,1.37,3.19
		c-0.07,1.11-0.63,1.94-1.39,2.7c-3.93,3.93-7.85,7.87-11.77,11.81c-0.93,0.93-1.84,1.89-2.79,2.8c-0.96,0.92-2.13,1.27-3.43,0.96
		c-2.7-0.65-3.74-3.86-1.93-5.98c0.52-0.61,1.1-1.16,1.67-1.72c2-2.01,4.01-4.02,6.01-6.03C96.29,229.99,96.53,229.69,96.97,229.19z
		"
    />
    <path
      d="M97,164.89c-0.8-0.03-1.31-0.06-1.81-0.06c-7.84,0-15.67,0-23.51,0c-0.48,0-0.96,0.01-1.44-0.04
		c-1.9-0.21-3.26-1.78-3.23-3.68c0.03-1.9,1.44-3.41,3.36-3.56c0.52-0.04,1.04-0.04,1.56-0.04c7.8,0,15.59,0,23.39,0
		c0.46,0,0.92-0.04,1.68-0.07c-0.5-0.54-0.79-0.85-1.09-1.15c-2.42-2.44-4.84-4.88-7.26-7.32c-1-1.01-1.44-2.19-1.08-3.6
		c0.69-2.66,3.76-3.69,5.9-1.95c0.53,0.43,0.98,0.93,1.46,1.42c4.42,4.45,8.86,8.89,13.26,13.36c0.52,0.53,1,1.17,1.25,1.85
		c0.54,1.44,0.13,2.75-0.94,3.84c-1.4,1.42-2.81,2.84-4.22,4.26c-3.41,3.43-6.83,6.85-10.23,10.28c-1.09,1.1-2.32,1.67-3.89,1.23
		c-2.5-0.71-3.52-3.55-2.02-5.66c0.27-0.39,0.62-0.73,0.96-1.07c2.28-2.3,4.57-4.59,6.85-6.88C96.24,165.74,96.52,165.42,97,164.89z
		"
    />
  </svg>,
  'SensorVelocidadRadarSinContacto',
);

export default SensorVelocidadRadarSinContacto;
