import { UpdateDeviceCalibrationLevelInput } from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { DeviceTabProps } from '@features/deviceDataConfigurationModal/components/tabs/types';
import UPDATE_DEVICE_CALIBRATION_LEVEL from '@features/deviceDataConfigurationModal/graphql/mutations/updateDeviceCalibrationLevel';
import GET_DEVICE_LEVEL_CALIBRATION from '@features/deviceDataConfigurationModal/graphql/queries/getDeviceLevelCalibration';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { useEffect, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import NumberController from './NumberController';

const schema = () => {
  return z.object({
    deviceId: z.string(),
    level: z.coerce.number({ invalid_type_error: 'Debes ingresar un número' }),
  }) satisfies z.ZodType<UpdateDeviceCalibrationLevelInput>;
};

type FormValues = z.infer<ReturnType<typeof schema>>;

const LevelCalibrationForm: FC<DeviceTabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { data } = useSuspenseQuery(GET_DEVICE_LEVEL_CALIBRATION, { variables: { deviceId } });
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();

  const defaultValues = {
    deviceId,
    level: data.device.dataConfiguration.calibration?.level ?? undefined,
  };

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(schema()),
  });

  const [update, { loading: updateLoading }] = useMutation(UPDATE_DEVICE_CALIBRATION_LEVEL, {
    onCompleted: (data) => {
      openModalSuccessMessage(_(msg`Datos actualizados correctamente`));
      methods.reset({
        deviceId,
        level: data.updateDeviceCalibrationLevel.calibration?.level ?? undefined,
      });
    },
    onError: (errors) => {
      openModalErrorMessage(_(msg`Error al actualizar los datos: ${errors.message}`));
    },
  });

  const { isDirty, isSubmitting } = methods.formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  if (!data.device.profile.sensor?.includes('L')) return null;

  const submitHandler = async (formData: FormValues) => {
    update({ variables: { input: formData } });
  };

  return (
    <form onSubmit={methods.handleSubmit(submitHandler)}>
      <DialogContent
        sx={{
          maxWidth: 'md',
          minHeight: 500,
          margin: 'auto',
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <Typography variant="h5" mb={2}>
          <Trans>Calibración nivel</Trans>
        </Typography>
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" fontSize="small" gap={2} mb={3}>
          <NumberController
            control={methods.control}
            name="level"
            label={_(msg`Nivel de calibración`)}
            endAdornment="cm"
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ maxWidth: 'md', margin: 'auto', py: 2 }}>
        <Button
          disabled={!isDirty}
          color="info"
          variant="outlined"
          onClick={() => methods.reset(defaultValues)}
        >
          <Trans>Restablecer Formulario</Trans>
        </Button>
        <Box flexGrow={1} />
        <Button color="info" variant="outlined" onClick={() => onClose()}>
          <Trans>Cerrar</Trans>
        </Button>
        <LoadingButton
          disabled={!isDirty}
          loading={isSubmitting || updateLoading}
          type="submit"
          variant="contained"
        >
          <Trans>Guardar</Trans>
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default LevelCalibrationForm;
