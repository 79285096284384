import { useSuspenseQuery } from '@apollo/client';
import { Access, AuthorizationWrapper } from '@features/authorization';
import { Trans } from '@lingui/macro';
import { Close, Help } from '@mui/icons-material';
import {
  Box,
  DialogTitle,
  Fab,
  FabOwnProps,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { type JSX, Suspense, type FC } from 'react';
import { useFormatDeviceType } from '@/hooks/useFormatDeviceType';
import GET_MODAL_TITLE from '@/graphql/querys/getModalTitle';

export interface DeviceModalTitleAction {
  key?: string | number;
  access?: Access;
  label: string;
  icon?: JSX.Element;
  color?: FabOwnProps['color'];
  onClick?: () => void;
}

interface Props {
  deviceId: string;
  onClose: () => void;
  actions?: Array<DeviceModalTitleAction>;
}

const Component: FC<Props> = ({ deviceId, onClose, actions }) => {
  const { data } = useSuspenseQuery(GET_MODAL_TITLE, {
    variables: {
      deviceId,
    },
  });

  const deviceType = useFormatDeviceType(deviceId);

  return (
    <DialogTitle
      sx={{
        backgroundColor: '#2a3542',
        display: 'flex',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <Close />
        </IconButton>
        <Box pl={2} flexGrow={1}>
          {data.device.profile.name}
          <Typography variant="subtitle2" color="text.secondary">
            ID: <span style={{ color: 'white' }}>{data.device.id}</span>
          </Typography>
        </Box>
      </Box>
      <Box flex={1}></Box>
      <Box display="flex" gap={2}>
        {actions?.map(({ key, label, access, icon = <Help />, color, onClick }) => (
          <Suspense
            key={key}
            fallback={
              <Skeleton variant="circular">
                <Fab size="medium" />
              </Skeleton>
            }
          >
            <AuthorizationWrapper access={access} fallback={null} deviceId={deviceId}>
              <Tooltip title={label}>
                <Fab onClick={onClick} size="medium" color={color}>
                  {icon}
                </Fab>
              </Tooltip>
            </AuthorizationWrapper>
          </Suspense>
        ))}
      </Box>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          <Trans>Tipo</Trans>: <span style={{ color: deviceType.color }}>{deviceType.text}</span>
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          <Trans>ORG</Trans>:{' '}
          <span style={{ color: 'white' }}>
            {data.device.profile.organization?.name ?? <Trans>Sin organización</Trans>}
          </span>
        </Typography>
      </Box>
    </DialogTitle>
  );
};

const Fallback: FC<Props> = ({ onClose: handleClose }) => {
  return (
    <DialogTitle
      sx={{
        backgroundColor: '#2a3542',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <Close />
        </IconButton>
        <Box pl={2} flexGrow={1}>
          <Skeleton width={200} />
          <Box display="flex" gap={1}>
            <Typography variant="subtitle2" color="text.secondary">
              ID:
            </Typography>
            <Skeleton>
              <Typography variant="subtitle2">XXXX</Typography>
            </Skeleton>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" gap={1}>
          <Typography variant="subtitle2" color="text.secondary">
            <Trans>Tipo:</Trans>
          </Typography>
          <Skeleton>
            <Typography variant="subtitle2">Tcore-L</Typography>
          </Skeleton>
        </Box>
        <Box display="flex" gap={1}>
          <Typography variant="subtitle2" color="text.secondary">
            <Trans>ORG:</Trans>
          </Typography>
          <Skeleton>
            <Typography variant="subtitle2">Captahydro</Typography>
          </Skeleton>
        </Box>
      </Box>
    </DialogTitle>
  );
};

const DeviceModalTitle: FC<Props> = (props) => {
  return (
    <Suspense fallback={<Fallback {...props} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default DeviceModalTitle;
