import { createSvgIcon } from '@mui/material';

const HousingFacilInstalar15mIcon = createSvgIcon(
  <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      d="M94.68,131.69c2.54,0.03,5.04,0.32,7.49,0.95c0.85,0.22,1.39,0.07,2-0.55c7.45-7.59,14.93-15.16,22.4-22.73
		c6.15-6.24,12.3-12.48,18.45-18.71c4.52-4.58,9.03-9.18,13.59-13.73c0.63-0.63,0.69-1.18,0.49-2c-2.79-11.35-0.42-21.52,7.32-30.27
		c5.59-6.31,12.73-9.78,21.14-10.59c5.83-0.56,11.39,0.43,16.7,2.86c1.72,0.79,2.48,2.06,2.36,3.79c-0.06,0.88-0.42,1.62-1.02,2.24
		c-0.7,0.71-1.41,1.42-2.12,2.12c-5.95,5.99-11.9,11.97-17.84,17.96c-0.23,0.23-0.44,0.46-0.66,0.7c-1.64,1.77-2.14,3.83-1.37,6.1
		c0.8,2.33,2.46,3.8,4.86,4.35c1.95,0.45,3.73,0.04,5.27-1.28c0.58-0.49,1.14-1.01,1.67-1.55c6.2-6.19,12.38-12.39,18.6-18.57
		c0.53-0.52,1.15-1.04,1.83-1.31c1.84-0.74,3.78,0.15,4.64,2.07c1.01,2.23,1.73,4.57,2.13,6.98c1.72,10.36-0.72,19.63-7.66,27.55
		c-5.52,6.3-12.56,9.88-20.91,10.81c-3.73,0.42-7.43,0.2-11.07-0.72c-0.84-0.21-1.37-0.03-1.98,0.59
		c-15.11,15.37-30.25,30.72-45.39,46.07c-2.98,3.02-5.93,6.06-8.95,9.04c-0.67,0.66-0.76,1.22-0.53,2.1
		c4.67,18.07-6.25,36.06-24.41,40.17c-7.05,1.6-13.9,0.83-20.51-2.11c-0.61-0.27-1.22-0.64-1.68-1.11
		c-1.29-1.31-1.32-3.37-0.13-4.81c0.28-0.34,0.61-0.64,0.92-0.95c6.48-6.53,12.97-13.05,19.46-19.57c0.95-0.95,1.73-2,2.02-3.33
		c0.72-3.25-1.47-6.71-4.77-7.55c-2.03-0.51-3.89-0.2-5.51,1.18c-0.64,0.54-1.26,1.11-1.85,1.7c-6.2,6.19-12.4,12.38-18.58,18.58
		c-0.83,0.83-1.73,1.4-2.94,1.39c-1.66-0.02-2.8-0.83-3.45-2.33c-1.05-2.39-1.81-4.88-2.2-7.46c-1.56-10.44,1.07-19.69,8.19-27.51
		c5.37-5.9,12.11-9.3,20.06-10.26C92.05,131.84,93.37,131.79,94.68,131.69z M89.08,188.88c0.52,0.14,0.77,0.24,1.04,0.28
		c2.26,0.37,4.51,0.52,6.81,0.3c6.23-0.61,11.61-3.07,15.85-7.64c7.05-7.6,8.81-16.43,5.51-26.24c-0.64-1.9-0.35-3.37,1.07-4.78
		c4.52-4.52,8.99-9.11,13.48-13.66c14.99-15.21,30-30.41,44.98-45.63c1.37-1.39,2.83-1.75,4.67-1.11c4.29,1.48,8.68,1.74,13.12,0.84
		c12.05-2.45,21.05-13.47,20.14-26.62c-0.06-0.82-0.21-1.63-0.36-2.72c-0.55,0.52-0.9,0.83-1.22,1.16
		c-4.56,4.55-9.11,9.11-13.67,13.66c-0.62,0.62-1.27,1.22-1.94,1.79c-2.97,2.5-6.38,3.6-10.24,3.1c-5.58-0.72-9.51-3.71-11.49-8.93
		c-1.96-5.16-0.92-9.91,2.82-14.01c1.02-1.12,2.1-2.19,3.17-3.27c4.14-4.17,8.29-8.34,12.44-12.51c0.26-0.26,0.49-0.56,0.8-0.92
		c-0.32-0.12-0.46-0.19-0.61-0.22c-4.08-0.81-8.12-0.62-12.09,0.58c-13.98,4.2-21.44,19.13-16.44,32.93
		c0.67,1.86,0.38,3.38-1.04,4.8c-5.56,5.58-11.07,11.21-16.61,16.82c-13.96,14.15-27.92,28.3-41.87,42.46
		c-1.36,1.39-2.81,1.71-4.67,1.14c-1.86-0.57-3.79-1.07-5.72-1.23c-7.72-0.65-14.49,1.66-20.01,7.12
		c-5.89,5.82-8.27,12.96-7.39,21.19c0.04,0.38,0.16,0.75,0.29,1.32c0.45-0.41,0.77-0.69,1.07-0.99c4.56-4.55,9.11-9.11,13.67-13.66
		c0.68-0.68,1.39-1.33,2.12-1.95c5.27-4.51,12.74-3.93,17.45,0.02c3.75,3.14,5.46,7.2,5.02,12.11c-0.29,3.16-1.68,5.81-3.88,8.04
		c-5.03,5.1-10.08,10.17-15.12,15.26C89.87,188.03,89.55,188.38,89.08,188.88z"
    />
    <path
      d="M193.24,209.58c-24.63,0.04-44.96-19.66-45.3-44.6c-0.35-25.68,20.25-45.57,44.57-45.94
		c24.76-0.37,45.95,19.44,45.96,45.26C238.48,189.62,218,209.58,193.24,209.58z M193.25,202.24c21-0.08,37.99-17.04,37.86-38.12
		c-0.13-21.63-17.95-38.09-38.62-37.71c-20.28,0.37-37.53,17-37.2,38.53C155.62,185.74,172.55,202.22,193.25,202.24z"
    />
    <path
      d="M150.26,254.08c-1.83,0-3.43,0-5.01,0c-0.28-0.86-0.4-20.2-0.13-22.79c1.62-0.15,3.26-0.03,4.99-0.06
		c0.04,0.9,0.08,1.64,0.12,2.41c0.44,0.22,0.76,0.18,1.05-0.22c1.85-2.57,4.5-2.81,7.31-2.43c1.66,0.22,2.97,1.13,3.97,2.47
		c0.14,0.19,0.25,0.41,0.4,0.6c0.45,0.58,0.79,0.58,1.23,0.02c0.17-0.22,0.3-0.47,0.47-0.69c1.12-1.49,2.61-2.36,4.48-2.44
		c1.07-0.05,2.18-0.08,3.22,0.14c3.25,0.68,5.42,3.24,5.69,6.57c0.04,0.52,0.06,1.04,0.06,1.56c0,4.52,0,9.04,0,13.55
		c0,0.39,0,0.79,0,1.28c-1.76,0-3.41,0-5.22,0c0-0.46,0-0.88,0-1.31c0-4.12,0-8.24,0-12.35c0-0.36,0-0.72,0-1.08
		c-0.06-2.32-1.09-3.62-3.16-4.02c-2.18-0.42-4.29,0.57-5.02,2.54c-0.35,0.95-0.51,2.02-0.52,3.04c-0.06,3.92-0.02,7.84-0.02,11.75
		c0,0.44,0,0.87,0,1.29c-0.89,0.28-3.57,0.34-5.13,0.11c-0.02-0.4-0.05-0.83-0.05-1.26c0-4.24,0-8.48,0-12.71c0-0.2,0-0.4,0-0.6
		c-0.03-2.5-1.12-3.87-3.36-4.2c-2.33-0.34-4.31,0.81-4.96,3c-0.25,0.83-0.35,1.73-0.36,2.59c-0.04,3.92-0.02,7.84-0.02,11.75
		C150.26,253.03,150.26,253.5,150.26,254.08z"
    />
    <path
      d="M126.63,221.88c0,1.48,0,2.98,0,4.6c-5.48,0-10.91,0-16.48,0c-0.11,2.97-0.02,5.83-0.06,8.67
		c0.56,0.32,0.88,0.13,1.23-0.22c2.85-2.88,6.3-2.85,9.82-1.84c3.53,1.01,5.65,3.52,6.46,7.07c0.5,2.21,0.5,4.46-0.07,6.66
		c-1.15,4.44-4.21,6.85-8.58,7.69c-2.59,0.5-5.18,0.37-7.71-0.38c-4.56-1.36-7.4-5.14-7.45-9.89c0-0.16,0.03-0.31,0.05-0.45
		c0.92-0.31,4.06-0.34,5.23-0.05c0.1,0.61,0.17,1.24,0.32,1.85c0.52,2.06,1.79,3.45,3.83,4.09c1.81,0.56,3.64,0.54,5.43-0.06
		c2.57-0.86,4.02-3.18,4.01-6.32c-0.01-3-1.45-5.17-4.03-6.03c-2.34-0.78-5.36-0.39-6.9,1.91c-0.17,0.25-0.57,0.47-0.87,0.48
		c-1.84,0.04-3.68,0.01-5.52,0c-0.11,0-0.22-0.07-0.4-0.14c0-5.85,0-11.72,0-17.57C105.81,221.63,125.27,221.56,126.63,221.88z"
    />
    <path
      d="M202.07,254.05c-1.73,0-3.35,0-4.97,0c-0.24-0.88-0.3-21.27-0.07-22.62c0.9-0.28,3.89-0.3,5.06,0
		c0,0.95,0,1.94,0,2.86c0.57,0.29,0.85,0.1,1.11-0.29c2.17-3.3,5.43-3.56,8.84-2.9c3.57,0.69,5.68,3.08,6.53,6.57
		c0.2,0.81,0.27,1.66,0.28,2.49c0.03,4.36,0.01,8.71,0.01,13.07c0,0.24-0.03,0.47-0.06,0.8c-1.68,0-3.33,0-5.11,0
		c-0.02-0.42-0.05-0.84-0.05-1.27c0-3.96,0.01-7.91-0.01-11.87c0-0.75-0.09-1.52-0.27-2.25c-0.43-1.69-1.56-2.79-3.25-3.18
		c-3.74-0.87-7.2,0.81-7.8,5.39c-0.11,0.87-0.13,1.75-0.14,2.63c-0.02,3.04,0,6.08-0.01,9.11
		C202.14,253.06,202.1,253.52,202.07,254.05z"
    />
    <path
      d="M93.22,225.64c-0.86-0.24-1.02,0.19-1.21,0.6c-0.62,1.34-1.23,2.69-1.85,4.03c-0.88,1.92-1.77,3.84-2.67,5.8
		c-1.82,0-3.6,0-5.5,0c0.15-0.39,0.25-0.73,0.4-1.04c1.95-4.11,3.93-8.21,5.86-12.34c0.33-0.71,0.71-1.03,1.52-1.01
		c2.52,0.05,5.04,0.02,7.55,0.02c0.35,0,0.7,0.05,1.12,0.08c0,10.77,0,21.46,0,32.33c-0.86,0-1.68,0.01-2.5,0.01
		c-0.84,0-1.67,0-2.65,0c-0.03-0.52-0.08-0.99-0.08-1.45c0-8.55,0-17.1,0-25.66C93.22,226.54,93.22,226.08,93.22,225.64z"
    />
    <path
      d="M189.95,254.06c-1.72,0-3.34,0-5.05,0c0-7.59,0-15.12,0-22.81c1.66-0.04,3.31-0.04,5.05,0.01
		C189.95,238.89,189.95,246.41,189.95,254.06z"
    />
    <path
      d="M190.84,224.81c-0.01,2.06-1.41,3.43-3.5,3.4c-2.02-0.03-3.48-1.51-3.44-3.51c0.04-1.92,1.52-3.34,3.49-3.35
		C189.45,221.34,190.85,222.75,190.84,224.81z"
    />
    <path
      d="M196.87,160.62c0.71,0,1.19,0,1.66,0c7.76,0,15.52,0,23.27,0.01c0.59,0,1.21,0.03,1.77,0.19
		c1.7,0.48,2.81,2.16,2.61,3.83c-0.23,1.89-1.65,3.23-3.51,3.32c-0.28,0.01-0.56,0-0.84,0c-9.32,0-18.64,0-27.95,0
		c-0.44,0-0.89,0.02-1.32-0.05c-1.54-0.23-2.75-1.49-2.96-3.05c-0.06-0.47-0.07-0.96-0.07-1.44c0-8.76,0-17.52,0-26.27
		c0-0.48,0-0.96,0.07-1.44c0.24-1.78,1.75-3.09,3.56-3.12c1.83-0.03,3.42,1.33,3.65,3.15c0.06,0.47,0.06,0.96,0.06,1.44
		c0,7.24,0,14.48,0,21.71C196.87,159.43,196.87,159.94,196.87,160.62z"
    />
    <path
      d="M166.48,159.87c1.76,0,3.52-0.03,5.28,0.01c1.59,0.04,2.74,0.83,3.34,2.3c0.97,2.38-0.71,4.94-3.31,5.02
		c-1.68,0.05-3.36,0.01-5.04,0.01c-1.84,0-3.68,0.05-5.52-0.01c-2.84-0.09-4.49-3.17-3.01-5.58c0.7-1.14,1.75-1.73,3.09-1.75
		C163.04,159.86,164.76,159.87,166.48,159.87z"
    />
    <path
      d="M196.86,191.87c0,1.76,0.04,3.52-0.01,5.27c-0.06,1.92-1.25,3.23-3.11,3.54c-1.93,0.32-3.81-1.01-4.12-2.94
		c-0.07-0.43-0.08-0.88-0.08-1.31c-0.01-3.04-0.02-6.07,0.01-9.11c0-0.63,0.05-1.29,0.25-1.88c0.56-1.65,2.17-2.54,4.01-2.31
		c1.61,0.2,2.93,1.53,3.02,3.23c0.09,1.83,0.02,3.67,0.02,5.51C196.84,191.87,196.85,191.87,196.86,191.87z"
    />
  </svg>,
  'HousingFacilInstalar15mIcon',
);

export default HousingFacilInstalar15mIcon;
