import {
  DayOfWeek,
  DgaFrequency,
  UpdateDgaScheduleMutationVariables,
} from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import NumericTextField from '@components/NumericTextField';
import ModalActions from '@components/modal/ModalActions';
import UPDATE_DGA_SCHEDULE from '@features/dga/graphql/mutations/updateDgaSchedule';
import GET_DGA_CONFIGURATION_SCHEDULE from '@features/dga/graphql/queries/getDGAConfigurationSchedule';
import { getUpdateDgaScheduleMutationSchema } from '@features/dga/utils/validationSchemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Autocomplete,
  Box,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from '@mui/material';
import { useEffect, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DGATabProps } from './tabProps';

type FormValues = UpdateDgaScheduleMutationVariables;

const SubmissionsTab: FC<DGATabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { i18n, _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { data } = useSuspenseQuery(GET_DGA_CONFIGURATION_SCHEDULE, {
    variables: { deviceInput: { id: deviceId } },
  });

  const defaultValues: FormValues = {
    deviceId,
    input: {
      enable: data.device.dgaConfiguration?.enabled ?? false,
      hour: data.device.dgaConfiguration?.hour ?? null,
      frequency: data.device.dgaConfiguration?.frequency ?? DgaFrequency.Monthly,
      weekday: data.device.dgaConfiguration?.weekday ?? DayOfWeek.Monday,
      day: data.device.dgaConfiguration?.day ?? 1,
    },
  };

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(getUpdateDgaScheduleMutationSchema(i18n)),
  });

  useEffect(() => {
    setIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  const [updateDgaSchedule, { loading }] = useMutation(UPDATE_DGA_SCHEDULE, {
    onError: (error) => {
      if (error?.message === 'unconfigured required values') {
        openModalErrorMessage(
          _(
            msg`No es posible activar porque faltan valores de configuración. Complete los valores en "Editar configuración DGA" y vuelva a intentarlo.`,
          ),
        );
      } else {
        openModalErrorMessage(error.message);
      }
    },
    onCompleted: (data) => {
      openModalSuccessMessage(_(msg`Recurrencia de envíos actualizada correctamente`));
      methods.reset({
        deviceId,
        input: {
          enable: data.updateDgaSchedule.dgaConfiguration?.enabled ?? false,
          hour: data.updateDgaSchedule.dgaConfiguration?.hour ?? null,
          frequency: data.updateDgaSchedule.dgaConfiguration?.frequency ?? DgaFrequency.Monthly,
          weekday: data.updateDgaSchedule.dgaConfiguration?.weekday ?? DayOfWeek.Monday,
          day: data.updateDgaSchedule.dgaConfiguration?.day ?? 1,
        },
      });
      setTimeout(() => {
        onClose();
      }, 100);
    },
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          __typename: 'Device',
          id: deviceId,
        }),
        fields: {
          dgaConfiguration() {
            return data.updateDgaSchedule.dgaConfiguration;
          },
        },
      });
    },
    onQueryUpdated(observableQuery) {
      if (observableQuery.queryName !== 'StandardAutocompleteData') {
        return observableQuery.refetch();
      }
    },
  });

  const resetForm = () => {
    methods.reset();
  };

  const onSubmit = (values: FormValues) => {
    if (values.input.enable === false) {
      values = {
        ...defaultValues,
        input: {
          ...defaultValues.input,
          enable: false,
        },
      };
    }

    const { deviceId, input } = values;

    updateDgaSchedule({
      variables: {
        deviceId,
        input,
      },
    });
  };

  const enableSchedule = methods.watch('input.enable');
  const frequency = methods.watch('input.frequency');

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent sx={{ height: 360 }}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Controller
            control={methods.control}
            name="input.enable"
            render={({ field: { value, ref, ...rest } }) => (
              <FormGroup>
                <FormControlLabel
                  {...rest}
                  checked={value}
                  inputRef={ref}
                  control={<Switch />}
                  label={_(msg`Habilitar envíos DGA`)}
                />
              </FormGroup>
            )}
          />

          {enableSchedule && (
            <>
              <Controller
                name="input.frequency"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    options={Object.values(DgaFrequency)}
                    disableClearable
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(value) => {
                      if (value === DgaFrequency.Daily) return _(msg`Diario`);
                      if (value === DgaFrequency.Weekly) return _(msg`Semanal`);
                      if (value === DgaFrequency.Monthly) return _(msg`Mensual`);
                      if (value === DgaFrequency.Hourly) return _(msg`Cada hora`);
                      return value;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={_(msg`Frecuencia`)}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
              {frequency === DgaFrequency.Weekly && (
                <Controller
                  name="input.weekday"
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      options={[
                        DayOfWeek.Monday,
                        DayOfWeek.Tuesday,
                        DayOfWeek.Wednesday,
                        DayOfWeek.Thursday,
                        DayOfWeek.Friday,
                        DayOfWeek.Saturday,
                        DayOfWeek.Sunday,
                      ]}
                      disableClearable
                      value={field.value || undefined}
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                      getOptionLabel={(value) => {
                        if (value === DayOfWeek.Monday) return _(msg`Lunes`);
                        if (value === DayOfWeek.Tuesday) return _(msg`Martes`);
                        if (value === DayOfWeek.Wednesday) return _(msg`Miércoles`);
                        if (value === DayOfWeek.Thursday) return _(msg`Jueves`);
                        if (value === DayOfWeek.Friday) return _(msg`Viernes`);
                        if (value === DayOfWeek.Saturday) return _(msg`Sábado`);
                        if (value === DayOfWeek.Sunday) return _(msg`Domingo`);
                        return value;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={_(msg`Día de la semana`)}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              )}
              {frequency === DgaFrequency.Monthly && (
                <Controller
                  control={methods.control}
                  name="input.day"
                  render={({ field, fieldState }) => (
                    <NumericTextField
                      {...field}
                      label={_(msg`Día del mes`)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      inputMode="numeric"
                      value={Number.isNaN(field.value) ? '' : field.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '') field.onChange(NaN);
                        else field.onChange(value);
                      }}
                    />
                  )}
                />
              )}
              {frequency !== DgaFrequency.Hourly && (
                <Controller
                  control={methods.control}
                  name="input.hour"
                  render={({ field, fieldState }) => (
                    <NumericTextField
                      {...field}
                      label={_(msg`Hora`)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={Number.isNaN(field.value) ? '' : field.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '') field.onChange(NaN);
                        else field.onChange(value);
                      }}
                    />
                  )}
                />
              )}
            </>
          )}
        </Box>
      </DialogContent>
      <Divider />
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        onResetForm={resetForm}
        dirtyForm={methods.formState.isDirty}
        submitLoading={loading}
      />
    </form>
  );
};

export default SubmissionsTab;
