import { gql } from 'src/__generated__';

const DISABLE_DEVICE_ALERTS = gql(`#graphql
  mutation DisableDeviceAlerts($deviceId: ID!) {
    disableAlertConfiguration(input: { deviceId: $deviceId }) {
      id
    }
  }
`);
export default DISABLE_DEVICE_ALERTS;
