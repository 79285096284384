import { useMutation, useSuspenseQuery } from '@apollo/client';
import { DeviceTabProps } from '@features/deviceDataConfigurationModal/components/tabs/types';
import UPDATE_DEVICE_CALIBRATION_POSITION_FROM_ANGLE from '@features/deviceDataConfigurationModal/graphql/mutations/updateDeviceCalibrationPositionFromAngle';
import GET_DEVICE_POSITION_FROM_ANGLE_CALIBRATION from '@features/deviceDataConfigurationModal/graphql/queries/getDevicePositionFromAngleCalibration';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { useEffect, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { UpdateDeviceCalibrationPositionFromAngleInput } from 'src/__generated__/graphql';
import { useModalStatusMessage } from 'src/hooks';
import { z } from 'zod';
import NumberController from './NumberController';
import PositionFromAngleFormula from './PositionFromAngleFormula';

const schema = () => {
  return z.object({
    deviceId: z.string(),
    positionFromAngle: z.object({
      K: z.coerce.number(),
      R: z.coerce.number(),
      P: z.coerce.number(),
    }),
  }) satisfies z.ZodType<UpdateDeviceCalibrationPositionFromAngleInput>;
};

type FormValues = z.infer<ReturnType<typeof schema>>;

const PositionFromAngleCalibrationForm: FC<DeviceTabProps> = ({
  deviceId,
  onClose,
  setIsDirty,
}) => {
  const { data } = useSuspenseQuery(GET_DEVICE_POSITION_FROM_ANGLE_CALIBRATION, {
    variables: { deviceId },
  });
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();

  const defaultValues = {
    deviceId: deviceId,
    positionFromAngle: {
      K: data.device.dataConfiguration.calibration?.positionFromAngle?.K ?? undefined,
      R: data.device.dataConfiguration.calibration?.positionFromAngle?.R ?? undefined,
      P: data.device.dataConfiguration.calibration?.positionFromAngle?.P ?? undefined,
    },
  };

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(schema()),
  });

  useEffect(() => setIsDirty(methods.formState.isDirty), [methods.formState.isDirty, setIsDirty]);

  const [update, { loading: updateLoading }] = useMutation(
    UPDATE_DEVICE_CALIBRATION_POSITION_FROM_ANGLE,
    {
      onCompleted: (data) => {
        openModalSuccessMessage(_(msg`Datos actualizados correctamente`));
        methods.reset({
          deviceId,
          positionFromAngle: {
            K: data.updateDeviceCalibrationPositionFromAngle.calibration?.positionFromAngle?.K,
            R: data.updateDeviceCalibrationPositionFromAngle.calibration?.positionFromAngle?.R,
            P: data.updateDeviceCalibrationPositionFromAngle.calibration?.positionFromAngle?.P,
          },
        });
      },
      onError: (errors) => {
        openModalErrorMessage(_(msg`Error al actualizar los datos: ${errors.message}`));
      },
    },
  );

  if (!data.device.profile.sensor?.includes('G')) return null;

  const { isDirty, isSubmitting } = methods.formState;

  const submitHandler = async (formData: FormValues) => {
    update({ variables: { input: formData } });
  };

  return (
    <form onSubmit={methods.handleSubmit(submitHandler)}>
      <DialogContent
        sx={{
          maxWidth: 'md',
          minHeight: 500,
          margin: 'auto',
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <Typography variant="h5" mb={2}>
          <Trans>Calibración posición</Trans>
        </Typography>
        <Box display="flex" gap={2}>
          <NumberController
            control={methods.control}
            name="positionFromAngle.K"
            label="K"
            endAdornment="°"
          />
          <NumberController
            control={methods.control}
            name="positionFromAngle.R"
            label="R"
            endAdornment="cm"
          />
          <NumberController
            control={methods.control}
            name="positionFromAngle.P"
            label="P"
            endAdornment="cm"
          />
        </Box>
        <PositionFromAngleFormula />
      </DialogContent>
      <DialogActions sx={{ maxWidth: 'md', margin: 'auto', py: 2 }}>
        <Button
          disabled={!isDirty}
          color="info"
          variant="outlined"
          onClick={() => methods.reset(defaultValues)}
        >
          <Trans>Restablecer Formulario</Trans>
        </Button>
        <Box flexGrow={1} />
        <Button color="info" variant="outlined" onClick={() => onClose()}>
          <Trans>Cerrar</Trans>
        </Button>
        <LoadingButton
          disabled={!isDirty}
          loading={isSubmitting || updateLoading}
          type="submit"
          variant="contained"
        >
          <Trans>Guardar</Trans>
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default PositionFromAngleCalibrationForm;
